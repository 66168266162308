import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import helpers from "../../services/helpers";
import MediaSubdetails from "../common/MediaSubdetails";
import { Link, useNavigate } from "react-router-dom";
import LikeMovie from "../common/LikeMovie";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Modal, Stack, Typography } from "@mui/material";
import { getCinescoreData } from "../../slices/cinescore";
// import NewBadgeIcon from "../../assets/NewBadgeIcon.svg"
import { toggleLoginModal } from "../../slices/global";
import { HELPER_TEXT } from "../../lang/HelperText";
// import AddFriends from '../../assets/icons/add-friends.png'
import { mediaIntermediateData } from "../../slices/media";
import InviteUsers from "../common/InviteUsers";

const NewBadgeIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/NewBadgeIcon.svg";
const AddFriends = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/add-friends.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: "90%", sm: "760px" },
  bgcolor: "#16213E",
  boxShadow: 24,
  borderRadius: "7px",
  maxHeight: "95vh",
  overflowY: "auto",
};

export const HandpickSliderNew = (props) => {
  const { data, isShowTag } = props;
  const [removedId, setRemovedId] = useState('');
  const _global = useSelector((state) => state.global);
  const cinescore = useSelector((state) => state.cinescore);
  const [freindsData, setFreindsData] = useState({});
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open1, setOpen1] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePaperClick = (e) => {
    helpers.mediaClick(data?.title, data.media ? data.media : data.uuid ? data.uuid : '', 'Handpicked');
    if (e.target.className.includes('mylayer')) {
      navigate(helpers.getMediaLink(data));
    } else {
      e.preventDefault();
      return false;
    }
  }

  const handleAddFreind = () => {
    if (global.is_public) {
      dispatch(
        toggleLoginModal({
          isOpen: true,
          content: HELPER_TEXT.NUDGE_TEXT.ADD_FRIENDS,
        })
      );
    } else {
      handleOpen1();
    }
  };

  useEffect(() => {
    if (!_global.is_public) {
      getData();
    }
  }, [_global.is_public, data.uuid]);

  const getData = () => {
    dispatch(getCinescoreData());

    if (data.uuid && data.uuid !== "") {
      dispatch(
        mediaIntermediateData({
          tmdb_id: data.uuid,
          media_type: data.media_type,
        })
      ).then((resp) => {
        if (resp.payload && resp.payload.status) {
          setFreindsData(resp.payload.data);
        }
      });
    }
  }

  console.log('aman', freindsData.friends)

  return (
    <div className="w-100">
      {/* <Link onClick={() => helpers.mediaClick(data?.title, data.media ? data.media : data.uuid ? data.uuid : '', 'Handpicked')} to={helpers.getMediaLink(data)}> */}

        <Paper
          sx={{
            // maxWidth: { xs: '286px', sm: '481px', md: '781px', lg: '1020px' },
            backgroundImage: {
              xs: `url(${data.poster ? helpers.getFullUrlOriginal(data.poster, 500) : helpers.getDefaultImage('poster')
                })`,
              sm: `url(${(data.backdrop || data.poster) ? helpers.getFullUrlOriginal(data.backdrop || data.poster, 780) : helpers.getDefaultImage('backdrop')
                })`,
              lg: `url(${(data.backdrop || data.poster) ? helpers.getFullUrlOriginal(data.backdrop || data.poster, 1280) : helpers.getDefaultImage('backdrop')
                })`,
              xl: `url(${(data.backdrop || data.poster) ? helpers.getFullUrlOriginal(data.backdrop || data.poster) : helpers.getDefaultImage('backdrop')
                })`
            },
            backgroundSize: '100%',
            backgroundPosition: 'top',
            minWidth: '100%',
            height: '100vh',
            maxHeight: { xs: '75vh', sm: '440px', md: '500px', lg: '720px', xl: '80vh' }
          }}
          className="movie-card w-100 pointer"
        >
          <div className="h-100 relative" style={{ background: 'linear-gradient(rgba(0, 0, 0, 0) 14.51%, rgba(26, 26, 46, 1) 100%)' }}>
            <div className="mylayer absolute"
              style={{ width: '100%', height: '100%', top: 0, bottom: 0, left: 0, right: 0, zIndex: 0 }}
              onClick={(e) => handlePaperClick(e)}></div>
            <div className="app-container-new">
              <Grid container sx={{ paddingBottom: { xs: '30px', sm: '30px' }, position: 'absolute', bottom: 0, left: { lg: 112 }, zIndex: 111, flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                <Grid item xs={8} md={8}>
                  {/* <div style={{ paddingTop: "15px", paddingRight: '50px' }} className='relative no-absolute'>
                  <Grid container flexWrap={'nowrap'} alignItems='center' >
                    {title}
                    <div style={{ paddingLeft: '10px' }}>
                    <BookmarkAction
                      size={52}
                      data={{ ...data, media: data.media ? data.media : data?.uuid }}
                      addedId={addedId} setAddedId={setAddeddId}
                      handlePlay={props?.handlePlay}
                      handleStop={props?.handleStop}
                    />
                  </div>
                  </Grid>
                </div> */}
                  {_global.is_public ?
                    ''
                    :
                    <div className="relative" style={{ paddingTop: '150px' }}>
                      <div className="row">
                        <div>
                          <LikeMovie
                            removedId={removedId}
                            setRemovedId={(id) => {
                              setRemovedId(id);
                              props?.setRemovedIds && props?.setRemovedIds(id);
                            }}
                            isDislike={true}
                            data={data}
                            hideLabel={true}
                            handlePlay={props?.handlePlay}
                            handleStop={props?.handleStop}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <Link onClick={() => helpers.mediaClick(data?.title, data.media ? data.media : data.uuid ? data.uuid : '', 'Handpicked')} to={helpers.getMediaLink(data)}>
                    <MediaSubdetails data={data} className="mw-450 fs-16" />
                    <h1 style={{ paddingBottom: '10px' }} className="fs-40 m-0 pr-40 ellipsis shadow text-left fw-600 lh-48">
                      {data?.title}
                    </h1>
                  </Link>
                  {_global.is_public ? '' :
                    <Grid item display={'flex'} alignItems="center">
                      {data.friends_data && data.friends_data.length > 0 && (
                        <Stack
                          direction="row"
                          sx={{ marginRight: "10px" }}
                          className="ai-center"
                        >
                          {data.friends_data.map((item, j) => {
                            if (j < 3) {
                              return (
                                <Avatar
                                  key={j}
                                  sx={{
                                    width: 36,
                                    height: 36,
                                    maxWidth: { xs: 24, sm: 36 },
                                    maxHeight: { xs: 24, sm: 36 },
                                  }}
                                  alt="Remy Sharp"
                                  src={
                                    item.profile_image
                                      ? helpers.getFullUrl(item.profile_image)
                                      : helpers.getDefaultImage(
                                        "halfsmallprofile"
                                      )
                                  }
                                  className="ott-avatar"
                                />
                              );
                            }
                          })}
                        </Stack>
                      )}
                      {!freindsData.friends ? (
                        <Box
                          onClick={() => handleAddFreind()}
                          component={"img"}
                          className="d-block pointer"
                          src={AddFriends}
                          sx={{
                            maxWidth: "35px",
                            maxHeight: "35px",
                            marginRight: "7px",
                          }}
                          alt="add"
                        />
                      ) : (
                        ""
                      )}
                      <p
                        onClick={() => handleAddFreind()}
                        className={`fw-600 m-0 p-0 fs-18 ${global.is_public ? "pointer" : ""
                          }`}
                      >
                        {helpers.setUpFaceRating(
                          freindsData.rating,
                          freindsData.friends,
                          data?.friends_rated > data?.friends_data
                            ? data?.friends_rated - data?.friends_data.length
                            : 0,
                          data?.media_type
                        )}
                        <br />
                      </p>

                      {freindsData.friends && freindsData.rating !== 0 ? (
                        <p
                          onClick={handleOpen}
                          className="fw-600 fs-16 text-left ask-friends text-selected pointer mt-0 mb-0"
                          style={{ marginLeft: '30px' }}
                        >
                          ask friends
                        </p>
                      ) : (
                        ""
                      )}

                    </Grid>
                  }
                </Grid>
                {_global.is_public ? '' :
                  <Grid item xs={4} md={4} sx={{ mt: '200px' }} >
                    <Link to="/mycinescore" className="w-100" >
                      <Grid item xs={7} md={3.5} className="p-0 h-100"
                        sx={{
                          borderRadius: '5px',
                          backgroundColor: '#E94560',
                          maxHeight: { sm: '55px', xs: '30px' },
                          minHeight:{xs:'30px'}
                        }}
                      >
                        {/* <Grid container className="p-10" sx={{ background: 'linear-gradient(to top, transparent 35.51%, rgba(255, 0, 128, 1) 100%)', borderRadius: '7px' }} justifyContent={'center'}> */}
                        <Grid container alignItems={'center'} className="h-100" justifyContent={'center'} sx={{ borderRadius: '5px' }} flexWrap="nowrap">
                          <Box src={NewBadgeIcon} component="img" sx={{ maxHeight: { xs: '15px', sm: '25px' }, paddingRight: '3px' }} />
                          <Typography sx={{ fontSize: { xs: '14px', sm: '24px' } }} className="fw-600 text-light pl-10">
                            {" "}
                            {(cinescore.cinescoreResults &&
                              cinescore.cinescoreResults.total_watched_tv
                              ? cinescore.cinescoreResults.total_watched_tv
                              : 0) +
                              (cinescore.cinescoreResults &&
                                cinescore.cinescoreResults.total_watched_movie
                                ? cinescore.cinescoreResults.total_watched_movie
                                : 0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </Grid>
                }

                {/* <div className="relative row" style={{ paddingRight: 100, paddingTop: 13 }}>
                <p className="fw-500 m-0 fs-24">
                  {data?.release_year ? data.release_year : ''} <span>|</span><span> {data?.media_type.toUpperCase()} </span>
                  <span>|</span>
                  <span> {data.data && data.data[0]?.ott_name}</span>
                </p>
              </div> */}
              </Grid>
            </div>
          </div>
          {isShowTag && data?.tag_key && data?.tag_key != "" &&
            <div className="tag-container">
              <div className="relative pr-20 h-100">
                <p className=" m-0 text-right fs-16 pb-2 fw-600">{data?.tag_key}</p>
              </div>
            </div>
          }
        </Paper>
      {/* </Link> */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <InviteUsers onClose={handleClose1} />
        </Box>
      </Modal>
    </div >
  );
};
