import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ActivityDataService from "../services/activity.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    activityHomeData: {},
    activityNewsPageData: {},
    activityNewsData: [],
    pollPageData: {},
    pollData: [],
    quizPageData: {},
    quizData: [],
    haveYouWatchedPageData: {},
    haveYouWatchedData: [],
    haveYouWatchedCalled: false,
    pollResponseData: {},
    quizResponseData: {}
};


export const getActivityHomeData = createAsyncThunk(
    "native_main/activitybirthday",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.getActivityHomeData()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getActivityNews = createAsyncThunk(
    "native_main/trendingnewsdata",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.getActivityNews(data.page, data.pagelimit, data?.is_blog)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPollList = createAsyncThunk(
    "native_main/userpolllist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.getPollList(data.page, data?.is_public, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getQuizList = createAsyncThunk(
    "native_main/userquizlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.getQuizList(data.page, data?.is_public, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const addPollResponse = createAsyncThunk(
    "native_main/userpolllistadd",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.addPollResponse(data?.id, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const startQuiz = createAsyncThunk(
    "native_main/startquiz",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.startQuiz(data.id)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addQuizResponse = createAsyncThunk(
    "native_main/userquizlistadd",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.addQuizResponse(data?.id, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const haveYouWatched = createAsyncThunk(
    "native_main/haveyouwatched",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.haveYouWatched(data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addActivityBirthday = createAsyncThunk(
    "native_main/addactivitybirthday",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ActivityDataService.addActivityBirthday(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const activitySlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        [getActivityHomeData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getActivityHomeData.fulfilled]: (state, action) => {
            state.activityHomeData = getPayloadData(state, action);
        },
        [getActivityHomeData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getActivityNews
        [getActivityNews.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getActivityNews.fulfilled]: (state, action) => {
            state.activityNewsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.activityNewsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.activityNewsData = helpers.removeDuplicate(state.activityNewsData.concat(getArrayData(state, action)));
            }
        },
        [getActivityNews.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPollList
        [getPollList.pending]: (state, action) => {
            state.pollResponseData = {};
            handlePendingRequest(state, action);
        },
        [getPollList.fulfilled]: (state, action) => {
            state.pollPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.pollData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.pollData = helpers.removeDuplicate(state.pollData.concat(getArrayData(state, action)));
            }
        },
        [getPollList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getQuizList
        [getQuizList.pending]: (state, action) => {
            state.quizResponseData = {};
            handlePendingRequest(state, action);
        },
        [getQuizList.fulfilled]: (state, action) => {
            state.quizPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination) {
                if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                    state.quizData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
                } else {
                    state.quizData = helpers.removeDuplicate(state.quizData.concat(getArrayData(state, action)));
                }
            } else {
                state.quizData = action.payload.data.results;
            }
        },
        [getQuizList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addPollResponse
        [addPollResponse.pending]: (state, action) => {
            state.pollResponseData = {};
            handlePendingRequest(state, action);
        },
        [addPollResponse.fulfilled]: (state, action) => {
            state.pollResponseData = getPayloadData(state, action);
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addPollResponse.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // startQuiz
        [startQuiz.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [startQuiz.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [startQuiz.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addQuizResponse
        [addQuizResponse.pending]: (state, action) => {
            state.quizResponseData = {};
            handlePendingRequest(state, action);
        },
        [addQuizResponse.fulfilled]: (state, action) => {
            state.quizResponseData = getPayloadData(state, action);
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addQuizResponse.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // haveYouWatched
        [haveYouWatched.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [haveYouWatched.fulfilled]: (state, action) => {
            state.haveYouWatchedPageData = getPayloadData(state, action);
            state.haveYouWatchedCalled = true;
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.haveYouWatchedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.haveYouWatchedData = helpers.removeDuplicate(state.haveYouWatchedData.concat(getArrayData(state, action)));
            }
        },
        [haveYouWatched.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addActivityBirthday
        [addActivityBirthday.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addActivityBirthday.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addActivityBirthday.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    },
});
export const { clearMessages } = activitySlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default activitySlice.reducer;