import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import helpers from '../../services/helpers';
import { getSimilarMediaData } from '../../slices/media';
import CommonMediumTileListing from '../common/CommonMediumTileListing';
import SingleMediaTile from '../common/SingleMediaTile';

export default function SimilarMovies(props) {
    const [similarData, setSimilarData] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const { uuid, title, type, size } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if (uuid && uuid !== '') {
            getData(uuid);
        }
    }, [uuid])

    const getData = (uuid) => {
        dispatch(getSimilarMediaData({ id: uuid })).then((resp) => {
            if (resp && resp.payload && resp.payload.data) {
                setPaginationData({ ...resp.payload.data.pagination });
                if (resp.payload.data.results.length > 0) {
                    if (resp.payload?.data?.pagination && resp.payload.data.pagination?.page === 1) {
                        setSimilarData([...resp.payload.data.results]);
                    } else {
                        setSimilarData(helpers.removeDuplicate([...similarData, ...resp.payload.data.results]));
                    }
                }
            }
        });
    }


    return (
        <div>
            {type === 'grid' ?
                <>
                    {similarData && similarData.length > 0 && similarData.map((x, i) => {
                        return <Grid item key={i}>
                            <SingleMediaTile x={x} size={size} refPage={'Media Detail'} />
                        </Grid>
                    })}
                </>
                :
                <>
                    {similarData.length > 0 && (
                        <CommonMediumTileListing
                            refPage={'Media Detail'}
                            isShowHeading={true}
                            heading={
                                <>
                                    more like{" "}
                                    <span className="text-selected">{`${title}`}</span>
                                </>
                            }
                            data={similarData}
                        />
                    )
                    }
                </>
            }
        </div>
    )
}
