import {
  Grid,
  Paper,
  IconButton,
  InputBase,
  Button,
  CircularProgress
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from 'react-redux';
import InviteWith from "../common/InviteWith";
import { getCinephilesUserDetails, getFrenziUsers } from "../../slices/connect";
import helpers from "../../services/helpers";
import FollowUnfollowPerson from "../common/FollowUnfollowPerson";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CloseIcon from "@mui/icons-material/Close";
import { getFriendsList } from "../../slices/common";

export default function InviteUsers(props) {
  const { heading } = props;
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const connect = useSelector(state => state.connect);

  const { cinephilesUserData, cinephilesUserPageData, frenziUserPageData } = connect;


  useEffect(() => {
    if (value && value !== "") {
      if (props?.type === "friends") {
        dispatch(getFriendsList({ query: value, page: 1, pagelimit: 50 }));
      } else {
        dispatch(getFrenziUsers({ query: value, page: 1, pagelimit: 50 }));
      }
    }
  }, [value, dispatch])

  useEffect(() => {
    dispatch(getCinephilesUserDetails({ page: 1, pagelimit: 18 }));
  }, [])

  const handleSearchTextChange = (e) => {
    setValue(e.target.value);
  };

  const _global = useSelector((state) => state.global);


  return (
    <>
      <Grid container className="bg-secondary jc-center br-8">
        <CloseIcon onClick={props.onClose}
          className='ml-3 pt-2 pointer absolute animate-close'
          color='light'
          style={{
            top: '10px',
            right: '10px'
          }}
        />
        <Grid item xs={10}>
          <h2 className="fw-700 fs-28 m-0 text-left pt-10">
            {heading}
          </h2>
        </Grid>
        <Grid item xs={12} style={{ position: 'sticky', top: 0, zIndex: 99 }} className="bg-violet mt-24 pt-20 pb-20 mb-24">
          <Grid container className="jc-center">
            <Grid item xs={10}>
              <Grid item className="mb-12">
                <h2 className="text-left fw-500 fs-16 m-0">
                  invite with
                </h2>
              </Grid>
              <InviteWith />
            </Grid>
          </Grid>
        </Grid>
        {!_global.is_public &&
          <Grid item xs={12} className="pt-0 pb-20">
            <Grid container className="jc-center">
              <Grid item xs={10}>
                <h2 className='fw-700 fs-20 lh-20 mb-0'>{props?.heading || <>find friends on <span className='text-selected'>frenzi</span></>}</h2>
                <h2 className='fw-300 fs-14 lh-14 text-muted m-0'>you can select multiple contacts to {props?.category || 'follow'}</h2>
                <Grid className='relative pb-10'>
                  <Paper
                    style={{ opacity: 1 }}
                    className='w-100 d-flex ai-center br-25 bg-light mb-5 mt-20'

                  >
                    <IconButton aria-label="search" className=' h-100 bg-selected' sx={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px', }}>
                      <SearchIcon className='ml-3 pt-2' color="light" />
                    </IconButton>
                    <InputBase
                      className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500  text-light'
                      value={value}
                      type='text'
                      onChange={handleSearchTextChange}
                      placeholder='search user'
                    />

                    <IconButton aria-label="search" onClick={() => setValue('')} className='h-100 bg-muted' sx={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                      <CloseIcon className='ml-3 pt-2' color="light" />
                    </IconButton>
                  </Paper>
                </Grid>
                {frenziUserPageData && frenziUserPageData.results && frenziUserPageData.results.length > 0 ?
                  <div className="mt-10 mh-250" style={{ overflowY: 'auto' }}>
                    <Grid container spacing={2}>
                      {frenziUserPageData.results.map((x, i) => {
                        return <Grid item xs={4} sm={3} md={3} className='text-center pointer'>
                          <Grid item className='jc-center'>
                            <Box
                              component="img"
                              className='d-inline-block'
                              sx={{
                                height: 80,
                                width: 80,
                                maxHeight: { xs: 60, sm: 80 },
                                maxWidth: { xs: 60, sm: 80 },
                                borderRadius: '50%',
                                objectFit:'cover'
                              }}
                              alt={x?.username || 'icon'}
                              src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('person')}
                            />
                            <p title={x?.username} className='fs-10 ellipsis fw-600 text-center pt-5 pb-12 m-0'>{x.username ? x.username : ''}</p>
                            {props?.category !== 'invite' &&
                              <FollowUnfollowPerson data={x} />
                            }
                          </Grid>
                        </Grid>
                      })}
                    </Grid>
                  </div>
                  :
                  <div className="mt-10 mh-250" style={{ overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                      {cinephilesUserData && cinephilesUserData.length > 0 && cinephilesUserData.map((x, i) => {
                        return <Grid item xs={4} sm={3} md={3} className='text-center pointer' sx={{ boxSizing: 'border-box' }} >
                          {/* <Link to={`/profile/${x.uuid}`}> */}
                          <Box
                            component="img"
                            className='d-inline-block'
                            sx={{
                              height: 80,
                              width: 80,
                              maxHeight: { xs: 60, sm: 80 },
                              maxWidth: { xs: 60, sm: 80 },
                              borderRadius: '50%',
                              objectFit: 'cover'
                            }}
                            alt={x?.username || 'icon'}
                            src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('person')}
                          />
                          {/* </Link> */}
                          <h2 title={x?.username} className='fs-12 ellipsis fw-600 text-center pt-5 m-0'>{x.username ? x.username : ''}</h2>
                          <h2 title={x?.unique_name} className='fs-10 ellipsis text-muted fw-400 text-center pb-12 m-0'>{x?.unique_name || ''}</h2>
                          {props?.category !== 'invite' &&
                            <FollowUnfollowPerson data={x} />
                          }
                        </Grid>
                      })}
                    </Grid>
                    {cinephilesUserPageData?.pagination && cinephilesUserPageData?.pagination.is_next_page &&
                      <div className='text-center mt-20'>
                        {connect.isLoading ?
                          <CircularProgress size={20} color="success" />
                          :
                          <RefreshOutlinedIcon onClick={() => {
                            if (cinephilesUserPageData?.pagination && cinephilesUserPageData?.pagination.is_next_page) {
                              dispatch(getCinephilesUserDetails({ page: cinephilesUserPageData?.pagination.page + 1, pagelimit: 18 }));
                            }
                          }} className='pointer' />
                        }
                      </div>
                    }
                  </div>
                }
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid item xs={12}>
          <Paper elevation={9} style={{ borderRadius: "00px 00px 10px 10px" }}>
            <Grid
              container
              className="jc-center bg-secondary pt-20 pb-40 "
              style={{ borderRadius: "00px 00px 10px 10px" }}
            >
              <Grid item xs={6} className="text-center">
                <Button onClick={props.onClose} className="rounded-btn bg-selected text-light fw-500 fs-14 mb-12">
                  {props?.category || 'continue'}
                </Button>
                <Button onClick={props.onClose} className="rounded-btn bg-light text-selected fw-500 fs-14">
                  cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
