import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import MyHelmet from './MyHelmet';

export default function News(props) {
    const locationData = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='bg-light'>
        <MyHelmet
            title = {""}
        />
            {locationData && locationData.state && locationData.state.link && locationData.state.link !== "" &&
                <iframe width={'100%'} height={'100%'} style={{ minHeight: '100vh', border: 'none' }} src={locationData.state.link}>
                </iframe>
            }
        </div>
    )
}
