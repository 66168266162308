import { Box, Grid, Popover } from "@mui/material";
import React, { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import helpers from "../../services/helpers";

const defaultAnchorOrigin = {
  vertical: "bottom",
  horizontal: "center",
}

const defaultTransformOrigin = {
  vertical: "top",
  horizontal: "center",
}

export default function ShareMovie(props) {
  const { height, width, size, myComponent, customComponent, hideShare } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
    props.onClick && props?.onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        container
        onClick={handleClickListItem}
        className={`${myComponent ? 'pointer ai-center' : ''} ${props.className}`}
        justifyContent={myComponent ? props?.justifyContent : "center"}
      >
        {myComponent ?
          customComponent
          :
          <Box
            className={`pointer icon-container ${props.className}`}
            sx={{
              maxHeight: height ? height : 40,
              maxWidth: width ? width : 40,
              width: "100%",
              height: "100%",
              padding: "8px",
            }}
          >
            <ShareIcon style={{ padding: '3px' }} className={`pointer scale-1 fs-${size ? size : 40}`} />
          </Box>
        }
      </Grid>
      {!hideShare &&
        <span
          onClick={handleClickListItem}
          className="d-block text-center pointer fs-14 fw-600"
        >
          Share
        </span>
      }
      <Popover
        id={"menbud"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={props.anchorOrigin ? props.anchorOrigin : defaultAnchorOrigin}
        transformOrigin={props.transformOrigin ? props.transformOrigin : defaultTransformOrigin}
      >
        <Grid container style={{ padding: '14px' }} >
          <Grid item>
            <Grid style={{ paddingBottom: '7px' }} container alignItems='center'>
              <FacebookShareButton style={{ display: 'flex', alignItems: 'center' }} url={props.shareUrl ? props.shareUrl : window.location.href} >
                <FacebookIcon size={32} round={true} />
                {/* <FacebookShareCount url={props.shareUrl ? props.shareUrl : window.location.href} /> */}
                <span style={{ marginLeft: '10px', marginTop: '-5px' }} className="p-0 mb-0 fs-14 lh-14">share on facebook</span>
              </FacebookShareButton>
            </Grid>
            <Grid style={{ paddingTop: '7px', paddingBottom: '7px' }} container alignItems='center'>
              <WhatsappShareButton style={{ display: 'flex', alignItems: 'center' }} url={props.shareUrl ? props.shareUrl : window.location.href} >
                <WhatsappIcon size={32} round={true} />
                <span style={{ marginLeft: '10px', marginTop: '-5px' }} className="p-0 mb-0 fs-14 lh-14">share on whatsapp</span>
              </WhatsappShareButton>
            </Grid>
            <Grid style={{ paddingTop: '7px', paddingBottom: '7px' }} container alignItems='center'>
              <TwitterShareButton style={{ display: 'flex', alignItems: 'center' }} url={props.shareUrl ? props.shareUrl : window.location.href} >
                <TwitterIcon size={32} round={true} />
                <span style={{ marginLeft: '10px', marginTop: '-5px' }} className="p-0 mb-0 fs-14 lh-14">share on twitter</span>
              </TwitterShareButton>
            </Grid>
            <Grid style={{ paddingTop: '7px' }} container alignItems='center'>
              <CopyToClipboard text={props.shareUrl ? props.shareUrl : window.location.href}
                onCopy={() => helpers.toastSuccess('copied')}>
                <span className="d-flex ai-center pointer">
                  <ContentCopyRoundedIcon className="copy-share-icon" />
                  <span style={{ marginLeft: '10px', marginTop: '-5px' }} className="p-0 mb-0 fs-14 lh-14">copy link</span>
                </span>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
