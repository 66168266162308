import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import helpers from '../../services/helpers';
import { addBlogReply, deleteCommentReply, getRepliesList, likeCommentReply } from '../../slices/blog';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import { Button, Grid, TextareaAutosize } from '@mui/material';
import { Box } from '@mui/system';
import { toggleLoginModal } from '../../slices/global';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import MediaOverView from '../common/MediaOverView';

export default function BlogReplies(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [repliesList, setRepliesList] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [reply, setReply] = useState('');
    const [isCurrentLoading, setIsCurrentLoading] = useState(false);
    const { commentId, replyId, commentData } = props;
    const dispatch = useDispatch();

    const _global = useSelector((state) => state.global);
    const settings = useSelector((state) => state.settings);
    const { userDetails } = settings;
    const navigate = useNavigate();

    useEffect(() => {
        if (commentId) {
            getData(commentId, currentPage);
        }
    }, [commentId, currentPage])


    const getData = (id, page = 1) => {
        setIsCurrentLoading(true);
        dispatch(getRepliesList({ id: id, page: page })).then((resp) => {
            if (resp && resp.payload && resp.payload.data && resp.payload.data.results) {
                setPaginationData(resp.payload.data.pagination);
                setIsCurrentLoading(false);
                if (resp.payload.data.pagination.page > 1) {
                    setRepliesList(helpers.removeDuplicate([...repliesList, ...resp.payload.data.results]));
                } else {
                    setRepliesList([...resp.payload.data.results])
                }
            }
        });
    }

    const handleDeleteReply = (id) => {
        let path = `reply=${id}`;
        dispatch(deleteCommentReply({ url: path })).then(() => {
            let j = document.getElementById(`reply-${id}`);
            j.style.display = "none";
        })
    }

    const handleLikeReply = (id) => {
        if (_global.is_public) {
            dispatch(toggleLoginModal({isOpen: true}));
        } else {
            let path = `reply=${id}`;
            dispatch(likeCommentReply({ url: path })).then((resp) => {
                let tempList = repliesList;
                if (resp.payload.message === 'liked') {
                    tempList.map((x, i) => {
                        if (x.reply_id === id) {
                            x.is_liked = true
                            x.total_likes = x.total_likes + 1
                        }
                    })
                    setRepliesList([...tempList]);
                } else {
                    tempList.map((x, i) => {
                        if (x.reply_id === id) {
                            x.is_liked = false
                            x.total_likes = x.total_likes - 1
                        }
                    })
                    setRepliesList([...tempList]);
                }
            })
        }
    }

    const handleAddReply = (id) => {
        if (_global.is_public) {
            dispatch(toggleLoginModal({isOpen: true}));
        } else {
            setReply("");
            setIsCurrentLoading(true);
            if (reply !== '') {
                const body = {
                    comment: id,
                    reply: reply,
                }
                dispatch(addBlogReply({ body: body })).then((resp) => {
                    setIsCurrentLoading(false);
                    if (resp.payload && resp.payload.data && resp.payload.data.reply_id) {
                        props?.setReplyId("");
                        setRepliesList([resp.payload.data, ...repliesList]);
                    }
                });
            }
        }
    }

    const navigateToProfile = (id) => {
        if (_global.is_public) {
            dispatch(toggleLoginModal({isOpen: true}));
        } else {
            navigate(`/profile/${id}`);
        }
    }

    return (
        <div>
            {replyId === commentData?.comment_id &&
                <Grid container style={{ marginTop: '10px' }}>
                    <Grid item sx={{ width: { xs: '32px', sm: '52px' }, height: { xs: '32px', sm: '52px' } }} >
                        {userDetails && userDetails.profile_image
                            ?
                            <Box
                                component={'img'}
                                src={helpers.getFullUrl(userDetails?.profile_image)}
                                alt='profilepic'
                                className='d-block'
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '7px',
                                    objectFit: 'cover'
                                }}
                            />
                            :
                            userDetails.username ?
                                <div className='name-initials'>{userDetails?.username[0]}</div>
                                :
                                <div className='name-initials'>F</div>
                        }
                    </Grid>
                    <Grid item style={{ flex: '1', paddingLeft: '10px' }} className='relative'>
                        <TextareaAutosize
                            style={{ width: '100%' }}
                            placeholder={`you are replying to ${commentData?.username}`}
                            className="comment-box fs-24"
                            onChange={(e) => setReply(e.target.value)}
                            onClick={() => {
                                if (_global.is_public) {
                                    dispatch(toggleLoginModal({isOpen: true}));
                                }
                            }}
                            value={reply}
                            autoFocus={true}
                        />
                        <div className='post-action'>
                            <Grid container justifyContent={'flex-end'}>
                                <Grid item>
                                    <Button size='sm'
                                        className='rounded-btn w-100 bg-selected fs-20 text-light mt-0 pr-30 pl-30'
                                        sx={{ marginTop: { xs: '20px', sm: '30px' }, textTransform: 'none' }}
                                        onClick={() => handleAddReply(replyId)}
                                        disabled={reply === ''}
                                    >
                                        reply
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            }
            {commentData?.total_replies > 0 &&
                <div style={{ marginTop: '10px' }}>
                    {repliesList.length > 0 && repliesList.map((x, i) => {
                        return <div key={i} style={{ marginBottom: '20px' }} id={`reply-${x?.reply_id}`}>
                            <Grid container>
                                <Grid item sx={{ width: { xs: '32px', sm: '52px' }, height: { xs: '32px', sm: '52px' } }} >
                                    <Box
                                        component={'img'}
                                        src={helpers.getFullUrl(x?.profile_image)}
                                        alt='profilepic'
                                        className='d-block pointer'
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '7px',
                                            objectFit: 'cover'
                                        }}
                                        onClick={() => navigateToProfile(x?.user)}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ flex: '1', paddingLeft: '10px' }} className='relative'>
                                    <Grid container className='mb-10' justifyContent={'space-between'}>
                                        <Grid item>
                                            <h2 className='m-0 fw-700 fs-15'>{x?.username}</h2>
                                            <h2 className='m-0 text-muted fs-12'>{helpers.getIndianTimeAgo(x?.created_at)}</h2>
                                        </Grid>
                                        <Grid item>
                                            {userDetails?.uuid === x?.user && !_global.is_public &&
                                                <DeleteRoundedIcon onClick={() => handleDeleteReply(x?.reply_id)} className='pointer' />
                                            }
                                        </Grid>
                                    </Grid>
                                    <MediaOverView text={x?.reply} isLess={true} fullHeight={true} max={200} />
                                    <Grid container className='mt-10' alignItems={'center'}>
                                        <ThumbUpRoundedIcon onClick={() => handleLikeReply(x?.reply_id)} style={{ marginRight: '10px' }} className={`pointer scale-1 ${x?.is_liked ? 'text-selected' : ''}`} />
                                        <p className='mt-0 mb-0 scale-1 pointer' style={{ marginRight: '10px' }}>{x?.total_likes || 0}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    })}
                </div>
            }

            {isCurrentLoading &&
                <Grid container justifyContent={'center'}>
                    <CircularProgress />
                </Grid>
            }

            {paginationData?.is_next_page && <p
                className='m-0 pointer text-muted'
                sx={{ marginTop: { xs: '20px', sm: '30px' } }}
                onClick={() => {
                    getData(commentId, (currentPage + 1));
                    setCurrentPage(currentPage + 1)
                }}
            >
                load more replies
            </p>}
        </div>
    )
}
