import { Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPreferences } from "../../../slices/auth";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommonMediumTileListing from "../../common/CommonMediumTileListing";
import Loader from "../../common/Loader";
import PreferMood from "../PreferMood";
import AddIcon from "@mui/icons-material/Add";
import Language from "../../onboarding/Language";
import {
  getGenreMedia,
  getLanguageMediaMovie,
  getLanguageMediaSearched,
  getLanguageMediaTv,
  getPlatformMedia,
} from "../../../slices/trending";
import TrendingPlatform from "../TrendingPlatform";
import MyHelmet from "../../common/MyHelmet";
import MyAds from "../../adsense/MyAds";
import SectionHeading from "../../component/headings/SectionHeading";
import ContentAccordion from "../../common/ContentAccordion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -50%)",
  maxWidth: { xs: "90%", sm: "75%", md: "50%" },
  bgcolor: "transparent",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  zIndex: 999,
};

export default function PreferLanguageInner() {
  const page = 1;
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageName, setSelectedLanguageName] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedGenreName, setSelectedGenreName] = useState("")
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedPlatformName, setSelectedPlatformName] = useState("");
  const [searchParams] = useSearchParams();
  const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("")
  const is_free =
    searchParams.get("is_free") && searchParams.get("is_free") === "true"
      ? true
      : false;
  const languageId = searchParams.get("language")
    ? searchParams.get("language")
    : "";

  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const trending = useSelector((state) => state.trending);
  const _global = useSelector((state) => state.global);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectedLanguage(languageId);
  }, [languageId]);


  useEffect(() => {
    if (selectedLanguageName && selectedLanguageName != '') {
      navigate(`/languages/${selectedLanguageName}${window.location?.search}`);
    }
  }, [selectedLanguageName]);

  useEffect(() => {
    if (
      auth.allPreferences &&
      auth.allPreferences.languages &&
      auth.allPreferences.languages.length > 0
    ) {
      if (!languageId || languageId === "") {
        if (params?.language && params?.language != '') {
          let filtered = auth.allPreferences.languages.filter((x) => x.language == params?.language || x.language.toLowerCase() === params?.language);
          setSelectedLanguage(filtered[0]?.code || '');
          setSelectedLanguageName(filtered[0]?.language.toLowerCase() || '');
        } else {
          setSelectedLanguage(
            auth.allPreferences.languages[0].code
              ? auth.allPreferences.languages[0].code
              : ""
          );
          setSelectedLanguageName(
            auth.allPreferences.languages[0]?.language.toLowerCase()
          );
        }
      } else {
        auth.allPreferences.languages.map((l, j) => {
          if (l.code === languageId) {
            setSelectedLanguageName(l?.language.toLowerCase());
          }
        });
      }
    }
  }, [auth.allPreferences]);

  useEffect(() => {
    if (auth.successMesg && auth.successMesg !== "") {
      dispatch(getAllPreferences({ is_public: _global.is_public }));
    }
  }, [auth]);

  useEffect(() => {
    dispatch(getAllPreferences({ is_public: _global.is_public }));
  }, []);

  useEffect(() => {
    if (selectedLanguage && selectedLanguage !== "") {
      var body = {
        page: page,
        pagelimit: 20,
        id: selectedLanguage,
        is_free: is_free,
        is_public: _global.is_public,
      };
      dispatch(getLanguageMediaMovie({ ...body, media_type: "movie" }));
      dispatch(getLanguageMediaTv({ ...body, media_type: "tv" }));
      dispatch(
        getLanguageMediaSearched({ ...body, media_type: "all", sort: "s" })
      );
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform !== "") {
      var body = {
        page: page,
        pagelimit: 20,
        id: selectedPlatform,
        body: { language: [selectedLanguage] },
        is_free: is_free,
        is_public: _global.is_public,
      };
      dispatch(getPlatformMedia(body));
    }
  }, [selectedPlatform, selectedLanguage]);

  useEffect(() => {
    if (selectedGenre && selectedGenre !== "") {
      var body = {
        page: page,
        pagelimit: 20,
        id: selectedGenre,
        body: { language: [selectedLanguage] },
        is_free: is_free,
        is_public: _global.is_public,
      };
      dispatch(getGenreMedia(body));
    }
  }, [selectedGenre, selectedLanguage]);

  return (
    <>
      <MyHelmet
        title={
          "Trending Titles by Language on OTT"
        }
        og_title={'Trending OTT movies and series by Language - MyFrenzi'}
        description={
          "Discover trending OTT movies and series sorted by language. Find your next watch with MyFrenzi."
        }
        keywords={'Languages, OTT, Trending, Movies, Series'}
      />
      {(trending.isLoading || auth.isLoading) && <Loader />}
      <div className="new-container">
        <ContentAccordion
          isFull={true}
          title={'LANGUAGE_H'}
          data={'LANGUAGE'}
        />
      </div>
      <Grid className="new-container">
        <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }} justifyContent={'center'}>
          <Grid container justifyContent={'center'}>
            <MyAds type="banner_2" adv_type={'web_prefer_lang_top_ad'} hideInMobile={true} />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          className="pt-20"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <ArrowBackRoundedIcon
                  onClick={() => navigate(-1)}
                  className="pointer mr-10 mt-7"
                />
              </Grid>
              <Grid item>
                <SectionHeading title={<><span>trending in</span><span className="text-selected"> {selectedLanguageName}</span></>} />
              </Grid>
              {/* <h2 className="fs-28 fw-700 lh-28 m-0">
                trending in <span className="text-selected">{selectedLanguageName}</span>
              </h2> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* <Grid container alignItems={"center"} className="mb-0 w-100 mt-20"> */}
          <Grid
            container
            alignItems={"center"}
            className="m-0 w-100 pt-20 overflow-no-scroll"
            flexWrap={"nowrap"}
          >
            {!_global.is_public && (
              <Grid
                item
                onClick={() => setOpenModal(true)}
                sx={{
                  maxWidth: { xs: 40, sm: 60 },
                  minWidth: { xs: 40, sm: 60 },
                  maxHeight: { xs: 40, sm: 60 },
                  minHeight: { xs: 40, sm: 60 },
                  borderRadius: "50%",
                  position: "sticky",
                  left: 0,
                }}
                className="mb-10 add-language-container pointer mr-20"
              >
                <AddIcon />
              </Grid>
            )}
            {auth?.allPreferences &&
              auth.allPreferences.languages &&
              selectedLanguage !== "" &&
              auth.allPreferences.languages.length > 0 &&
              auth.allPreferences.languages.map((x, i) => {
                if (_global.is_public && i > 2) {
                  return true;
                }
                return (
                  <Grid
                    sx={{
                      maxWidth: { xs: 40, sm: 64 },
                      minWidth: { xs: 40, sm: 60 },
                      maxHeight: { xs: 40, sm: 64 },
                      minHeight: { xs: 40, sm: 60 },
                      borderRadius: "50%",
                    }}
                    className={`mb-10 circle-lang-container ${selectedLanguage === x.code ? "lang-selected" : ""
                      } pointer mr-20`}
                    onClick={() => {
                      setSelectedLanguage(x.code);
                      setSelectedLanguageName(x?.language.toLowerCase());
                    }}
                    key={i}
                  >
                    <Typography className="fw-500 text-center fs-14 lh-14 p-2">
                      {x.language ? x.language : "N/A"}
                    </Typography>
                  </Grid>
                );
              })}
            {_global.is_public && (
              <Grid
                item
                onClick={() => setOpenModal(true)}
                sx={{
                  maxWidth: { xs: 40, sm: 60 },
                  minWidth: { xs: 40, sm: 60 },
                  maxHeight: { xs: 40, sm: 60 },
                  minHeight: { xs: 40, sm: 60 },
                  borderRadius: "50%",
                  position: "sticky",
                  left: 0,
                }}
                className="mb-10 add-language-container pointer"
              >
                <AddIcon />
              </Grid>
            )}
          </Grid>
        </Grid>

        <CommonMediumTileListing
          heading={
            <>
              popular <span className="text-selected">movies</span> in{" "}
              {selectedLanguageName}
            </>
          }
          subheading=""
          isShowHeading={true}
          nextLink={`/languages/${selectedLanguageName}/movie?id=${selectedLanguage}${is_free ? '&is_free=true' : ''}`}
          data={
            trending.languageMediaMovieData
              ? trending.languageMediaMovieData
              : []
          }
          refPage={"Preferred Language"}
        />
        <br />
        <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
          <Grid container justifyContent={'center'}>
            <MyAds type="banner_2" adv_type={'web_prefer_language_ad'} />
          </Grid>
        </Grid>
        <CommonMediumTileListing
          heading={
            <>
              popular <span className="text-selected">web series</span> in{" "}
              {selectedLanguageName}
            </>
          }
          subheading=""
          isShowHeading={true}
          nextLink={`/languages/${selectedLanguageName}/tv?id=${selectedLanguage}${is_free ? '&is_free=true' : ''}`}
          data={
            trending.languageMediaTvData ? trending.languageMediaTvData : []
          }
          refPage={"Preferred Language"}
        />
        <br />
        <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
          <Grid container justifyContent={'center'}>
            <MyAds type="banner_2" adv_type={'web_prefer_language_ad'} />
          </Grid>
        </Grid>

        <TrendingPlatform
          isShowHeading={true}
          platforms={
            auth.allPreferences && auth.allPreferences.platforms
              ? auth.allPreferences.platforms
              : []
          }
          heading={
            <>
              trending on <span className="text-selected">{selectedPlatformOttName}</span> in{" "}
              {selectedLanguageName}
            </>
          }
          subheading="search content on your favourite platforms"
          setSelectedPlatform={setSelectedPlatform}
          selectedPlatform={selectedPlatform}
          setSelectedPlatformName={setSelectedPlatformName}
          setSelectedPlatformOttName={setSelectedPlatformOttName}
          selectedPlatformOttName={selectedPlatformOttName}
          data={trending.platformMediaData ? trending.platformMediaData : []}
          is_free={is_free}
          is_public={_global.is_public}
          nextLink={`/languages/${selectedLanguageName}/${selectedPlatformOttName}?id=${selectedLanguage}&platform=${selectedPlatformName}${is_free ? '&is_free=true' : ''}`}
          // nextLink={`/platforms/${selectedPlatformOttName}/languages/${selectedLanguageName}?lang=${selectedLanguage}${is_free ? '&is_free=true' : ''}`}

          refPage={"Preferred Language"}
        />
        <br />
        <CommonMediumTileListing
          heading={
            <>
              most <span className="text-selected">searched</span> in{" "}
              {selectedLanguageName}
            </>
          }
          subheading="titles popular with like-minded fans"
          isShowHeading={true}
          nextLink={`/languages/${selectedLanguageName}/searched?id=${selectedLanguage}${is_free ? '&is_free=true' : ''}`}
          data={
            trending.languageMediaSearchedData
              ? trending.languageMediaSearchedData
              : []
          }
          refPage={"Preferred Language"}
        />
        <br />
        <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
          <Grid container justifyContent={'center'}>
            <MyAds type="banner_2" adv_type={'web_prefer_language_ad'} />
          </Grid>
        </Grid>

        <PreferMood
          genres={
            auth.allPreferences && auth.allPreferences.genres
              ? auth.allPreferences.genres
              : []
          }
          heading={
            <>
              trending in <span className="text-selected">{selectedGenreName}</span> in{" "}
              {selectedLanguageName}
            </>
          }
          subheading="content that appeals to you"
          setSelectedGenre={setSelectedGenre}
          selectedGenre={selectedGenre}
          setSelectedGenreName={setSelectedGenreName}
          data={trending.genreMediaData ? trending.genreMediaData : []}
          is_free={is_free}
          isPublic={_global.is_public}
          refPage={"Preferred Language"}
          // nextLink={`/languages/detail/searched?id=${selectedLanguage}${is_free ? '&is_free=true' : ''}`}
          nextLink={`/languages/${selectedLanguageName}/${selectedGenreName}?id=${selectedLanguage}&genre=${selectedGenreName}${is_free ? '&is_free=true' : ''}`}

        />
        <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
          <Grid container justifyContent={'center'}>
            <MyAds type="banner_2" adv_type={'web_prefer_language_ad'} />
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Language
              setActiveSteps={() => setOpenModal(false)}
              activeSteps={1}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
}
