import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import CreateWatchlist from './CreateWatchlist';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, getFreindsSharelist, getFrenziSharelist, getPopularCreatorSharelist, getSharelistData, getUnwatchedMedias, getWatchlistData, getWatchlistIJoined, getWatchlistLandingDetail, getWatchlistSection } from '../../slices/watchlist';

import LandingWatchlist from './LandingWatchlist';
import helpers from '../../services/helpers';
import Loader from '../common/Loader';
import BulkWatchlist from '../common/BulkWatchlist';
// import { CustomCarousel } from '../component/custom3DCarousel/CustomCarousel';
import CarouselTile from '../common/CarouselTile';
import SearchWatchlist from './includes/SearchWatchlist';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import MyHelmet from '../common/MyHelmet';
import MediaSubdetails from '../common/MediaSubdetails';

export default function WatchlistSection(props) {
    const [currentLoading, setCurrentLoading] = useState(false);
    const activeSection = "my";
    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);
    const { watchlistPageData, sharelistPageData, watchlistIJoinedPageData, frenziSharelistPageData, friendsSharelistPageData, popularCreatorSharelistPageData } = watchlist;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (watchlist.errMesg && watchlist.errMesg !== "") {
            helpers.toastError(watchlist.errMesg);
            dispatch(clearMessages());
        }
        if (watchlist.successMesg && watchlist.successMesg !== "") {
            helpers.toastSuccess(watchlist.successMesg);
            getData();
            dispatch(clearMessages());
        }
    }, [watchlist])

    useEffect(() => {
        setCurrentLoading(true);
        getData();
    }, [])

    const getData = () => {
        dispatch(getWatchlistLandingDetail({ section: activeSection })).then(() => {
            setTimeout(() => {
                window.scrollTo(0, 0);
                setCurrentLoading(false);
            }, 500)
        });

        dispatch(getUnwatchedMedias({ page: 1, pagelimit: 10 }));
        dispatch(getWatchlistData({ page: 1, pagelimit: 10 }));
        dispatch(getSharelistData({ page: 1, pagelimit: 10 }));
        dispatch(getWatchlistIJoined({ page: 1, pagelimit: 10 }));
        dispatch(getFrenziSharelist({ page: 1, pagelimit: 10 }));
        dispatch(getFreindsSharelist({ page: 1, pagelimit: 10 }));
        dispatch(getPopularCreatorSharelist({ section: 'popular', page: 1, pagelimit: 10 }));
    }
    // console.log("aman",watchlist.unwatchedMediaData.title)

    console.log(watchlistIJoinedPageData.results)
    return (
        <>
            <MyHelmet
                title={"All Watchlists"}
                description={'Create Your Frenzi Watchlist: Curate and Share Your Personalized List of Favorites with People and Enjoy the Collective Movie Experience!'}
            />
            <Grid className='new-container ml-50 mt-70'>
                {currentLoading &&
                    <Loader />

                }
                <Grid className=''>
                    <SearchWatchlist isFullWidth={false} />
                    {/* {watchlist.watchlistLandingData &&
                        <PendingWatchlist imported={true} limited={true} />
                    }
                    {watchlist.watchlistLandingData &&
                        <PendingRequests imported={true} limited={true} />
                    } */}
                    {/* <WatchlistBanner data={watchlist.unwatchedMediaData} /> */}

                    {/* <Grid container columnSpacing={2} justifyContent={'center'}>
                        <Grid item>
                            <Typography className='fs-20 fw-800 lh-36'>watchlist</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className='fs-20 fw-800 lh-36'>sharelist</Typography>
                        </Grid>
                    </Grid> */}

                    {watchlist.unwatchedMediaData && watchlist.unwatchedMediaData.length > 0 &&
                        <Grid item xs={12} style={{ overflow: 'hidden' }} className="pb-30 mt-30 mb-20">
                            <Grid container className='new-container'>
                                <Grid item xs={12} className="mb-30">
                                    <p className='fw-700 fs-28 lh-28 m-0'>
                                        unwatched titles
                                    </p>
                                </Grid>
                            </Grid>
                            {/* <CustomCarousel
                            // autoplay={true}
                            interval={3000}
                            slides={getSlides(watchlist.unwatchedMediaData)}
                            offsetNumber={watchlist.unwatchedMediaData.length < 9 ? 1 : 3}
                        /> */}
                            <Grid xs={12} className='app-container'>
                                <Swiper
                                    // spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                        // pauseOnMouseEnter: true
                                    }}
                                    loop={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    // speed={1000}
                                    navigation={true}
                                    modules={[Autoplay, Navigation, Pagination]}
                                    className="mySwiper"
                                >
                                    {watchlist.unwatchedMediaData.map((x, i) => {
                                        return <SwiperSlide>
                                            <CarouselTile ref_page={'Watchlist'} x={x} customDetails={true} isMediaSubDetails={true} />
                                        </SwiperSlide>
                                    })}
                                </Swiper>
                            </Grid>
                        </Grid>
                    }
                    {watchlist.watchlistLandingData && watchlist.watchlistLandingData.watchlist_created ?
                        <LandingWatchlist section={'watchlist-section'} data={watchlist.watchlistLandingData} />
                        :
                        <CreateWatchlist />
                    }

                    <div className='no-scale'>
                        {sharelistPageData && sharelistPageData.results && sharelistPageData.results.length > 0 &&
                            <>
                                <BulkWatchlist
                                    pageData={sharelistPageData}
                                    data={sharelistPageData.results}
                                    heading={<>my <span className='text-selected'>sharelist</span></>}
                                    subheading={'my list to the world'}
                                    nextLink={`/watchlist-list/1`}
                                />
                            </>
                        }
                        {watchlistPageData && watchlistPageData.results && watchlistPageData.results.length > 0 &&
                            <>
                                <BulkWatchlist
                                    pageData={watchlistPageData}
                                    data={watchlistPageData.results}
                                    showAds={true}
                                    heading={<>my <span className='text-selected'>watchlist</span></>}
                                    subheading={'my personal collection'}
                                    nextLink={"/watchlist-list/2"}
                                />
                            </>
                        }
                        {watchlistIJoinedPageData && watchlistIJoinedPageData.results && watchlistIJoinedPageData.results.length > 0 &&
                            <>
                                <BulkWatchlist
                                    pageData={watchlistIJoinedPageData}
                                    data={watchlistIJoinedPageData.results}
                                    heading={<><span className='text-selected'>sharelists</span> i've joined</>}
                                    subheading={'curated by like minded filmyheads'}
                                    nextLink={"/watchlist-list/3"}
                                    showName={true}
                                />
                            </>
                        }
                        {frenziSharelistPageData && frenziSharelistPageData.results && frenziSharelistPageData.results.length > 0 &&
                            <>
                                <BulkWatchlist
                                    pageData={frenziSharelistPageData}
                                    showAds={true}
                                    data={frenziSharelistPageData.results}
                                    heading={<>frenzi's <span className='text-selected'>sharelists</span></>}
                                    subheading={'frenzi editorial team'}
                                    nextLink={"/watchlist-list/4"}
                                />
                            </>
                        }
                        {friendsSharelistPageData && friendsSharelistPageData.results && friendsSharelistPageData.results.length > 0 &&
                            <>
                                <BulkWatchlist
                                    pageData={friendsSharelistPageData}
                                    data={friendsSharelistPageData.results}
                                    heading={<>friend <span className='text-selected'>sharelists</span></>}
                                    subheading={'interesting list by friends'}
                                    nextLink={"/watchlist-list/5"}
                                    showName={true}
                                />
                            </>
                        }
                        {popularCreatorSharelistPageData && popularCreatorSharelistPageData.results && popularCreatorSharelistPageData.results.length > 0 &&
                            <>
                                <BulkWatchlist
                                    pageData={popularCreatorSharelistPageData}
                                    showAds={true}
                                    data={popularCreatorSharelistPageData.results}
                                    heading={<>popular creator's <span className='text-selected'>sharelists</span></>}
                                    subheading={'sharelist getting attention'}
                                    nextLink={"/watchlist-list/6"}
                                    showName={true}
                                />
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
