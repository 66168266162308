import React from 'react'
import { Grid, Box } from '@mui/material';
// import Seen from '../../../assets/icons/Seen.svg';
import helpers, { MEDIUM_TILE_BREAKPOINTS2 } from '../../../services/helpers';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import { useNavigate } from 'react-router-dom';

const Seen = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/Seen.svg";

export default function TopList(props) {
    const { data, link, customLink } = props;
    const navigate = useNavigate();

    const handleClick = (link, id, data) => {
        if (link && link !== "") {
            if (customLink && customLink != '') {
                if (customLink == ":decade") {
                    let newLink = link.replace(customLink, data[customLink.replace(":", "")]+"0s");
                    navigate(`${newLink}?id=${id}`);
                } else {
                    let newLink = link.replace(customLink, data[customLink.replace(":", "")]);
                    navigate(`${newLink}?id=${id}`);
                }
            } else {
                navigate(`${link}?id=${id}`);
            }
        }
    }

    return (
        <Grid item className='w-100 mt-20 pb-50'>
            <Swiper
                slidesPerView={2}
                spaceBetween={20}
                // slidesPerGroup={5}
                freeMode={true}
                navigation={true}
                speed={1000}
                breakpoints={MEDIUM_TILE_BREAKPOINTS2}
                modules={[FreeMode, Navigation]}
                className="mySwiper"
            >
                {data.map((x, i) => {
                    return <SwiperSlide key={i}>
                        <div style={{ padding: '20px 0px 20px 0px' }} key={i}>
                            <Grid item
                                onClick={() => handleClick(link, x?.uuid || x?.decade, x)}
                                className={`toplist-container ${link && link !== "" && 'pointer'}`}
                                alignItems={'center'}
                            >
                                <Grid container flexDirection={'column'} justifyContent="space-between" className='h-100' >
                                    <Grid xs={12} item className={`pt-20`}>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 112,
                                                width: 112,
                                                maxHeight: { xs: 64, sm: 112 },
                                                maxWidth: { xs: 64, sm: 112 },
                                                borderRadius: "50%",
                                                objectFit: 'cover',
                                                objectPosition: 'top'

                                            }}
                                            src={x.genre_logo ? helpers.getFullUrl(x.genre_logo) : x.image ? helpers.getFullUrl(x.image) : x.icon ? helpers.getFullUrl(x.icon) : x.logo ? helpers.getFullUrl(x.logo) : x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('cinescoregenres')}
                                            alt='logo'
                                        />
                                        <Grid container justifyContent={'center'}>
                                            <div style={{ maxWidth: '80%' }}>
                                                <p className='fw-600 fs-16 m-0 text-center pb-12 ellipsis'>
                                                    {/* Sports */}
                                                    {x.genre ? x.genre : x.language ? x.language : x.decade ? x.decade + "0s" : x.ott_name ? x.ott_name : x?.name ? x.name : ''}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item className='toplist-bottom-container w-100 mt-20'>
                                        <Grid container alignItems={'center'} justifyContent={'center'} className='w-100'>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 24,
                                                    width: 24,
                                                    maxHeight: { xs: 24, sm: 24 },
                                                    maxWidth: { xs: 24, sm: 24 },
                                                }}
                                                src={Seen}
                                                alt='seen'
                                            />
                                            <span className='d-block ml-20 text-center fs-16 fw-500'>
                                                {x.views ? x.views : 0}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </SwiperSlide>
                })}
            </Swiper>
        </Grid >
    )
}
