import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react'
// import CameraRec from '../../assets/icons/CameraRec.svg';
// import TvUpload from '../../assets/icons/TvUpload.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import SearchTitleCast from './SearchTitleCast';
import AWS from 'aws-sdk'
import helpers from '../../services/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { EffectCoverflow, FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { addWatchlist, getSharelistToAdd, getWatchlistTitleDetail } from "../../slices/watchlist";
import Loader from '../common/Loader';
import { addClip, getDiscoverTags } from '../../slices/clips';
import MediaPlayer from '../common/MediaPlayer';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { toggleLoginModal } from '../../slices/global';

const CameraRec = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/CameraRec.svg";
const TvUpload = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/TvUpload.svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "90%", sm: "750px" },
    bgcolor: "#16213E",
    boxShadow: 24,
    p: { xs: 2, sm: 3 },
    borderRadius: "7px",
    maxHeight: "70vh",
    height: '100%',
    // minHeight:"400px",
    overflowY: "auto",
};

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

const breakpoints = {
    319: {
        slidesPerView: 3.5,
        spaceBetween: 15,
    },
    425: {
        slidesPerView: 4,
        spaceBetween: 15,
    },
    640: {
        slidesPerView: 5,
        spaceBetween: 15,
    },
    768: {
        slidesPerView: 6,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    1024: {
        slidesPerView: 7,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1440: {
        slidesPerView: 7.7,
        spaceBetween: 20,
        slidesPerGroup: 5
    },
    1880: {
        slidesPerView: 11,
        spaceBetween: 30,
        slidesPerGroup: 5
    },
    2560: {
        slidesPerView: 10,
        spaceBetween: 30,
        slidesPerGroup: 8
    },
}

const page = 1;

export default function UploadClip(props) {
    const { isBtn, mediaData, hideSearch } = props;
    const [open, setOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('review');
    const [videoUrl, setVideoUrl] = useState('');
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedSharelistData, setSelectedSharelistData] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    const containerRef = useRef(null);
    const [drawerOpen, toggleDrawer] = useState(false);
    const videoInputChange = useRef(null);
    const watchlist = useSelector((state) => state.watchlist);
    const dispatch = useDispatch();
    const [watchlistName, setWatchlistName] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState([]);
    const [loadingSharelistData, setLoadingSharelistData] = useState(false);
    const _global = useSelector((state) => state.global);

    useEffect(() => {
        if (mediaData && mediaData.uuid) {
            const newD = { ...mediaData, media: mediaData.uuid, poster: mediaData.poster_path };
            setSelectedData([newD]);
        }
    }, [mediaData])

    useEffect(() => {
        if (page > 1) {
            dispatch(
                getSharelistToAdd({ uuid: '', page: page, pagelimit: 10 })
            );
        }
    }, [page]);

    const handleTypeSelect = (type) => {
        setSelectedType(type)
    }

    const handleCreateClick = () => {
        toggleDrawer(true);
        if (mediaData && mediaData.uuid) {
            setIsSelected(true);
        }
    }

    const handleUploadVideo = () => {
        if (_global.is_public) {
            dispatch(toggleLoginModal({ isOpen: true }));
        } else {
            setOpen(true);
            dispatch(
                getSharelistToAdd({ uuid: '', page: 1, pagelimit: 10 })
            );
        }
    }

    const handleOnSelect = (data) => {
        setSelectedData(data && data != '' ? [data[data.length - 1]] : []);
        if (data.length > 0) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }

    const handleOnSelectTitle = (data) => {
        setSelectedData(data && data != '' ? [...data] : []);
    }

    const handleClose = () => {
        setOpen(false);
        toggleDrawer(false);

    }

    const setFileInfo = (file) => {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            var duration = video.duration;
            console.log(duration);
            if (duration > 14 && duration < 61) {
                var timeStamp = new Date().getTime();
                var randomNo = parseInt(Math.random() * 100000);
                let fileName = file.name.replace(/\s/g, "");
                var keyName = "discover_videos/discovery-post-" + timeStamp + "-" + randomNo + "-" + fileName;
                handleUpload(file, keyName, 'video');
            } else {
                helpers.toastError("video must be 15 - 60 sec long");
            }
        }
        video.src = URL.createObjectURL(file);;
    }

    const handleVideoFileChange = (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        setFileInfo(file);
    }

    const handleUpload = async (file, keyName, type = "audio") => {
        setIsVideoLoading(true);
        var bucket = process.env.REACT_APP_S3_BUCKET_NAME;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                console.log(err);
                // helpers.toastError(JSON.stringify(err));
                return err;
            }
            if (data && data.Location) {
                setVideoUrl(data.Location);
                setIsVideoLoading(false);
                helpers.toastWarning('click on continue button to save the clip');
            }
        });
    }

    const handleSelectSharelist = (data) => {
        setLoadingSharelistData(true);
        dispatch(getWatchlistTitleDetail({ id: data.uuid, page: 1, pagelimit: 12, sort: '', body: {}, is_public: false })).then((resp) => {
            if (resp.payload && resp.payload.data && resp.payload.data.status_flag) {
                setSelectedSharelistData({ ...resp.payload.data.status_flag, watchlist_id: data.uuid });
                setIsSelected(true);
            } else {
                setSelectedSharelistData({});
                setIsSelected(false);
            }
            setLoadingSharelistData(false);
        })
    }

    const handleCreateWatchlist = async (data) => {
        if (watchlistName != "") {
            let mediaIds = data.map(media => media.media || media.uuid);
            if (mediaIds && watchlistName != "") {
                setLoadingSharelistData(true);
                const payload = {
                    is_public: true,
                    category_name: watchlistName,
                    medias: mediaIds,
                };
                dispatch(addWatchlist({ body: payload })).then((resp) => {
                    if (resp.payload && resp.payload.data && resp.payload.data.category_id) {
                        setWatchlistName("");
                        setSelectedData([]);
                        handleSelectSharelist({ uuid: resp.payload.data.category_id });
                    }
                });
            }
        } else {
            helpers.toastError("Sharelist name should not be empty.");
            return true;
        }
    };

    // const handleChnageInDesc = (val) => {
    //     setDescription(val);
    // }

    const getWords = (text) => {
        let newArr = text.split(" ").filter(x => x[0] == '#');
        return newArr.map(tag => tag.slice(1));
    }

    const handleCreateClip = async () => {
        let tempTags = await getWords(description);
        let tempData = selectedData && selectedData[0] ? selectedData[0] : '';
        let sharelistId = selectedSharelistData.watchlist_id ? selectedSharelistData.watchlist_id : '';

        if (((tempData && tempData != '') || sharelistId != '')) {
            const body = {
                post_type: selectedType,
                description: description || null,
                post_url: videoUrl,
                tags: tempTags,
                uploaded_from: 'web'
            }

            if (selectedType == "collection") {
                body.watchlist = sharelistId
            }

            if (selectedType != "collection") {
                body.media = tempData.media || tempData.uuid
            }

            dispatch(addClip({ body: body })).then((resp) => {
                if (resp.payload.status) {
                    helpers.toastSuccess(resp.payload.message);
                    setVideoUrl('');
                    setSelectedData([]);
                    setDescription("");
                    setSelectedSharelistData({});
                    setIsSelected(false);
                    setOpen(false);
                    toggleDrawer(false);
                } else {
                    helpers.toastError(resp.payload.message);
                }
            });
        } else {
            if (description == "") {
                helpers.toastError("description can't be empty !!!");
            } else {
                helpers.toastError("missing parameters !!!");
            }
        }
    }

    const handleRequestOptions = (val) => {
        dispatch(getDiscoverTags({ query: val })).then((resp) => {
            if (resp.payload && resp.payload.data && resp.payload.data.results) {
                setTags([...resp.payload.data.results]);
            }
        });
    }

    return (
        <>
            {isBtn ?
                <Button onClick={() => handleUploadVideo()} className="rounded-btn bg-light text-selected mt-30">create a clip</Button>
                :
                <Box
                    component={'img'}
                    className="d-block scale-1 camera-rec-icon pointer"
                    alt="recording"
                    src={CameraRec}
                    onClick={() => handleUploadVideo()}
                />
            }
            <Modal
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                ref={containerRef}
            >
                <Box sx={style} className={`${drawerOpen ? 'overlay-open' : ''}`}>
                    <Grid container justifyContent={'center'} className='relative'>
                        <CloseRoundedIcon
                            sx={{
                                position: 'absolute',
                                left: -10,
                                top: 0,
                                zIndex: 1
                            }}
                            onClick={() => handleClose()} className='animate-close pointer close-icon'
                        />
                        <Grid item xs={12} sm={12} className='p-30 text-center'>
                            <p className='fw-700 fs-32 m-0'>what do you want to <span className='text-selected'>create?</span></p>
                            <Box
                                component={'img'}
                                alt=""
                                src={TvUpload}
                                sx={{
                                    margin: '20px',
                                }}
                            />
                            <Grid container justifyContent={'center'}>
                                <Button
                                    onClick={(e) => {
                                        handleTypeSelect('review');
                                        setSelectedSharelistData({});
                                        setIsSelected(false);
                                    }}
                                    className={`rounded-border-btn pr-30 pl-30 mr-30 ${selectedType == "review" ? "active" : ""
                                        }`}
                                >
                                    review
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        handleTypeSelect('clip');
                                        setSelectedSharelistData({});
                                        setIsSelected(false);
                                    }}
                                    className={`rounded-border-btn pr-30 pl-30 mr-30 ${selectedType == "clip" ? "active" : ""
                                        }`}
                                >
                                    clip
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        handleTypeSelect('collection');
                                        setSelectedSharelistData({});
                                        setIsSelected(false);
                                    }}
                                    className={`rounded-border-btn pr-30 pl-30 ${selectedType == "collection" ? "active" : ""
                                        }`}
                                >
                                    sharelists
                                </Button>
                            </Grid>
                            {selectedType == 'review' &&
                                <p className='fw-400 fs-20 text-muted text-center'>create a video review for a movie or web-series</p>
                            }
                            {selectedType == 'clip' &&
                                <p className='fw-400 fs-20 text-muted text-center'>share interesting scenes of movie or web-series</p>
                            }
                            {selectedType == 'collection' &&
                                <p className='fw-400 fs-20 text-muted text-center'>share an interesting collection of movies or web-series</p>
                            }
                            <Button onClick={() => handleCreateClick()} className='rounded-btn pr-20 pl-20 bg-selected mw-150 text-light'>
                                create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={drawerOpen}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                ref={containerRef}
                className="clips-modal"
            >
                <Box sx={style}>
                    {(loadingSharelistData || isVideoLoading) && <Loader style={{ width: '100%' }} />}
                    <CloseRoundedIcon
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            zIndex: 9,
                            margin: '10px'
                        }}
                        onClick={() => {
                            if (selectedSharelistData && selectedSharelistData.uuid) {
                                setSelectedSharelistData({});
                                setIsSelected(false);
                            } else {
                                toggleDrawer(false)
                            }
                        }} className='animate-close pointer close-icon'
                    />
                    {selectedSharelistData && selectedSharelistData.uuid ?
                        <div>
                            <p className='fw-700 fs-32 m-0 pb-10'>upload <span className='text-selected'>sharelist</span> clip</p>
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                speed={1000}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: true,
                                }}
                                autoplay={true}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation]}
                                className="mySwiper"
                            >
                                {selectedSharelistData.backdrops && selectedSharelistData.backdrops.length > 0 && selectedSharelistData.backdrops.map((x, i) => {
                                    return <SwiperSlide key={i}>
                                        <Box
                                            component={'img'}
                                            className="d-block"
                                            sx={{
                                                width: '100%',
                                                maxHeight: '230px',
                                                borderRadius: '7px'
                                            }}
                                            alt="banner"
                                            src={helpers.getFullUrlOriginal(x) || helpers.getDefaultImage('banner')}
                                        />
                                    </SwiperSlide>
                                })}
                            </Swiper>
                        </div>
                        :
                        <>

                            {selectedType == 'review' &&
                                <p className='fw-700 fs-32 m-0'>choose a movie or web-series for <span className='text-selected'>review</span></p>
                            }

                            {selectedType == 'clip' &&
                                <p className='fw-700 fs-32 m-0'>tag the title or cast of the <span className='text-selected'>clip</span></p>
                            }

                            {selectedType == 'collection' &&
                                <p className='fw-700 fs-32 m-0'>give a name to your <span className='text-selected'>sharelist</span></p>
                            }

                            {selectedType == 'review' || selectedType == 'clip' ?
                                <SearchTitleCast hideSearch={hideSearch} subheading={<span>title, cast</span>} onSelect={handleOnSelect} placeholder={"search here"} selectedData={selectedData} />
                                :
                                <>
                                    <Grid container alignItems={'center'}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                sx={{ marginTop: '20px' }}
                                                id="sharelist-basic" label="sharelist name" variant="outlined"
                                                className='w-100'
                                                value={watchlistName}
                                                onChange={(e) => setWatchlistName(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item className='text-center' xs={12} sm={6}>
                                            <Button onClick={() => handleCreateWatchlist(selectedData)} sx={{ marginTop: '20px' }}
                                                disabled={watchlistName == "" || selectedData.length == 0 || watchlist.isLoading} className='rounded-btn w-75 bg-selected text-light'>
                                                create
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {watchlistName && watchlistName != '' &&

                                        <SearchTitleCast subheading={<span>title, cast</span>} onSelect={handleOnSelectTitle} placeholder={"search here"} selectedData={selectedData} mediaOnly={true} />

                                    }
                                </>
                            }
                            {watchlistName == '' && selectedType == 'collection' &&
                                <>
                                    <p className='fw-700 fs-24 mt-10'>or choose from existing sharelist</p>
                                    {watchlist.sharelistToAddData &&
                                        watchlist.sharelistToAddData.length > 0 && (
                                            <>
                                                <Grid
                                                    xs={12}
                                                >
                                                    <Swiper
                                                        slidesPerView={2}
                                                        spaceBetween={20}
                                                        // slidesPerGroup={5}
                                                        freeMode={true}
                                                        speed={1000}
                                                        navigation={true}
                                                        breakpoints={breakpoints}
                                                        modules={[FreeMode, Navigation]}
                                                        className="mySwiper"
                                                    >
                                                        {watchlist.sharelistToAddData.map((x, i) => {
                                                            return (
                                                                <SwiperSlide key={i}>
                                                                    <div
                                                                        className="pointer sharelist-item"
                                                                        key={i}
                                                                        onClick={() =>
                                                                            handleSelectSharelist(x)
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            className={`text-center`}
                                                                        >
                                                                            <Grid item className="relative">
                                                                                <Box
                                                                                    component="img"
                                                                                    className="d-block"
                                                                                    sx={{
                                                                                        height: 120,
                                                                                        width: "100%",
                                                                                        maxHeight: { xs: 70, sm: 120 },
                                                                                        borderRadius: "9px",
                                                                                    }}
                                                                                    alt="poster"
                                                                                    src={
                                                                                        x.poster
                                                                                            ? helpers.getFullUrl(x.poster)
                                                                                            : helpers.getDefaultImage(
                                                                                                "watchlistsection"
                                                                                            )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid>
                                                                                <p
                                                                                    title={
                                                                                        x.category_name
                                                                                            ? x.category_name
                                                                                            : "N/A"
                                                                                    }
                                                                                    className="text-light fs-14 lh-12 fw-700"
                                                                                >
                                                                                    {x.category_name
                                                                                        ? x.category_name.length > 12
                                                                                            ? x.category_name.substring(0, 12) +
                                                                                            "..."
                                                                                            : x.category_name
                                                                                        : "N/A"}
                                                                                </p>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </SwiperSlide>
                                                            );
                                                        })}
                                                    </Swiper>
                                                </Grid>
                                            </>
                                        )}
                                </>
                            }

                        </>
                    }

                    {isSelected ?
                        <>
                            <p className='fw-700 fs-28 m-0'><span className='text-selected'>{videoUrl && videoUrl != '' ? 'uploaded' : 'upload'}</span></p>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={'center'} flexWrap={'nowrap'} alignItems={'center'}>
                                        {videoUrl && videoUrl != '' ?
                                            <>
                                                <Grid item xs={12} className="relative">
                                                    <Grid container>
                                                        <Grid item xs={12} sm={5} sx={{ p: 1 }}>
                                                            <MediaPlayer type="video" src={videoUrl} />
                                                        </Grid>
                                                        <Grid item className='autocomplete-box' xs={12} sm={7} sx={{ p: 1, height: { xs: '150px', sm: "133px" } }}>
                                                            <p className='fw-700 fs-24 mb-10 mt-0 lh-21'>description</p>
                                                            <TextInput
                                                                trigger={["#"]}
                                                                options={{ "#": tags.length > 0 ? tags.map(j => j.tag) : [] }}
                                                                maxOptions={20}
                                                                onRequestOptions={handleRequestOptions}
                                                                onChange={(val) => setDescription(val)}
                                                                value={description}
                                                                height={{ xs: '150px', sm: '100px' }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent={'center'}>
                                                                <Button onClick={() => videoInputChange.current.click()} sx={{ margin: '10px' }} className='rounded-btn pr-20 pl-20 text-selected mw-220 bg-light' >
                                                                    upload again
                                                                    <BackupOutlinedIcon style={{ marginLeft: '20px' }} />
                                                                </Button>
                                                                <input
                                                                    style={{ display: 'none' }}
                                                                    ref={videoInputChange}
                                                                    type="file"
                                                                    onChange={(e) => handleVideoFileChange(e)}
                                                                    accept="video/mp4"
                                                                />
                                                                <Button onClick={() => handleCreateClip()} sx={{ margin: '10px' }} className='rounded-btn pr-20 pl-20 bg-selected mw-220 text-light'>
                                                                    continue
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    sx={{
                                                        margin: { xs: '10px', sm: '20px' },
                                                    }}
                                                    onClick={() => videoInputChange.current.click()}
                                                    className='rounded-btn pr-20 pl-20 text-selected bg-light mw-220'>
                                                    upload
                                                    <BackupOutlinedIcon style={{ marginLeft: '20px' }} />
                                                </Button>
                                                <input
                                                    style={{ display: 'none' }}
                                                    ref={videoInputChange}
                                                    type="file"
                                                    onChange={(e) => handleVideoFileChange(e)}
                                                    accept="video/mp4"
                                                />
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        :
                        ''
                    }
                </Box>

            </Modal>
            {/* <Modal
                open={openRecorder}
                onClose={() => handleCloseRecorder()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='relative'>
                    <CloseRoundedIcon
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            zIndex: 1,
                            margin: '10px'
                        }}
                        onClick={() => setOpenRecorder(false)} className='animate-close pointer close-icon'
                    />
                    <ClipRecorder
                        contentUrl={videoUrl}
                        handleDone={(data) => handleVideoUpload(data)}
                        handleReset={() => setVideoUrl('')}
                        blobType={'video/mp4'}
                    />
                </Box>
            </Modal> */}
        </>
    )
}
