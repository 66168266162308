import axiosInstance from "../http-common";


const getPartnerData = (page = 1, pagelimit = 20) => {
    let url = `native_main/watchingwith?page=${page}&pagelimit=${pagelimit}`;
    return axiosInstance.get(url);
};

const createGroup = (body) => {
    let url = `native_main/watchingwith`;
    return axiosInstance.post(url, body);
};

const leaveGroup = (body) => {
    let url = `native_main/watchingwithactivity`;
    return axiosInstance.post(url, body);
};

const getGroupHandpickedData = (id, page = 1, pagelimit = 20) => {
    let url = `native_main/groupmembershandpicked?watching_with=${id}&page=${page}&pagelimit=${pagelimit}&is_web=true`;
    return axiosInstance.get(url);
};

const getWatchingSliderData = (id, page = 1, pagelimit = 20) => {
    let url = `native_main/watchingwithslider?watching_with=${id}&page=${page}&pagelimit=${pagelimit}&is_web=true`;
    return axiosInstance.get(url);
};

const PartnerDataService = {
    getPartnerData,
    createGroup,
    leaveGroup,
    getGroupHandpickedData,
    getWatchingSliderData
};

export default PartnerDataService;