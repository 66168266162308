import { cardActionAreaClasses } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BlogsDataService from "../services/blogs.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    blogsCategoryList: [],
    commentListPageData: {},
    commentListData: [],
    replyListPageData: {},
    replyListData: [],
    webStoriesListData: [],
    webStoriesReelListData: [],
};

export const getBlogCategoryList = createAsyncThunk(
    "blog/blogcategorylist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.getBlogCategoryList()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getBlogs = createAsyncThunk(
    "blog/blogs",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.getBlogs(data?.section, data?.category, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getBlogDetail = createAsyncThunk(
    "blog/retrieveblog",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.getBlogDetail(data?.unique_title, data?.id, data?.section, data?.category, data?.index)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCommentLists = createAsyncThunk(
    "blog/blogcomment",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.getCommentLists(data?.id, data?.sort, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getRepliesList = createAsyncThunk(
    "blog/blogreply",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.getRepliesList(data?.id, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addBlogComment = createAsyncThunk(
    "blog/addblogcommnet",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.addBlogComment(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addBlogReply = createAsyncThunk(
    "blog/addblogreply",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.addBlogReply(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteCommentReply = createAsyncThunk(
    "blog/deletecommentblogreply",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.deleteCommentReply(data?.url)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const likeCommentReply = createAsyncThunk(
    "blog/bloglikecommentreply",
    async (data, { rejectWithValue }) => {
        try {
            const response = await BlogsDataService.likeCommentReply(data?.url)
            return { ...response.data, type: data?.type, id: data?.id };
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const webStoriesList = createAsyncThunk(
    "blog/webstorylist" , 
    async(data , {rejectWithValue} ) => {
        try {
            const response = await BlogsDataService.webStoriesList(data?.url)
            return { ...response.data, type:data?.type, id:data?.id};
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const webStoriesReelList = createAsyncThunk(
    "blog/webstoryreellist",
    async(data, {rejectWithValue}) => {
        try {
            const response = await BlogsDataService.webStoriesReelList(data?.unique_title)
            return response;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
)

export const blogsSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        resetCommentData: (state) => {
            state.commentListPageData = {};
            state.commentListData = [];
        }
    },
    extraReducers: {
        //webStoriesList
        [webStoriesList.pending]:(state,action) => {
            handlePendingRequest(state,action);
        },
        [webStoriesList.fulfilled]:(state,action) => {
            state.webStoriesListData = action.payload.data;
        },
        [webStoriesList.rejected]:(state,action) => {
            handleRejectedAction(state,action)
        },

         //webStoriesReelList
         [webStoriesReelList.pending]:(state,action) => {
            handlePendingRequest(state,action);
        },
        [webStoriesReelList.fulfilled]:(state,action) => {
            state.webStoriesReelListData = action.payload.data;
        },
        [webStoriesReelList.rejected]:(state,action) => {
            handleRejectedAction(state,action)
        },

        // getBlogCategoryList
        [getBlogCategoryList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getBlogCategoryList.fulfilled]: (state, action) => {
            state.blogsCategoryList = action.payload.data;
        },
        [getBlogCategoryList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getCommentLists
        [getCommentLists.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCommentLists.fulfilled]: (state, action) => {
            state.commentListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.commentListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.commentListData = helpers.removeDuplicate(state.commentListData.concat(getArrayData(state, action)));
            }
        },
        [getCommentLists.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [likeCommentReply.fulfilled]: (state, action) => {
            if (action.payload && action.payload.type == 'comment') {
                if (action.payload && action.payload.message == "liked") {
                    state.commentListData.map((x, i) => {
                        if (action.payload.id == x.comment_id) {
                            x.total_likes = x.total_likes + 1
                            x.is_liked = true
                        }
                    });
                } else {
                    state.commentListData.map((x, i) => {
                        if (action.payload.id == x.comment_id) {
                            x.total_likes = x.total_likes > 0 ? x.total_likes - 1 : x.total_likes
                            x.is_liked = false
                        }
                    });
                }
            }
        }
        // getRepliesList
        // [getRepliesList.pending]: (state, action) => {
        //     handlePendingRequest(state, action);
        // },
        // [getRepliesList.fulfilled]: (state, action) => {
        //     state.replyListPageData = getPayloadData(state, action);
        //     if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
        //         state.replyListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
        //     } else {
        //         state.replyListData = helpers.removeDuplicate(state.replyListData.concat(getArrayData(state, action)));
        //     }
        // },
        // [getRepliesList.rejected]: (state, action) => {
        //     handleRejectedAction(state, action)
        // },
    }
})

export const { clearMessages, resetCommentData } = blogsSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default blogsSlice.reducer;