import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import helpers from "../../services/helpers";

export default function FeaturedReview(props) {
  const { data } = props;
  const [fullText, setFullText] = useState([]);

  const handleFullText = (i) => {
    if (fullText.includes(i)) {
      var tempArr = fullText;
      const index = tempArr.indexOf(i);
      if (index > -1) {
        tempArr.splice(index, 1);
        setFullText([...tempArr]);
      }
    } else {
      setFullText([...fullText, i]);
    }
  };

  return (
    <Grid container className="bg-secondary pb-24">
      <Grid item className="app-container">
        <p className="fs-28 fw-700 m-0">featured reviews</p>
      </Grid>
      <Grid item xs={12} className="pr-30 pl-30">
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          interval={3000}
          autoPlay={true}
          infiniteLoop={true}
        >
          {data &&
            data.length > 0 &&
            data.map((x, i) => {
              return (
                <Grid container className="app-container" key={i}>
                  <Grid item xs={12} className="pb-50 pt-20">
                    <div>
                      <Grid container flexWrap={"nowrap"}>
                        <Grid item sm={1} xs={4}>
                          <Box
                            component="img"
                            sx={{
                              height: 101,
                              width: 101,
                              maxHeight: { xs: 71, sm: 101 },
                              maxWidth: { xs: 71, sm: 101 },
                              borderRadius: "8px",
                            }}
                            alt="poster"
                            src={
                              x.poster
                                ? helpers.getFullUrl(x.poster)
                                : helpers.getDefaultImage("reviews")
                            }
                          />
                          <p className="fs-20 fw-600 text-center m-0">
                            {x.user_rating ? x.user_rating : ""}
                          </p>
                        </Grid>
                        {x?.comment_type === "text" && (
                          <Grid sm={11} xs={8} item className="ml-25">
                            <p
                              style={{ color: "#979797" }}
                              className="fs-12 fw-500 text-left "
                            >
                              {x.user_name ? x.user_name : ""}
                            </p>
                            <p className="fs-20 text-left m-0 fw-600">
                              {helpers.getRatingText(Number(x.user_rating))}
                            </p>
                            {x.comment && x.comment !== "" && (
                              <p
                                style={{ color: "#D9D9D9" }}
                                className="fs-14 fw-400 text-left"
                              >
                                “
                                {fullText.includes(i)
                                  ? x.comment
                                  : x.comment.substring(0, 300)}{" "}
                                {x.comment.length > 300 && (
                                  <span
                                    onClick={() => handleFullText(i)}
                                    className="text-selected pointer"
                                  >
                                    {fullText.includes(i) ? "LESS" : "MORE"}
                                  </span>
                                )}
                                ”
                              </p>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </div>

                    { /*<Grid className="bg-secondary mb-10">
                      <Grid container className="pt-12" flexWrap={"nowrap"} justifyContent={"space-between"}>
                        <Grid item className=" pl-17 pb-28" xs={8}>
                          <p className="text-selected fs-16 fw-700 m-0">frenzied</p>

                        </Grid>
                        <Grid item xs={2} className="pr-10">
                          <Typography className="fs-24 fw-400">
                            {helpers.getRatingText(Number(x.imdb_rating))}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} className="mr-20 fs-30" sx={{ height: "32px", width: "32px", borderRadius: "50%", backgroundColor: "white", }} border={"1px solid rgba(217, 217, 217, 1)"} >

                        </Grid>

                      </Grid>
                      {x?.comment_type === "text" && (
                        <Grid container>
                          <Grid item className=" pl-17" xs={3.5}>
                            <Box
                              component="img"
                              sx={{
                                height: 99,
                                width: 66,
                                maxHeight: { xs: 99, sm: 99 },
                                maxWidth: { xs: 66, sm: 66 },
                                borderRadius: "8px",
                              }}
                              alt="banner"
                              src={
                                x.poster
                                  ? helpers.getFullUrl(x.poster)
                                  : helpers.getDefaultImage("reviews")
                              }
                            />
                          </Grid>

                          <Grid item xs={8.5}>

                            {x.comment && x.comment !== "" && (
                              <p
                                style={{ color: "#D9D9D9" }}
                                className="fs-14 fw-400 text-left pr-10"
                              >
                                “
                                {fullText.includes(i)
                                  ? x.comment
                                  : x.comment.substring(0, 300)}{" "}
                                {x.comment.length > 300 && (
                                  <span
                                    onClick={() => handleFullText(i)}
                                    className="text-selected pointer"
                                  >
                                    {fullText.includes(i) ? "LESS" : "MORE"}
                                  </span>
                                )}
                                ”
                              </p>


                            )}
                          </Grid>
                        </Grid>
                      )}
                      <Grid container>
                        <Grid item className="pt-40 pl-17" xs={8}>
                          <Grid container justifyContent={"space-between"}>
                            <Grid item className="rounded-circle pointer">
                              <ThumbUpAltOutlinedIcon />
                            </Grid>
                            <Grid item className="rounded-circle pointer">
                              <OutlinedFlagIcon />
                            </Grid>
                            <Grid item className="rounded-circle">
                              <ShareMovie hideShare={true} size={24} width={40} height={40} />
                            </Grid>
                            <Grid item className='pt-10'>
                              <Typography sx={{ color: '#FFFFFF' }} className="fs-12 fw-500">14,000 likes</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} >
                          <Grid item className="pl-60 pb-10">
                            <Avatar sx={{ width: 32, height: 32 }}>H</Avatar>
                          </Grid>
                          <Typography className="fs-10 pl-30 fw-500 pb-10" sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                            {x.user_name ? x.user_name : ""}

                          </Typography>
                          <Grid item className="pl-40 m-0" sx={{ height: 40, width: "100%", maxWidth: '110px' }}>
                            <FollowUnfollowPerson data={{}} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>*/}
                  </Grid>
                </Grid>
              );
            })}
        </Carousel>
      </Grid>
    </Grid>
  );
}
