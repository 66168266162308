import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getAdData } from '../../slices/common';

export default function MyAds(props) {
    const { type, adv_type, hideInMobile, mobtype, slotId, width, height } = props;
    const [isShowAd, setIsShowAd] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdData({ name: adv_type })).then((resp) => {
            if (resp.payload.data && resp.payload.data.is_active) {
                setIsShowAd(true);
                let is_mobile = window.screen.width < 500;
                setIsMobile(is_mobile);
                setTimeout(() => {
                    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});
                }, 700)
            }
        })
    }, [adv_type])


    return (
        <>
            {isShowAd ?
                <>
                    {isMobile ?
                        <>
                            {!hideInMobile ?
                                <>
                                    {mobtype === 'mobile_1' ?
                                        <div style={{ maxWidth: '100%' }} className='mobilex text-center w-100'>
                                            <ins className="adsbygoogle"
                                                style={{
                                                    display: 'inline-block',
                                                    width: width ? `${width}px` : '320px',
                                                    height: height ? `${height}px` : '50px',
                                                }}
                                                data-ad-client="ca-pub-4165705111926933"
                                                data-ad-slot={slotId || "5157795350"}>
                                            </ins>
                                        </div>
                                        :
                                        <div style={{ maxWidth: '100%' }} className='mobilex text-center w-100'>
                                            <ins className="adsbygoogle"
                                                style={{ display: 'inline-block', width: '300px', height: '250px' }}
                                                data-ad-client="ca-pub-4165705111926933"
                                                data-ad-slot="8377301392">
                                            </ins>
                                        </div>
                                        // <div style={{ maxWidth: '100%' }} className='mobilex w-100'>
                                        //     <ins className="adsbygoogle"
                                        //         style={{ display: 'block', textAlign: 'center', width: '100%' }}
                                        //         data-ad-layout="in-article"
                                        //         data-ad-format="fluid"
                                        //         data-ad-client="ca-pub-4165705111926933"
                                        //         data-ad-slot="6549214409">
                                        //     </ins>
                                        // </div>
                                    }
                                </>
                                :
                                ''
                            }
                        </>
                        :
                        <div className='desktopx'>
                            {type === 'native_responsive' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'block' }}
                                        data-ad-format="autorelaxed"
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="7419442944">
                                    </ins>
                                </>
                            }
                            {type === 'inarticle_native' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'block', textAlign: 'center' }}
                                        data-ad-layout="in-article"
                                        data-ad-format="fluid"
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="6549214409">
                                    </ins>
                                </>
                            }
                            {type === 'feed_native' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'block' }}
                                        data-ad-format="fluid"
                                        data-ad-layout-key="-70+ez-s-4l+b4"
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="5176422982">
                                    </ins>
                                </>
                            }
                            {/* <!-- 300x600_Banner --> */}
                            {type === 'banner_1' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'inline-block', width: '300px', height: '600px' }}
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="5031284455">
                                    </ins>
                                </>
                            }
                            {/* <!-- 728x90_Banner --> */}
                            {type === 'banner_2' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'inline-block', width: '728px', height: '90px' }}
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="2872589623">
                                    </ins>
                                </>
                            }
                            {/* <!-- 300x250_Banner --> */}
                            {type === 'banner_3' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'inline-block', width: '300px', height: '250px' }}
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="8377301392">
                                    </ins>
                                </>
                            }
                            {type === 'banner_4' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'inline-block', width: '160px', height: '600px' }}
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-slot="8089716072">
                                    </ins>
                                </>
                            }
                            {type === 'frenzi_responsive' &&
                                <>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'block' }}
                                        data-ad-client="ca-pub-4165705111926933"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"
                                        data-ad-slot="5535022778">
                                    </ins>
                                </>
                            }
                        </div>
                    }
                </>
                :
                ''
            }
        </>
    )
}
