import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import MyHelmet from '../common/MyHelmet';

export default function TermsAndCondition() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Grid container className='new-container'>
            <MyHelmet
                title={"terms & conditions"}
                description={"Explore Frenzi's Terms and Conditions to understand the guidelines that shape our platform and ensure a fair experience for all users."}
            />
            <Grid item className='pl-45'>
                <Grid container sx={{ justifyContent: { xs: 'center', sm: 'start' } }}>
                    <Grid item xs={8}>
                        <p className='fs-42 fw-800  lh-50'>Terms and Conditions</p>
                        <p className=' lh-25'>This Policy describes how we treat personal information on the websites where it is located. </p>
                    </Grid>
                </Grid>
                <Grid item className='mt-50 pt-50'>
                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4}
                            sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-20 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'>DEFINITIONS</h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: 0, sm: '45px' } }} >
                            <p className='fw-400 lh-25 text-muted' sx={{ marginBottom: '16px' }}>
                                Unless otherwise specified, the capitalized terms shall have the meanings set out below:</p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted' > <b>Account</b> <span className='lh-25 text-muted'>means and includes the account created on the Frenzi Mobile Application and Website, by the User, in accordance with the terms of the Agreement, registered with and approved by Frenzi.</span></p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'> <b>Agreement</b> <span className='lh-25 text-muted'>means and includes the Terms and Conditions, Privacy Policy and any other such terms and conditions that may be mutually agreed upon between Frenzi and the User in relation to the Services.</span></p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'><b>Applicable Law</b> <span className='lh-25 text-muted' >means and includes any statute, law, regulation, sub-ordinate legislation, ordinance, rule, judgment, rule of law, order (interim or final), writ, decree, clearance, Authorizations, approval, directive, circular guideline, policy, requirement, code of practice or guidance note, or other governmental, regulatory, statutory, administrative restriction or any similar form of decision, or determination by, or any interpretation or administration of any of the foregoing by, any statutory or regulatory authority or government agency or any other authority, in each case having jurisdiction over the subject matter of this Agreement.</span></p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'>
                                <b>Computer Virus</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes any computer instruction, information, data or programme that destroys, damages, degrades or adversely affects the performance of a computer resource or attaches itself to another computer resource and operates when a programme, data or instruction is executed or some other event takes place in that computer resource.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'>
                                <b>Confidential Information</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes all information that is not in the public domain, in spoken, printed, electronic or any other form or medium, relating directly or indirectly to, the assets, business processes, practices, methods, policies, subscription plans, publications, documents, research, operations, services, strategies, techniques, agreements, contracts, terms of agreements, transactions, potential transactions, negotiations, pending negotiations, know-how, trade secrets, computer programs, computer software, applications, operating systems, software design, web design, work-in-process, databases, manuals, records and reports, articles, systems, material, sources of material, supplier identity and information, vendor identity and information, User identity and information, financial information, results, accounting information, accounting records, legal information, marketing information, advertising information, pricing information, credit information, developments, internal controls, security procedures, graphics, drawings, sketches, sales information, costs, formulae, product plans, designs, ideas, inventions, original works of authorship, discoveries and specifications, of Frenzi and/or affiliates or their respective businesses, or any existing or prospective customer, supplier, investor or other associated third party, or of any other person or entity that has entrusted information to Frenzi in confidence.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'>
                                <b>Content</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes any information all data and information on the&nbsp; Frenzi Mobile Application and Website.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }}
                                className='lh-25 text-muted' >
                                <b>Government Authority</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes any government, any state or other political subdivision thereof, any entity exercising executive, legislative, judicial, regulatory or administrative functions of or pertaining to government, or any other government authority, agency, department, board, commission or instrumentality or any political subdivision thereof, and any court, tribunal or arbitrator(s) of competent jurisdiction, and, any Government or non-Government self-regulatory organization, agency or authority; having jurisdiction over the Agreement and the Services contemplated under the Agreement.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }}
                                className='lh-25 text-muted'>
                                <b>Information Technology Act</b>
                                <span className='lh-25 text-muted'>&nbsp;means the Information Technology Act, 2000.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'>
                                <b>Intellectual Property</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes patents, inventions, know how, trade secrets, trademarks, service marks, designs, tools, devices, models, methods, procedures, processes, systems, principles, algorithms, works of authorship, flowcharts, drawings, and other confidential and proprietary information, data, documents, instruction manuals, records, memoranda, notes, user guides, ideas, concepts, information, materials, discoveries, developments, and other copyrightable works, and techniques in either printed or machine-readable form, whether or not copyrightable or patentable, each of which is not in the public domain or would by its very nature fall within public domain.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Intellectual Property Rights</b>
                                <span className='lh-25 text-muted'>&nbsp;mean and include (a) all right, title, and interest under including but not limited to patent, trademark, copyright under the Patents Act, 1970, Trademarks Act, 1999 and Copyright Act, 1957 respectively; any statute or under common law including patent rights; copyrights including moral rights; and any similar rights in respect of Intellectual Property, anywhere in the world, whether negotiable or not; (b) any licenses, permissions and grants in connection therewith; (c) applications for any of the foregoing and the right to apply for them in any part of the world; (d) right to obtain and hold appropriate registrations in Intellectual Property; (e) all extensions and renewals thereof; (f) causes of action in the past, present or future, related thereto including the rights to damages and profits, due or accrued, arising out of past, present or future infringements or violations thereof and the right to sue for and recover the same; (g) any Confidential Information.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Frenzi</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes Frenzi Intelligence Private Limited, a company incorporated under the Companies Act, 2013 having its registered office at Mumbai, India including its officers, directors, employees and representatives along with its&nbsp; Frenzi Mobile Application and Website.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Privacy Policy</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes the privacy policy of Frenzi&nbsp;</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Prohibited Conduct</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes the User’s use of the Service in contravention of the Agreement and Applicable Law; violation of or the abetment of violation of third party rights; infringement or misappropriation of Frenzi’s or any persons Intellectual Property Right; attempt to gain or assist another person/ User to gain unauthorized access to the&nbsp; Frenzi Mobile Application and Website and/or Services or its related systems or networks; create internet links to the&nbsp; Frenzi Mobile Application and Website or “frame" or “mirror" any content on any other server or wireless or Internet-based device; act of sending spam, duplicated or unsolicited messages; usage or storage of obscene, threatening, libellous, or otherwise unlawful or tortious material, including material harmful to children or in violation of third party privacy rights leading to harassment, annoyance, anxiety or inconvenience to any person; modify or make derivative works based upon the Service and/or impersonation in relation to any person or entity, claiming a false affiliation, accessing any other Account without permission, or falsely representing User Information.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Registration Data</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes the mobile number, e-mail address, username and such other particulars and/or information as may be required by Frenzi and supplied by the User on initial application and subscription.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Personal Information</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes details of Aadhaar Card, PAN Card, biometric information, financial information such as bank account, debit card, credit card details and such other sensitive information, of the User, and/or concerned person which personally identifies such User and/or person.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Services</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes the services provided by Frenzi within the territory of India to the User of the&nbsp; Frenzi Mobile Application and Website and shall include the provision of (a) connecting tenants and owners to find each other without paying any brokerage, (b) services to the Users who wish to post their listings for the purposes of renting their property, (c) services to the Users who wish to secure/buy a property through the&nbsp; Frenzi Mobile Application and Website, (d) services to the Users pertaining to paperwork and documentation processing, relating to lease agreement registration, bank franking, police verification and society approvals with regard to the rental property listed on its web Frenzi Mobile Application and Website. (e) services to those who wish to purchase advertisement space of their products or services on the&nbsp; Frenzi Mobile Application and Website, (f) services to the Users who wish to receive advertisements and promotional messages on the&nbsp; Frenzi Mobile Application and Website and through e-mails and text messages, (g) providing residents with a building specific network to enable interaction with neighbours for sharing knowledge of the neighbourhood, (h) services to the Users allowing them to pay rental money to the other Users through the&nbsp; Frenzi Mobile Application and Website using third party payment gateways, (i) connecting the Users to various Third Party Service Providers for the provision of other related services.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Frenzi Mobile Application and Website</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes the web Frenzi Mobile Application and Website owned, operated and managed by Frenz Intelligence Private Limited&nbsp;</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>User Terms and Conditions</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes the terms and conditions contained in the Agreement more particularly set out in Section I.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>Third Party Service Provider</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes any service provider with whom Frenzi has an agreement for the purposes of making services in addition to the Services available for the User.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>User </b>
                                <span className='lh-25 text-muted'>means any person who accesses the&nbsp; Frenzi Mobile Application and Website or avails Services on the&nbsp; Frenzi Mobile Application and Website for the purposes of hosting, publishing, sharing, transacting, displaying or uploading information or views and whether as a tenant or as an owner looking for potential tenants includes other persons jointly participating in using the&nbsp; Frenzi Mobile Application and Website.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <b>User Information</b>
                                <span className='lh-25 text-muted'>&nbsp;means and includes Registration Data, Personal Information and any other data, details or information in relation to the User, provided by the User to Frenzi in relation to the Services, whether through the&nbsp; Frenzi Mobile Application and Website, e-mail or any other form of communication, agreeable to the User and Frenzi.</span>
                            </p>



                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>

                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-20 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> INTERPRETATION </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <p sx={{ marginBottom: '16px' }} className='lh-25 text-muted'
                            >
                                <span className='lh-25 text-muted'>Unless the context otherwise requires or a contrary indication appears:</span>
                            </p>


                            <p sx={{ marginBottom: '16px' }}
                            >
                                <span className='lh-25 text-muted'> <strong>(a)</strong> Reference to any legislation or law shall include references to any such legislation or law as it may from time to time be amended, supplemented or re-enacted, and any reference to a statutory provision shall include any subordinate legislation made from time to time under that provision.</span>
                            </p>


                            <p sx={{ marginBottom: '16px' }}
                            >
                                <span className='lh-25 text-muted'> <strong>(b)</strong> Any reference in this Agreement to a person includes any individual, corporation, partnership, unincorporated organization or governmental agency and shall include their respective successors and permitted assigns and in case of an individual shall include his legal representatives, administrators, executors and heirs and in case of a trust shall include the trustee or the trustees for the time being.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }}
                            >
                                <span className='lh-25 text-muted'> <strong>(c)</strong> Headings to Sections and Clauses are for ease of reference only and shall be ignored in the construction of the relevant Sections and Clauses.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }}
                            >
                                <span className='lh-25 text-muted'> <strong>(d)</strong> The singular includes the plural and vice versa and words importing a particular gender include all genders.</span>
                            </p>

                            <p sx={{ marginBottom: '16px' }}
                            >
                                <span className='lh-25 text-muted'> <strong>(e)</strong> The words ‘other’ or ‘otherwise’ and ‘whatsoever’ will not be construed ejusdem generis or be construed as any limitation upon the generality of any preceding words or matters specifically referred to.</span>
                            </p>



                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>

                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'>ACCEPTANCE OF THE TERMS AND CONDITIONS</h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <ol>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'> Frenzi agrees to provide to the User and the User agrees to avail from Frenzi the Services in accordance with and on the basis of this Agreement and the User’s acceptance of the Terms and Conditions and Privacy Policy constitute the Agreement between the User and Frenzi and such Agreement shall deem to replace all previous arrangements between the User and Frenzi in relation to the Services and access of the&nbsp; Frenzi Mobile Application and Website.</span>
                                </li>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'>The User undertakes to be bound by the Agreement each time the User accesses the&nbsp; Frenzi Mobile Application and Website, completes the registration process</span>
                                </li>
                                <li className='lh-25 text-muted' style={{ marginBottom: '16px' }}>The User represents and warrants to Frenzi that the User is 18 (eighteen) years of age or above, and is capable of entering into, performing and adhering to the Agreement. Individuals under the age of 18 (eighteen) may access the&nbsp; Frenzi Mobile Application and Website or avail a Service only with the involvement, guidance, supervision and/or prior consent of their parents and/or legal guardians, through the Account of or under the guidance of such parent/ legal guardian. Frenzi shall not be responsible for verifying if the User is atleast 18 (eighteen) years of age.</li>
                            </ol>


                            {/* <p sx={{ borderLeft: '1px solid red' }}
                                className='pl-45'>

                            </p> */}
                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>

                        <Grid item xs={8} sm={4} sx={{
                            borderRight: '1px solid red',
                            paddingRight: { xs: '10px', sm: '45px' }
                        }}>
                            <p className='fs-24 lh-24  fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'>
                                    ACCESS TO THE FRENZI MOBILE APPLICATION AND WEBSITE
                                </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                            <ol>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'> First time Users can access the&nbsp; Frenzi Mobile Application and Website for preliminary browsing without creating an Account.</span>
                                </li>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'>The User undertakes and agrees to provide User Information, uploading Content and create an Account in order to retrieve specific information and avail the Services.</span>
                                </li>
                                <li className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Frenzi shall verify the Account by requesting for the one-time password from the User. The User undertakes and agrees that a mobile number can only be used once to create an Account. The User is prohibited from creating multiple Accounts and Frenzi reserves the right to conduct appropriate verification to detect such multiplicity of Accounts and take appropriate action.</li>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'>The User undertakes to cooperate with any of Frenzi’s personnel in connecting with the User’s access to the&nbsp; Frenzi Mobile Application and Website, as may be required by Frenzi.</span>
                                </li>
                            </ol>


                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> USER INFORMATION </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>


                            <ol>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'> Frenzi shall not be responsible for the accuracy, quality, integrity, legality, reliability, appropriateness and Intellectual Property Rights of and/or in relation to the User Information.</span>
                                </li>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'>Frenzi shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any User Information, in whole or in part.</span>
                                </li>
                                <li className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Frenzi reserves the right to withhold access, remove or discard any User Information from the&nbsp; Frenzi Mobile Application and Website, in whole or in part at on its sole discretion.</li>
                                <li style={{ marginBottom: '16px' }}>
                                    <span className='lh-25 text-muted'>Frenzi shall have the right but not an obligation to maintain or forward any User Information and to verify the authenticity of any User Information made available to it through the&nbsp; Frenzi Mobile Application and Website or otherwise.</span>
                                </li>
                            </ol>


                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> RIGHTS OF FRENZI </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                            <ol >
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'> Frenzi, at all times, reserves the right, to reject or disable an Account in the event of the User’s violation of any Applicable Law or anything done by the User in contravention of this Agreement and including but not limited for any other reason in relation to the safe and secure operation of the&nbsp; Frenzi Mobile Application and Website.</span></li>
                                <li className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Frenzi has the right to modify, suspend or terminate all or any part of the Services including its features, structure, fees and layout, and/or suspend or deactivate, whether temporarily or permanently, the User Account and/or User’s access to the&nbsp; Frenzi Mobile Application and Website, at any time, without any prior notice to the User.</li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>Frenzi shall endeavor to provide the User an advance notice, without any obligation to do so, of any suspension or deactivation of the&nbsp; Frenzi Mobile Application and Website for the purposes of repair, any inspection or testing by a Government Authority, or for maintenance, upgradation, testing or any other reason as Frenzi may deem fit. Frenzi shall use its best efforts to rectify any disruption of or rectify an error on the&nbsp; Frenzi Mobile Application and Website and restore regular operations of the&nbsp; Frenzi Mobile Application and Website.</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>Frenzi has the right to issue notifications, confirmations and other communications to the User, on the&nbsp; Frenzi Mobile Application and Website, through e-mail, text message, Notification and WhatsApp or any other form as may be agreed to by the User and/or send promotional or other relevant information in relation to the Services to the User. These communications shall be for account updates, community updates and deals being offered to the user</span></li>
                                <li className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Frenzi may also send users occasional e-mail bulletins unless the Users have opted not to receive these e-mails. These e-mails may also contain offer provided by third party. Frenzi does not take any responsibility of the validity of any of these offers. The Users shall have the right to unsubscribe from receiving any such notifications or promotional information at any time by sending a mail to support@Frenzi.in.</li>
                            </ol>


                        </Grid>
                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> EUROPEAN UNION DATA PROTECTION RIGHTS </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <ul>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'> Subject to Applicable Law and in accordance with the European Union General Data Protection Regulations (EU GDPR), Frenzi acknowledges and undertakes to comply on a best effort basis with the rights available to its Users of European nationality/ EU residents set out below:</span></li>
                            </ul>
                            <ol>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to know the purposes for which the User Information shall be collected and used;</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to access information that personally identifies the User;</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to rectify incorrect information;</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to delete or remove information after discontinuation of the Services;</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to data portability;</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to restrict data or information from being processed; and</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>the right to opt out of providing or sharing information with Frenzi.</span></li>
                            </ol>
                            <ul>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'> The Users of Frenzi to whom the EU GDPR applies, understand and acknowledge that these rights shall at all times be subject to Applicable Law and are not an exhaustive list of the rights available pursuant to the EU GDPR. The relevant Users shall exercise any of these rights by raising a personal and direct request with Frenzi on hello@Frenzi.in.</span></li>
                            </ul>


                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> UNAUTHORIZED ACCESS </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <ol>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>The User shall be liable for all acts conducted through the User’s Account and shall be responsible for the safekeeping of the details and password of the Account.</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'> Subject to Applicable Law, the User is responsible for notifying Frenzi immediately upon becoming aware of any unauthorized access into or misuse of the Account causing a breach of security as per the Terms and Conditions and Privacy Policy of Frenzi.</span></li>
                                <li className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Frenzi shall extend support by ensuring immediate termination or suspension of such Account and shall take such other appropriate safety measures as it may deem necessary. Further, Frenzi shall not be held liable for any unauthorized access into the Account and/or any loss or damage caused to the User by such unauthorized access or loss or damage caused as a consequence of the delay or failure of the User in informing Frenzi about such unauthorized access.</li>
                                <li><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>In order to better protect the secrecy of the User Information, the User is encouraged to change the password of the Account from time to time.</span></li>
                            </ol>


                        </Grid>
                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'>  USE OF INFORMATION </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <ol>
                                <li style={{ marginBottom: '16px' }}>
                                    <p><span className='lh-25 text-muted'>Frenzi undertakes that the procurement, storage, usage and dissemination of all information including User Information and/or Content, as the case may be, pursuant to this Agreement, shall at all times, including upon expiration of the Services or termination of the Agreement with the User, be in accordance with the Information Technology Act 2000, the rules made thereunder and other relevant Applicable Laws.</span></p>
                                </li>
                                <li style={{ marginBottom: '16px' }}>
                                    <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}>The User hereby irrevocably and unequivocally authorizes Frenzi to utilize User Information for the purposes including those set out below:</p>
                                    <p style={{ marginBottom: '16px' }} ><span className='lh-25 text-muted'>(a) provision of Services in accordance with the Agreement;</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) disclose User Information to its directors, officers, employees, advisors, auditors, counsel, or its authorized representatives on a need to know basis for provision of the Services;</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(c) contacting a Third Party Service Provider and/ or facilitating/ enabling the services of a Third Party Service Provider for the User pursuant to the arrangement between Frenzi and such Third Party Service Provider;</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(d) conducting internal studies, consumer research, surveys and preparing reports in connection with the Services;</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(e) entering the registration data for an Account or receiving alerts, contacting a property seller/ buyer; and</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(f) sending alerts, contact details, promotional messages and promotional calls whether by Frenzi itself or through its partners/ vendors and sub-partners/ sub-vendors.</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(g) disclosing to third parties (including law enforcement agencies and the User’s building management personnel) personally identifiable information where Frenzi has reasonable cause to believe that the Users are guilty of any Prohibited Conduct.</span></p>
                                    <p style={{ marginBottom: '16px' }}><b className='lh-25 text-muted'>iii. Third party services</b></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(a) Subject to the need and request of the User, Frenzi shall engage, directly or indirectly, the services of Third-Party Service Providers from time to time in order to provide the Services to the Users, in accordance with the terms and conditions separately agreed to between Frenzi and such Third-Party Service Provider.</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) Frenzi shall have the unequivocal consent of the User to share User Information, in whole or in part, with the Third-Party Service Provider, without intimation to the User.</span></p>
                                    <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(c) The&nbsp; Frenzi Mobile Application and Website may serve as a platform for relevant and interested Third Party Service Providers for the purposes of advertising or promoting their services in relation to the Services provided by Frenzi.</span></p>
                                    <p><span className='lh-25 text-muted'>(d) Nothing contained herein shall constitute or be deemed to constitute an agency or partnership or association of persons for and on behalf of Frenzi or any Third-Party Service Provider. The arrangement specified in this clause is strictly executed on principal to principal basis and each concerned person shall be bound for their distinct responsibilities, rights, liabilities and obligations in accordance with the relevant bilateral agreement between such persons.</span></p>
                                </li>
                            </ol>


                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-20 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> Tailored Advertising </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(a) The User acknowledges and agrees that Third Party Service Providers may use cookies or similar technologies to collect information about the User’s pattern of availing the Services, in order to inform, optimize, and provide advertisements based on the User’s visits on the&nbsp; Frenzi Mobile Application and Website and general browsing pattern and report how Third-Party Service Providers advertisement impressions, other uses of advertisement services, and interactions with these impressions and services are in relation to the User’s visits on such third party’s web Frenzi Mobile Application and Website.</span></p>
                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) Frenzi also permits Third Party Service Providers to serve tailored advertisements to the User on the&nbsp; Frenzi Mobile Application and Website, and further permits them to access their own cookies or similar technologies on the User’s device to access the&nbsp; Frenzi Mobile Application and Website or avail the Services.</span></p>
                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(c) The User undertakes and agrees that when accessing the Services from a mobile application the User may also receive tailored in-application advertisements. Each operating system provides specific instructions on the prevention of tailored in-application advertisements.</span></p>
                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(d) It is to be noted that Frenzi does not guarantee the accuracy, integrity or quality of any content provided by such Third-Party Service Provider. Further, the Users interactions with such Third-Party Service Providers found on or through the Services provided by Frenzi on the&nbsp; Frenzi Mobile Application and Website, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the Users and the Third Party Service Providers.&nbsp;</span><b><i>In no event shall Frenzi be liable for any damages arising out of any interaction between the User and such Third Party Service Provider. The information provided on the&nbsp; Frenzi Mobile Application and Website is provided to the Users on an “AS IS, WHERE IS" basis.</i></b></p>

                        </Grid>


                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-20 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> FRENZI PLATFORM </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                            <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}>1.1 <b>About Us:<br /></b><span className='lh-25 text-muted'>Frenzi allows you to discover movies, web-series, shows and various other content for free but also rewards its users for engagement. Frenzi is also a social platform which helps you connect with your friends and families to create your private groups or community. Our services are accessible through web browsers, apps or internet connected mobile phones/TVs/computers and various compatible devices discussed in System Requirements below.</span><span><br /></span><span className='lh-25 text-muted'>Our content and services are available only to our registered members by way of a sign-up process.</span></p>
                            <p style={{ marginBottom: '16px' }}><span><br /></span><span>1.2.&nbsp;</span><b className='lh-25 text-muted'>Access &amp; Personalization:</b><span><br />
                            </span><span className='lh-25 text-muted'>We offer features to personalize your digital streaming experience by creating a profile to access our Service. For the purpose of registration, we would require certain information as prompted by the applicable registration form explained further under User Accounts &amp; Memberships. Such personal information submitted is subject to the Privacy Policy of Frenzi.</span><span><br /></span><span className='lh-25 text-muted'>You will have control of your account and the password so created. While you may share your account details with members of your household, please be aware that it would be your responsibility to maintain its confidentiality and to restrict access to such account.</span><span></span></p>
                            <p></p>
                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>1.3.&nbsp;</span><b className='lh-25 text-muted'>System Requirements:</b><span><br /></span><span className='lh-25 text-muted'>The use of our services are subject to certain minimum requirements of hardware, software, and network resources.</span></p>
                            <ul>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>To access our services, you will need a device that meets the system and compatibility requirements. We may change the requirements for compatible devices from time to time and in some cases whether a device is (or remains) a compatible device may depend on software or systems provided or maintained by the device manufacturer or other third parties and hence, compatible devices may cease to be so in the future dependent on such factors.</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>The functionality, and features of our services that you receive on your device will be contingent on a variety of factors including the type of device used, the configuration of such device, the kind of content selected and the internet bandwidth available.&nbsp;</span></li>
                                <li style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>Some of the services we offer might be available on other third-party platforms and devices which may again be dependent on software or systems provided or maintained by such platforms. You understand that we would not be able to take up any responsibility for such content or features as available in these third-party platforms or devices.</span></li>
                            </ul>
                            <p style={{ marginBottom: '16px' }}><span><br /></span><span className='lh-25 text-muted'>1.4.&nbsp;</span><b className='lh-25 text-muted'>Modification of Content:<br /></b><span className='lh-25 text-muted'>We update our content library regularly for a better content discovery experience and reserve the right to modify, suspend, or discontinue the service or any part thereof, at any time without notice. Such modification may impact your ability to use certain part of our services or features and may change the availability of content. Additionally, for various reasons, such as restrictions under the law in force, or otherwise, certain content and functionality may be changed, suspended, or discontinued either temporarily or permanently with respect to any or all users.</span></p>
                            <p><span><br /></span><span>1.5.&nbsp;</span><b className='lh-25 text-muted'>Troubleshooting and Grievance Redressal Mechanism:</b><span><br /></span><span className='lh-25 text-muted'>In the event you require any assistance or troubleshooting with your account, features or usage of our platform or our services, please write to us at&nbsp;feedback@frenzi.in. Under circumstances wherein you find the content on our Platform to be offensive or for any copyright issue (copyrighted work), please feel free to write to us on compliance@frenzi.in</span></p>

                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>

                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-24 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'>  USER ACCOUNT &amp; MEMBERSHIP </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>Your use of our content is subject to the User Terms and Conditions contained herein.</span><span></span></p>
                            <p></p>
                            <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>2.1.&nbsp;</span><b className='lh-25 text-muted'>Promotional Offers:</b>
                                <span><br /></span><span className='lh-25 text-muted'>We sometimes offer eligible customers certain additional features or promotional memberships, free of cost or at discounted rates, either directly or through third party alliances. Such memberships as offered by third parties in conjunction with their own products and services, may have separate terms and conditions which will be communicated by the third party or disclosed at the time of registration. We are not responsible for the services provided by such third parties. We may use information such as device ID, method of payment or an account email address used with the existing or recent membership to determine offer eligibility. The eligibility requirements and other limitations and conditions will be disclosed when you sign-up for the offer or in other communications made available to you.</span><span><br /></span></p>

                        </Grid>

                    </Grid>

                    <Grid container className='mb-48 jc-center'>
                        <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                            <p className='fs-24 lh-20 fw-300'
                                sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                <h4 className='mt-0'> Rewards/Tokens: </h4>
                            </p>
                        </Grid>

                        <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                            <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Frenzi, from time to time would offer rewards points “Frenzi Points” to the users based on watching content, engaging with the platform, sharing the application with other users and many more. The earned rewards will be stored in Frenzi’s digital wallet, which can be used in the Frenzi ecosystem only and utilized/redeemed for deals, digital goods, partner products and physical merchandise, among others. These rewards cannot be sold/exchanged in any other platform and not a form legal tender. The utility or the manner in which the rewards can be redeemed, will be determined by Frenzi and changed or modified from time to time.

                            </p>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>  USER’S TERMS AND CONDITIONS </h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}><b>User responsibilities</b><span><br /></span><span className='lh-25 text-muted'>For us to keep the Services safe and available for everyone to use, you must follow some rules. You agree to observe the terms and conditions below</span></p>
                        <p></p>
                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}><b>Usage Guidelines:<br /></b><span className='lh-25 text-muted'>Frenzi grants you right, to access and view our content for your personal and non-commercial use only and no other right/title/interest.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Usage Restrictions: You agree to use our Service, in accordance with all applicable laws, rules and restrictions for usage of Services and content and hereby acknowledge that you will not:</span><span><br /></span><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>(a) transfer, copy or display the content, or exploit the same for public performances;</span><span><br /></span><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>(b) sell, rent, lease, distribute, or broadcast any right to the content;</span><span><br /></span><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>(c) decompile, disassemble, reverse engineer, de-activate or circumvent the content or the service in any manner whatsoever;</span><span><br /></span><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>(d) remove any proprietary notices or labels on the content;</span><span><br /></span><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>(e) attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management or other content protection system used as part of the Service.</span></p>
                        <p><span className='lh-25 text-muted' style={{ marginBottom: '16px' }}>(f) Spam&nbsp;– This is not allowed on Frenzi (creating or submitting unwanted email, comments, likes or other forms of commercial or harassing communications).</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(g) We remove credible threats of violence, hate speech and the targeting of private individuals. We do not allow attacks or abuse based on race, ethnicity, national origin, sex, gender, gender identity, sexual orientation, religion, disability or disease.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(I) Any use of words abuses, profanity and individual outrageous comments towards individuals or group of individuals shall be removed or account deactivated for failure to maintain usage guideline</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(j) Frenzi shall reverse the Frenzi points gained by the user through any reverse engineering of the platform, circumvent a process or earmarked process. In the event of repeated violation the account shall be permanently deactivated. </span></p>

                    </Grid>

                </Grid>

                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>CONTENT ON THE FRENZI MOBILE APPLICATION AND WEBSITE</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>Frenzi shall endeavor to ensure that the Content on its&nbsp; Frenzi Mobile Application and Website is monitored to ensure that the same is not in contravention of Applicable Law and this Agreement. In this regard, the Users represent and warrant that they shall not do any of the following via the&nbsp; Frenzi Mobile Application and Website or otherwise in connection with the Service:</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(i) attempt or help anyone else attempt to gain unauthorized access to the&nbsp; Frenzi Mobile Application and Website or Frenzi’s related systems or networks (including without limitation the impersonation of another User or use of a false identity or address to gain access to the&nbsp; Frenzi Mobile Application and Website);</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(ii) use the&nbsp; Frenzi Mobile Application and Website to violate the Intellectual Property Rights of any person (including without limitation posting pirated music or computer programs or links to such material on&nbsp; Frenzi Mobile Application and Website or on the User’s Profile);</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(iii) send spam or otherwise duplicative or unsolicited messages in violation of Applicable Laws;</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(iv) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights;</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(v) send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs;</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(vi) interfere with or disrupt the integrity or performance of the Service or the data contained therein;</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(vii) license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service or any content contained in or made available through the Service in any way;</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(viii) modify or make derivative works based upon the Service or the Content of Frenzi;</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(ix) create internet “links" to the&nbsp; Frenzi Mobile Application and Website or “frame" or “mirror" any Content on any other server or wireless or internet-based device; or</span></p>
                        <p style={{ marginBottom: '16px' }}><span style={{ fontWeight: '400px' }} className='lh-25 text-muted'>(x) reverse engineer or access the Service in order to (a) build a product competitive with the Service, (b) build a product using ideas, features, functions or graphics similar to those of the Service, or (c) copy any ideas, features, functions or graphics contained in the Service.</span></p>

                    </Grid>
                </Grid>

                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>USER GENERATED CONTENT</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>The&nbsp; Frenzi Mobile Application and Website may contain user generated content&nbsp;</span><b>(“UGC”)</b><span className='lh-25 text-muted'>&nbsp;which Frenzi does not pre-screen and which contains views that may be opinions of Users and also of experts. These views do not represent Frenzi’s views, opinions, beliefs, morals or values. Frenzi does not claim any ownership rights in the text, files, images including photos, videos, sounds, musical works or any UGC that the Users submit or publish on the&nbsp; Frenzi Mobile Application and Website. After posting any UGC on the&nbsp; Frenzi Mobile Application and Website, the Users continue to own the rights that the Users may have in that UGC, subject to the limited license set out here. Frenzi shall do its best to monitor, edit or remove such UGC where Frenzi considers it appropriate or necessary to do so. Frenzi does not promise that the content in or on the&nbsp; Frenzi Mobile Application and Website is accurate, complete or updated, that it will not offend or upset any person or that it does not infringe the Intellectual Property Rights of third parties.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>The Users hereby expressly acknowledge and agree that Frenzi will not be liable for the User’s losses or damages (whether direct or indirect) caused by any activity undertaken by the User on the basis of any UGC.</span></p>

                    </Grid>
                </Grid>
                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>INTELLECTUAL PROPERTY</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>i. Frenzi respects the Intellectual Property Rights of others and asks its Users to do the same. The User shall have the sole responsibility for the Intellectual Property ownership or right to use of any information that the Users submit via the&nbsp; Frenzi Mobile Application and Website and the Users may not use the&nbsp; Frenzi Mobile Application and Website to transmit or reproduce someone else’s Intellectual Property.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>ii. The User shall be held liable for acts including but not limited to those set out below herein, for any such infringement of Frenzi’s Intellectual Property Rights:</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(a) misrepresentation of User Information or Content as their own property;</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) using the Content directly or indirectly, publicly or privately for charging brokerage from the Users;</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(c) using the Content directly or indirectly, publicly or privately for charging brokerage from any third party; and</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(d) using the Content to display Broker like behaviour.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>iii. The User shall not upload, post or otherwise make available on the&nbsp; Frenzi Mobile Application and Website, Intellectual Property without the express permission of the concerned owner and the User shall be solely liable for any damage resulting from any infringement of such Intellectual Property.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>vi. Frenzi reserves the right to initiate appropriate legal proceedings against any User or third party for an infringement of its Intellectual Property Rights, in accordance with Applicable Law.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>v. The User further accepts and agrees that Frenzi shall have Intellectual Property Rights on all information and data provided or shared by the User on the&nbsp; Frenzi Mobile Application and Website.</span></p>

                    </Grid>
                </Grid>
                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>OPERATIONAL HAZARDS/ COMPUTER VIRUS ATTACKS</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>i. Frenzi does not warrant in any manner whatsoever that the&nbsp; Frenzi Mobile Application and Website or any other software utilized by Frenzi for internal purposes, shall at all times remain free from any harmful components and operational hazards such as Computer Virus, worms, trojans and such related components that might be a threat to the information available with Frenzi.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>ii. Frenzi shall endeavour to keep the&nbsp; Frenzi Mobile Application and Website secured against any possible bugs, viruses or other technical problems in compliance with the best practices of this industry.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>iii.Frenzi shall not be held liable for any damage or injury caused due to performance, failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, Computer Virus, link failure,&nbsp; Frenzi Mobile Application and Website crash, malfunctioning or software/ hardware, unavailability of network, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information, whether resulting in whole or in part from negligence or otherwise.</span></p>

                    </Grid>

                </Grid>
                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-20 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>DISCLAIMER</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(a) The information and opinions available on the&nbsp; Frenzi Mobile Application and Website in relation to the Services are mere guidelines for the purposes of providing general information on the subject and any other information through or on the&nbsp; Frenzi Mobile Application and Website is not an offer and/or recommendation from/ by Frenzi.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) The Services are chosen and used at the User’s risk and discretion and Frenzi makes no representations or warranties, express or implied, to the User in relation to the Services or otherwise regarding this Agreement, including the implied warranties of merchantability and fitness for a particular purpose.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(c) The User understands and acknowledges that the User assumes certain risks prior to interacting with other Users/ persons/ Third Party Service Providers on and through the&nbsp; Frenzi Mobile Application and Website, and the User shall be solely responsible for such User’s personal assessment of specific requirements with regard to interaction with other Users/ persons/ Third-Party Service Providers.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(d) Frenzi is not responsible and liable for any views, opinions or reviews provided by any user or group of users, the opinion, views and reviews are completely responsibility of the users and Frenzi shall not he held responsible</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(e) Frenzi expressly disclaims any liability or responsibility whatsoever and howsoever arising as a result of any Content posted on the&nbsp; Frenzi Mobile Application and Website/ made available to Frenzi by any User or any third party or any deficiency in service that is caused to the User due to a Third-Party Service Provider.</span></p>
                        <p></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(f) Frenzi does not warrant the Services or the results obtained from the use or that the Services will meet the User’s expectations or requirements or that the&nbsp; Frenzi Mobile Application and Website will be uninterrupted or free from any technical error.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(g) Frenzi is not responsible for the accuracy or reliability of any third-party reports, market information, studies and analysis made available on the&nbsp; Frenzi Mobile Application and Website and any external web links mentioned on the&nbsp; Frenzi Mobile Application and Website.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(i) Frenzi shall not be liable for any damages, expenses, costs or losses incurring from the User’s engagement in Prohibited Conduct and availing services of Third-Party Service Providers.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(j) Frenzi shall not be held liable or responsible for indemnifying, refunding or reimbursing any User for any loss, damages or expenses suffered or incurred by such User as a consequence of the suspension or deactivation of the User’s Account.</span></p>
                        <p></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(k) Frenzi shall not be liable for any third party fees, costs, charges or expenses incurred by the User for accessing the&nbsp; Frenzi Mobile Application and Website or availing the Services. Frenzi shall not be liable in respect of any disputes or legal proceedings between the User and such third party for any reason whatsoever and any such disputes or proceedings shall be resolved outside the&nbsp; Frenzi Mobile Application and Website without any reference or recourse to Frenzi.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(l) Frenzi neither has access to, nor does the Agreement govern the use of cookies or other tracking technologies that may be placed by Third Party Service Providers on the&nbsp; Frenzi Mobile Application and Website. These parties may permit the User to opt out of tailored advertisement at any time, however, to the extent advertising technology is integrated into the Services, the User may still receive advertisements and related updates even if they choose to opt-out of tailored advertising. Frenzi assumes no responsibility or liability whatsoever for the User’s receipt or use of such tailored advertisements.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(m) Frenzi shall not be liable for the non-performance or defective or late performance of any of the Services or any of its obligations under this Agreement to such extent and for such period of time if such non-performance, defective performance or late performance is due to acts of God, war (declared or undeclared), civil insurrection or unrest, acts (including failure to act) of any Governmental Authority, riots, revolutions, fire, floods, strikes, lock-outs or industrial action.</span></p>

                    </Grid>

                </Grid>

                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>LIMITATION OF LIABILITY</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}>‘Frenzi’s total aggregate liability under this Agreement and in relation to anything which Frenzi has done or not done in relation with this Agreement shall be limited to the fees paid by the User for availing the Services in relation to which such liability of Frenzi arises. ‘,</p>

                    </Grid>

                </Grid>

                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-20 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>INDEMNITY</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>Frenzi and its officers, directors, employees and agents collectively at all times shall be held indemnified against any losses, costs, expenses, claims, suits and/or damages (including reasonable attorney fees)incurred and/or suffered by Frenzi, whether directly or indirectly, resulting from, an act or a failure to act, of any User, person or Third-Party Service Provider for any reason whatsoever and against any claims, suits and/or legal proceedings initiated by third parties inclusive of but not limited to the User’s:</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(a) breach of any terms of this Agreement;</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) third party claims arising from an infringement or alleged infringement of such third party’s Intellectual Property;</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(c) claims made by any Government Authority due to the User’s violation of Applicable Law; or</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(d) actual or alleged gross negligence or willful misconduct in connection with the User’s use of the Services or this Agreement.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(e) a fraudulent act committed by a User which results in loss or damage, suffered or incurred by any other User or by any third party.</span></p>

                    </Grid>
                </Grid>

                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-20 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>USER GRIEVANCE</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>Any User grievance relating to the discrepancies or misuse of information so provided to Frenzi may be addressed to the grievance officer, whose details are provided below appointed by Frenzi for this purpose.</span></p>
                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}><b>Name : Jyoti</b></p>
                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}><b>Designation : Customer Service</b></p>
                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}><b>Email Id : support@Frenzi.in</b></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>The grievance officer shall address the grievance within one month of the date of receipt of such grievance from the User.</span></p>

                    </Grid>
                </Grid>

                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>GUIDELINES FOR LAW ENFORCEMENT AGENCIES (LEAs)/ REPORT A FRAUD</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}>The LEAs can send us the legal requests/ notices to us on support@Frenzi.in or rajan@frenzi.in for assistance required on user details. Additionally, in case if you have any query, We will try to respond within 72 hours of receiving the request.</p>

                    </Grid>
                </Grid>


                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>WAIVER AND SEVERABILITY</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>i. No failure or delay on the part of Frenzi in exercising any right or remedy shall operate as a waiver of such right or remedy.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>ii. In the event that any of the terms and conditions of the Agreement are declared as invalid or unenforceable by a court of competent jurisdiction, such enforceability or invalidity shall not render the other terms and conditions as invalid or unenforceable as a whole.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>iii. The invalid or unenforceable provision shall be construed to reasonably reflect the actual intention of the party (in this case, Frenzi) while drafting it.</span></p>

                    </Grid>
                </Grid>


                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-20 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>AMENDMENT</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(a) Frenzi shall change, update or modify this Agreement, in whole or in part, without any prior notice to the User, provided, however, that, a notification of such change, updation or modification will be made available on the&nbsp; Frenzi Mobile Application and Website.</span></p>
                        <p style={{ marginBottom: '16px' }}><span className='lh-25 text-muted'>(b) The User’s uninterrupted and continued usage of the Services and the&nbsp; Frenzi Mobile Application and Website shall be deemed as acknowledgment and acceptance of the changes, updates or modifications to the Agreement.</span></p>

                    </Grid>
                </Grid>


                <Grid container className='mb-48 jc-center'>
                    <Grid item xs={8} sm={4} sx={{ borderRight: '1px solid red', paddingRight: { xs: '10px', sm: '45px' } }}>
                        <p className='fs-24 lh-24 fw-300'
                            sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            <h4 className='mt-0'>GOVERNING LAW AND JURISDICTION</h4>
                        </p>
                    </Grid>

                    <Grid item xs={8} sm={8} sx={{ paddingLeft: { xs: '0px', sm: '45px' } }}>

                        <p className='lh-25 text-muted' style={{ marginBottom: '16px' }}>Any action, claim, dispute or difference arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination (Dispute) shall be governed by and construed in accordance with the laws of India and the courts in Mumbai shall have exclusive jurisdiction over Disputes arising out of this Agreement. Notwithstanding anything contained herein, Frenzi shall not be restricted or withheld from instituting proceedings in courts/ tribunals of any jurisdiction other than Bengaluru that it may in its sole discretion deem appropriate and convenient.</p>

                    </Grid>
                </Grid>
            </Grid >
        </Grid >

    )
}
