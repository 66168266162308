import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SearchDataService from "../services/search.services";
import helpers from "../services/helpers";
import { decryptData } from "../services/Utils";


const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    searchAutoCompleteResult: {},
    recentSearchPageData: {},
    recentSearchData: [],
    topSearchPageData: {},
    topSearchData: [],
    advancedSearchPageData: {},
    advancedSearchData: [],
    mediaSearchPageData: {},
    mediaSearchData: [],
    personSearchPageData: {},
    personSearchData: []

};

export const getSearchAutoComplete = createAsyncThunk(
    "native_main/autocomplete",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SearchDataService.getSearchAutoComplete(data.query, data.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getMediaSearch = createAsyncThunk(
    "native_main/mediasearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SearchDataService.getMediaSearch(data.query, data?.is_public, data.page, data?.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        }
        catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
)

export const getPersonSearch = createAsyncThunk(
    "native_main/personsearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SearchDataService.getPersonSearch(data.query, data?.is_public, data.page, data?.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        }
        catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
)

export const getRecentSearches = createAsyncThunk(
    "native_main/visithistory",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SearchDataService.getRecentSearches(data.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getTopSearches = createAsyncThunk(
    "native_main/topsearches",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SearchDataService.getTopSearches(data.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAdvancedSearchResults = createAsyncThunk(
    "native_main/advancedsearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SearchDataService.getAdvancedSearchResults(data.body, data?.page, data?.pagelimit, data?.is_public)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const searchSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        resetSearchAutoComplete: (state) => {
            state.searchAutoCompleteResult = {};
        }
    },
    extraReducers: {
        [getSearchAutoComplete.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getSearchAutoComplete.fulfilled]: (state, action) => {
            state.searchAutoCompleteResult = getPayloadData(state, action);
        },
        [getSearchAutoComplete.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },

        //getMediaSearch
        [getMediaSearch.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },

        [getMediaSearch.fulfilled]: (state, action) => {
            state.mediaSearchPageData = action.payload.data || {};
            if (action.payload && action.payload.data && action.payload.data.page == 1) {
                state.mediaSearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            }
            else {
                state.mediaSearchData = helpers.removeDuplicate(state.mediaSearchData.concat(getArrayData(state, action)));
            }
        },
        [getMediaSearch.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },

        //getPersonSearch
        [getPersonSearch.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPersonSearch.fulfilled]: (state, action) => {
            state.personSearchPageData = action.payload.data || {};
            if (action.payload && action.payload.data && action.payload.data.page == 1) {
                state.personSearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            }
            else {
                state.personSearchData = helpers.removeDuplicate(state.personSearchData.concat(getArrayData(state, action)));
            }
        },
        [getPersonSearch.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },

        // getRecentSearches
        [getRecentSearches.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getRecentSearches.fulfilled]: (state, action) => {
            state.recentSearchPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.recentSearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.recentSearchData = helpers.removeDuplicate(state.recentSearchData.concat(getArrayData(state, action)));
            }
        },
        [getRecentSearches.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getTopSearches
        [getTopSearches.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getTopSearches.fulfilled]: (state, action) => {
            state.topSearchPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.topSearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.topSearchData = helpers.removeDuplicate(state.topSearchData.concat(getArrayData(state, action)));
            }
        },
        [getTopSearches.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getAdvancedSearchResults
        [getAdvancedSearchResults.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getAdvancedSearchResults.fulfilled]: (state, action) => {
            state.advancedSearchPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.advancedSearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.advancedSearchData = helpers.removeDuplicate(state.advancedSearchData.concat(getArrayData(state, action)));
            }
        },
        [getAdvancedSearchResults.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages, resetSearchAutoComplete } = searchSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const getPayloadDataSection = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ?
        action.payload.data.section ?
            { ...state.watchlistSectionPageData, [action.payload.data.section]: action.payload.data }
            : action.payload.data
        : {};
}

const getArrayDataSection = (state, action) => {
    var res = action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ?
        action.payload.data.section ?
            { ...state.watchlistSectionData, [action.payload.data.section]: state.watchlistSectionData[action.payload.data.section] ? helpers.removeDuplicate(state.watchlistSectionData[action.payload.data.section].concat(action.payload.data.results)) : action.payload.data.results }
            : action.payload.data.results
        : [];
    state.isLoading = false;
    return res;
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default searchSlice.reducer;