import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestedFrenziUserDetails, getSuggestedUserDetails } from '../../slices/connect';
import NewUserCard from '../common/NewUserCard';

export default function MovieLovers() {
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    const connect = useSelector((state) => state.connect);

    const { suggestedFrenziData, suggestedPageData, suggestedData } = connect;

    useEffect(() => {
        dispatch(getSuggestedFrenziUserDetails());
    }, []);

    useEffect(() => {
        dispatch(getSuggestedUserDetails({ page: page }));
    }, [page, dispatch]);

    return (
            <div className='mt-20 mb-20'>
                <p className='p-0 mt-0 fs-20'>Follow other movie lovers</p>
                <NewUserCard
                    data={[...suggestedFrenziData, ...suggestedData]}
                    pageData={suggestedPageData}
                    handlePageChange={(nextPage) => setPage(nextPage)}
                />
            </div>
    )
}
