import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MediaOverView from "../../common/MediaOverView";
import helpers from "../../../services/helpers";
import { Link } from "react-router-dom";
import ReviewActions from "./ReviewActions";
import { ratingEmoji } from "../../common/RateMovie";


export default function TextReviewTile(props) {
  const { x, type } = props;

  const search = ratingEmoji.filter(function (item) {
    return item.valeu === parseInt(x?.user_rating) / 2
  });


  return (
    <>
      {type === 'grid' ?
        <Grid container >
          <Grid item >
            <Grid container justifyContent={"space-between"}>
              <Grid item sx={{ minHeight: { sm: '36px' } }}>
                {helpers.getRatingText(x.user_rating ? x.user_rating : 0)}
                <Link to={`/profile/${x?.user_id}`} >
                  <p className="m-0 fs-12 fw-700 ellipsis">
                    {x.user_name ? x.user_name : ""}
                  </p>
                </Link>
              </Grid>
              <Grid item>
                <p className="m-0 fw-500 fs-12 pt-5">
                  {x.total_likes ? x.total_likes : 0} likes
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} >
                <MediaOverView fullHeight={true} isLess={true} text={x.comment ? x.comment : ""} />
              </Grid>
            </Grid>
            <ReviewActions x={x} isOwner={props?.isOwner || false} />
          </Grid>
        </Grid>
        :
        <Grid item xs={12} className="pl-17 br-7 pt-12 bg-secondary" >
          <Grid container className="mb-20" flexWrap={"nowrap"} justifyContent={"space-between"}>
            <Grid item xs={10}>
              <p className="text-selected fs-16 fw-700 m-0">{search[0]?.title || ''}</p>
            </Grid>
            {x.user_rating > 0 ?
              <>
                <Grid item xs={1} className="pr-10">
                  <Typography className="fs-24 fw-400">
                    {x.user_rating ? x.user_rating : x.user_rating ? x.user_rating : 0}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="mr-20" >
                  <img style={{ height: '36px', width: '36px' }} alt="search" src={search[0]?.emoji} />
                </Grid>
              </>
              : ''}

          </Grid>
          <Grid container rowSpacing={3}>
            <Grid item xs={4} lg={3}>
              <Link onClick={() =>
                helpers.mediaClick(
                  x?.title,
                  x.media ? x.media : x.uuid ? x.uuid : "",
                  props?.refPage
                )
              }
                to={helpers.getMediaLink(x)}
              >
                <Box
                  component="img"
                  sx={{
                    height: 99,
                    width: 66,
                    maxHeight: { xs: 99, sm: 99 },
                    maxWidth: { xs: 100, sm: 100 },
                    borderRadius: "8px",
                  }}
                  alt="banner"
                  src={
                    x.poster
                      ? helpers.getFullUrl(x.poster, 92)
                      : helpers.getDefaultImage("poster")
                  }
                />
              </Link>
            </Grid>
            <Grid item xs={8} lg={8.7}>
              <Typography sx={{ color: '#D9D9D9' }} className="fs-14 fw-500">
                <MediaOverView fullHeight={true} isLess={true} text={x.comment ? x.comment : ""} />
              </Typography>
            </Grid>

          </Grid>
          <Grid container>
            <Grid item className="pt-20 pl-15 pr-15" xs={12}>
              <ReviewActions x={x} isOwner={props?.isOwner || false} />
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
}
