import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ProgressBar from "../component/ProgressBar";
import Box from "@mui/system/Box";
import helpers from "../../services/helpers";
import { Link } from "react-router-dom";
import BookmarkAction from "../watchlist/BookmarkAction";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { getTopTen } from "../../slices/trending";
import "swiper/css";
import "swiper/css/navigation";
import SectionHeading from "../component/headings/SectionHeading";
import { isMobileDevice } from "../../services/Utils";
import SeeAll from "../common/SeeAll";

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";


export default function TopTen(props) {
  const { hideNext, is_free, heading } = props;
  const [addedId, setAddeddId] = useState("");

  const dispatch = useDispatch();
  const trending = useSelector((state) => state.trending);
  const global = useSelector((state) => state.global);
  const { toptenPageData } = trending;

  useEffect(() => {
    var body = {
      page: 1,
      pagelimit: 10,
      is_free: is_free,
      is_public: global.is_public,
    };
    dispatch(getTopTen(body));
  }, []);


  return (
    <>
      <Grid
        container
        alignItems={"center"}
        sx={{ marginTop: { xs: "20px", sm: "30px" } }}
        justifyContent="space-between"
      >
        <div>
          {/* <p className="fs-28 fw-700 lh-28 m-0">
            <span className="text-selected">top 10</span> on ott this week
          </p> */}
          <SectionHeading title={heading} />
        </div>
        {!hideNext && (
          <Link to="/trending-movies-series-on-ott-this-week">
            {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
            <SeeAll />
          </Link>
        )}
      </Grid>
      <Grid item xs={12}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          slidesPerView={"auto"}
          spaceBetween={10}
          speed={1000}
          navigation={true}
          modules={[Pagination, FreeMode, Navigation]}
          className="mySwiper"
          freeMode={true}
        >
          {toptenPageData?.results &&
            toptenPageData.results.length > 0 &&
            toptenPageData.results.map((x, i) => {
              return (
                <SwiperSlide key={i} className="pr-20" style={{ alignSelf: 'center', width: 'auto' }}>
                  <Grid sx={{
                    maxWidth: { xs: 100, sm: 140, md: 270 },
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: 'inline-block'
                  }}
                  >
                    <Grid item className="scale-1">
                      <Grid item className=" movie-container">
                        <Link
                          onClick={() =>
                            helpers.mediaClick(
                              x?.title,
                              x.media ? x.media : x.uuid ? x.uuid : "",
                              "Top Ten"
                            )
                          }
                          to={helpers.getMediaLink(x)}
                        >
                          <Box
                            component="img"
                            className="d-block"
                            sx={{
                              // height: 360,
                              // width: 270,
                              height: 210,
                              width: 140,
                              // maxHeight: { xs: 150, sm: 210, md: 360 },
                              // maxWidth: { xs: 100, sm: 140, md: 270 },
                              maxHeight: { xs: 150, sm: 210 },
                              maxWidth: { xs: 100, sm: 140 },
                              borderRadius: "8px",
                              objectFit: 'cover',
                              objectPosition: 'top'
                            }}
                            alt="poster"
                            src={
                              x.poster
                                ? helpers.getFullUrl(x.poster, 300)
                                : helpers.getDefaultImage("trending")
                            }
                          />
                        </Link>
                        {/* {x.media_type &&
                          (x.media_type == "movie" || x.media_type == "tv") && (
                            <BookmarkAction
                              size={42}
                              data={x}
                              addedId={addedId}
                              setAddedId={setAddeddId}
                            />
                          )} */}
                        <Grid item xs={12}>
                          <Grid
                            container
                            alignItems={"center"}
                            sx={{ minWidth: { xs: 30, xl: 80 } }}
                            flexWrap={"nowrap"}
                            className="bottom-right w-100"
                          >
                            <p
                              style={{ textTransform: "uppercase" }}
                              className="m-0 fs-10 pl-10"
                            >
                              {" "}
                              {x.media_type ? x.media_type : ""}{" "}
                            </p>
                            <p className="m-0 fs-10 pl-5 pr-5"> | </p>{" "}
                            <p
                              className="m-0 fs-10 pr-10"
                              style={{ textTransform: "uppercase" }}
                            >
                              {" "}
                              {x.language ? x.language : ""}
                            </p>
                          </Grid>
                        </Grid>
                        {props.isTopTen && (
                          <p className="top-ten-txt fs-64 m-0">{10 - i}</p>
                        )}
                      </Grid>
                      <Grid
                        container
                        className="jc-sb ai-center"
                        sx={{ marginTop: "6px" }}
                      >
                        <Grid item xs={8} className="p-0 m-0">
                        </Grid>
                        <ProgressBar
                          value={x.rating ? Number(x.rating) * 10 : 0}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Grid>
    </>
  );
}
