import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import { useNavigate, useParams } from "react-router-dom";
import { getCinescoreDetailsByMediaType } from "../../slices/cinescore";
import LinearLoader from "../common/LinearLoader";
import CinescoreCard from "./CinescoreCard";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import helpers from "../../services/helpers";
import { clearMessages } from "../../slices/media";


export default function MyCinescoreDetails(props) {
  const params = useParams();
  const cinescore = useSelector((state) => state.cinescore);
  const media = useSelector((state) => state.media);
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (media.errMesg && media.errMesg !== "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessages());
    }
    if (media.successMesg && media.successMesg !== "") {
      helpers.toastSuccess(media.successMesg);
      dispatch(clearMessages());
    }
  }, [media]);

  const {
    cinescoreDetailsByMediaTypePageData,
    cinescoreDetailsByMediaTypeData,
  } = cinescore;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const body = {
      page: page,
      pagelimit: 20,
      exclude_already_watched: "yes",
      media_type: getMediaType(),
      prefered_platforms: "yes",
    };
    dispatch(getCinescoreDetailsByMediaType(body));
  }, [page]);

  const getMediaType = () => {
    let media_type = "";
    if (params.mediaType === "movies") {
      media_type = "movie";
    } else if (params.mediaType === "webseries") {
      media_type = "tv";
    }
    return media_type;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (cinescoreDetailsByMediaTypePageData && cinescoreDetailsByMediaTypePageData && cinescoreDetailsByMediaTypePageData.pagination && cinescoreDetailsByMediaTypePageData.pagination.is_next_page && !cinescore.isLoading) {
        if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
          setPage(page + 1);
        }
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, cinescore]);


  return (
    <Grid
      container
      className="new-container pb-40 pt-40"
      justifyContent={"center"}
    >
      <Grid item xs={12} className="app-section" style={{ overflow: "hidden" }}>
        <div className="mb-20 row" style={{ alignItems: 'center' }}>

          <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />

          <p className="fs-28 fw-700 lh-28 m-0">
            Seen
            <span className="text-selected">
              {/* {params.mediaType === "movies" ? " Movies" : " Webseries"} */} titles
            </span>
          </p>
        </div>
        {(auth.isLoading || cinescore.isLoading) && <LinearLoader />}
        {cinescoreDetailsByMediaTypeData?.length > 0 && (
          <Grid container spacing={2}>
            {cinescoreDetailsByMediaTypeData?.map((x, i) => {
              return <Grid xs={12} sm={6} md={4} item key={i}>
                <CinescoreCard data={x} />
              </Grid>
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

/* */
