
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PublicDataService from "../services/public.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    freshPageData: {},
    freshData: []
}

export const getNewlyAdded = createAsyncThunk(
    "native_main/fresh",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PublicDataService.getCommonForAllPublicData(data?.date_range ? data?.date_range : '', 'fresh', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);



export const publicSlice = createSlice({
    name: "public",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        // getNewlyAdded
        [getNewlyAdded.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getNewlyAdded.fulfilled]: (state, action) => {
            state.freshPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.freshData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.freshData = helpers.removeDuplicate(state.freshData.concat(getArrayData(state, action)));
            }
        },
        [getNewlyAdded.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        }
    }
})


export const { clearMessages } = publicSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default publicSlice.reducer;