import { Box, Grid } from "@mui/material";
import React from "react";
import helpers from "../../services/helpers";

import FollowUnfollowPerson from "../common/FollowUnfollowPerson";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const breakpoints = {
  319: {
    slidesPerView: 2.7,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 4,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 5,
    spaceBetween: 20,
    slidesPerGroup: 4
  },
  1024: {
    slidesPerView: 7,
    spaceBetween: 20,
    slidesPerGroup: 6
  },
  1440: {
    slidesPerView: 9.5,
    spaceBetween: 20,
    slidesPerGroup: 8.5
  },
  1880: {
    slidesPerView: 11,
    spaceBetween: 30,
    slidesPerGroup: 10
  },
  2560: {
    slidesPerView: 13,
    spaceBetween: 30,
    slidesPerGroup: 12
  },
}



export default function Fans(props) {
  const { data, name } = props;
  const getProfileUrl = (uuid) => {
    let url = "/profile";
    let currentUser = localStorage.getItem("frenzi_user_access");
    var currentUserData = JSON.parse(currentUser);
    if (currentUserData.uuid === uuid) {
      return url;
    } else {
      return url + "/" + uuid;
    }
  };

  return (
    <Grid container className="pt-30" justifyContent={"center"}>
      <Grid item xs={12}>
        {name && name !== "" && (
          <h2 style={{ paddingBottom: '15px' }} className="m-0 fs-24 fw-700">super fans of {name}</h2>
        )}
        <Grid item xs={12}>
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            // slidesPerGroup={5}
            freeMode={true}
            speed={1000}
            navigation={true}
            breakpoints={breakpoints}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
          >
            {data.length > 0 &&
              data.map((x, i) => {
                return (
                  <SwiperSlide style={{minWidth:'140px'}} speed={1000} key={i}>
                    <Grid container flexDirection={'column'} justifyContent={'space-between'} className='h-100'>
                      <Grid item className="text-center mr-20 pt-20">
                        <Link to={getProfileUrl(x.uuid)}>
                          <Box
                            component="img"
                            className="d-inline-block scale-1"
                            sx={{
                              height: "80px",
                              width: "80px",
                              maxHeight: { xs: 60, sm: 80 },
                              maxWidth: { xs: 60, sm: 80 },
                              borderRadius: "50%",
                              objectFit:'cover'
                            }}
                            src={
                              x.profile_image
                                ? helpers.getFullUrl(x.profile_image)
                                : helpers.getDefaultImage("profile")
                            }
                            alt={x?.username}
                          />
                        </Link>
                        <p className="fs-10 fw-600 text-center ellipsis pt-5 pb-12 m-0">
                          {x.username ? x.username.split(" ")[0] : ""}
                        </p>
                        <FollowUnfollowPerson data={x} />
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Grid>
      </Grid>
    </Grid>
  );
}
