import { Box, Grid, Skeleton } from '@mui/material'
import React from 'react'
// import DefaultBG from '../../assets/img/DefaultBG.png'

const DefaultBG = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/DefaultBG.png";

export default function HandpickDefault({ isPublic }) {
    return (
        <div className='w-100 relative'>
            <Box
                component={'img'}
                alt='Handpicked Titles'
                src={DefaultBG}
                className="w-100"
                sx={{
                    height: '100vh',
                    objectFit: 'cover',
                    maxHeight: { xs: '75vh', sm: '440px', md: '500px', lg: '720px', xl: '85vh' }
                }}
            />
            <div className="app-container new-container">
                <Grid sx={{ padding: { xs: '20px', sm: '50px' }, position: 'absolute', bottom: 0, zIndex: 111, width: '82%' }} >
                    {/* <div className='row' style={{ alignItems: 'center' }}>
                        <Skeleton
                            sx={{
                                height: 30,
                                width: 180,
                                maxHeight: { xs: 20, sm: 30 },
                                maxWidth: { xs: 130, sm: 180 },
                                borderRadius: '3px',
                                marginRight: '10px'
                            }}
                            animation="wave"
                            variant="rectangular"
                        />
                        <Skeleton
                            sx={{
                                height: 50,
                                width: 50,
                                maxHeight: { xs: 35, sm: 50 },
                                maxWidth: { xs: 35, sm: 50 },
                                borderRadius: '50%',
                            }}
                            animation="wave"
                            variant="rectangular"
                        />
                    </div> */}
                    {/* <Skeleton
                        sx={{
                            height: 48,
                            width: 500,
                            maxHeight: { xs: 35, sm: 48 },
                            maxWidth: { xs: '80%', sm: 500 },
                            borderRadius: '3px',
                            marginTop: '13px'
                        }}
                        animation="wave"
                        variant="rectangular"
                    /> */}

                    <Grid container className='w-100' justifyContent={'space-between'}>
                        <Grid xs={12} sm={8}>
                            <Skeleton
                                sx={{
                                    height: 26,
                                    width: 300,
                                    maxHeight: { xs: 18, sm: 26 },
                                    maxWidth: { xs: '60%', sm: 300 },
                                    borderRadius: '3px',
                                    marginTop: '13px'
                                }}
                                animation="wave"
                                variant="rectangular"
                            />
                            <Skeleton
                                sx={{
                                    height: 40,
                                    width: 400,
                                    maxHeight: { xs: 30, sm: 40 },
                                    maxWidth: { xs: '70%', sm: 400 },
                                    borderRadius: '3px',
                                    marginTop: '13px'
                                }}
                                animation="wave"
                                variant="rectangular"
                            />
                        </Grid>
                        {!isPublic ?
                            <Grid>
                                <Skeleton
                                    sx={{
                                        height: 74,
                                        width: 150,
                                        maxHeight: { xs: 45, sm: 74 },
                                        maxWidth: 150,
                                        borderRadius: '3px',
                                        marginTop: '13px'
                                    }}
                                    animation="wave"
                                    variant="rectangular"
                                />
                            </Grid>
                            : ''
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
