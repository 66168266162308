import { Grid, Skeleton } from '@mui/material'
import React from 'react'

const arr1 = [1, 2, 3, 4];
const arr2 = [1, 2, 3, 4, 5, 6];

export default function ComponentLoader() {
    return (
        <div>
            <Grid container style={{ marginBottom: '10px' }} className='w-100' justifyContent={'space-between'}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                        height: 30,
                        width: "100%",
                        maxHeight: { xs: 18, sm: 24, md: 30 },
                        maxWidth: { xs: 90, sm: 110, md: 250 },
                        borderRadius: "4px",
                        marginRight: "20px"
                    }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                        height: 30,
                        width: "100%",
                        maxHeight: { xs: 18, sm: 24, md: 30 },
                        maxWidth: { xs: 70, sm: 80, md: 90 },
                        borderRadius: "4px",
                        marginRight: "0px"
                    }}
                />
            </Grid>
            <Grid container className='w-100 pt-20'>
                {arr1.map(x => {
                    return <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            height: 64,
                            width: "100%",
                            maxHeight: { xs: 32, sm: 48, md: 64 },
                            maxWidth: { xs: 32, sm: 48, md: 64 },
                            borderRadius: "50%",
                            marginRight: "20px"
                        }}
                    />
                })}
            </Grid>
            <Grid container wrap='nowrap' style={{ overflow: 'hidden' }} className='w-100 pt-20'>
                {arr2.map(x => {
                    return <Grid sx={{
                        maxWidth: { xs: 100, sm: 140 },
                        marginRight: '20px',
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        display: 'inline-block'
                    }}
                    >
                        <Grid item className='scale-1'>
                            <Grid item className='movie-container'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    sx={{
                                        height: 210,
                                        width: 140,
                                        maxHeight: { xs: 150, sm: 210 },
                                        maxWidth: { xs: 100, sm: 140 },
                                        borderRadius: "7px",
                                    }}
                                />
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    sx={{
                                        height: 14,
                                        width: 36,
                                        borderRadius: "32px",
                                        marginTop: '6px',
                                        float: 'right'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                })}
            </Grid>
        </div>
    )
}
