import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { notifyMe } from '../../slices/media';
import CustomButton from './CustomButton';

import { Button } from "@mui/material";
// import { alpha, styled } from '@mui/material/styles';
// import { pink } from '@mui/material/colors';
import { toggleLoginModal } from '../../slices/global';
import { HELPER_TEXT } from '../../lang/HelperText';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '100%',
//     maxWidth: { xs: '90vw', sm: '75vw', md: '600px' },
//     bgcolor: "#16213E",
//     boxShadow: 24,
//     p: { xs: 3, sm: 5, md: 7 },
//     borderRadius: 5,
//     zIndex: 999,
// };

// const PinkSwitch = styled(Switch)(({ theme }) => ({
//     '& .MuiSwitch-switchBase.Mui-checked': {
//         color: pink[600],
//         '&:hover': {
//             backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
//         },
//     },
//     '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//         backgroundColor: pink[600],
//     },
// }));

// const defaultForm = {
//     // free: false,
//     ott: false,
//     subscription: false,
//     television: false,
// }

export default function NotifyMe(props) {
    const { uuid, notified, isLarge, label, isWhite, data } = props;

    // const [ottFormData, setOttFormData] = useState(defaultForm);
    // const [open, setOpen] = useState(false);
    const [notifiedData, setNotifiedData] = useState([]);

    const global = useSelector(state => state.global);

    // const handleOpen = () => {
    //     if (global.is_public) {
    //         dispatch(toggleLoginModal({
    //             isOpen: true,
    //             content: HELPER_TEXT.NUDGE_TEXT.NOTIFY_ME
    //         }))
    //     } else {
    //         dispatch(getNotifyMeData({ id: uuid })).then((e) => {
    //             if (e.payload && e.payload.data) {
    //                 setOttFormData({ ...e.payload.data });
    //             }
    //             setOpen(true);
    //         });
    //     }
    // };
    // const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const handleNotifyMe = (id, isNotified) => {
        if (global.is_public) {
            dispatch(toggleLoginModal({
                isOpen: true,
                content: HELPER_TEXT.NUDGE_TEXT.NOTIFY_ME
            }))
        } else {
            if (id && id !== "") {
                helpers.trackMoengageEvent('FR3_Searches', {
                    title: data.title,
                    notify: MOENGAGE_ATTRIBUTE.YES,
                });
                // if (!isNotified) {
                dispatch(notifyMe({ id: id })).then((resp) => {
                    if (resp.payload.status) {
                        let tempData = notifiedData;
                        if (resp.payload.message === 'updated') {
                            tempData.push(id);
                            setNotifiedData([...tempData]);
                        } else {
                            const index = tempData.indexOf(id);
                            if (index > -1) {
                                tempData.splice(index, 1);
                            }
                            setNotifiedData([...tempData]);
                        }
                    }
                });
                // } else {
                //     dispatch(removeNotifyMe({ id: id }));
                // }
            }
        }
        // handleClose();
    }
    return (
        <>
            {notified || notifiedData.includes(uuid) ?
                <>
                    {isLarge ?
                        <Button
                            onClick={() => handleNotifyMe(uuid, notified)}
                            className={`rounded-btn ${isWhite ? 'bg-selected text-light' : 'bg-light text-selected'} ${props?.className} pl-20 pr-20`}>notified</Button>
                        :
                        <CustomButton
                            label={'notified'}
                            selected={true}
                            className={props?.className}
                            onClick={() => handleNotifyMe(uuid, notified)}
                        />
                        
                    }
                </>
                :
                <>
                    {isLarge ?
                        <Button
                            onClick={() => handleNotifyMe(uuid, notified)}
                            className={`rounded-btn ${!isWhite ? 'bg-selected text-light' : 'bg-light text-selected'} ${props?.className} pl-20 pr-20`}>{label && label !== "" ? label : 'notify me when available'}</Button>
                        :
                        <CustomButton
                            label={label && label !== "" ? label : 'notify me when available'}
                            // className={'fs-14'}
                            selected={false}
                            // fontsize={'fs-10'}
                            onClick={() => handleNotifyMe(uuid, notified)}
                        />
                    }
                </>
            }
            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            > */}
            {/* <Box sx={style}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} sm={12} className='pl-20 pr-20'>
                            <Grid item>
                                <p className='fw-900 fs-24 m-0'><b>notify me</b></p>
                                <p className='fw-600 text-light-white fs-16 m-0'>when</p>
                            </Grid>
                            <Grid item className="mt-20 text-center"> */}
            {/* <Grid container alignItems={'center'} className='p-5' justifyContent={'space-between'}>
                                        <p className='fw-600 text-light-white fs-16 m-0'>available when free</p>
                                        <FormControlLabel onChange={(e) => handleFormChange(e.target.checked, 'free')} control={<PinkSwitch checked={ottFormData.free} />} />
                                    </Grid> */}
            {/* {!helpers.checkOttAvailableOn(data.data, 'subscription') && !helpers.checkOttAvailableOn(data.data, 'rent') &&
                                    <Grid container alignItems={'center'} className='p-5' justifyContent={'space-between'}>
                                        <p className='fw-600 text-light-white fs-16 m-0'>available on ott</p>
                                        <FormControlLabel onChange={(e) => handleFormChange(e.target.checked, 'ott')} control={<PinkSwitch checked={ottFormData.ott} />} />
                                    </Grid>
                                } */}
            {/* {!helpers.checkOttAvailableOn(data.data, 'subscription') && helpers.checkOttAvailableOn(data.data, 'rent') &&
                                    <Grid container alignItems={'center'} className='p-5' justifyContent={'space-between'}>
                                        <p className='fw-600 text-light-white fs-16 m-0'>available as part of subscription</p>
                                        <FormControlLabel onChange={(e) => handleFormChange(e.target.checked, 'subscription')} control={<PinkSwitch checked={ottFormData.subscription} />} />
                                    </Grid>
                                } */}
            {/* <Grid container alignItems={'center'} className='p-5' justifyContent={'space-between'}>
                                    <p className='fw-600 text-light-white fs-16 m-0'>available on television</p>
                                    <FormControlLabel onChange={(e) => handleFormChange(e.target.checked, 'television')} control={<PinkSwitch checked={ottFormData.television} />} />
                                </Grid> */}
            {/* <Button onClick={() => handleNotifyMe(uuid, notified)} style={{ marginTop: '20px' }} className='rounded-btn mw-240 bg-light text-selected'>notify me</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal> */}
        </>
    )
}
