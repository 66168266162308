import axiosInstance from "../http-common";

const getCinephilesUserDetails = (page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/cinephiles?page=${page}&pagelimit=${pagelimit}`);
};


const getSuggestedUserDetails = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/suggested?page=${page}&pagelimit=${pagelimit}`);
};

const getSuggestedFrenziUserDetails = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/frenzisuggested?page=${page}&pagelimit=${pagelimit}`);
};

const getFrenziUsers = (query = "", page = 1, pagelimit = 20) => {
    return axiosInstance.post(`native_main/frenziusersearch?query=${query}&page=${page}&pagelimit=${pagelimit}`);
};

const getFriendsWatchlist = (page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/friendswatchlist?page=${page}&pagelimit=${pagelimit}`);
};

const getFollowers = (search = '', user_id = "", page = 1, pagelimit = 20) => {
    let url = `native_main/followers?search=${search}&page=${page}&pagelimit=${pagelimit}`;
    if (user_id && user_id != "") {
        url += `&user=${user_id}`
    }
    return axiosInstance.get(url);
};

const getFollowing = (search = '', user_id = "", page = 1, pagelimit = 20) => {
    let url = `native_main/following?search=${search}&page=${page}&pagelimit=${pagelimit}`;
    if (user_id && user_id != "") {
        url += `&user=${user_id}`
    }
    return axiosInstance.get(url);
};

const ConnectDataService = {
    getCinephilesUserDetails,
    getSuggestedUserDetails,
    getSuggestedFrenziUserDetails,
    getFrenziUsers,
    getFriendsWatchlist,
    getFollowers,
    getFollowing
};

export default ConnectDataService;