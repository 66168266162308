import axiosInstance from "../http-common";

const frenziSearch = (value = "", page = 1, pagelimit = 20, media_type = "", type = "media") => {
    return axiosInstance.post(`native_main/frenzisearch?query=${value}&search_type=${type}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}`);
};

const userWatchListDetail = (media_id, page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/userwatchlistdetail?media=${media_id}&page=${page}&pagelimit=${pagelimit}`);
};

const createWatchList = (body) => {
    return axiosInstance.post(`native_main/frenziwatchlist`, body);
};

const updateWatchList = (uuid, body) => {
    return axiosInstance.put(`native_main/watchlistdetail/${uuid}`, body);
};

const getFriendsList = (query = '', page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/friendslist?search=${query}&page=${page}&pagelimit=${pagelimit}`);
};

const getAdData = (name) => {
    return axiosInstance.get(`native_main/advstate/${name}`);
};

const getReferalLink = () => {
    return axiosInstance.post(`connect/firebaseshortlink`);
};

const CommonDataService = {
    frenziSearch,
    userWatchListDetail,
    createWatchList,
    updateWatchList,
    getFriendsList,
    getAdData,
    getReferalLink
};

export default CommonDataService;