import { Grid, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import helpers from "../../services/helpers";
import MediaOverView from "../common/MediaOverView";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addRemovePersonFan } from "../../slices/cast";

import CommonDetailTileListing from "../common/CommonDetailTileListing";
import ShareMovie from "../common/ShareMovie";
import { toggleLoginModal } from "../../slices/global";
import MyAds from "../adsense/MyAds";
import { HELPER_TEXT } from "../../lang/HelperText";
import { isMobileDevice } from "../../services/Utils";


const breakpoints = {
  319: {
    slidesPerView: 1,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 1.5,
    spaceBetween: 15,
  },
  490: {
    slidesPerView: 1.8,
    spaceBetween: 15,
  },
  602: {
    slidesPerView: 1.5,
    spaceBetween: 15,
  },

  690: {
    slidesPerView: 1.7,
    spaceBetween: 15,
  },
  755: {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1
  },
  1024: {
    slidesPerView: 2.5,
    spaceBetween: 20,
    slidesPerGroup: 1
  },
  1410: {
    slidesPerView: 2.7,
    spaceBetween: 20,
    slidesPerGroup: 2
  },
  1680: {
    slidesPerView: 4,
    spaceBetween: 30,
    slidesPerGroup: 2
  },
  2560: {
    slidesPerView: 4.2,
    spaceBetween: 30,
    slidesPerGroup: 3
  },
}

export default function Detail(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const cast = useSelector((state) => state.cast);
  const _global = useSelector((state) => state.global);


  const handleFollowPerson = (id) => {
    if (_global.is_public) {
      dispatch(
        toggleLoginModal({
          isOpen: true,
          content: (
            <>
              {HELPER_TEXT.NUDGE_TEXT.FOLLOW_ACTOR}{" "}
              <b>{data.name ? data.name : "this celebrity"}</b> ?{" "}
            </>
          ),
        })
      );
    } else {
      dispatch(addRemovePersonFan({ body: { person: id } }));
    }
  };

  const handleAddFreind = () => {
    if (global.is_public) {
      dispatch(
        toggleLoginModal({
          isOpen: true,
          content: HELPER_TEXT.NUDGE_TEXT.ADD_FRIENDS,
        })
      );
    }
  };

  return (
    <Grid className="app-container relative" sx={{ paddingTop: "30px" }}>
      {/* <ArrowBackRoundedIcon title='back' className="pointer scale-1 mr-0 movie-back-btn" style={{ position: 'absolute', right: 0, top: 0, zIndex: 99 }} onClick={() => navigate(-1)} /> */}
      <Grid
        container
        sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}
        className={"w-100"}
      >
        <Grid
          item
          className="relative"
          sx={{
            maxWidth: { xs: '100%', sm: '440px' },
            paddingRight: { xs: '0px', sm: '24px' }
          }} md={5} sm={6} xs={12}>
          {data.poster ?
            <Box
              component="img"
              className="d-block"
              sx={{
                // height: 500,
                width: '100%',
                // maxHeight: '500px',
                // maxHeight: { xs: 500, sm: 400, md: 450, lg: 500 },
                // maxWidth: { xs: "100%", sm: "100%", md: "420px", lg: '440px' },
                borderRadius: '10px',
                objectFit: 'cover',
                objectPosition: 'top',
                aspectRatio: '3/4'
              }}
              src={
                data.poster
                  ? helpers.getFullUrl(data.poster)
                  : helpers.getDefaultImage("cinescoregenres")
              }
              alt={'poster'}
            />
            :
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                width: '100%',
                height: '100vh',
                maxHeight: { xs: '450px', sm: '426px', md: '470px', lg: '509px' }
              }}
            />
          }

          {/* for desktop */}
          <Grid
            item
            xs={12}
            sx={{ position: "absolute", bottom: "10px", width: "100%", display: { xs: 'none', lg: 'block' } }}
          >
            <Grid container justifyContent={"center"}>
              <Box
                className={`pointer icon-container ${props.className}`}
                sx={{
                  height: 48,
                  width: 48,
                  maxHeight: { xs: 48, sm: 48 },
                  maxWidth: { xs: 48, sm: 48 },
                }}
                onClick={() => handleFollowPerson(data.uuid)}
              >
                <ThumbUpOutlinedIcon
                  className={`fs-32 ${data.is_fan && "text-selected"}`}
                />
              </Box>
            </Grid>
          </Grid>

          {/* for mobile */}
          <Grid
            item
            xs={12}
            className='dark-bg-glass'
            sx={{
              position: "absolute",
              bottom: '-1px',
              right: 0,
              left: 0,
              width: { xs: '100%', sm: "calc(100% - 24px)" },
              display: { xs: 'block', lg: 'none' }
            }}
          >
            <div style={{ padding: '15px' }}>
              <Grid item xs={12}>

                <h1 className="m-0 fs-32 fw-600">
                  {data.name ? data.name : ""}
                </h1>
                <Grid container alignItems={'end'} justifyContent={'space-between'} flexWrap='nowrap'>
                  <Grid item>
                    <Grid container className="ai-center mw-600">
                      <span className="dot mt-3"></span>
                      <Grid item className="ml-5 mr-5">
                        <Typography className="fs-18 fw-500">
                          {data.known_for_department
                            ? data.known_for_department
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    {data.total_titles && data.total_titles !== "" && (
                      <p className="fs-18 m-0 pt-5 fw-600">
                        {data.total_titles} titles{" "}
                      </p>
                    )}
                    <Grid item>
                      {data.birthday && data.birthday !== "" && (
                        <p className="fs-18 m-0 fw-500">
                          DOB : {data.birthday}
                        </p>
                      )}
                      <span className="fs-18 mt-5 fw-500">
                        {data.titles_watched ? data.titles_watched : 0}/
                        {data.total_titles ? data.total_titles : 0} watched
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} flexWrap={'nowrap'}>
                      <Grid item>
                        <Grid >
                          <Box
                            className={`pointer icon-container ${props.className}`}
                            sx={{
                              height: 40,
                              width: 40,
                            }}
                            onClick={() => handleFollowPerson(data.uuid)}
                          >
                            <ThumbUpOutlinedIcon
                              className={`fs-32 ${data.is_fan && "text-selected"}`}
                            />
                          </Box>
                          <span className="d-block text-center pointer fs-14 fw-600">Like</span>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <ShareMovie />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Grid
            container
            justifyContent={"space-between"}
            flexDirection="column"
            className="h-100"
          >
            <Grid item style={{ width: "100%" }}>
              <Grid container sx={{ justifyContent: { xs: 'flex-end', lg: 'flex-start' } }}>
                <Grid item sx={{ flex: 1, display: { xs: 'none', lg: 'block' } }}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                    style={{ paddingRight: '20px' }}
                  >
                    <Grid item>
                      <h1 className="m-0 fs-36 fw-600">
                        {data.name ? data.name : ""}
                      </h1>
                    </Grid>
                    <Grid item>
                      <ShareMovie />
                    </Grid>
                  </Grid>

                  <Grid container className="ai-center mw-600">
                    <span className="dot mt-3"></span>
                    <Grid item className="ml-5 mr-5">
                      <Typography className="fs-18 fw-500">
                        {data.known_for_department
                          ? data.known_for_department
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  {data.total_titles && data.total_titles !== "" && (
                    <p className="fs-18 m-0 pt-5 fw-600">
                      {data.total_titles} titles{" "}
                    </p>
                  )}
                  <Grid item>
                    {data.birthday && data.birthday !== "" && (
                      <p className="fs-18 m-0 fw-500">
                        DOB : {data.birthday}
                      </p>
                    )}
                    <span className="fs-18 mt-5 fw-500">
                      {data.titles_watched ? data.titles_watched : 0}/
                      {data.total_titles ? data.total_titles : 0} watched
                    </span>
                  </Grid>
                </Grid>
                {!isMobileDevice() ?
                  <Grid item>
                    <Grid container justifyContent={"center"}>
                      <MyAds
                        type="banner_3"
                        adv_type={"web_cast_page_detail_ad"}
                      />
                    </Grid>
                  </Grid>
                  :
                  ''
                }
              </Grid>
            </Grid>

            <Grid
              item
              className="bg-violet mt-30 br-5 p-10 w-100"
              sx={{ maxHeight: { xs: 'auto', sm: '150px', md: '180px', lg: "270px" }, overflowY: "auto" }}
            >
              <h2 className="fs-24 m-0 fw-600">{data.name ? data.name : ""} - Biography</h2>
              {data.biography && data.biography !== "" ? (
                <MediaOverView
                  noShort={true}
                  className={"fs-14"}
                  text={data.biography ? data.biography : ""}
                  fullHeight={true}
                />
              ) : (
                ""
              )}
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ width: "100%", marginTop: '20px' }}>
        <Grid item>
          <Grid
            item
            className="no-scale"
            style={{ overflow: "hidden" }}
          >
            {cast.personMediaData && cast.personMediaData.length > 0 ?
              <p className="fs-24 fw-600 m-0">
                <span className="text-selected">known</span> for
              </p>
              : ''
            }
            <CommonDetailTileListing
              isShowHeading={false}
              subheading={false}
              breakpoints={breakpoints}
              data={cast.personMediaData}
              refPage="Celebrity"
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}
