import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    is_public: false,
    selectedTitlesForWatchlist: [],
    isCreateWatchlistFormOpen: false,
    isOpenReviewModal: '',
    isOpenRateModal: false,
    watchlistType: true,
    isAdvanceSearch: false,
    isLoginModalOpen: false,
    isSignUpModalOpen: false,
    loginContent: null,
    loginLogo: null,
    activeAudio: '',
    activeVideo: '',
    kidsAgeGroup: '',
    followUnfollowData: [],
    watchlistMediaFilter: {
        service: [],
        genre: [],
        language: [],
        content_type: [],
        exclude_already_watched: '',
        sort: 'd',
        apply_filter: false
    },
    searchMediaFilter: {
        service: [],
        genre: [],
        language: [],
        content_type: [],
        exclude_already_watched: '',
        sort: '',
        apply_filter: false
    },
    topTenTabValue: 'all',
    isOneTimeModal: false,
    isPartnerJourneyModal: false,
    isClipsMuted: true,
    reviewCategoryType: 'all',
    activeClipIndex: 0,
    searchString: '',
    mobileVerify: false,
};


export const globalSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        updateSelectedTitlesForWatchlist: (state, action) => {
            state.selectedTitlesForWatchlist = action.payload;
        },
        setCreateWatchlistFormOpen: (state, action) => {
            if (action.payload.forceClose && action.payload.forceClose) {
                state.isCreateWatchlistFormOpen = false;
            } else {
                if (state.watchlistType === action.payload.type) {
                    state.isCreateWatchlistFormOpen = action.payload.open;
                } else {
                    state.isCreateWatchlistFormOpen = true;
                }
                state.watchlistType = action.payload.type
            }
        },
        updateWatchlistMediaFilter: (state, action) => {
            if (action.payload.name) {
                state.watchlistMediaFilter = { ...state.watchlistMediaFilter, [action.payload.name]: action.payload.value ? action.payload.value : '' };
            }
        },
        updateSearchMediaFilter: (state, action) => {
            if (action.payload.name) {
                state.searchMediaFilter = { ...state.searchMediaFilter, [action.payload.name]: action.payload.value ? action.payload.value : typeof (action.payload.value) == 'boolean' ? false : '' };
            }
        },
        toggleAdvanceSearch: (state, action) => {
            state.isAdvanceSearch = action.payload.value
        },
        resetSearchFilter: (state) => {
            state.searchMediaFilter = {
                service: [],
                genre: [],
                language: [],
                content_type: [],
                exclude_already_watched: '',
                sort: '',
                apply_filter: false
            }
        },
        toggleActiveAudio: (state, action) => {
            state.activeAudio = action.payload.value;
            state.activeVideo = '';
        },
        toggleActiveVideo: (state, action) => {
            state.activeVideo = action.payload.value
            state.activeAudio = '';
        },
        updateAgeGroup: (state, action) => {
            state.kidsAgeGroup = action.payload
        },
        updateTopTenTabs: (state, action) => {
            state.topTenTabValue = action.payload
        },
        updateToggleReview: (state, action) => {
            state.isOpenReviewModal = action.payload
        },
        updateToggleRate: (state, action) => {
            state.isOpenRateModal = action.payload
        },
        toggleLoginModal: (state, action) => {
            if (action.payload.isOpen) {
                let referer = window.localStorage.getItem('referer');
                if (referer != 'interactions') {
                    let link = window.location.pathname + window.location?.search
                    window.localStorage.setItem("referer", link);
                }
            }
            state.loginContent = action.payload.content || null;
            state.loginLogo = action.payload.logo || null;
            state.isLoginModalOpen = action.payload.isOpen
            if (action.payload.isOpen) {
                state.isSignUpModalOpen = false
            }
        },
        toggleSignUpModal: (state, action) => {
            state.isSignUpModalOpen = action.payload
            if (action.payload) {
                state.isLoginModalOpen = false
            }
        },
        toggleOneTimeModal: (state, action) => {
            state.isOneTimeModal = action.payload
        },
        togglePartnerJourneyModal: (state, action) => {
            state.isPartnerJourneyModal = action.payload
        },
        toggleClipsMuted: (state, action) => {
            state.isClipsMuted = action.payload
        },
        toggleIsPublic: (state, action) => {
            state.is_public = action.payload
        },
        updateFollowUnfollowData: (state, action) => {
            if (action.payload.data && action.payload.data.uuid) {
                if (state.followUnfollowData && state.followUnfollowData.length > 0) {
                    state.followUnfollowData = state.followUnfollowData.map((item) => {
                        if (item.uuid == action.payload.data.uuid) {
                            return { ...action.payload.data }
                        }
                    })
                } else {
                    state.followUnfollowData.push(action.payload.data);
                }
            }
        },
        setReviewCategoryType: (state, action) => {
            state.reviewCategoryType = action.payload
        },
        updateClipActiveIndex: (state, action) => {
            state.activeClipIndex = action.payload
        },
        setSearchString: (state, action) => {
            state.searchString = action.payload
        },
        openMobileVerify: (state, action) => {
            state.mobileVerify = action.payload
        },
    },
    extraReducers: {}
});
export const {
    clearMessages,
    updateSelectedTitlesForWatchlist,
    setCreateWatchlistFormOpen,
    updateWatchlistMediaFilter,
    updateSearchMediaFilter,
    resetSearchFilter,
    toggleAdvanceSearch,
    toggleActiveAudio,
    toggleActiveVideo,
    updateAgeGroup,
    updateTopTenTabs,
    updateToggleReview,
    updateToggleRate,
    toggleLoginModal,
    toggleSignUpModal,
    toggleIsPublic,
    toggleOneTimeModal,
    togglePartnerJourneyModal,
    toggleClipsMuted,
    updateFollowUnfollowData,
    setReviewCategoryType,
    updateClipActiveIndex,
    setSearchString,
    openMobileVerify
} = globalSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default globalSlice.reducer;