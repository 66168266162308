import { Grid, CircularProgress, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { blockUser, getBlockedUsers, getMutedUsers, getReportedUsers, reportUser } from '../../../slices/settings';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export default function AccountsView(props) {
    const { type } = props;
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { mutedUsersPageData, mutedUsersData, reportedUsersPageData, reportedUsersData, blockedUsersPageData, blockedUsersData } = settings;

    console.log('aman', blockedUsersData)

    const handleUnblockReport = (data, type) => {
        if (type == 'unBlock') {
            const body = {
                target_user: data?.uuid,
                is_blocked: false,
            };
            helpers.trackMoengageEvent('FR3_Frd_Profile', {
                block_click: MOENGAGE_ATTRIBUTE.YES,
                user_name: data?.username,
                unique_name: data?.unique_name
            });
            dispatch(blockUser({ body: body })).then(() => {
                setTimeout(() => {
                    dispatch(getBlockedUsers({ page: 1, pagelimit: 20 }));
                }, 1000)
            })
        }
        if (type == "reported") {
            const body = {
                target_user: data?.uuid,
                is_reported: false,
            };
            helpers.trackMoengageEvent('FR3_Frd_Profile', {
                report_click: MOENGAGE_ATTRIBUTE.YES,
                user_name: data?.username,
                unique_name: data?.unique_name
            });
            dispatch(reportUser({ body: body })).then(() => {
                setTimeout(() => {
                    dispatch(getReportedUsers({ page: 1, pagelimit: 20 }));
                }, 1000)
            })
        }
    };


    const getUserView = (x, i) => {
        return (
            <div>
                <Grid container alignItems={'center'} justifyContent={'space-between'} className='mt-20'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <Box
                                    component="img"
                                    className='mt-10'
                                    sx={{
                                        height: 40,
                                        width: 40,
                                        maxHeight: { xs: 40, sm: 40 },
                                        maxWidth: { xs: 40, sm: 40 },
                                        borderRadius: '50%'
                                    }}
                                    alt="profilepic"
                                    src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('person')}
                                />
                            </Grid>
                            <Grid item className='pl-16'>
                                <p className="fw-500 fs-20 m-0">
                                    {x?.username}
                                </p>
                                <p className="fw-500 fs-12 m-0">
                                    {x?.unique_name}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item className='history-point-container'>
                        <p className='fw-500  fs-15 m-0'>blocked</p>
                    </Grid> */}
                    {type == 'blocked' &&
                        <Grid item>
                            <Button onClick={() => handleUnblockReport(x ,'unBlock')} className='text-selected bg-light rounded-btn mw-100'>unblock</Button>
                        </Grid>
                    }
                    {type == 'reported' &&
                        <Grid item>
                            <Button onClick={() => handleUnblockReport(x , 'reported')} className='text-selected bg-light rounded-btn mw-100'>remove report</Button>
                        </Grid>
                    }
                </Grid> 

            </div>
        )
    }

    const getPaginationView = (data) => {
        return (
            <>
                {data?.pagination && data?.pagination.is_next_page &&
                    <div className='text-center mt-10'>
                        {settings.isLoading ?
                            <CircularProgress size={20} color="success" />
                            :
                            <RefreshOutlinedIcon onClick={() => {
                                if (data?.pagination && data?.pagination.is_next_page) {
                                    if (type == "blocked") {
                                        dispatch(getBlockedUsers({ page: data?.pagination.page + 1, pagelimit: 20 }));
                                    }
                                    if (type == "muted") {
                                        dispatch(getMutedUsers({ page: data?.pagination.page + 1, pagelimit: 20 }));
                                    }
                                    if (type == "reported") {
                                        dispatch(getReportedUsers({ page: data?.pagination.page + 1, pagelimit: 20 }));
                                    }
                                }
                            }} className='pointer' />
                        }
                    </div>
                }
            </>
        )
    }

    useEffect(() => {
        dispatch(getMutedUsers({ page: 1, pagelimit: 20 }));
        dispatch(getReportedUsers({ page: 1, pagelimit: 20 }));
        dispatch(getBlockedUsers({ page: 1, pagelimit: 20 }));
    }, [type])

    // console.log('amanji' , blockedUsersData)

    return (
        <div>
            <Grid item>
                <Grid container alignItems={'center'} onClick={() => props.setIsAccountsScreen(true)} >
                    <ArrowBackIosNewRoundedIcon className='mt-4 pointer' />
                    <p className='fw-700 fs-20 m-0 lh-20'>accounts</p>
                </Grid>
                {/* <div className='relative pb-10'>
                    <Paper
                        component="form"
                        style={{ opacity: 1 }}
                        className='w-100 d-flex ai-center br-25 relative mb-5 mt-20'
                    >
                        <IconButton aria-label="search" className='bg-selected' sx={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}>
                            <SearchIcon className='ml-3 pt-2' color="light" />
                        </IconButton>
                        <InputBase
                            className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500'
                            value={value}
                            type='text'
                            onChange={handleSearchTextChange}
                            placeholder='search user'
                        />

                        <IconButton aria-label="search" onClick={() => setValue('')} className='h-100 bg-muted' sx={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                            <CloseIcon className='ml-3 pt-2' color="light" />
                        </IconButton>
                    </Paper>
                </div> */}
                {type == "blocked" &&
                    <>
                        {blockedUsersData && blockedUsersData.length > 0 ?
                            <div className='mh-300' style={{ overflowY: 'auto', marginRight: -15, paddingRight: 15 }}>
                                {blockedUsersData.map((x, i) => {
                                    return <div key={i}>{getUserView(x, i)}</div>
                                })}
                                {getPaginationView(blockedUsersPageData)}
                            </div>
                            :
                            <div>
                                <p className='fs-14 fw-300 text-center text-muted'>No data</p>
                            </div>
                        }
                    </>
                }
                {type == "muted" &&
                    <>
                        {mutedUsersData && mutedUsersData.length > 0 ?
                            <div className='mh-300' style={{ overflowY: 'auto', marginRight: -15, paddingRight: 15 }}>
                                {mutedUsersData.map((x, i) => {
                                    return <div key={i}>{getUserView(x, i)}</div>
                                })}
                                {getPaginationView(mutedUsersPageData)}
                            </div>
                            :
                            <div>
                                <p className='fs-14 fw-300 text-center text-muted'>No data</p>
                            </div>
                        }
                    </>
                }
                {type == "reported" &&
                    <>
                        {reportedUsersData && reportedUsersData.length > 0 ?
                            <div className='mh-300' style={{ overflowY: 'auto', marginRight: -15, paddingRight: 15 }}>
                                {reportedUsersData.map((x, i) => {
                                    return <div key={i}>{getUserView(x, i)}</div>
                                })}
                                {getPaginationView(reportedUsersPageData)}
                            </div>
                            :
                            <div>
                                <p className='fs-14 fw-300 text-center text-muted'>No data</p>
                            </div>
                        }
                    </>
                }
            </Grid>
        </div>
    )
}
