import { Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPreferences } from '../../../slices/auth';
// import Selection from './Selection'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CommonMediumTileListing from '../../common/CommonMediumTileListing';
import Loader from '../../common/Loader';
// import PreferMood from '../PreferMood';
import PreferLanguage from '../PreferLanguage';
import AddIcon from '@mui/icons-material/Add';
// import Language from '../../onboarding/Language';
import TrendingPlatform from '../TrendingPlatform';
import { getGenreMediaMovie, getGenreMediaSearched, getGenreMediaTv, getLanguageMedia, getPlatformMedia } from '../../../slices/trending';
import Genre from '../../onboarding/Genre';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';
import ContentAccordion from '../../common/ContentAccordion';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '90%', sm: '75%', md: '50%' },
    bgcolor: 'transparent',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
};

export default function PreferGenreInner() {
    const [page, setPage] = useState(1);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('');
    const [selectedGenreName, setSelectedGenreName] = useState('');
    const [selectedLanguageName, setSelectedLanguageName] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("")
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') === 'true' ? true : false;
    const genreId = searchParams.get('genre') ? searchParams.get('genre') : '';

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const trending = useSelector(state => state.trending);
    const _global = useSelector(state => state.global);
    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        setSelectedGenre(genreId);
    }, [genreId])

    useEffect(() => {
        if (selectedGenreName && selectedGenreName != '') {
            navigate(`/genres/${selectedGenreName}${window.location?.search}`);
        }
    }, [selectedGenreName])

    useEffect(() => {
        if (auth.allPreferences && auth.allPreferences.genres && auth.allPreferences.genres.length > 0) {
            if (!genreId || genreId === '') {
                if (params?.genre && params?.genre != '') {
                    let filtered = auth.allPreferences.genres.filter((x) => x.genre == params?.genre || x.genre.toLowerCase() === params?.genre);
                    setSelectedGenre(filtered[0]?.uuid || '');
                    setSelectedGenreName(filtered[0]?.genre.toLowerCase() || '');
                } else {
                    setSelectedGenre(auth.allPreferences.genres[0].uuid ? auth.allPreferences.genres[0].uuid : '')
                    setSelectedGenreName(auth.allPreferences.genres[0].genre ? auth.allPreferences.genres[0].genre.toLowerCase() : '');
                }
            } else {
                auth.allPreferences.genres.map((g) => {
                    if (genreId === g?.uuid) {
                        setSelectedGenreName(g.genre ? g.genre.toLowerCase() : '')
                    }
                })
            }
        }
    }, [auth.allPreferences])

    useEffect(() => {
        if (auth.successMesg && auth.successMesg !== "") {
            dispatch(getAllPreferences({ is_public: _global.is_public }));
        }
    }, [auth])

    useEffect(() => {
        dispatch(getAllPreferences({ is_public: _global.is_public }));
    }, [])

    useEffect(() => {
        if (selectedLanguage && selectedLanguage !== "") {
            var body = {
                page: page,
                pagelimit: 20,
                id: selectedLanguage,
                body: { genre: [selectedGenreName] },
                is_free: is_free,
                is_public: _global.is_public
            }
            dispatch(getLanguageMedia(body));
        }
    }, [selectedLanguage, selectedGenreName])

    useEffect(() => {
        if (selectedPlatform && selectedPlatform !== "") {
            var body = {
                page: page,
                pagelimit: 20,
                id: selectedPlatform,
                body: { genre: [selectedGenreName] },
                is_free: is_free,
                is_public: _global.is_public
            }
            dispatch(getPlatformMedia(body));
        }
    }, [selectedPlatform, selectedGenreName])

    useEffect(() => {
        if (selectedGenre && selectedGenre !== "") {
            var body = {
                page: page,
                pagelimit: 20,
                id: selectedGenre,
                is_free: is_free,
                is_public: _global.is_public
            }
            dispatch(getGenreMediaMovie({ ...body, media_type: 'movie' }));
            dispatch(getGenreMediaTv({ ...body, media_type: 'tv' }));
            dispatch(getGenreMediaSearched({ ...body, media_type: 'all', sort: 's' }));
        }
    }, [selectedGenre])

    return (
        <>
            <MyHelmet />
            {(trending.isLoading || auth.isLoading) && <Loader />}
            <div className="new-container">
                <ContentAccordion
                    isFull={true}
                    title={'GENRE_H'}
                    data={'GENRE'}
                />
            </div>
            <Grid className='new-container'>
                <Grid item key={`ads`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_prefer_genre_top_ad'} hideInMobile={true} />
                    </Grid>
                </Grid>
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            <h2 className='fs-28 fw-700 lh-28 m-0'>choose from these <span className='text-selected'>genres</span></h2>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className='pt-20'>
                    <Grid container alignItems={'center'} className='mb-0 w-100'>
                        {!_global.is_public &&
                            <Grid item onClick={() => setOpenModal(true)} sx={{ width: { xs: 40, sm: 64 }, height: { xs: 40, sm: 64 }, borderRadius: '50%' }} className='mb-10 mr-20 add-language-container pointer'>
                                <AddIcon />
                            </Grid>
                        }
                        {auth.allPreferences && auth.allPreferences.genres && selectedGenre !== "" && auth.allPreferences.genres.length > 0 && auth.allPreferences.genres.map((x, i) => {
                            if (_global.is_public && i > 2) {
                                return true;
                            }
                            return <Grid
                                sx={{
                                    width: { xs: 40, sm: 64 },
                                    height: { xs: 40, sm: 64 },
                                    borderRadius: '50%',
                                }}
                                className={`mb-10 circle-lang-container ${selectedGenre === x.uuid ? 'lang-selected' : ''} pointer mr-20`}
                                onClick={() => {
                                    setSelectedGenre(x.uuid);
                                    setSelectedGenreName(x?.genre.toLowerCase())
                                }}
                                key={i}
                            >
                                <Typography className='fw-500 text-center fs-14 p-2'>{x.genre ? x.genre : 'N/A'}</Typography>
                            </Grid>
                        })}
                        {_global.is_public &&
                            <Grid item onClick={() => setOpenModal(true)} sx={{ width: { xs: 40, sm: 64 }, height: { xs: 40, sm: 64 }, borderRadius: '50%' }} className='mb-10 add-language-container pointer'>
                                <AddIcon />
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <CommonMediumTileListing
                    heading={<>popular <span className='text-selected'>movies</span> in {selectedGenreName}</>}
                    subheading=''
                    isShowHeading={true}
                    nextLink={`/genres/${selectedGenreName}/movie?id=${selectedGenre}${is_free ? '&is_free=true' : ''}`}
                    data={trending.genreMediaMovieData ? trending.genreMediaMovieData : []}
                    refPage={'Preferred Genre'}
                />
                <br />
                <Grid item key={`ads`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_prefer_genre_ad'} />
                    </Grid>
                </Grid>
                <CommonMediumTileListing
                    heading={<>popular <span className='text-selected'>web series</span> in {selectedGenreName}</>}
                    subheading=''
                    isShowHeading={true}
                    nextLink={`/genres/${selectedGenreName}/tv?id=${selectedGenre}${is_free ? '&is_free=true' : ''}`}
                    data={trending.genreMediaTvData ? trending.genreMediaTvData : []}
                    refPage={'Preferred Genre'}
                />
                <br />
                <Grid item key={`ads`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_prefer_genre_ad'} />
                    </Grid>
                </Grid>

                <TrendingPlatform
                    isShowHeading={true}
                    platforms={auth.allPreferences && auth.allPreferences.platforms ? auth.allPreferences.platforms : []}
                    heading={<>trending on <span className='text-selected'>{selectedPlatformOttName}</span> in {selectedGenreName}</>}
                    subheading={'search content on your favourite platforms'}
                    setSelectedPlatform={setSelectedPlatform}
                    setSelectedPlatformOttName={setSelectedPlatformOttName}
                    selectedPlatform={selectedPlatform}
                    data={trending.platformMediaData ? trending.platformMediaData : []}
                    is_free={is_free}
                    is_public={_global.is_public}
                    nextLink={`/genres/${selectedGenreName}/searched?id=${selectedGenre}&platform=${selectedPlatform}${is_free ? '&is_free=true' : ''}`}
                    refPage={'Preferred Genre'}
                />
                <br />
                <CommonMediumTileListing
                    heading={<>most <span className='text-selected'>searched</span> in {selectedGenreName}</>}
                    subheading='title that interest others'
                    isShowHeading={true}
                    nextLink={`/genres/${selectedGenreName}/searched?id=${selectedGenre}${is_free ? '&is_free=true' : ''}`}
                    data={trending.genreMediaSearchedData ? trending.genreMediaSearchedData : []}
                    refPage={'Preferred Genre'}
                />
                <br />
                <Grid item key={`ads`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_prefer_genre_ad'} />
                    </Grid>
                </Grid>
                <PreferLanguage
                    languages={auth.allPreferences && auth.allPreferences.languages ? auth.allPreferences.languages : []}
                    heading={<>trending in <span className='text-selected'>{selectedLanguageName}</span> in {selectedGenreName}</>}
                    subheading='content that speaks to you'
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguageName={setSelectedLanguageName}
                    data={trending.langMediaData ? trending.langMediaData : []}
                    is_free={is_free}
                    isPublic={_global.is_public}
                    refPage={'Preferred Genre'}
                    nextLink={`/genres/${selectedGenreName}/searched?id=${selectedGenre}${is_free ? '&is_free=true' : ''}`}
                />
                <Grid item key={`ads`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_prefer_genre_ad'} />
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Genre setActiveSteps={() => setOpenModal(false)} activeSteps={1} />
                    </Box>
                </Modal>
            </div>
        </>
    )
}
