import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFrenziUserAllReviews, getMyAllReviews } from '../../../slices/settings';
import TextReviewTile from '../../review/includes/TextReviewTile';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

export default function MyReview(props) {
    const { userId, isOwner } = props;
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { userReviewData, userReviewPageData, isLoading } = settings;

    useEffect(() => {
        if (userId && userId !== "") {
            dispatch(getFrenziUserAllReviews({ comment_type: '', user: userId, page: page, pagelimit: 20 }));
        } else {
            dispatch(getMyAllReviews({ comment_type: '', page: page, pagelimit: 20 }));
        }

    }, [userId, isOwner, page])

    useEffect(() => {
        const handleScroll = () => {
            if (userReviewPageData && userReviewPageData && userReviewPageData.pagination && userReviewPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, settings]);

    return (
        <Grid item className='app-container mt-20'>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <p className='fw-700 fs-28 m-0 ellipsis'>my reviews</p>
                <ArrowBackRoundedIcon className='pointer' onClick={props.handleBack} />
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
                {userReviewData && userReviewData.length > 0 ? userReviewData.map((x, i) => {
                    return <Grid item xs={12} sm={6} md={4} >
                        {x.comment_type === 'text' &&
                            <TextReviewTile x={x} isOwner={isOwner && !(userId && userId !== '')} />
                        }
                    </Grid>
                })
                    : !isLoading &&
                    <Grid item xs={12}>
                        <p className='fs-14 text-muted text-center'>No Data</p>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}
