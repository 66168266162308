import axiosInstance from "../http-common";
import helpers from "./helpers";
import { encryptData } from "./Utils";

const getMediaDetail = async (tmdb_id, unique_title, media_type, is_public = false) => {
    let url = `native_main/mediapage?is_web=false`;
    if (media_type) {
        url += `&media_type=${media_type}`;
    }
    if (tmdb_id) {
        url += `&tmdb_id=${tmdb_id}`;
    } else {
        url += `&unique_title=${unique_title}`;
    }
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();

    if (is_public) {
        const params = helpers.getQueryParams(url.split('?')[1]);
        let newBody = {
            ...params,
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_mediapage`, { data: encryptedData })
    } else {
        return axiosInstance.get(url);
    }
};

const getMediaCastDetail = async (media = "", page = 1, pagelimit = 10, is_public = false) => {
    let url = `native_main/mediacastcrew?section=cast&page=${page}&pagelimit=${pagelimit}&media=${media}`;
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();
    if (is_public) {
        const params = helpers.getQueryParams(url.split('?')[1]);
        let newBody = {
            ...params,
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_mediacastcrew`, { data: encryptedData })
    } else {
        return axiosInstance.get(url);
    }
};

const getMediaCrewDetail = async (media = "", page = 1, pagelimit = 10, is_public = false) => {
    let url = `native_main/mediacastcrew?section=crew&page=${page}&pagelimit=${pagelimit}&media=${media}`;
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();
    if (is_public) {
        const params = helpers.getQueryParams(url.split('?')[1]);
        let newBody = {
            ...params,
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_mediacastcrew`, { data: encryptedData })
    } else {
        return axiosInstance.get(url);
    }
};

const getFeaturedReview = (media = "", comment_type = "", page = 1, pagelimit = 10, unique_title = '') => {
    let url = `native_main/featuredreviews?page=${page}&pagelimit=${pagelimit}`;
    if (media && media != "") {
        url += `&media=${media}`;
    }
    if (unique_title && unique_title != "") {
        url += `&unique_title=${unique_title}`;
    }
    if (comment_type && comment_type != "") {
        url += `&comment_type=${comment_type}`;
    }
    return axiosInstance.get(url);
};

const getMediaReviewsList = (uuid = "", page = 1, pagelimit = 10, category = "", comment_type = "", sort_order = "", user = '') => {
    var url = `native_main/frenziallreviews?page=${page}&pagelimit=${pagelimit}&category=${category}&comment_type=${comment_type}&sort_order=${sort_order}`;
    if (uuid && uuid != "") {
        url = `native_main/frenziallreviews?page=${page}&pagelimit=${pagelimit}&category=${category}&comment_type=${comment_type}&sort_order=${sort_order}&media_id=${uuid}`;
    }
    if (user && user != '') {
        url += `&target_user=${user}`
    }
    return axiosInstance.get(url);
}

const getCastMedia = (media = "",) => {
    return axiosInstance.post(`native_main/personmedia?person=${media}`);
};

const getSimilarMediaData = (media = "",) => {
    return axiosInstance.get(`native_main/similardata?media=${media}`);
};

const rateAndLikeMedia = (body) => {
    return axiosInstance.post(`native_main/userlikerate`, body);
};

const addRemoveAlreadyWatched = (body) => {
    // return axiosInstance.post(`native_main/mediaalreadywatched`, body);
    return axiosInstance.post(`native_main/useralreadywatched`, body);
};

const removeAlreadyWatched = (uuid) => {
    return axiosInstance.delete(`native_main/removeuseralreadywatched/${uuid}`);
};

const notifyMe = (body, id) => {
    return axiosInstance.post(`native_main/notifymev3?media=${id}`, body);
};

const getNotifyMeData = (id) => {
    return axiosInstance.get(`native_main/notifymev3?media=${id}`);
};

const notifyMe_old = (body) => {
    return axiosInstance.post(`native_main/notifyme`, body);
};

const removeNotifyMe = (id) => {
    return axiosInstance.delete(`native_main/notifyme/${id}`);
};

const addReview = (body) => {
    return axiosInstance.post(`native_main/postreview`, body);
};

const reviewCheck = () => {
    return axiosInstance.get(`native_main/reviewcheck`);
};

const reportError = (body) => {
    return axiosInstance.post(`native_main/reporterror`, body);
};

const getreportError = (id) => {
    return axiosInstance.get(`native_main/reporterrorv3?media=${id}`)
}

const postreportError = (body) => {
    return axiosInstance.post(`native_main/reporterrorv3`, body)
}

const getFrenziAllReviews = (media = '', category = "all", comment_type = "all", page = 1, pagelimit = 10, sort = 'newest', unique_title = '') => {
    let url = `native_main/frenziallreviews?category=${category}&page=${page}&pagelimit=${pagelimit}&sort_order=${sort}`;
    if (media && media != "") {
        url += `&media_id=${media}`;
    }
    if (unique_title && unique_title != "") {
        url += `&unique_title=${unique_title}`;
    }
    if (comment_type && comment_type != "") {
        url += `&comment_type=${comment_type}`;
    }
    return axiosInstance.get(url);
};

const getFrenziTopReviewers = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/frenzitopreviewers?page=${page}&pagelimit=${pagelimit}`);
};

const likeDislikeReview = (body) => {
    return axiosInstance.post(`native_main/likereview`, body);
};

const reportReview = (body) => {
    return axiosInstance.post(`native_main/reportreview`, body);
};

const reportReason = () => {
    return axiosInstance.get(`native_main/reportreason`);
};

const getViewingOptions = async (id, section, is_public = false) => {
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();
    if (is_public) {
        let newBody = {
            media: id,
            section: section,
            captcha_token: captcha_token
        };
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_viewingoptions`, { data: encryptedData })
    } else {
        return axiosInstance.get(`native_main/viewingoptions?media=${id}&section=${section}`);
    }
};

const mediaIntermediateData = (tmdb_id = '', media_type = '') => {
    return axiosInstance.get(`native_main/mediaintermediatepage?media=${tmdb_id}&media_type=${media_type}`);
};

const addHistory = (body) => {
    return axiosInstance.post(`native_main/addhistory`, body);
};

const watchOnTv = (body, id) => {
    return axiosInstance.put(`connect/watchontv/${id}`, body);
};

const MediaDataService = {
    getMediaDetail,
    getMediaCastDetail,
    getMediaCrewDetail,
    getFeaturedReview,
    getMediaReviewsList,
    getCastMedia,
    getSimilarMediaData,
    rateAndLikeMedia,
    addRemoveAlreadyWatched,
    removeAlreadyWatched,
    notifyMe,
    removeNotifyMe,
    addReview,
    reviewCheck,
    reportError,
    getFrenziAllReviews,
    getFrenziTopReviewers,
    likeDislikeReview,
    reportReview,
    reportReason,
    getNotifyMeData,
    getViewingOptions,
    mediaIntermediateData,
    addHistory,
    watchOnTv,
    getreportError,
    postreportError
};

export default MediaDataService;