import { Box, Grid } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { rateAndLikeMedia } from "../../slices/media";
// import CutEye from "../../assets/icons/CutEye.svg";
import { HELPER_TEXT } from "../../lang/HelperText";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { toggleLoginModal } from "../../slices/global";

const CutEye = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/CutEye.svg";

export default function LikeMovie(props) {
  const { data, height, width, size, isDislike, hideLabel, removedId } = props;
  const dispatch = useDispatch();
  const global = useSelector((state) => state.global);

  const handleLike = (mdata) => {

    if (global.is_public) {
      dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.LIKE }));
    } else {
      helpers.trackMoengageEvent('FR3_Searches', {
        title: mdata.title,
        like: MOENGAGE_ATTRIBUTE.YES,
        seen: MOENGAGE_ATTRIBUTE.YES,
      });

      const mediaId = mdata.media && mdata.media.length > 25
        ? mdata.media
        : mdata.uuid && mdata.uuid.length > 25
          ? mdata.uuid
          : "";
      const payload = {
        media: mediaId,
        like_status: isDislike ? -1 : !mdata.is_liked,
      };
      if (isDislike) {
        if (removedId !== mediaId) {
          dispatch(rateAndLikeMedia({ body: payload }));
        }
      } else {
        dispatch(rateAndLikeMedia({ body: payload }));
      }
      if (isDislike) {
        props?.setRemovedId(mediaId);
      }
    }
  };

  return (
    <>
      <Grid
        container
        onClick={() => handleLike(data)}
        justifyContent={"center"}
      >
        {isDislike ?
          <Box
            className={`pointer eye icon-container ${removedId === data?.media ? 'bg-selected' : ''} ${props.className || ''}`}
            sx={{
              maxHeight: height ? height : 38,
              maxWidth: width ? width : 38,
              width: "100%",
              height: height ? height : 38,
              padding: "8px",
            }}
            title="do not show"
          >
            <Box
              component={'img'}
              alt='eye'
              src={CutEye}
              sx={{
                padding: '5px',
                width: 30
              }}
              onMouseLeave={props?.handlePlay}
              onMouseOver={props?.handleStop}
            />
          </Box>
          :
          <Box
            className={`pointer icon-container ${props.className}`}
            sx={{
              maxHeight: height ? height : 38,
              maxWidth: width ? width : 38,
              width: "100%",
              height: height ? height : 38,
              padding: "8px",
            }}
          >
            <ThumbUpOutlinedIcon
              className={`fs-${size ? size : 40} ${data.is_liked && "text-selected"
                }`}
            />
          </Box>
        }
      </Grid>
      {!hideLabel &&
        <span
          onClick={() => handleLike(data)}
          className="d-block text-center pointer fs-14 fw-600"
        >
          Like
        </span>
      }
    </>
  );
}
