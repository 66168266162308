import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/system/Box';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { Link } from 'react-router-dom';
import Platform from '../onboarding/Platform';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformList, getAllPreferences } from '../../slices/auth';
import CommonMediumTileListing from '../common/CommonMediumTileListing';
import SectionHeading from '../component/headings/SectionHeading';
import { isMobileDevice } from '../../services/Utils';
import SeeAll from '../common/SeeAll';

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '90%', sm: '75%', md: '50%' },
    bgcolor: 'transparent',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
};


export default function TrendingPlatform(props) {
    const { data, heading, hideAds, subheading, platforms, selectedPlatformName, isArrowBtn, selectedPlatform, nextLink, selectedPlatformOttName, isLoading } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const auth = useSelector(state => state.auth);
    const _global = useSelector(state => state.global);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.is_free && !props.is_public) {
            if (auth.platformList && auth.platformList.results && auth.platformList.results.length > 0) {
                props.setSelectedPlatform && props?.setSelectedPlatform(auth.platformList.results[0].uuid ? auth.platformList.results[0].uuid : '')
                props.setSelectedPlatformName && props?.setSelectedPlatformName(auth.platformList.results[0].code ? auth.platformList.results[0].code : '');
                props.setSelectedPlatformOttName && props?.setSelectedPlatformOttName(auth.platformList.results[0].ott_name ? auth.platformList.results[0].ott_name.replace('Amazon ', '') : '');
            }
        } else {
            if (auth.platformList && auth.platformList.results && auth.platformList.results.length > 0) {
                props.setSelectedPlatform && props?.setSelectedPlatform(auth.platformList.results[0].uuid ? auth.platformList.results[0].uuid : '')
                props.setSelectedPlatformName && props?.setSelectedPlatformName(auth.platformList.results[0].code ? auth.platformList.results[0].code : '');
                props.setSelectedPlatformOttName && props?.setSelectedPlatformOttName(auth.platformList.results[0].ott_name ? auth.platformList.results[0].ott_name.replace('Amazon ', '') : '');
            }
        }
    }, [auth.platformList])



    useEffect(() => {
        setCurrentSlide(0);
    }, [selectedPlatform])

    const handleAddPlatfrom = () => {
        helpers.trackMoengageEvent('FR3_Frenzi', { add_ott_platform_click: MOENGAGE_ATTRIBUTE.YES });
        setOpenModal(true);
    }

    useEffect(() => {
        dispatch(getPlatformList({ is_free: props.is_free, page: 1, pagelimit: 50, is_public: _global?.is_public }));
        dispatch(getAllPreferences({ is_free: props.is_free, page: 1, pagelimit: 50, is_public: _global?.is_public }));
    }, [])


    const nextLink1 = nextLink && nextLink !== "" ? nextLink : `/platforms/${selectedPlatformOttName}${props.is_free ? '?is_free=true' : ''}`;


    return (
        <>
            {props.isShowHeading &&
                <Grid
                    container
                    alignItems={"center"}
                    sx={{ marginTop: { xs: "20px", sm: "30px" } }}
                    justifyContent="space-between"
                >
                    <div>
                        {/* <h2 className='fs-28 fw-700 lh-28 m-0'>{heading && heading !== "" ? heading : <><span className='fs-28 fw-700 lh-28 m-0'>trending on </span> <span className='text-selected fs-28 fw-700 lh-28 m-0'>{selectedPlatformOttName}</span> </>}</h2> */}
                        <SectionHeading title={heading} />
                        {/* <h2 className='fs-24 fw-500 mt-0 mb-10 pt-5 text-dark-grey'>{subheading && subheading !== "" ? subheading : <>surfing across platform made simple</>}</h2> */}
                    </div>
                    {isArrowBtn ?
                        <ArrowForwardIcon onClick={() => props.onArrowClick && props?.onArrowClick()} className='pointer' />
                        :
                        <Link to={nextLink1}>
                            {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
                            <SeeAll />
                        </Link>
                    }
                </Grid>
            }
            {
                props.is_free && !props.is_public ?
                    <Grid container alignItems={'center'} flexWrap={'nowrap'} className='m-0 overflow-no-scroll pt-10'>
                        {!props.is_public && !props.is_free &&
                            <Grid item onClick={() => handleAddPlatfrom()} sx={{ maxHeight: { xs: 40, sm: 64 }, maxWidth: { xs: 40, sm: 64 }, minWidth: { xs: 40, sm: 64 }, position: 'sticky', left: 0 }} className='mb-10 add-platform-container pointer mr-20'>
                                <AddIcon />
                            </Grid>
                        }
                        {auth.platformList && auth.platformList.results && selectedPlatform !== "" && auth.platformList.results.length > 0 && auth.platformList.results.map((x, i) => {
                            if (props.is_public && i > 2) {
                                return true;
                            }
                            return <Grid item className={`${selectedPlatform === x.uuid ? 'platform-outer-ring' : ''} mr-15 pointer scale mb-10`} onClick={() => {
                                props.setSelectedPlatform && props?.setSelectedPlatform(x.uuid);
                                props.setSelectedPlatformName && props?.setSelectedPlatformName(x?.code);
                                props.setSelectedPlatformOttName && props?.setSelectedPlatformOttName(x?.ott_name.replace('Amazon ', ''));
                            }} key={i} >
                                <Box
                                    component="img"
                                    className='d-block mt-10'
                                    sx={{
                                        height: { xs: 40, sm: 64 },
                                        width: { xs: 40, sm: 64 },
                                        borderRadius: '50%'
                                    }}
                                    alt=""
                                    src={x.image ? helpers.getFullUrl(x.image, 300) : helpers.getDefaultImage("platform")}
                                />
                            </Grid>
                        })}
                        {props.is_public &&
                            <Grid item onClick={() => handleAddPlatfrom()} sx={{ maxHeight: { xs: 40, sm: 64 }, maxWidth: { xs: 40, sm: 64 }, minWidth: { xs: 40, sm: 64 }, position: 'sticky', left: 0 }} className='mb-10 add-platform-container pointer mr-20'>
                                <AddIcon />
                            </Grid>
                        }
                    </Grid>
                    :
                    <Grid container alignItems={'center'} flexWrap={'nowrap'} className='m-0 overflow-no-scroll pt-10'>
                        {!props.is_public &&
                            <Grid item onClick={() => handleAddPlatfrom()} sx={{
                                maxWidth: { xs: 40, sm: 60 },
                                minWidth: { xs: 40, sm: 60 },
                                maxHeight: { xs: 40, sm: 60 },
                                minHeight: { xs: 40, sm: 60 },
                                position: 'sticky', left: 0, zIndex: 999
                            }}
                                className='mb-10 add-platform-container pointer mr-20'>
                                <AddIcon />
                            </Grid>
                        }
                        {platforms && platforms.length > 0 && platforms.map((x, i) => {
                            if (props.is_public && i > 2) {
                                return true;
                            }
                            return <Grid item className={`${selectedPlatform === x.uuid ? 'platform-outer-ring' : ''} mr-20 pointer prefer-platform-select scale mb-10`} onClick={() => {
                                props.setSelectedPlatform && props?.setSelectedPlatform(x.uuid);
                                props.setSelectedPlatformName && props?.setSelectedPlatformName(x?.code);
                                props.setSelectedPlatformOttName && props?.setSelectedPlatformOttName(x?.ott_name.replace('Amazon ', ''));
                            }} key={i} >
                                <Box
                                    component="img"
                                    className='d-block'
                                    sx={{
                                        height: { xs: 40, sm: 60 },
                                        width: { xs: 40, sm: 60 },
                                        borderRadius: '50%'
                                    }}
                                    alt=""
                                    src={x.image ? helpers.getFullUrl(x.image, 300) : helpers.getDefaultImage("platform")}
                                />
                            </Grid>
                        })}
                        {props.is_public &&
                            <Grid item onClick={() => handleAddPlatfrom()} sx={{ maxHeight: { xs: 40, sm: 64 }, maxWidth: { xs: 40, sm: 64 }, minWidth: { xs: 40, sm: 64 }, position: 'sticky', left: 0 }} className='mb-10 add-platform-container pointer mr-20'>
                                <AddIcon />
                            </Grid>
                        }
                    </Grid>
            }

            <CommonMediumTileListing
                data={data}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                selectedPlatformName={selectedPlatformName}
                selectedPlatformOttName={selectedPlatformOttName}
                refPage={'Trending'}
                nextLink={nextLink1}
                selectedPlatform={selectedPlatform}
                isLoading={isLoading}
                withAd={props?.withAd}
                adv_type={!hideAds ? 'web_trending_platform_ad' : null}
            />

            <div>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Platform setActiveSteps={() => setOpenModal(false)} activeSteps={2} is_public={true} />
                    </Box>
                </Modal>
            </div>
        </>
    )
}
