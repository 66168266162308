import React, { useEffect, useState } from "react";
import { Grid, Button, Modal, Box } from "@mui/material";
import InviteUsers from "../common/InviteUsers";
import { useDispatch, useSelector } from "react-redux";
import FriendShareList from "./FriendShareList";
import CommonMenu from "../common/CommonMenu";
import FilmyHeads from "./FilmyHeads";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import {
  getCinephilesUserDetails,
  getFriendsWatchlist,
  getSuggestedUserDetails,
} from "../../slices/connect";
// import Loader from "../common/Loader";
import { getTrendingInFriends } from "../../slices/trending";
import FriendsWatching from "../trending/FriendsWatching";
import Followers from "./Followers";
import Followings from "./Followings";
import "./connect.scss";
import { clearMessages } from "../../slices/cast";
import { clearMessages as clearMessagesWatchlist } from "../../slices/watchlist";
import { useSearchParams } from "react-router-dom";
import ConnectWithCinematch from "../activity/ConnectWithCinematch";
import MyHelmet from "../common/MyHelmet";
import MyAds from "../adsense/MyAds";
import { toggleLoginModal } from "../../slices/global";
import LinearLoader from "../common/LinearLoader";

const menuList = [
  {
    link: "all",
    label: "all",
  },
  {
    link: "followers",
    label: "followers",
  },
  {
    link: "following",
    label: "following",
  },
];

const menuList2 = [
  {
    link: "followers",
    label: "followers",
  },
  {
    link: "following",
    label: "following",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: "90%", sm: "760px" },
  bgcolor: "#16213E",
  boxShadow: 24,
  // p: { xs: 2, sm: 4 },
  borderRadius: "7px",
  maxHeight: "95vh",
  overflowY: "auto",
};

export default function Connect(props) {
  const { hideAll, pageType } = props;
  const [searchParams] = useSearchParams();

  const [tabValue, setTabValue] = useState(
    pageType && pageType !== "" ? pageType : "all"
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const trending = useSelector((state) => state.trending);
  const connect = useSelector((state) => state.connect);
  const watchlist = useSelector((state) => state.watchlist);
  const cast = useSelector((state) => state.cast);
  const {
    cinephilesUserData,
    cinephilesUserPageData,
    friendsWatchListData,
    isLoading,
    friendsWatchListPageData,
  } = connect;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const handleTabChange = (val) => {
    setTabValue(val);
    helpers.trackMoengageEvent("FR3_Connect", {
      [`${val}_click`]: MOENGAGE_ATTRIBUTE.YES,
    });
  };

  useEffect(() => {
    var body = {
      page: 1,
      pagelimit: 20,
      is_free: false,
    };
    if (!hideAll) {
      dispatch(getSuggestedUserDetails());
      dispatch(getTrendingInFriends(body));
      dispatch(getFriendsWatchlist({ page: 1, pagelimit: 20 }));
    }
    helpers.trackMoengageEvent("FR3_Connect", {
      page_veiwed: MOENGAGE_ATTRIBUTE.YES,
    });
  }, []);

  useEffect(() => {
    if (watchlist.errMesg && watchlist.errMesg !== "") {
      helpers.toastError(watchlist.errMesg);
      dispatch(clearMessagesWatchlist());
    }
    if (watchlist.successMesg && watchlist.successMesg !== "") {
      helpers.toastSuccess(watchlist.successMesg);
      dispatch(getFriendsWatchlist({ page: 1, pagelimit: 20 }));
      dispatch(clearMessagesWatchlist());
    }
  }, [watchlist]);

  useEffect(() => {
    if (cast.errMesg && cast.errMesg !== "") {
      if (!props.hideToast) {
        helpers.toastError(cast.errMesg);
      }
      dispatch(clearMessages());
    }
    if (cast.successMesg && cast.successMesg !== "") {
      if (!props.hideToast) {
        helpers.toastSuccess(cast.successMesg);
      }
      handleReloadData();
      dispatch(clearMessages());
    }
  }, [cast]);

  useEffect(() => {
    if (!hideAll) {
      dispatch(getCinephilesUserDetails({ page: page, pagelimit: 20 }));
    }
  }, [page]);

  const handleReloadData = () => {
    if (page === 1) {
      dispatch(getCinephilesUserDetails({ page: page, pagelimit: 20 }));
    } else {
      setPage(1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        tabValue === "all" &&
        cinephilesUserPageData &&
        cinephilesUserPageData &&
        cinephilesUserPageData.pagination &&
        cinephilesUserPageData.pagination.is_next_page &&
        !isLoading
      ) {
        if (
          window.innerHeight + window.scrollY + 500 >=
          document.body.offsetHeight
        ) {
          setPage(page + 1);
        }
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, connect]);

  return (
    <>
      {!props.discoveryPage ?
        <MyHelmet
          title={'connect with your friends'}
          description={"Connect with Friends: Discover Similarities and Connect with Like minded Movie Enthusiasts!"}
        />
        :
        ""
      }
      <div className="pb-50">
        {props.discoveryPage && props.discoveryPage ? (
          <>
            <Grid item xs={12} className="app-section section-border-bottom pb-10 ">
              <Grid item xs={12} className="app-container pb-20">
                {trending.trendingInFriendsData &&
                  trending.trendingInFriendsData.length > 0 && (
                    <FriendsWatching
                      refPage={"Connect"}
                      data={trending.trendingInFriendsData}
                    />
                  )}
              </Grid>
            </Grid>
            <Grid item className="app-section section-border-bottom pb-10 ">
              <Grid item className="app-container mt-20">
                <FriendShareList
                  data={friendsWatchListData}
                  pageData={friendsWatchListPageData}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className="app-section app-container mt-20">
                <FilmyHeads data={cinephilesUserData} isHorizontal={true} />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <div className="new-container">
              {(isLoading || cast.isLoading) && <LinearLoader />}
              <CommonMenu
                setTabValeu={(val) => handleTabChange(val)}
                menuList={hideAll ? menuList2 : menuList}
                tabValue={tabValue}
                justify={"space-between"}
                isBack={true}
              />

              {tabValue === "all" && (
                <>
                  {/* <ConnectWithCinematch /> */}

                  <Grid container className='ai-center app-container mb-50'>
                    <Grid item xs={1} sm={3} className="section-border-bottom"></Grid>
                    <Grid item xs={10} sm={6}>
                      <Button onClick={() => {
                        if (global.is_public) {
                          dispatch(toggleLoginModal({ isOpen: true }));
                        } else {
                          handleOpen();
                        }
                      }} className="rounded-btn text-light bg-selected w-100 lh-20">invite your friends to frenzi and get 100 points </Button>
                    </Grid>
                    <Grid item xs={1} sm={3} className="section-border-bottom"></Grid>
                    <Grid item xs={12} className="text-center text-muted fs-16"><span>win 100 points for every freind that join frenzi</span></Grid>
                  </Grid>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <InviteUsers onClose={handleClose} />
                    </Box>
                  </Modal>
                  <Grid item xs={12} className='app-section mt-20'>
                    <Grid container justifyContent={'center'}>
                      <MyAds type="banner_2" adv_type={'web_connect_page_top_ad'} />
                    </Grid>
                  </Grid>

                  <Grid item className="app-section section-border-bottom pb-10 ">
                    <Grid item className="app-container pb-20">
                      {trending.trendingInFriendsData &&
                        trending.trendingInFriendsData.length > 0 && (
                          <FriendsWatching
                            refPage={"Connect"}
                            data={trending.trendingInFriendsData}
                          />
                        )}
                    </Grid>
                  </Grid>
                  <Grid item className="app-section section-border-bottom pb-10 ">
                    <Grid item className="app-container mt-20">
                      <FriendShareList
                        data={friendsWatchListData}
                        pageData={friendsWatchListPageData}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className='app-section mt-20'>
                    <Grid container justifyContent={'center'}>
                      <MyAds type="banner_2" adv_type={'web_connect_page_middle_ad'} />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid item className="app-section app-container mt-20">
                      <FilmyHeads data={cinephilesUserData} />
                    </Grid>
                  </Grid>
                </>
              )}

              {tabValue === "followers" && (
                <>
                  <Followers
                    otheruser={hideAll && pageType}
                    userId={searchParams.get("user_id")}
                    name={searchParams.get("name")}
                  />
                </>
              )}

              {tabValue === "following" && (
                <>
                  <Followings
                    otheruser={hideAll && pageType}
                    userId={searchParams.get("user_id")}
                    name={searchParams.get("name")}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
