import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material'
import React from 'react'
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { useDispatch } from 'react-redux';
import { changeAllowPosts, changeProfileType } from '../../../slices/settings';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
}));


export default function PrivacySettings(props) {
    const { data } = props;
    const dispatch = useDispatch();

    const handleToggleAccountType = (val) => {
        props.setIsAccountsScreen(false);
        props.setAccountsType(val);
    }

    const handleTogglePrivateAccount = (isChecked) => {
        dispatch(changeProfileType({ body: { public_profile: isChecked } }));
        handleTracking('private_account', isChecked ? MOENGAGE_ATTRIBUTE.TRUE : MOENGAGE_ATTRIBUTE.FALSE);
    }

    const handleAllowPost = (isChecked) => {
        dispatch(changeAllowPosts({ body: { allow_post: isChecked } }));
        handleTracking('allow_post', isChecked ? MOENGAGE_ATTRIBUTE.TRUE : MOENGAGE_ATTRIBUTE.FALSE);
    }

    const handleAdultPost = (isChecked) => {
        dispatch(changeAllowPosts({ body: { allow_adult_post: isChecked ? 'yes' : 'no' } }));
        handleTracking('allow_adult', isChecked ? MOENGAGE_ATTRIBUTE.TRUE : MOENGAGE_ATTRIBUTE.FALSE)
    }

    const handleTracking = (name, val) => {
        helpers.trackMoengageEvent('FR3_PrivacySettings', {
            [name]: val
        });
    }

    return (
        <div>
            <Grid container justifyContent={'space-between'} className='privacy-setting-container pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <AccountCircleOutlinedIcon />
                        <Grid item xs={8} className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>private account</p>
                            <p className={`fw-500 fs-12 m-0 ${!data.public_profile ? 'text-green' : 'text-muted'}`}>{!data.public_profile ? 'active' : 'disabled'}</p>
                        </Grid>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        checked={!data.public_profile}
                                        onChange={(e) => handleTogglePrivateAccount(!e.target.checked)}
                                    />
                                }
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid container justifyContent={'space-between'} className='privacy-setting-container pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <RssFeedOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>allow post on feed</p>
                            <p className={`fw-500 fs-12 m-0 ${data.allow_post ? 'text-green' : 'text-muted'}`}>{data.allow_post ? 'active' : 'disabled'}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <GreenSwitch
                                checked={data.allow_post}
                                onChange={(e) => handleAllowPost(e.target.checked)}
                            />
                        }
                    />
                </FormGroup>
            </Grid> */}

            <Grid container justifyContent={'space-between'} className='privacy-setting-container pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <LockOutlinedIcon />
                        <Grid item xs={8} className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>allow adult content</p>
                            <p className={`fw-500 fs-12 m-0 ${data.allow_adult_post == 'yes' ? 'text-green' : 'text-muted'}`}>{data.allow_adult_post == 'yes' ? 'active' : 'disabled'}</p>
                        </Grid>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        checked={data.allow_adult_post == 'yes' ? true : false}
                                        onChange={(e) => handleAdultPost(e.target.checked)}
                                    />
                                }
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid alignItems={'center'} onClick={() => {
                handleToggleAccountType('blocked');
                handleTracking('block_account_click', MOENGAGE_ATTRIBUTE.YES);
            }} container justifyContent={'space-between'} className='privacy-setting-container pb-20 pointer'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <BlockOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>blocked accounts</p>
                            <p className='fw-500 fs-12 m-0 text-muted'>see blocked accounts</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ArrowForwardIosRoundedIcon className='pr-30' />
                </Grid>
            </Grid>

            {/* <Grid alignItems={'center'} onClick={() => {
                handleToggleAccountType('muted');
                handleTracking('muted_account_click', MOENGAGE_ATTRIBUTE.YES);
            }} container justifyContent={'space-between'} className='privacy-setting-container pointer pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <VolumeOffOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>muted accounts</p>
                            <p className='fw-500 fs-12 m-0 text-muted'>see muted accounts</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ArrowForwardIosRoundedIcon className='pr-30' />
                </Grid>
            </Grid> */}

            <Grid alignItems={'center'} container onClick={() => {
                handleToggleAccountType('reported');
                handleTracking('reported_account_click', MOENGAGE_ATTRIBUTE.YES);
            }} justifyContent={'space-between'} className='privacy-setting-container pointer pb-20'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <ReportGmailerrorredOutlinedIcon />
                        <Grid item className='pl-25'>
                            <p className='fw-500 fs-18 m-0'>reported accounts</p>
                            <p className='fw-500 fs-12 m-0 text-muted'>see reported accounts</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ArrowForwardIosRoundedIcon className='pr-30' />
                </Grid>
            </Grid>

        </div>
    )
}
