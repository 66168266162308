import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TrendingDataService from "../services/trending.services";
import helpers from "../services/helpers";
import { decryptData } from "../services/Utils";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    castData: {},
    handpickedPageData: {},
    handpickedData: [],
    freshPageData: {},
    freshData: [],
    toptenPageData: {},
    toptenData: [],
    platformMediaPageData: {},
    platformMediaData: [],
    genreMediaPageData: {},
    genreMediaData: [],
    langMediaPageData: {},
    langMediaData: [],
    popularTodayPageData: {},
    popularTodayData: [],
    trendingInFriendsPageData: {},
    trendingInFriendsData: [],
    comingSoonPageData: {},
    comingSoonData: [],
    frenziWatchlistPageData: {},
    frenziWatchlistData: [],
    allWatchlistPageData: {},
    allWatchlistData: [],
    freshMoviePageData: {},
    freshMovieData: [],
    freshTvPageData: {},
    freshTvData: [],
    freshSearchedPageData: {},
    freshSearchedData: [],
    freshTrailerPageData: {},
    freshTrailerData: [],
    platformMoviePageData: {},
    platformMovieData: [],
    platformTvPageData: {},
    platformTvData: [],
    platformMediaSearchedPageData: {},
    platformMediaSearchedData: [],
    languageMediaMoviePageData: {},
    languageMediaMovieData: [],
    languageMediaTvPageData: {},
    languageMediaTvData: [],
    languageMediaSearchedPageData: {},
    languageMediaSearchedData: [],
    genreMediaMoviePageData: {},
    genreMediaMovieData: [],
    genreMediaTvPageData: {},
    genreMediaTvData: [],
    genreMediaSearchedPageData: {},
    genreMediaSearchedData: [],
    hiddenGemsPageData: {},
    hiddenGemsData: [],
    collectionMetaData: {},
    collectionMediaPageData: {},
    collectionMediaData: [],
    popularTodayMoviePageData: {},
    popularTodayMovieData: [],
    popularTodayTvPageData: {},
    popularTodayTvData: [],
    popularTodaySearchPageData: {},
    popularTodaySearchData: [],
    hiddenGemsMoviePageData: {},
    hiddenGemsMovieData: [],
    hiddenGemsTvPageData: {},
    hiddenGemsTvData: [],
    hiddenGemsSearchPageData: {},
    hiddenGemsSearchData: [],
    watchingWithPageData: {},
    watchingWithData: [],
    certificateMedia: {}
};


export const getHandPicked = createAsyncThunk(
    "native_main/handpicked",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'handpicked', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit, data?.no_filter)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPublicHandpicked = createAsyncThunk(
    "native_main/public_handpicked",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getPublicHandpicked(data?.is_free)
            let decryptedData = await decryptData(response?.data?.data);
            return decryptedData ? decryptedData : { data: null }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getNewlyAdded = createAsyncThunk(
    "native_main/fresh",
    async (data, { rejectWithValue }) => {
        try {
            window.generateNewToken();
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'fresh', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
            // return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getTopTen = createAsyncThunk(
    "native_main/topten",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'topten', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit, data?.no_filter)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPlatformMedia = createAsyncThunk(
    "native_main/platformmedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'platformmedia?platform=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getGenreMedia = createAsyncThunk(
    "native_main/genremedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'genremedia?genre=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getLanguageMedia = createAsyncThunk(
    "native_main/languagemedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'languagemedia?language=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPopularToday = createAsyncThunk(
    "native_main/populartoday",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'populartoday', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getTrendingInFriends = createAsyncThunk(
    "native_main/trendinginfriends",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'trendinginfriends', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getComingSoon = createAsyncThunk(
    "native_main/comingsoon",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'comingsoon', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
            // return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getFrenziWatchLists = createAsyncThunk(
    "native_main/frenziwatchlists",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getFrenziWatchLists(data?.is_public, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAllWatchLists = createAsyncThunk(
    "native_main/allwatchlistmedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'allwatchlistmedia', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getFreshArrivalMovieData = createAsyncThunk(
    "native_main/freshmovie",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'fresh', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFreshArrivalTvData = createAsyncThunk(
    "native_main/freshtv",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'fresh', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getMostSearchedTitles = createAsyncThunk(
    "native_main/freshsearched",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'fresh', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFreshTrailers = createAsyncThunk(
    "native_main/freshtrailers",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'freshtrailers', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPlatformMediaMovie = createAsyncThunk(
    "native_main/platformmediamovie",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'platformmedia?platform=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getPlatformMediaTv = createAsyncThunk(
    "native_main/platformmediatv",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'platformmedia?platform=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPlatformMediaSearched = createAsyncThunk(
    "native_main/platformmediasearched",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'platformmedia?platform=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getLanguageMediaMovie = createAsyncThunk(
    "native_main/languagemediamovie",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'languagemedia?language=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getLanguageMediaTv = createAsyncThunk(
    "native_main/languagemediatv",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'languagemedia?language=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getLanguageMediaSearched = createAsyncThunk(
    "native_main/languagemediasearched",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'languagemedia?language=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getGenreMediaMovie = createAsyncThunk(
    "native_main/genremediamovie",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'genremedia?genre=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getGenreMediaTv = createAsyncThunk(
    "native_main/genremediatv",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'genremedia?genre=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getGenreMediaSearched = createAsyncThunk(
    "native_main/genremediasearched",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'genremedia?genre=' + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getHiddenGems = createAsyncThunk(
    "native_main/hiddengems",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'hiddengems', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            debugger

            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCollectionMetadata = createAsyncThunk(
    "native_main/collection_metadata",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCollectionMetadata(data?.is_trending)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCollectionMediaData = createAsyncThunk(
    "native_main/collectionsmedia",
    async (data, { rejectWithValue }) => {
        try {
            let path;
            if (data?.collectionType == 'youtube') {
                path = `youtubelistingdetailsbyuser/${data?.id}`;
            } else {
                path = `collectionsmedia/${data?.id}`;
            }
            const response = await TrendingDataService.getCollectionMediaData(data?.date_range ? data?.date_range : '', path, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPopularTodayMovie = createAsyncThunk(
    "native_main/populartodayMovie",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'populartoday', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPopularTodayTv = createAsyncThunk(
    "native_main/populartodayTv",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'populartoday', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPopularTodaySearched = createAsyncThunk(
    "native_main/populartodaySearched",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'populartoday', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getHiddenGemsMovie = createAsyncThunk(
    "native_main/hiddengemsmovie",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'hiddengems', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getHiddenGemsTv = createAsyncThunk(
    "native_main/hiddengemstv",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'hiddengems', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getHiddenGemsSearch = createAsyncThunk(
    "native_main/hiddengemssearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', 'hiddengems', data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchingWith = createAsyncThunk(
    "native_main/whomwatchingmedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', `whomwatchingmedia?whom_watch_type=${data?.type}`, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getCommonDataForComponent = createAsyncThunk(
    "native_main/dynamicdataforcomponent",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', data?.path, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCertificateMedia = createAsyncThunk(
    "native_main/certificatemedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCertificateMedia(data?.is_public);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getCommonForAllData = createAsyncThunk(
    "native_main/commonforalldata",
    async (data, { rejectWithValue }) => {
        try {
            const response = await TrendingDataService.getCommonForAllData(data?.is_public, data?.date_range ? data?.date_range : '', data?.path + data?.id, data?.is_free, data?.body, data?.media_type, data?.sort, data.page, data.pagelimit);
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const trendingSlice = createSlice({
    name: "trending",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        [getHandPicked.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getHandPicked.fulfilled]: (state, action) => {
            state.handpickedPageData = getPayloadData(state, action);
            state.handpickedData = helpers.removeDuplicate(state.handpickedData.concat(getArrayData(state, action)));
        },
        [getHandPicked.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPublicHandpicked
        [getPublicHandpicked.fulfilled]: (state, action) => {
            state.handpickedPageData = getPayloadData(state, action);
            state.handpickedData = helpers.removeDuplicate(state.handpickedData.concat(getArrayData(state, action)));
        },
        // getNewlyAdded
        [getNewlyAdded.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getNewlyAdded.fulfilled]: (state, action) => {
            state.freshPageData = getPayloadData(state, action);
            state.freshData = helpers.removeDuplicate(state.freshData.concat(getArrayData(state, action)));
        },
        [getNewlyAdded.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getTopTen
        [getTopTen.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getTopTen.fulfilled]: (state, action) => {
            state.toptenPageData = getPayloadData(state, action);
            state.toptenData = helpers.removeDuplicate(state.toptenData.concat(getArrayData(state, action)));
        },
        [getTopTen.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPlatformMedia
        [getPlatformMedia.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPlatformMedia.fulfilled]: (state, action) => {
            state.platformMediaPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.platformMediaData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.platformMediaData = helpers.removeDuplicate(state.platformMediaData.concat(getArrayData(state, action)));
            }
        },
        [getPlatformMedia.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getGenreMedia
        [getGenreMedia.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getGenreMedia.fulfilled]: (state, action) => {
            state.genreMediaPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.genreMediaData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.genreMediaData = helpers.removeDuplicate(state.genreMediaData.concat(getArrayData(state, action)));
            }
        },
        [getGenreMedia.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getLanguageMedia
        [getLanguageMedia.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getLanguageMedia.fulfilled]: (state, action) => {
            state.langMediaPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.langMediaData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.langMediaData = helpers.removeDuplicate(state.langMediaData.concat(getArrayData(state, action)));
            }
        },
        [getLanguageMedia.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPopularToday
        [getPopularToday.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPopularToday.fulfilled]: (state, action) => {
            state.popularTodayPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.popularTodayData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.popularTodayData = helpers.removeDuplicate(state.popularTodayData.concat(getArrayData(state, action)));
            }
        },
        [getPopularToday.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getTrendingInFriends
        [getTrendingInFriends.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getTrendingInFriends.fulfilled]: (state, action) => {
            state.trendingInFriendsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.trendingInFriendsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.trendingInFriendsData = helpers.removeDuplicate(state.trendingInFriendsData.concat(getArrayData(state, action)));
            }
        },
        [getTrendingInFriends.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getComingSoon
        [getComingSoon.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getComingSoon.fulfilled]: (state, action) => {
            state.comingSoonPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.comingSoonData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.comingSoonData = helpers.removeDuplicate(state.comingSoonData.concat(getArrayData(state, action)));
            }
        },
        [getComingSoon.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFrenziWatchLists
        [getFrenziWatchLists.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFrenziWatchLists.fulfilled]: (state, action) => {
            state.frenziWatchlistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.frenziWatchlistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.frenziWatchlistData = helpers.removeDuplicate(state.frenziWatchlistData.concat(getArrayData(state, action)));
            }
        },
        [getFrenziWatchLists.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getAllWatchLists
        [getAllWatchLists.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getAllWatchLists.fulfilled]: (state, action) => {
            state.allWatchlistPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.allWatchlistData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.allWatchlistData = helpers.removeDuplicate(state.allWatchlistData.concat(getArrayData(state, action)));
            }
        },
        [getAllWatchLists.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFreshArrivalMovieData
        [getFreshArrivalMovieData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFreshArrivalMovieData.fulfilled]: (state, action) => {
            state.freshMoviePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.freshMovieData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.freshMovieData = helpers.removeDuplicate(state.freshMovieData.concat(getArrayData(state, action)));
            }
        },
        [getFreshArrivalMovieData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFreshArrivalTvData
        [getFreshArrivalTvData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFreshArrivalTvData.fulfilled]: (state, action) => {
            state.freshTvPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.freshTvData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.freshTvData = helpers.removeDuplicate(state.freshTvData.concat(getArrayData(state, action)));
            }
        },
        [getFreshArrivalTvData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getMostSearchedTitles
        [getMostSearchedTitles.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getMostSearchedTitles.fulfilled]: (state, action) => {
            state.freshSearchedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.freshSearchedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.freshSearchedData = helpers.removeDuplicate(state.freshSearchedData.concat(getArrayData(state, action)));
            }
        },
        [getMostSearchedTitles.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFreshTrailers
        [getFreshTrailers.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFreshTrailers.fulfilled]: (state, action) => {
            state.freshTrailerPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.freshTrailerData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.freshTrailerData = helpers.removeDuplicate(state.freshTrailerData.concat(getArrayData(state, action)));
            }
        },
        [getFreshTrailers.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPlatformMediaMovie
        [getPlatformMediaMovie.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPlatformMediaMovie.fulfilled]: (state, action) => {
            state.platformMoviePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.platformMovieData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.platformMovieData = helpers.removeDuplicate(state.platformMovieData.concat(getArrayData(state, action)));
            }
        },
        [getPlatformMediaMovie.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPlatformMediaTv
        [getPlatformMediaTv.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPlatformMediaTv.fulfilled]: (state, action) => {
            state.platformTvPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.platformTvData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.platformTvData = helpers.removeDuplicate(state.platformTvData.concat(getArrayData(state, action)));
            }
        },
        [getPlatformMediaTv.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPlatformMediaSearched
        [getPlatformMediaSearched.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPlatformMediaSearched.fulfilled]: (state, action) => {
            state.platformMediaSearchedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.platformMediaSearchedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.platformMediaSearchedData = helpers.removeDuplicate(state.platformMediaSearchedData.concat(getArrayData(state, action)));
            }
        },
        [getPlatformMediaSearched.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getLanguageMediaMovie
        [getLanguageMediaMovie.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getLanguageMediaMovie.fulfilled]: (state, action) => {
            state.languageMediaMoviePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.languageMediaMovieData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.languageMediaMovieData = helpers.removeDuplicate(state.languageMediaMovieData.concat(getArrayData(state, action)));
            }
        },
        [getLanguageMediaMovie.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getLanguageMediaTv
        [getLanguageMediaTv.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getLanguageMediaTv.fulfilled]: (state, action) => {
            state.languageMediaTvPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.languageMediaTvData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.languageMediaTvData = helpers.removeDuplicate(state.languageMediaTvData.concat(getArrayData(state, action)));
            }
        },
        [getLanguageMediaTv.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getLanguageMediaSearched
        [getLanguageMediaSearched.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getLanguageMediaSearched.fulfilled]: (state, action) => {
            state.languageMediaSearchedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.languageMediaSearchedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.languageMediaSearchedData = helpers.removeDuplicate(state.languageMediaSearchedData.concat(getArrayData(state, action)));
            }
        },
        [getLanguageMediaSearched.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getGenreMediaMovie
        [getGenreMediaMovie.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getGenreMediaMovie.fulfilled]: (state, action) => {
            state.genreMediaMoviePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.genreMediaMovieData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.genreMediaMovieData = helpers.removeDuplicate(state.genreMediaMovieData.concat(getArrayData(state, action)));
            }
        },
        [getGenreMediaMovie.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getGenreMediaTv
        [getGenreMediaTv.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getGenreMediaTv.fulfilled]: (state, action) => {
            state.genreMediaTvPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.genreMediaTvData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.genreMediaTvData = helpers.removeDuplicate(state.genreMediaTvData.concat(getArrayData(state, action)));
            }
        },
        [getGenreMediaTv.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getGenreMediaSearched
        [getGenreMediaSearched.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getGenreMediaSearched.fulfilled]: (state, action) => {
            state.genreMediaSearchedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.genreMediaSearchedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.genreMediaSearchedData = helpers.removeDuplicate(state.genreMediaSearchedData.concat(getArrayData(state, action)));
            }
        },
        [getGenreMediaSearched.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getHiddenGems
        [getHiddenGems.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getHiddenGems.fulfilled]: (state, action) => {
            state.hiddenGemsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.hiddenGemsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.hiddenGemsData = helpers.removeDuplicate(state.hiddenGemsData.concat(getArrayData(state, action)));
            }
        },
        [getHiddenGems.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getCollectionMetadata
        [getCollectionMetadata.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCollectionMetadata.fulfilled]: (state, action) => {
            state.collectionMetaData = getPayloadData(state, action);
        },
        [getCollectionMetadata.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getCollectionMediaData
        [getCollectionMediaData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCollectionMediaData.fulfilled]: (state, action) => {
            state.collectionMediaPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.collectionMediaData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.collectionMediaData = helpers.removeDuplicate(state.collectionMediaData.concat(getArrayData(state, action)));
            }
        },
        [getCollectionMediaData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPopularTodayMovie
        [getPopularTodayMovie.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPopularTodayMovie.fulfilled]: (state, action) => {
            state.popularTodayMoviePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.popularTodayMovieData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.popularTodayMovieData = helpers.removeDuplicate(state.popularTodayMovieData.concat(getArrayData(state, action)));
            }
        },
        [getPopularTodayMovie.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPopularTodayTv
        [getPopularTodayTv.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPopularTodayTv.fulfilled]: (state, action) => {
            state.popularTodayTvPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.popularTodayTvData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.popularTodayTvData = helpers.removeDuplicate(state.popularTodayTvData.concat(getArrayData(state, action)));
            }
        },
        [getPopularTodayTv.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPopularTodaySearched
        [getPopularTodaySearched.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPopularTodaySearched.fulfilled]: (state, action) => {
            state.popularTodaySearchPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.popularTodaySearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.popularTodaySearchData = helpers.removeDuplicate(state.popularTodaySearchData.concat(getArrayData(state, action)));
            }
        },
        [getPopularTodaySearched.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getHiddenGemsMovie
        [getHiddenGemsMovie.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getHiddenGemsMovie.fulfilled]: (state, action) => {
            state.hiddenGemsMoviePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.hiddenGemsMovieData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.hiddenGemsMovieData = helpers.removeDuplicate(state.hiddenGemsMovieData.concat(getArrayData(state, action)));
            }
        },
        [getHiddenGemsMovie.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getHiddenGemsTv
        [getHiddenGemsTv.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getHiddenGemsTv.fulfilled]: (state, action) => {
            state.hiddenGemsTvPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.hiddenGemsTvData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.hiddenGemsTvData = helpers.removeDuplicate(state.hiddenGemsTvData.concat(getArrayData(state, action)));
            }
        },
        [getHiddenGemsTv.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getHiddenGemsSearch
        [getHiddenGemsSearch.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getHiddenGemsSearch.fulfilled]: (state, action) => {
            state.hiddenGemsSearchPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.hiddenGemsSearchData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.hiddenGemsSearchData = helpers.removeDuplicate(state.hiddenGemsSearchData.concat(getArrayData(state, action)));
            }
        },
        [getHiddenGemsSearch.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },

        // getWatchingWith
        [getWatchingWith.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchingWith.fulfilled]: (state, action) => {
            state.watchingWithPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.watchingWithData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.watchingWithData = helpers.removeDuplicate(state.watchingWithData.concat(getArrayData(state, action)));
            }
        },
        [getWatchingWith.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getCertificateMedia
        [getCertificateMedia.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCertificateMedia.fulfilled]: (state, action) => {
            state.certificateMedia = getPayloadData(state, action);
        },
        [getCertificateMedia.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages } = trendingSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default trendingSlice.reducer;