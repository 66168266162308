import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SectionMedia from '../SectionMedia';
import { clearMessages, getFreindsSharelist, getFrenziSharelist, getPopularCreatorSharelist, getSharelistData, getWatchlistData, getWatchlistIJoined } from '../../../slices/watchlist';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Grid } from '@mui/material';
import helpers from '../../../services/helpers';
import Loader from '../../common/Loader';
import MyHelmet from '../../common/MyHelmet';

const titles = {
    1: <>my <span className='text-selected'>sharelist</span></>,
    2: <>my <span className='text-selected'>watchlist</span></>,
    3: <><span className='text-selected'>sharelists</span> i've joined</>,
    4: <>frenzi <span className='text-selected'>sharelists</span></>,
    5: <>friend <span className='text-selected'>sharelists</span></>,
    6: <>popular creator <span className='text-selected'>sharelists</span></>,
}

const pageLimit = 20;

export default function WatchlistListing() {
    const [page, setPage] = useState(1);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);
    const { watchlistPageData, sharelistPageData, watchlistIJoinedPageData, frenziSharelistPageData, friendsSharelistPageData, popularCreatorSharelistPageData, watchlistData, sharelistData, watchlistIJoinedData, frenziSharelistData, friendsSharelistData, popularCreatorSharelistData, isLoading } = watchlist;

    useEffect(() => {
        if (watchlist.errMesg && watchlist.errMesg !== "") {
            helpers.toastError(watchlist.errMesg);
            dispatch(clearMessages());
        }
        if (watchlist.successMesg && watchlist.successMesg !== "") {
            helpers.toastSuccess(watchlist.successMesg);
            getData();
            dispatch(clearMessages());
        }
    }, [watchlist])

    useEffect(() => {
        setPage(1);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 700)
    }, [params])

    useEffect(() => {
        getData();
    }, [page])

    const getData = () => {
        if (params && params.type && params.type <= 6) {
            if (params.type == 1) {
                dispatch(getSharelistData({ page: page, pagelimit: pageLimit }));
            } else if (params.type == 2) {
                dispatch(getWatchlistData({ page: page, pagelimit: pageLimit }));
            } else if (params.type == 3) {
                dispatch(getWatchlistIJoined({ page: page, pagelimit: pageLimit }));
            } else if (params.type == 4) {
                dispatch(getFrenziSharelist({ page: page, pagelimit: pageLimit }));
            } else if (params.type == 5) {
                dispatch(getFreindsSharelist({ page: page, pagelimit: pageLimit }));
            } else if (params.type == 6) {
                dispatch(getPopularCreatorSharelist({ section: 'popular', page: page, pagelimit: pageLimit }));
            }
        } else {
            // navigate(-1);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            let pageData = params.type == 1 ? sharelistPageData : params.type == 2 ? watchlistPageData : params.type == 3 ? watchlistIJoinedPageData : params.type == 4 ? frenziSharelistPageData : params.type == 5 ? friendsSharelistPageData : params.type == 6 ? popularCreatorSharelistPageData : null
            if (pageData && pageData && pageData.pagination && pageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, watchlist]);

    return (
        <div className='new-container'>
            <MyHelmet />
            {isLoading && <Loader />}
            <Grid item className='app-container sticky-top bg-primary pt-0' style={{ zIndex: 99 }}>
                <Grid container alignItems={'center'}>
                    <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                    <p className='fw-700 fs-28 lh-28'>{titles[params.type] ? titles[params.type] : ''}</p>
                </Grid>
            </Grid>
            {params.type == 1 && sharelistData.length > 0 && sharelistData.map((item, i) => {
                return <SectionMedia showAds={i % 2 === 0} refPage={'Watchlist'} data={item} key={i} />
            })}
            {params.type == 2 && watchlistData.length > 0 && watchlistData.map((item, i) => {
                return <SectionMedia showAds={i % 2 === 0} refPage={'Watchlist'} data={item} key={i} />
            })}
            {params.type == 3 && watchlistIJoinedData.length > 0 && watchlistIJoinedData.map((item, i) => {
                return <SectionMedia showAds={i % 2 === 0} refPage={'Watchlist'} data={item} key={i} />
            })}
            {params.type == 4 && frenziSharelistData.length > 0 && frenziSharelistData.map((item, i) => {
                return <SectionMedia showAds={i % 2 === 0} refPage={'Watchlist'} data={item} key={i} />
            })}
            {params.type == 5 && friendsSharelistData.length > 0 && friendsSharelistData.map((item, i) => {
                return <SectionMedia showAds={i % 2 === 0} refPage={'Watchlist'} data={item} key={i} />
            })}
            {params.type == 6 && popularCreatorSharelistData.length > 0 && popularCreatorSharelistData.map((item, i) => {
                return <SectionMedia showAds={i % 2 === 0} refPage={'Watchlist'} data={item} key={i} />
            })}
        </div>
    )
}
