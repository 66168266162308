import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react'
// import UnsubscribeImg from '../../assets/img/Unsubscribe.svg';
import helpers from '../../services/helpers';
import MyHelmet from './MyHelmet';

export default function Unsubscribe() {
    const [isDone, setIsDone] = useState(false);
    const [reason, setReason] = useState('I no longer want these emails');
    const [otherReason, setOtherReason] = useState('');

    let isGone = localStorage.getItem('_is_frenzi_unsubscribed');

    const handleClick = () => {
        if ((otherReason && otherReason !== '' && reason === 'other') || reason !== 'other') {
            if (reason !== 'other') {
                localStorage.setItem('_is_frenzi_unsubscribed', 'yes');
                setIsDone(true);
            } else {
                if (otherReason.trim().length < 20) {
                    helpers.toastError("Please type atlease 20 characters.")
                } else {
                    localStorage.setItem('_is_frenzi_unsubscribed', 'yes');
                    setIsDone(true);
                }
            }
        } else {
            helpers.toastError("Please enter something.")
        }
    }

    return (
        <div className='app-container'>
        <MyHelmet
            title = {"unsubscribe"}
            description = {"Easily manage preferences with our unsubscribe option. Stay in control and customize your subscription."}
        />
            <Grid
                sx={{ minHeight: 'calc(100vh - 268px)', padding: '10px', paddingTop: '40px', paddingBottom: '40px' }}
                container justifyContent={'center'} flexDirection='column' className='h-100'>
                <Grid item xs={12} className='text-center'>
                    <p className='p-0 m-0' style={{fontSize:'100px'}}>☹️</p>
                    {isGone === 'yes' || isDone ?
                        <>
                            <h1 className='text-selected lh-30 fs-32'>Unsubscribed</h1>
                            <Grid container justifyContent={'center'}>
                                <div className='text-center'>
                                    <p className='m-0 fs-22'>We are always here to answer any queries</p>
                                    <p className='m-0 fs-22'>Please contact us at <a href='mailto:support@frenzi.in' className='text-selected' >support@frenzi.in</a></p>
                                    <p className='m-0 pt-20 fs-22'>Thank you,</p>
                                    <p className='m-0 fs-22 fw-700'>Team Frenzi</p>
                                </div>
                            </Grid>
                        </>
                        :
                        <>
                            <h1 className='text-selected lh-30 fs-32'>Tussi na jao!</h1>
                            <Grid container className='unsubscribe-form' justifyContent={'center'}>
                                <div className='text-left'>
                                    <h1 className='lh-42 fs-22'>If you still want to go, let us know why : </h1>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="I no longer want these emails"
                                            name="radio-buttons-group"
                                            onChange={(e) => setReason(e.target.value)}
                                        >
                                            <FormControlLabel value="I no longer want these emails" control={<Radio />} label="I no longer want these emails" />
                                            <FormControlLabel value="I never signed up for this" control={<Radio />} label="I never signed up for this" />
                                            <FormControlLabel value="The emails are spam" control={<Radio />} label="The emails are spam" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                                        </RadioGroup>
                                    </FormControl>
                                    {reason === 'other' ?
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={4}
                                            maxRows={3}
                                            style={{
                                                width: "100%",
                                                maxHeight: "100px",
                                                height: "100%",
                                                borderRadius: "8px",
                                                paddingBottom: "20px",
                                                padding: 10,
                                                background: "transparent",
                                                color: "white",
                                            }}
                                            value={otherReason}
                                            onChange={(e) => setOtherReason(e.target.value)}
                                            placeholder="type here..."
                                        />
                                        :
                                        ''
                                    }

                                    <Grid container justifyContent={'center'}>
                                        <Button onClick={() => handleClick()} className='rounded-btn mt-20 mw-170 bg-selected text-light'>submit</Button>
                                    </Grid>

                                    {/* <h1 className='text-selected lh-42 fs-48'>It's not the same <br /> without you!</h1>
                                    <p className='m-0 fs-22'>You've been successfully unsubscribed from Frenzi's email newsletter.</p>
                                    <p className='m-0 fs-22'>Miss us already?, The feeling's mutual. Follow this link to rejoin.</p> */}
                                </div>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </div>
    )
}
