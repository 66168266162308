import React, { useEffect } from 'react'
import MyHelmet from '../common/MyHelmet';
import './careers.scss'


export default function ContactUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <MyHelmet
                title={"contact us"}
                description={"We value your feedback and inquiries. Connect with us at Frenzi for prompt support. Let us assist you further."}
            />
            <div className='new-container'>
                <iframe width={'100%'} height={'100%'} style={{ minHeight: '100vh', paddingTop: '20px', background: '#fff', border: 'none' }} src="https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Manual_uploads/shubham/htmlweb.html">

                </iframe>
            </div>
            {/* <Grid container className='app-container' sx={{ marginBottom: '50px' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems='center'>
                        <Grid item xs={12} sm={6} className=''>
                            <h1 className='fs-42 fw-800 lh-45'>How can we help you?</h1>
                            <p className='fs-16 lh-25 fw-300 text-muted'>for sales and advertisement enquiry
                                mail us at <a href="mailto:sales@myfrenzi.com">sales@myfrenzi.com</a>
                            </p>

                            <Grid item xs={8}>
                                <Grid container className='jc-sb mt-50'>
                                    <Grid item xs={2}>
                                        <Link>
                                            <Box component={'img'}
                                                src={MessageIcon}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="mailto:sales@myfrenzi.com">support@myfrenzi.com</a>
                                    </Grid>
                                </Grid>
                                <Grid container className='jc-sb mt-50'>
                                    <Grid item xs={2}>
                                        <Link>
                                            <Box component={'img'}
                                                src={LocationImg}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className='fs-16'>Kandivali</p>
                                    </Grid>
                                </Grid>
                                <Grid container className='jc-sb mt-50'>
                                    <Grid item xs={2}>
                                        <Link>
                                            <Box component={'img'}
                                                src={TeleImg}
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="tel:+91 9876543211">+91 9876543211</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='bg-light br-10 p-50' xs={12} sm={6} sx={{ marginTop: '50px' }}>
                            <Box component="form">
                                <p>
                                    <h1 className='fw-700 fs-35 lh-50 pb-20 mt-20 text-dark'>Say something</h1>
                                </p>
                                <Grid item xs={12}>
                                    <FormControl className='mb-10 w-100'>
                                        <Grid item>
                                            <p className='text-dark m-0'>your name</p>
                                            <OutlinedInput className='border mh-50 mb-10 pr-50 w-100' />
                                        </Grid>
                                        <p className='text-dark m-0'>your mail</p>
                                        <OutlinedInput className='border w-100 mh-50 mb-10' />

                                        <p className='text-dark m-0'>your message</p>
                                        <OutlinedInput variant='outlined' className='border br-8' sx={{ minHeight: '220px' }} />
                                    </FormControl>
                                </Grid>

                            </Box>
                            <Button className='rounded-btn mw-240 bg-selected text-light fs-14 fw-500 lh-16 ml-20'>submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    )
}
