import { Box, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
// import cinescore_image from '../../assets/icons/Vector.svg'
// import SeenActive from '../../assets/icons/SeenActive.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveAlreadyWatched, removeAlreadyWatched } from '../../slices/media';
import { toggleLoginModal } from '../../slices/global';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { HELPER_TEXT } from '../../lang/HelperText';

const cinescore_image = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/Vector.svg";
const SeenActive = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/SeenActive.svg";

export default function SeenMovie(props) {
    const { data, height, width, size, hideLabel } = props;
    const [alreadyWatchedIds, setAlreadyWatchedIds] = useState([]);
    const global = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const handleSeen = (mdata) => {
        if (global?.is_public) {
            dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.SEEN }));
        } else {
            helpers.trackMoengageEvent('FR3_Searches', {
                title: mdata.title,
                seen: MOENGAGE_ATTRIBUTE.YES,
            });
            const payload = {
                media: mdata.media && mdata.media.length > 25 ? mdata.media : mdata.uuid && mdata.uuid.length > 25 ? mdata.uuid : '',
            }
            let tempArr = alreadyWatchedIds;
            if (data?.already_watched || alreadyWatchedIds.includes(payload.media)) {
                dispatch(removeAlreadyWatched({ uuid: payload.media }));
                let index1 = tempArr.indexOf(payload.media);
                if (index1 !== -1) {
                    tempArr.splice(index1, 1);
                    setAlreadyWatchedIds([...tempArr]);
                }
            } else {
                dispatch(addRemoveAlreadyWatched({ body: payload }));
                if (tempArr.length > 0) {
                    let index = tempArr.indexOf(payload.media);
                    if (index === -1) {
                        tempArr.splice(index, 1);
                        setAlreadyWatchedIds([...tempArr]);
                    } else {
                        tempArr.push(payload.media);
                        setAlreadyWatchedIds([...tempArr]);
                    }
                } else {
                    tempArr.push(payload.media);
                    setAlreadyWatchedIds([...tempArr]);
                }
            }
        }
    }


    return (
        <>
            <Grid container onClick={() => handleSeen(data)} justifyContent={'center'}>
                {props?.type && props?.type === 'button' ?
                    <Button sx={{ alignItems: 'center' }} className={`${alreadyWatchedIds.includes(data.media || data.uuid) || data.already_watched ? 'bg-selected text-light' : 'bg-gunpowder text-selected'} d-flex rounded-btn mr-20 w-100 `} >
                        <Box
                            className={`pointer icon-container ${props.className}`}
                            sx={{
                                maxHeight: height ? height : 48,
                                maxWidth: width ? width : 48,
                                minWidth: 24,
                                width: '100%',
                                height: '100%',
                                padding: "6px",
                                left: '10px',
                                position: 'absolute'
                            }}
                        >
                            <img alt={''} src={alreadyWatchedIds.includes(data.media || data.uuid) || data.already_watched ? SeenActive : cinescore_image} className={`w-100`} />
                        </Box>
                        {!hideLabel &&
                            <span style={{ marginTop: '-3px', marginLeft: '20px' }} className={`${alreadyWatchedIds.includes(data.media || data.uuid) || data.already_watched ? 'text-light' : 'text-selected'} d-block fs-16 fw-600 `}>{alreadyWatchedIds.includes(data.media || data.uuid) || data.already_watched ? 'Seen' : 'mark as seen'}</span>
                        }
                    </Button>
                    :
                    <Box
                        className={`pointer icon-container ${props.className}`}
                        sx={{
                            maxHeight: height ? height : 48,
                            maxWidth: width ? width : 48,
                            width: '100%',
                            height: '100%',
                            padding: "8px"
                        }}
                    >
                        {/* {data.already_watched ?
                        <img src={DontShowIcon} />
                        :
                        <VisibilityOutlinedIcon className={`fs-${size ? size : 40} ${data.already_watched && 'text-selected'}`} />
                    } */}
                        <img alt={''} src={alreadyWatchedIds.includes(data.media || data.uuid) || data.already_watched ? SeenActive : cinescore_image} />
                    </Box>
                }
            </Grid>
            {!hideLabel && props?.type !== 'button' &&
                <span onClick={() => handleSeen(data)} className='d-block text-center pointer fs-14 fw-600'>Seen</span>
            }
        </>
    )
}
