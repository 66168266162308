import { Box, Grid, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserWatchlist } from '../../../slices/settings';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Link } from 'react-router-dom';

export default function MyWatchlist(props) {
    const { userId, isOwner } = props;
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { userWatchlistData, userWatchlistPageData, isLoading } = settings;

    useEffect(() => {
        if (userId && userId !== "") {
            dispatch(getUserWatchlist({ privacy: '', sort: '', user: userId, page: page, pagelimit: 20 }));
        } else {
            if (isOwner) {
                // let currentUser = localStorage.getItem('frenzi_user_access');
                dispatch(getUserWatchlist({ privacy: '', sort: '', user: '', page: page, pagelimit: 20 }));
            }
        }

    }, [userId, isOwner, page])

    useEffect(() => {
        const handleScroll = () => {
            if (userWatchlistPageData && userWatchlistPageData && userWatchlistPageData.pagination && userWatchlistPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, settings]);

    return (
        <Grid item className='app-container mt-20'>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <h2 className='fw-700 fs-28 m-0 ellipsis'>my watchlists</h2>
                <ArrowBackRoundedIcon className='pointer' onClick={props.handleBack} />
            </Grid>
            {/* <Grid container justifyContent={'space-between'} className='mt-20'>
                <Button className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 '>
                    explore more watchlists
                </Button>
                <Button className='rounded-btn mw-120 bg-selected text-light fs-14 fw-500 '>
                    filter
                </Button>
            </Grid> */}
            <Grid container spacing={3} style={{ marginTop: 10 }}>
                {userWatchlistData && userWatchlistData.length > 0 ? userWatchlistData.map((x, i) => {
                    return <Grid item xs={12} sm={6} md={4} className={'scale pointer'} key={i}>
                        <div>
                            <Link onClick={() => {
                                helpers.trackMoengageEvent('FR3_View_Watchlist', {
                                    watchlist_click: MOENGAGE_ATTRIBUTE.YES,
                                    click_my_watchlist: MOENGAGE_ATTRIBUTE.YES,
                                    watchlist_name: x?.category_name
                                });
                                helpers.watchlistClick(x?.category_name, x?.uuid, 'MyWatchlist');
                            }} to={`/watchlist-detail/${x.uuid}`}>
                                <Box component="img"
                                    className='d-block'
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        maxHeight: 190,
                                        borderTopLeftRadius: '8px',
                                        borderTopRightRadius: '8px',
                                    }}
                                    alt=""
                                    src={x.backdrop ? helpers.getFullUrl(x.backdrop) : helpers.getDefaultImage("watchlist")}
                                />
                            </Link>
                            <Box
                                className='d-block'
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    borderBottomLeftRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    backgroundColor: '#16213E',
                                }}
                            >
                                <Grid container justifyContent={'space-between'} className="w-100 pl-16 pr-16 h-100" alignItems={'center'} >
                                    <Grid item xs={10}>
                                        <p title={x?.category_name} className='p-0 m-0 fs-16 fw-600 ellipsis'>{x?.category_name}</p>
                                    </Grid>
                                    <Grid item xs={2} className={'text-right'}>
                                        <p className='p-0 m-0 fs-12 fw-400'>{x?.total_titles} titles</p>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                })
                    : !isLoading &&
                    <Grid item sx={{alignItems:'center'}}>
                        <p className='fs-14 text-muted text-center'>No Data</p>
                    </Grid>
                }
                {isLoading &&
                    <Grid item sm={6} md={4} xs={12} key={'skeleton'}>
                        <Skeleton
                            sx={{
                                height: '100%',
                                width: '100%',
                                maxHeight: 220,
                                borderRadius: '8px',
                                backgroundColor: '#c4c4c4 !important'
                            }}
                            variant="rectangular"
                        />
                    </Grid>
                }
            </Grid>
        </Grid >
    )
}
