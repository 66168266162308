import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PartnerDataService from "../services/partner.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    partnerPageData: {},
    partnerData: [],
    watchingSliderPageData: {},
    watchingSliderData: [],
    groupHandpickedPageData: {},
    groupHandpickedData: []
};

export const getPartnerData = createAsyncThunk(
    "native_main/watchingwith",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PartnerDataService.getPartnerData(data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const createGroup = createAsyncThunk(
    "native_main/watchingwithcreate",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PartnerDataService.createGroup(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const leaveGroup = createAsyncThunk(
    "native_main/watchingwithactivityleave",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PartnerDataService.leaveGroup(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getGroupHandpickedData = createAsyncThunk(
    "native_main/groupmembershandpicked",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PartnerDataService.getGroupHandpickedData(data?.id, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getWatchingSliderData = createAsyncThunk(
    "native_main/watchingwithslider",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PartnerDataService.getWatchingSliderData(data?.id, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const partnerSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        // getPartnerData
        [getPartnerData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPartnerData.fulfilled]: (state, action) => {
            state.partnerPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.partnerData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.partnerData = helpers.removeDuplicate(state.partnerData.concat(getArrayData(state, action)));
            }
        },
        [getPartnerData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getGroupHandpickedData
        [getGroupHandpickedData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getGroupHandpickedData.fulfilled]: (state, action) => {
            state.groupHandpickedPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.groupHandpickedData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.groupHandpickedData = helpers.removeDuplicate(state.groupHandpickedData.concat(getArrayData(state, action)));
            }
        },
        [getGroupHandpickedData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getWatchingSliderData
        [getWatchingSliderData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getWatchingSliderData.fulfilled]: (state, action) => {
            state.watchingSliderData = getPayloadData(state, action);
        },
        [getWatchingSliderData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages } = partnerSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default partnerSlice.reducer;