import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Drawer, AccordionDetails, Typography, Grid, Button, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { getGenreList, getLanguageList, getPlatformList } from '../../slices/auth';
import { updateWatchlistMediaFilter } from '../../slices/global';

export default function WatchlistDrawerFilter(props) {
    
    const [toggle, setToggle] = useState({
        platform: true,
        language: false,
        genre: false
    });
    const currentPage = 1;
    const { drawerOpen } = props;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const _global = useSelector(state => state.global);
    const { watchlistMediaFilter } = _global;

    useEffect(() => {
        dispatch(getLanguageList());
        dispatch(getPlatformList({ page: currentPage, pagelimit: 100, is_public: _global?.is_public }));
        dispatch(getGenreList());
    }, [])

    const handleToggle = (name) => {
        const list = ['platform', 'language', 'genre'];
        let index = list.indexOf(name);
        list.splice(index, 1);
        setToggle({
            [list[0]]: false,
            [list[1]]: false,
            [name]: !toggle[name]
        })
    }

    const handleSelectBoxChange = (name, val, checked) => {
        if (checked) {
            let tempArr = watchlistMediaFilter[name];
            dispatch(updateWatchlistMediaFilter({ name: name, value: [...tempArr, val] }));
        } else {
            let tempArr = watchlistMediaFilter[name];
            let index = tempArr.indexOf(val);
            let newArr = [...tempArr];
            newArr.splice(index, 1);
            dispatch(updateWatchlistMediaFilter({ name: name, value: newArr }));
        }
    }

    const handleChangeSeenType = (val) => {
        dispatch(updateWatchlistMediaFilter({ name: 'exclude_already_watched', value: val ? 'yes' : '' }));
    }

    return (
        <Drawer
            anchor={props.anchor ? props.anchor : 'right'}
            open={drawerOpen}
            onClose={() => props.toggleDrawer(!drawerOpen)}
        >
            <Grid container flexDirection={'column'} justifyContent="space-between" className='h-100' sx={{ minWidth: { xs: 250, sm: 320, md: 350 } }} flexWrap={'nowrap'}>
                <Grid item style={{ overflowY: 'auto' }} className='w-100' >
                    <Accordion expanded={toggle.platform}>
                        <AccordionSummary onClick={() => handleToggle('platform')} expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header">
                            <Typography className='fs-16 fw-600' >Platform</Typography>
                        </AccordionSummary>
                        {auth.platformList && auth.platformList.results && auth.platformList.results.length > 0 &&
                            <AccordionDetails style={{ maxHeight: 200, overflowY: 'auto' }}>
                                {auth.platformList.results.map((x, i) => {
                                    return <Grid key={i} container justifyContent={'space-between'}>
                                        <Grid item><Typography className='fs-15 fw-500'>{x.ott_name ? x.ott_name : ''}</Typography></Grid>
                                        <Grid item>
                                            <Checkbox checked={watchlistMediaFilter.service.includes(x.code)} onChange={(e) => handleSelectBoxChange('service', x.code, e.target.checked)} />
                                        </Grid>
                                    </Grid>
                                })}
                            </AccordionDetails>
                        }
                    </Accordion>
                    <Accordion expanded={toggle.language}>
                        <AccordionSummary onClick={() => handleToggle('language')} expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header">
                            <Typography className='fs-16 fw-600'>Languages</Typography>
                        </AccordionSummary>
                        {auth.languageList && auth.languageList.languages && auth.languageList.languages.length > 0 &&
                            <AccordionDetails style={{ maxHeight: 200, overflowY: 'auto' }}>
                                {auth.languageList.languages.map((x, i) => {
                                    return <Grid key={i} container justifyContent={'space-between'}>
                                        <Grid item><Typography className='fs-15 fw-500'>{x.language ? x.language : ''}</Typography></Grid>
                                        <Grid item>
                                            <Checkbox checked={watchlistMediaFilter.language.includes(x.code)} onChange={(e) => handleSelectBoxChange('language', x.code, e.target.checked)} />
                                        </Grid>
                                    </Grid>
                                })}
                            </AccordionDetails>
                        }
                    </Accordion>
                    <Accordion expanded={toggle.genre}>
                        <AccordionSummary onClick={() => handleToggle('genre')} expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header">
                            <Typography className='fs-16 fw-600'>Genre</Typography>
                        </AccordionSummary>
                        {auth.genreList && auth.genreList.genres && auth.genreList.genres.length > 0 &&
                            <AccordionDetails style={{ maxHeight: 200, overflowY: 'auto' }}>
                                {auth.genreList.genres.map((x, i) => {
                                    return <Grid key={i} container justifyContent={'space-between'}>
                                        <Grid item><Typography className='fs-15 fw-500'>{x.genre ? x.genre : 'N/A'}</Typography></Grid>
                                        <Grid item>
                                            <Checkbox checked={watchlistMediaFilter.genre.includes(x.genre.toLowerCase())} onChange={(e) => handleSelectBoxChange('genre', x.genre.toLowerCase(), e.target.checked)} />
                                        </Grid>
                                    </Grid>
                                })}
                            </AccordionDetails>
                        }
                    </Accordion>
                    <Typography className='fs-16 mt-10 fw-600' style={{ paddingLeft: '16px' }} >
                        Hide Already Seen?
                        <Checkbox checked={watchlistMediaFilter.exclude_already_watched === "yes" ? true : false} className='text-selected' onChange={(e) => handleChangeSeenType(e.target.checked)} />
                    </Typography>
                </Grid>
                <Grid item className='text-center' sx={{ marginBottom: { xs: '80px', sm: '20px' } }} >
                    <Button
                        onClick={() => {
                            dispatch(updateWatchlistMediaFilter({ name: 'apply_filter', value: true }));
                            props.toggleDrawer(!drawerOpen);
                        }}
                        className='rounded-btn bg-selected w-80 text-light'>apply</Button>
                    <Button onClick={() => props.toggleDrawer(!drawerOpen)} className='rounded-btn mt-20 w-80  bg-light text-selected'>cancel</Button>
                </Grid>
            </Grid>
        </Drawer>
    )
}
