import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ProgressBar from '../component/ProgressBar';
import helpers from '../../services/helpers';
import BookmarkAction from '../watchlist/BookmarkAction';

export default function MediumTile(props) {
    const { x, hideBookmark, hideFooter, selectedPlatformOttName } = props;
    const [addedId, setAddeddId] = useState('');

    return (
        <>
            <Grid item className='scale-1'>
                <Grid item className='movie-container'>
                    <Link onClick={() => {
                        if (x.media_type === 'movie' || x.media_type === 'tv') {
                            helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage);
                        } else if (x.watchlist_id && x.watchlist_id !== "") {
                            helpers.watchlistClick(x?.category_name || x?.watchlist, x?.watchlist_id, props?.refPage);
                        }
                    }} to={helpers.getDynamicLink(x)}>
                        <Box
                            component="img"
                            className='d-block'
                            sx={{
                                height: 210,
                                width: 140,
                                maxHeight: { xs: 150, sm: 210 },
                                maxWidth: { xs: 100, sm: 140 },
                                borderRadius: "7px",
                                objectFit: 'cover',
                                objectPosition: 'top'
                            }}
                            alt={x?.title || 'poster'}
                            src={x.poster ? helpers.getFullUrl(x.poster, 300) : helpers.getDefaultImage('trendingmedia')}
                        />
                    </Link>
                    {/* {!hideBookmark && x.media_type && (x.media_type === "movie" || x.media_type === "tv") &&
                        <BookmarkAction data={x} addedId={addedId} setAddedId={setAddeddId} />
                    } */}
                    <Grid item xs={12} className='pt-0'>
                        <Grid container alignItems={'center'} sx={{ minWidth: { xs: '30px', sm: '50px', xl: '60px' } }} flexWrap={'nowrap'} className='bottom-right w-100'>
                            <p style={{ textTransform: 'uppercase' }} className='m-0 fs-10 pl-10'> {x.media_type ? x.media_type : ''} </p><p style={{ marginTop: '-3px' }} className='mr-0 ml-0 mb-0 fs-10 pl-5 pr-5'> | </p> <p className='m-0 pr-10 fs-10' style={{ textTransform: 'uppercase' }}> {x.language ? x.language : ''}</p>
                        </Grid>
                    </Grid>
                </Grid>
                {!hideFooter &&
                    <Grid container className='jc-sb' sx={{ marginTop: '5px' }} alignItems={'center'}>
                        <Grid item xs={8.5}>
                            {(x.person || x.watchlist_id) && x.title ?
                                <Typography className='fs-14 ellipsis lh-21 fw-400' sx={{ marginTop: '-3px' }} >
                                    {x.title}
                                </Typography>
                                :
                                <Typography
                                    title={selectedPlatformOttName || (x.data && x.data[0] && x.data[0].ott_name ? x.data[0].ott_name.replace("Amazon ", '') + `${x.data.length > 1 ? ` +${x.data.length - 1}` : ''}` : '')}
                                    className='fs-14 ellipsis lh-21 fw-400' sx={{ marginTop: '-3px' }} >
                                    {/* {selectedPlatformOttName || (x.data && x.data[0] && x.data[0].ott_name ? x.data[0].ott_name.replace("Amazon ", '') + `${x.data.length > 1 ? ` +${x.data.length - 1}` : ''}` : '')} */}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {x.rating && x.rating > 0 ?
                                <ProgressBar value={x.rating ? Number(x.rating) * 10 : 0} />
                                :
                                ''
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}
