import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from "@mui/material/Grid";
import "../trending.scss";
import { useDispatch, useSelector } from "react-redux";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../../services/helpers";
import {
  clearMessages,
  getComingSoon,
  getFrenziWatchLists,
  getGenreMedia,
  getLanguageMedia,
  getNewlyAdded,
  getPlatformMedia,
  getPopularToday,
  getPublicHandpicked,
} from "../../../slices/trending";
import { getAllPreferences } from "../../../slices/auth";
import { clearMessages as clearMessagesWatchlist } from "../../../slices/watchlist";
import { clearMessages as clearMessagesMedia } from "../../../slices/media";
import MyAds from '../../adsense/MyAds';
import CrouselNudge from "../../../nudges/CrouselNudge";
import { toggleOneTimeModal, togglePartnerJourneyModal } from "../../../slices/global";
import CrouselReviewNudge from "../../../nudges/CrouselReviewNudge";
import { Autoplay, EffectFade, FreeMode, Navigation, Pagination } from "swiper";
import { NUDGE_DATA } from "../../../nudges/NudgesData";
import { HandpickSliderNew } from "../HandpickSliderNew";
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import MyHelmet from "../../common/MyHelmet";
import LinearLoader from "../../common/LinearLoader";
import loadable from '@loadable/component';
import { isMobileDevice } from "../../../services/Utils";
import ComponentLoader from "../../common/ComponentLoader";

const LoadableHandpickDefault = loadable(() => import('../HandpickDefault'), {
  preload: true
});
const LoadableTopTen = loadable(() => import('../TopTen'));
const LoadableTrendingPlatform = loadable(() => import('../TrendingPlatform'));
const LoadableNewlyAdded = loadable(() => import('../NewlyAdded'));
const LoadablePreferLanguage = loadable(() => import('../PreferLanguage'));
const LoadablePreferMood = loadable(() => import('../PreferMood'));
const LoadableCommonDetailTileListing = loadable(() => import('../../common/CommonDetailTileListing'));
const LoadableJoinWatchlist = loadable(() => import('../JoinWatchlist'));
const LoadableFrenziPopular = loadable(() => import('../FrenziPopular'));

LoadableHandpickDefault.preload();

const page = 1;

function PublicTrending() {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("")
  const [currentLoading, setCurrentLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const trending = useSelector((state) => state.trending);
  const watchlist = useSelector((state) => state.watchlist);
  const media = useSelector((state) => state.media);
  const auth = useSelector((state) => state.auth);
  const _global = useSelector((state) => state.global);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageName, setSelectedLanguageName] = useState("")
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedGenreName, setSelectedGenreName] = useState("");
  const [controlledSwiper, setControlledSwiper] = useState(null);

  useEffect(() => {
    window.onscroll = function (e) {
      if (!localStorage.getItem("opened")) {
        localStorage.setItem("opened", true);
        setOpened(true);
      } else {
        setOpened(true);
      }
    }
  }, []);

  useEffect(() => {
    executeFirst();
    let isDone = false;
    setTimeout(() => {
      let y = localStorage.getItem("pfsy") || 0;
      window.scrollTo(0, y);
      isDone = true;
    }, 0)

    function handleScroll() {
      if (isDone) {
        const scrolledY = window.scrollY;
        localStorage.setItem("pfsy", scrolledY);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    showToastMessages();
  }, [trending, media, watchlist]);

  const showToastMessages = () => {
    if (trending.errMesg && trending.errMesg != "") {
      if (!trending.errMesg.includes('captcha')) {
        helpers.toastError(trending.errMesg);
      }
      dispatch(clearMessages());
    }
    if (trending.successMesg && trending.successMesg != "") {
      helpers.toastSuccess(trending.successMesg);
      dispatch(clearMessages());
    }
    if (media.errMesg && media.errMesg != "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessagesMedia());
    }
    if (media.successMesg && media.successMesg != "") {
      if (media.successMesg != "Like status changed") {
        helpers.toastSuccess(media.successMesg);
      }
      dispatch(clearMessagesMedia());
    }
    if (watchlist.errMesg && watchlist.errMesg != "") {
      helpers.toastError(watchlist.errMesg);
      dispatch(clearMessagesWatchlist());
    }
    if (watchlist.successMesg && watchlist.successMesg != "") {
      helpers.toastSuccess(watchlist.successMesg);
      dispatch(clearMessagesWatchlist());
    }
  }

  useEffect(() => {
    if (selectedPlatform && selectedPlatform != "") {
      var body = {
        page: page,
        pagelimit: 10,
        id: selectedPlatform,
        is_public: true
      };
      dispatch(getPlatformMedia(body));
    }
  }, [selectedPlatform]);


  useEffect(() => {
    if (selectedLanguage && selectedLanguage != "") {
      var body = {
        page: page,
        pagelimit: 10,
        id: selectedLanguage,
        is_public: true
      };
      dispatch(getLanguageMedia(body));
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedGenre && selectedGenre != "") {
      var body = {
        page: page,
        pagelimit: 10,
        id: selectedGenre,
        is_public: true
      };
      dispatch(getGenreMedia(body));
    }
  }, [selectedGenre]);

  const executeFirst = () => {
    setCurrentLoading(true);
    var body = {
      page: page,
      pagelimit: 10,
      is_free: false,
      is_public: true,
    };
    dispatch(getPublicHandpicked()).then(() => {
      setCurrentLoading(false);
    });
    refreshMovieData(body);

    if (!localStorage.getItem("is_auth_modal_shown") && !_global.isLoginModalOpen) {
      setTimeout(() => {
        dispatch(toggleOneTimeModal(true));
      }, 15000)
    }

    if (!localStorage.getItem("is_partner_journey_modal_shown") && !_global.isLoginModalOpen) {
      setTimeout(() => {
        dispatch(togglePartnerJourneyModal(true));
      }, 7000)
    }

    helpers.trackMoengageEvent('FR3_Land', { landing_page_load: MOENGAGE_ATTRIBUTE.YES });
    if (!localStorage.getItem('FirstSeen')) {
      helpers.trackMoengageEvent('FR3_FirstSeen', { home_page_load: MOENGAGE_ATTRIBUTE.YES });
      localStorage.setItem('FirstSeen', true);
    }

  }

  const refreshMovieData = (body) => {
    setTimeout(() => {
      dispatch(getNewlyAdded(body));
    }, 5000)
    dispatch(getAllPreferences({ is_public: true }));
    dispatch(getPopularToday(body));
    dispatch(getComingSoon(body));
    dispatch(getFrenziWatchLists(body));
  };

  const handleStop = () => {
    controlledSwiper.autoplay.stop();
  }

  const handlePlay = () => {
    controlledSwiper.autoplay.start();
  }

  return (
    <>
      <MyHelmet
        title={'Frenzi ! Simplifying what to watch on OTT'}
        description={'Discover What to Watch on OTT, Find the Perfect Shows and Movies, Stream on Your Preferred Platforms, and Get Notified when New Titles drop on OTT'}
      />

      {currentLoading && <LinearLoader />}
      <div className='app-section w-100' style={{ overflow: "hidden" }}>
        {!currentLoading && trending?.handpickedPageData.length > 0 ? (
          <Swiper
            centeredSlides={true}
            fadeEffect={{ crossFade: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true
            }}
            effect={"fade"}
            modules={[Autoplay, Navigation, EffectFade, Pagination]}
            className="new-swiper"
            onSwiper={setControlledSwiper}
            pagination={{
              clickable: true,
            }}
          >
            {trending.handpickedPageData.map((x, i) => {
              return <SwiperSlide>
                <HandpickSliderNew
                  data={x}
                  isShowTag={false}
                  title={<p className="fs-28 shadow m-0 fw-700 lh-28">
                    <span className="text-selected">trending</span>
                  </p>}
                  handlePlay={handlePlay}
                  handleStop={handleStop}
                />
              </SwiperSlide>
            })}
          </Swiper>
        )
          :
          <LoadableHandpickDefault isPublic={true} />
        }
        <Grid container justifyContent={'center'}>
          <MyAds type="banner_2" mobtype={"mobile_1"} slotId={'5721372290'} adv_type={'web_trending_top_ad'} />
        </Grid>
      </div>

      <Grid container className="new-container">
        <Grid flex={1} className="left-container">
          <>
            <Grid container className="pb-40" justifyContent={"center"}>
              <Grid item xs={12} className='app-container app-section'>

                <LoadableTopTen withAd={true} is_free={false} isTopTen={true} heading={<span><span className="text-selected">top 10</span> on ott this week</span>} isShowHeading={true} />

                {opened ? <>
                  <LoadableTrendingPlatform
                    isShowHeading={true}
                    platforms={
                      auth.allPreferences && auth.allPreferences.platforms
                        ? auth.allPreferences.platforms
                        : []
                    }
                    is_public={true}
                    setSelectedPlatform={setSelectedPlatform}
                    setSelectedPlatformOttName={setSelectedPlatformOttName}
                    selectedPlatformOttName={selectedPlatformOttName}
                    selectedPlatform={selectedPlatform}
                    hideAds={true}
                    withAd={true}
                    data={trending.platformMediaData ? trending.platformMediaData : []}
                    heading={<><span>trending on </span> <span className='text-selected'>{selectedPlatformOttName}</span></>}

                  />

                  <Grid container justifyContent={"center"}>
                    <MyAds type="banner_2" mobtype={"mobile_1"} slotId={'8155963946'} adv_type={"web_trending_fold_ad"} />
                  </Grid>

                  {trending.freshPageData &&
                    trending.freshPageData.results &&
                    trending.freshPageData.results.length > 0 && (
                      <LoadableNewlyAdded data={trending.freshPageData.results} withAd={true} className='app-container'
                        heading={<><span className="text-selected">fresh</span><span> arrivals</span></>}
                      />
                    )
                  }

                  <LoadablePreferLanguage
                    isPublic={true}
                    languages={
                      auth.allPreferences && auth.allPreferences.languages
                        ? auth.allPreferences.languages
                        : []
                    }
                    refPage={'Trending'}
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    selectedLanguageName={selectedLanguageName}
                    setSelectedLanguageName={setSelectedLanguageName}
                    data={trending.langMediaData ? trending.langMediaData : []}
                    withAd={true}
                    heading={<><span>trending in </span><span className="text-selected">{selectedLanguageName}</span></>}

                  />

                  <Grid container justifyContent={"center"}>
                    <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"8198258178"} height={100} adv_type={"web_trending_fold_ad"} />
                  </Grid>

                  <LoadablePreferMood
                    isPublic={true}
                    refPage={'Trending'}
                    genres={
                      auth.allPreferences && auth.allPreferences.genres
                        ? auth.allPreferences.genres
                        : []
                    }
                    setSelectedGenre={setSelectedGenre}
                    selectedGenre={selectedGenre}
                    selectedGenreName={selectedGenreName}
                    setSelectedGenreName={setSelectedGenreName}
                    data={trending.genreMediaData ? trending.genreMediaData : []}
                    withAd={true}
                    heading={<><span>trending in </span><span className="text-selected"> {selectedGenreName}</span></>}
                  />
                </>
                  :
                  <ComponentLoader />
                }
              </Grid>
            </Grid>

            {opened ? <>
              <Grid container className="pb-40" justifyContent={"center"}>
                <Grid container justifyContent={"center"}>
                  <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"2945931490"} height={100} adv_type={"web_trending_fold_ad"} />
                </Grid>

                <Grid item xs={12} className="pt-50 pb-50 app-section">
                  <p className="app-container fw-700 fs-24 mt-0">Login to Unlock</p>
                  <Grid item className='app-container'>
                    <Swiper
                      effect={"coverflow"}
                      grabCursor={true}
                      slidesPerView={"auto"}
                      spaceBetween={30}
                      speed={1000}
                      navigation={true}
                      modules={[Pagination, FreeMode, Autoplay, Navigation]}
                      className="mySwiper paginationSwiper"
                      pagination={true}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: true,
                      }}
                      loop={true}
                      freeMode={true}
                    >
                      <SwiperSlide style={{ maxWidth: '465px' }}>
                        <Grid>
                          <CrouselNudge />
                        </Grid>
                      </SwiperSlide>
                      {
                        NUDGE_DATA.map((x, i) => {
                          return <SwiperSlide style={{ maxWidth: '465px' }} key={i}>
                            <Grid key={i}>
                              <CrouselReviewNudge data={x} />
                            </Grid>
                          </SwiperSlide>
                        })
                      }
                    </Swiper>
                  </Grid>
                </Grid>

                <Grid container justifyContent={"center"}>
                  <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"4133275680"} width={300} height={250} adv_type={"web_trending_fold_ad"} />
                </Grid>

                <Grid item xs={12} className='app-container app-section'>
                  <LoadableCommonDetailTileListing className='app-container'
                    heading={
                      <>
                        <span className="text-selected">coming soon</span> on ott
                      </>
                    }
                    subheading="get notified on release"
                    isShowHeading={true}
                    nextLink="/upcoming-movies-and-series-on-ott"
                    data={trending.comingSoonData}
                    showNotifyButton={true}
                    hideAds={true}
                    refPage="Trending"
                    withAd={true}
                  />
                </Grid>
              </Grid>

              <Grid container className="app-container app-section pt-20" justifyContent={"center"}>
                <Grid item xs={12}>
                  <LoadableJoinWatchlist hideAds={true} withAd={true} />
                </Grid>
              </Grid>

              <Grid container justifyContent={"center"}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"8128361012"} width={250} height={250} adv_type={"web_trending_bottom_ad"} />
              </Grid>

              <Grid
                container
                className="app-container app-section pt-0 pb-40"
                justifyContent={"center"}
              >
                <Grid item xs={12}>
                  {trending.popularTodayData &&
                    trending.popularTodayData.length > 0 && (
                      <LoadableFrenziPopular
                        data={trending.popularTodayData}
                        isShowHeading={true}
                        withAd={true}
                        heading={<><span className="text-selected">popular </span><span> on frenzi</span> </>}
                      />
                    )}

                  <Grid container justifyContent={"center"}>
                    <MyAds type="banner_2" mobtype={"mobile_1"} adv_type={"web_trending_bottom_ad"} />
                  </Grid>
                </Grid>
              </Grid>
            </>
              :
              ''
            }
          </>
        </Grid>
        {!isMobileDevice() ?
          <Grid className="right-container">
            <Grid className="right-container-ad">
              <MyAds type="banner_1" adv_type={'web_trending_top_ad'} />
            </Grid>
          </Grid>
          : ''}
      </Grid>
    </>
  );
}

export default PublicTrending;
