import { Button, Grid, Box, Modal, FormControl, Select, MenuItem, Slider, TextareaAutosize, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import FeedbackLogo from '../../../assets/icons/feedbackLogo.svg'
import helpers from '../../../services/helpers';
import { deleteUserAccount, deleteUserAccountOtp } from '../../../slices/settings';
import OTPInput from "otp-input-react";

const FeedbackLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/feedbackLogo.svg";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#16213E',
    boxShadow: 24,
    borderRadius: 2,
    p: 5,
    width: { xs: '100%', sm: '500px' }
};

const leavingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#16213E',
    boxShadow: 24,
    borderRadius: 2,
    p: 5,
    width: { xs: '100%', sm: '500px' }

};
const leavingList = [
    {
        id: 1,
        text: "i have a duplicate account"
    },
    {
        id: 2,
        text: "I am getting too many notifications"
    },
    {
        id: 3,
        text: "I am not getting any value from the membership"
    },
    {
        id: 4,
        text: "I have a privacy concern"
    },
    {
        id: 5,
        text: "I have issue with the app performance"
    },
    {
        id: 6,
        text: "Other"
    }
]

const otpLength = 6;


export default function DeleteAccount() {

    const [deletePopup, setDeletePopup] = useState(false)
    const [verifyOtpPopup, setVerifyOtpPopup] = useState(false)
    const [leavingPopup, setLeavingPopup] = useState(false)
    const [isOtpFilled, setIsOtpFilled] = useState(false);
    const [OTP, setOTP] = useState("");
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { deleteAccountOtpData } = settings;

    const [reason, setReason] = useState('');
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        setIsOtpFilled(OTP.length == otpLength ? true : false);
    }, [OTP])

    useEffect(() => {
        if (settings.successMesg && settings.successMesg != "") {
            if (settings.successMesg == 'otp sent') {
                setVerifyOtpPopup(true);
                setLeavingPopup(false);
            }
        }
    }, [settings])

    const handleSubmit = (skip = true) => {
        const body = {
            reason: reason,
            feedback: feedback
        }
        if (reason != "" && feedback != "") {
            dispatch(deleteUserAccount({ body: body }));
        } else {
            helpers.toastError("reason and feedback cannot be empty!!!")
        }
    }

    const handleVerifyOtp = () => {
        if (deleteAccountOtpData && deleteAccountOtpData.otp_id && deleteAccountOtpData.otp_id != "") {
            const tempForm = {
                otp_id: deleteAccountOtpData?.otp_id,
                otp: OTP
            }
            console.log("tempForm", tempForm)
            dispatch(deleteUserAccountOtp({ body: tempForm }));
        } else {
            helpers.toastError('something went wrong !!!');
            setVerifyOtpPopup(false);
        }
    }

    return (
        <>
            <Grid container sx={{justifyContent:{xs:'center' , sm:'flex-end'}}}>
                <Button onClick={() => setDeletePopup(true)} className='rounded-btn  bg-light text-selected lh-21 mw-320'>delete your account</Button>
            </Grid>
            <Modal
                open={deletePopup}
                onClose={() => setDeletePopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='fw-700 fs-32 m-0 pb-20'>delete frenzi account ?</p>
                    <p className='fw-400 fs-24 m-0 text-dark-grey'>You will lose all your points and subscriptions if you delete your account.</p>
                    <span className='text-selected fw-400 fs-24'> are you sure ?</span>
                    <Grid container flexDirection={'column'} alignItems={'center'} >
                        <Button onClick={() => { setLeavingPopup(true); setDeletePopup(false); }} className='rounded-btn w-100 mw-240 bg-selected text-light mt-40'>delete</Button>
                        <Button onClick={() => setDeletePopup(false)} className='rounded-btn w-100 mw-240 bg-light text-selected mt-10'>cancel</Button>
                    </Grid>
                </Box>
            </Modal>

            {/* LEAVING POPUP */}
            <Modal
                open={leavingPopup}
                onClose={() => setLeavingPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={leavingStyle}>
                    <Grid item>
                        <p className='fw-700 fs-28 m-0'>let us know why you’re leaving</p>
                    </Grid>
                    <Grid container className='bg-grey br-10 pl-10 pt-10 mh-105 mt-30' justifyContent={'space-between'}>
                        <Grid item xs={8}>
                            <p className='fw-700 fs-16 m-0'>Help us to improve your experience</p>
                            <p className='fw-500 fs-12 m-0 pt-24 text-dark-grey'>your feedback matter to us</p>
                        </Grid>
                        <Grid item  >
                            <Grid container>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 100,
                                        width: 100,
                                        maxHeight: { xs: 65, sm: 100 },
                                        maxWidth: { xs: 65, sm: 100 },

                                        // borderRadius: '50%'
                                    }}
                                    alt="feedback"
                                    src={FeedbackLogo}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className='mt-30'>
                        <p className='fw-600 fs-20 m-0 '>please select a Reason</p>
                    </Grid>
                    <Grid item className='mt-15'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                >
                                    {leavingList.map((item, index) => {
                                        return <MenuItem value={item?.text} className='w-100' key={index}>
                                            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                                                <p className='fs-13 fw-500 m-0 '>{item?.text}</p>
                                                {/* <Box sx={{ width: 250 }} className='mt-10 rating-slider-container'>
                                                    <Slider
                                                        aria-label="Temperature"
                                                        defaultValue={ratingValue}
                                                        valueLabelDisplay="auto"
                                                        step={0.1}
                                                        marks
                                                        min={0}
                                                        max={5}
                                                        onChange={(e) => setRatingValue(e.target.value)}
                                                    />
                                                </Box> */}
                                            </Grid>
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item className="mt-10">
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={4}
                            maxRows={10}
                            onChange={(e) => setFeedback(e.target.value)}
                            style={{ width: '100%', maxWidth: 'calc(100% - 20px)', maxHeight: '100%', height: '100%', borderRadius: '8px', paddingBottom: '20px', padding: 10, background: 'transparent', color: 'white' }}
                        />
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        {/* <Button onClick={() => handleSubmit(true)} className='rounded-btn w-100 mw-240 bg-light text-selected mt-30 '>skip and proceed</Button> */}
                        <Button onClick={() => handleSubmit(false)} className='rounded-btn w-100 mw-240 bg-selected text-light mt-30 '>submit and proceed</Button>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={verifyOtpPopup}
                onClose={() => setVerifyOtpPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="text-center">
                    <Typography variant="h3" className='fs-32'><b>verify</b> mobile no</Typography>
                    <Typography variant="subtitle1" className='mt-6 ls-1 mw-224 text-muted fs-14 lh-16'>We’ll call or text you with an OTP to confirm your number. Standard message and daa rates apply. </Typography>

                    <Box className='mt-24'>
                        <OTPInput className="otp-container"
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={otpLength}
                            otpType="number"
                            disabled={false} secure />
                        {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                    </Box>
                    <Button
                        disabled={!isOtpFilled}
                        onClick={() => handleVerifyOtp()}
                        className={`rounded-btn ${isOtpFilled ? 'bg-selected text-light' : 'bg-light text-selected'} mt-44 fs-14`}
                    >verify otp</Button>
                </Box>
            </Modal>
        </>

    )
}
