import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Divider, Grid, IconButton, InputBase, Paper, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import helpers from '../../services/helpers'
import { useDispatch, useSelector } from 'react-redux';
import { frenziSearch, getFriendsList } from '../../slices/common';
import { getFrenziUsers } from '../../slices/connect';

export default function CustomSearch(props) {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const common = useSelector(state => state.common);
    const connect = useSelector(state => state.connect);

    const handleSearchTextChange = (e) => {
        setValue(e.target.value)
    }

    useEffect(() => {
        if (value && value != "") {
            if (props?.type == "members") {
                dispatch(getFrenziUsers({ query: value, page: 1, pagelimit: 50 }));
            } else if (props?.type == "friends") {
                dispatch(getFriendsList({ query: value, page: 1, pagelimit: 50 }));
            } else {
                dispatch(frenziSearch({ val: value }));
            }
        }
    }, [value])

    const handleCLick = (e, item) => {
        if (props.isClearOnClick) {
            setValue('');
        }
        props.clickedItem(e, item);
    }

    return (
        <div className='relative pb-10'>
            <Paper
                sx={{ opacity: 1 , bgcolor:'white' }}
                className='w-100 d-flex br-25 relative mb-5 mt-20'
            >
                <IconButton aria-label="search" className='bg-selected' sx={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}>
                    <SearchIcon className='ml-3 pt-2' color="light" />
                </IconButton>
                <InputBase
                    className='f-1 fs-14 pl-10 fw-500 bg-light'
                    value={value}
                    type='text'
                    onChange={handleSearchTextChange}
                    placeholder={props?.placeholder ? props?.placeholder : 'Search'}
                />

                <IconButton aria-label="search" onClick={() => setValue('')} className='h-100 bg-muted' sx={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                    <CloseIcon className='ml-3 pt-2' color="light" />
                </IconButton>
            </Paper>
            {props.type == "members" ?
                <>
                    {value != "" && connect.frenziUserPageData && connect.frenziUserPageData.results && connect.frenziUserPageData.results.length > 0 &&
                        <Grid container className='search-result-show'>
                            <Grid item className='mh-300 bg-light w-100 br-10'
                                sx={{ overflowY: 'auto' }} lg={12}>
                                {connect.isLoading ?
                                    <div className='text-center pt-10 bb-20'><CircularProgress size={20} color='success' /></div>
                                    :
                                    <>
                                        {connect.frenziUserPageData.results.map((item, i) => {
                                            return <div onClick={(e) => handleCLick(e, item)} key={i}>
                                                <Grid container key={i} className='pl-20 pt-10 pointer ai-center' alignItems={'center'}>
                                                    <Grid item className='h-50px mw-65 w-100 pr-20'>
                                                        <Box
                                                            component="img"
                                                            src={item.profile_image && item.profile_image != "" ? helpers.getFullUrl(item.profile_image) : helpers.getDefaultImage(item.type ? item.type : 'search')}
                                                            alt="profilepic"
                                                            sx={{
                                                                height: '65px',
                                                                width: '50px',
                                                                borderRadius: '7px'
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography className='text-dark fw-500'>{helpers.getSelectedText(item.username, value)}</Typography>
                                                        <p className='fw-300 fs-14 m-0 p-0 text-muted'>{item?.unique_name}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className='pl-20 pr-20'>
                                                    <Box className='w-100'>
                                                        <Divider />
                                                    </Box>
                                                </Grid>
                                            </div>
                                        })}
                                    </>
                                }
                            </Grid>
                        </Grid>
                    }
                </>

                : props.type == "friends" ?
                    <>
                        {value != "" && common.friendsListPageData && common.friendsListPageData.results && common.friendsListPageData.results.length > 0 &&
                            <Grid container className='search-result-show'>
                                <Grid item className='mh-300 bg-light w-100 br-10'
                                    sx={{ overflowY: 'auto' }} lg={12}>
                                    {common.isLoading ?
                                        <div className='text-center pt-10 bb-20'><CircularProgress size={20} color='success' /></div>
                                        :
                                        <>
                                            {common.friendsListPageData.results.map((item, i) => {
                                                return <div onClick={(e) => handleCLick(e, item)} key={i}>
                                                    <Grid container key={i} className='pl-20 pt-10 pointer ai-center' alignItems={'center'}>
                                                        <Grid item className='h-50px mw-65 w-100 pr-20'>
                                                            <Box
                                                                component="img"
                                                                src={item.profile_image && item.profile_image != "" ? helpers.getFullUrl(item.profile_image) : helpers.getDefaultImage(item.type ? item.type : 'search')}
                                                                alt="profilepic"
                                                                sx={{
                                                                    height: '65px',
                                                                    width: '50px',
                                                                    borderRadius: '7px'
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography className='text-dark fw-500'>{helpers.getSelectedText(item.username, value)}</Typography>
                                                            <p className='fw-300 fs-14 m-0 p-0 text-muted'>{item?.unique_name}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className='pl-20 pr-20'>
                                                        <Box className='w-100'>
                                                            <Divider />
                                                        </Box>
                                                    </Grid>
                                                </div>
                                            })}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </>
                    :
                    <>
                        {value != "" && common.mediaSearchResults && common.mediaSearchResults.results && common.mediaSearchResults.results.length > 0 &&
                            <Grid container className='search-result-show'>
                                <Grid item className='mh-300 bg-light w-100 br-10'
                                    sx={{ overflowY: 'auto' }} lg={12}>
                                    {common.isLoading ?
                                        <div className='text-center pt-10 bb-20'><CircularProgress size={20} color='success' /></div>
                                        :
                                        <>
                                            {common.mediaSearchResults.results.map((item, i) => {
                                                return <div onClick={(e) => handleCLick(e, item)} key={i}>
                                                    <Grid container key={i} className='pl-20 pt-10 pointer ai-center' alignItems={'center'}>
                                                        <Grid item className='h-50px mw-65 w-100 pr-20'>
                                                            <Box
                                                                component="img"
                                                                src={item.poster && item.poster != "" ? helpers.getFullUrl(item.poster) : helpers.getDefaultImage(item.type ? item.type : 'search')}
                                                                alt="poster"
                                                                sx={{
                                                                    height: '65px',
                                                                    width: '50px',
                                                                    borderRadius: '7px'
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography className='text-dark fw-500'>{helpers.getSelectedText(item.title, value)}</Typography>
                                                            <p className='fw-300 fs-14 m-0 p-0 text-muted'>{item?.type}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className='pl-20 pr-20'>
                                                        <Box className='w-100'>
                                                            <Divider />
                                                        </Box>
                                                    </Grid>
                                                </div>
                                            })}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </>
            }
        </div>
    )
}
