import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import OTPInput from "otp-input-react";

import './auth.scss'
import { useDispatch, useSelector } from 'react-redux';
import { collectLoginInfo, loginWithOtp, resendOtp, setAuthenticatedData } from '../../slices/auth';
import { KMSClient, EncryptCommand, DecryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from 'buffer'
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import Loader from '../common/Loader';

import axios from 'axios';

import ReactGA from 'react-ga4';

const otpLength = 6;

const clientParams = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const config = {
    credentials: clientParams,
    endPoint: "",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    signatureVersion: 2,
    region: process.env.REACT_APP_AWS_REGION
}

const client = new KMSClient(config);

export default function VerifyOtp(props) {
    const [OTP, setOTP] = useState("");
    const [isOtpFilled, setIsOtpFilled] = useState(false);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [currentLoading, setCurrentLoading] = useState(false);

    const [timer, setTimer] = useState(30);

    useEffect(() => {
        let inputs = document.querySelectorAll('#otp-box input[type=password]');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].setAttribute('inputmode', 'numeric');
            inputs[i].setAttribute('pattern', '[0-9]*');
        }
    }, [])

    useEffect(() => {
        if (timer > 0) {
            var tempInterval = setInterval(() => {
                setTimer((prevState) => prevState - 1);
            }, 1000)
        } else {
            if (tempInterval) {
                setTimer(0);
                clearInterval(tempInterval);
            }
        }
        return () => clearInterval(tempInterval);
    }, [timer])

    useEffect(() => {
        setIsOtpFilled(OTP.length === otpLength ? true : false);
    }, [OTP])

    useEffect(() => {
        if (auth.verifiedData && auth.verifiedData.data) {
            convertAgainVerifiedData(auth.verifiedData.data)
        }
    }, [auth])

    const getIpData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        return res?.data?.IPv4;
    }

    const handleMoengageSignUpEvent = async (data) => {
        const { username, uuid, email, mobile, profile_image } = data;

        setTimeout(() => {
            let referer = window.localStorage.getItem('referer');
            if (referer === 'interactions') {
                window.location.href = "/rrvcfam";
            } else {
                if (data.langs_pref_set && data.platforms_pref_set) {
                    window.location.href = "/";
                    if (referer && referer !== '') {
                        window.location.href = referer;
                    } else {
                        window.location.href = "/";
                    }
                } else {
                    window.location.href = "/onboarding";
                }
            }
        }, 1000)

        ReactGA.set({
            userId: uuid,
            first_name: username,
            email: email,
            mobile: mobile,
            signup_source: 'normal',
            signup_date: new Date(),
            is_verified_email: data.email_verified ? true : false,
            is_verified_mobile: data.mobile_verified ? true : false,
        });

        await window.Moengage.add_unique_user_id(uuid);
        await window.Moengage.add_first_name(username);
        await window.Moengage.add_email(email || '');
        await window.Moengage.add_mobile(mobile || '');
        await window.Moengage.add_user_attribute("signup_source", 'normal');
        await window.Moengage.add_user_attribute("signup_date", new Date());
        await window.Moengage.add_user_attribute("data_source", 'web');
        await window.Moengage.add_user_attribute("is_verified_email", data.email_verified ? true : false);
        await window.Moengage.add_user_attribute("is_verified_mobile", data.mobile_verified ? true : false);

        if (profile_image && profile_image !== '') {
            await window.Moengage.add_user_attribute("profile_image", profile_image);
            ReactGA.set({ profile_image: profile_image });
        } else {
            await window.Moengage.add_user_attribute("profile_image", 'default');
            ReactGA.set({ profile_image: 'default' });
        }
        // await window.Moengage.addUserAttribute("isRefered", MOENGAGE_ATTRIBUTE.FALSE);
        const propData = {
            client_id: uuid,
            signup_date: new Date(),
            is_referral: MOENGAGE_ATTRIBUTE.FALSE,
            signup_source: 'normal',
            create_click: MOENGAGE_ATTRIBUTE.YES,
            email: email,
            mobile: mobile,
            name: username
        }
        // if (referedBy != null){
        //     propData.refered_by = referedBy;
        // }
        await helpers.trackMoengageEvent('FR3_Signup', propData);
    }

    const handleMoengageLoginEvent = async (data) => {
        const { username, uuid, email, mobile, profile_image } = data;

        setTimeout(() => {
            let referer = window.localStorage.getItem('referer');
            if (referer === 'interactions') {
                window.location.href = "/rrvcfam";
            } else {
                if (data.langs_pref_set && data.platforms_pref_set) {
                    window.location.href = "/";
                    if (referer && referer !== '') {
                        window.location.href = referer;

                    } else {
                        window.location.href = "/";
                    }
                } else {
                    window.location.href = "/onboarding";
                }
            }
        }, 1000)

        ReactGA.set({
            userId: uuid,
            first_name: username,
            email: email,
            mobile: mobile,
            login_type: 'normal',
            last_login_date: new Date(),
            is_verified_mobile: data.mobile_verified ? true : false,
        });

        await window.Moengage.add_unique_user_id(uuid);
        await window.Moengage.add_first_name(username);
        await window.Moengage.add_email(email || '');
        await window.Moengage.add_mobile(mobile || '');
        await window.Moengage.add_user_attribute("login_type", 'normal');
        await window.Moengage.add_user_attribute("data_source", 'web');
        await window.Moengage.add_user_attribute("last_login_date", new Date());
        await window.Moengage.add_user_attribute("is_verified_mobile", data.mobile_verified ? true : false);


        if (profile_image && profile_image !== '') {
            await window.Moengage.add_user_attribute("profile_image", profile_image);
            ReactGA.set({ profile_image: profile_image });
        } else {
            await window.Moengage.add_user_attribute("profile_image", 'default');
            ReactGA.set({ profile_image: 'default' });
        }

        const propData = {
            client_id: uuid,
            login_date: new Date(),
            type: 'normal',
            verified: MOENGAGE_ATTRIBUTE.YES,
            email: email,
            mobile: mobile,
            name: username
        }

        await helpers.trackMoengageEvent('FR3_Login', propData);
    }

    const convertAgainVerifiedData = async (responseText) => {
        var cipherText = _base64ToArrayBuffer(responseText);
        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            CiphertextBlob: cipherText
        }
        try {
            const command = new DecryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.Plaintext) {
                let win1251decoder = new TextDecoder('windows-1251');
                let bytes = new Uint8Array(response.Plaintext);
                var decryptedText = win1251decoder.decode(bytes);
                var jsonData = JSON.parse(decryptedText);
                if (jsonData && !helpers.isEmptyObject(jsonData)) {
                    dispatch(setAuthenticatedData(jsonData));
                    localStorage.setItem('frenzi_user_access', JSON.stringify(jsonData));
                    const payload = {
                        "device": window?.navigator?.platform,
                        "device_type": 'web',
                        "ip_address": await getIpData()
                    }
                    dispatch(collectLoginInfo({ body: payload }));
                    let userData = jsonData;
                    if (props.event === 'signup') {
                        handleMoengageSignUpEvent(userData);
                    } else {
                        handleMoengageLoginEvent(userData);
                    }
                }
            } else {
                helpers.toastError('Data decryption failed !!!');
            }
        } catch (error) {
            // error handling.\
            helpers.toastError(error);
        }
    }

    const getGuid = async () => {
        var nav = window.navigator;
        var screen = window.screen;
        var guid = nav.mimeTypes.length;
        guid += nav.userAgent.replace(/\D+/g, '');
        guid += nav.plugins.length;
        guid += screen.height || '';
        guid += screen.width || '';
        guid += screen.pixelDepth || '';
        return guid;
    };

    const handleVerifyOtp = async () => {
        setCurrentLoading(true);
        let deviceId = await getGuid();
        if (deviceId && deviceId !== '') {
            if (props?.loginType === 'mobile') {
                const tempForm = {
                    otp_id: props.otpId,
                    otp: OTP,
                    device_type: "web",
                    device_token: "some_device_token",
                    device_id: deviceId,
                    mobile: props.mobile
                }
                performFormSubmitProcess(tempForm);
            } else {
                const tempForm = {
                    otp_id: props.otpId,
                    otp: OTP,
                    device_type: "web",
                    device_token: "some_device_token",
                    device_id: deviceId,
                    email: props.mobile
                }
                performFormSubmitProcess(tempForm);
            }
        } else {
            helpers.toastError("Browser not supported");
            setCurrentLoading(false);
        }
    }

    const performFormSubmitProcess = async (tempForm, resend = false) => {
        const encoder = new TextEncoder();
        var unit8String = encoder.encode(JSON.stringify(tempForm));
        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            Plaintext: unit8String
        }
        try {
            const command = new EncryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.CiphertextBlob) {
                let buff = new Buffer(response.CiphertextBlob);
                let base64data = buff.toString('base64');
                if (resend) {
                    dispatch(resendOtp({ data: base64data })).then(() => {
                        setCurrentLoading(false);
                    });
                } else {
                    dispatch(loginWithOtp({ data: base64data })).then(() => {
                        setCurrentLoading(false);
                    });
                }
            } else {
                helpers.toastError('Data encryptiton failed !!!');
                setCurrentLoading(false);
            }
        } catch (error) {
            // error handling.\userLogin
            helpers.toastError(error);
            setCurrentLoading(false);
        }
    }

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        // return bytes.buffer;
        return bytes;
    }

    // const convertAgain = async (responseText) => {
    //     var cipherText = _base64ToArrayBuffer(responseText);
    //     const input = {
    //         KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
    //         CiphertextBlob: cipherText
    //     }
    //     try {
    //         const command = new DecryptCommand(input);
    //         const response = await client.send(command);
    //         // process data.
    //         if (response && response.Plaintext) {
    //             let win1251decoder = new TextDecoder('windows-1251');
    //             let bytes = new Uint8Array(response.Plaintext);
    //             var decryptedText = win1251decoder.decode(bytes);
    //             var jsonData = JSON.parse(decryptedText);
    //             if (jsonData && !helpers.isEmptyObject(jsonData)) {
    //                 // setLoader(false);
    //                 setTimeout(() => {
    //                     localStorage.setItem("frenzi_user_access", JSON.stringify(jsonData));
    //                     // dispatch(setRegistrationToken(jsonData.access));
    //                     let redirectUri = localStorage.getItem("redirect_url");
    //                     if (redirectUri && redirectUri !== '') {
    //                         window.location.href = redirectUri;
    //                     } else {
    //                         window.location.href = "/";
    //                     }
    //                 }, 2000)
    //             }
    //         } else {
    //             helpers.toastError('Data decryption failed !!!');
    //         }
    //     } catch (error) {
    //         // error handling.\
    //         helpers.toastError(error);
    //     }}


    const handleResendOtp = () => {
        const payload = {
            otp_id: props.otpId
        }
        setOTP('');
        setTimer(30);
        performFormSubmitProcess(payload, true);
    }

    return (
        <>
            {(!props.otpId || props.otpId === "") && <Loader />}
            <Paper elevation={10} className="bg-secondary p-40">
                <Grid align='center'>
                    <Typography variant="h3" className='fs-32'><b>verify</b> {props?.loginType === 'mobile' ? 'mobile number' : 'email'}</Typography>
                    <Typography variant="subtitle1" className='mt-6 ls-1 text-muted fs-14 lh-16'>we have sent an OTP on {props?.loginType === 'mobile' ? props.mobile.substring(0, 4) + 'xxxxxx' : props.mobile}</Typography>

                    <Box className='mt-24' id='otp-box'>
                        <OTPInput className="otp-container" inputmode={'numeric'} value={OTP} onChange={setOTP} autoFocus OTPLength={otpLength} otpType="number" disabled={false} secure />
                        {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                    </Box>
                    <Grid container justifyContent={'flex-end'} alignItems='center' style={{ marginTop: '10px' }}>
                        {timer && timer > 0 ?
                            <Grid className='pl-20 mt-12' item>
                                <p className='fw-500 text-muted fs-14 m-0'>Resend OTP in <span className='text-selected'>{timer}s</span></p>
                            </Grid>
                            :
                            <Grid onClick={() => handleResendOtp()} className='pl-20 mt-12' item>
                                <p className='fw-500 pointer fs-14 m-0'>Resend OTP</p>
                            </Grid>
                        }
                    </Grid>
                    <Button
                        disabled={!isOtpFilled || currentLoading}
                        onClick={() => handleVerifyOtp()}
                        className={`rounded-btn ${isOtpFilled ? 'bg-selected text-light' : 'bg-light text-selected'} mt-25 fs-14 mw-350`}
                    >
                        {currentLoading ? <CircularProgress style={{ color: '#000' }} /> : 'verify otp'}
                    </Button>
                </Grid>
            </Paper>
        </>
    )
}
