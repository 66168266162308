import { Box, Grid, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import Platform from '../../onboarding/Platform';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformList } from '../../../slices/auth';
import { toggleLoginModal } from '../../../slices/global';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '90%', sm: '75%', md: '50%' },
    bgcolor: 'transparent',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
};

export default function Selection(props) {
    const { platforms, is_free, p_name, selectedPlatform, selectedPlatformUuid } = props;
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const _global = useSelector(state => state.global);

    useEffect(() => {
        dispatch(getPlatformList({ is_free: is_free, page: 1, pagelimit: 50, is_public: _global.is_public }));
    }, [])

    useEffect(() => {
        if (p_name && p_name != '') {
            if (props?.is_free && !_global.is_public) {
                if (auth.platformList && auth.platformList.results && auth.platformList.results.length > 0) {
                    auth.platformList.results.map((p, k) => {
                        if (p_name === p.ott_name) {
                            props?.setSelectedPlatform(p?.code);
                            props?.setSelectedPlatformOttName(p?.ott_name.replace('Amazon ',''));
                            return true;
                        }
                    })
                }
            } else {
                if (platforms && platforms.length > 0) {
                    platforms.map((p, k) => {
                        if (p_name === p.ott_name) {
                            props?.setSelectedPlatform(p?.code);
                            props?.setSelectedPlatformOttName(p?.ott_name.replace('Amazon ',''));
                            return true;
                        }
                    })
                }
            }
        } else {
            if (!selectedPlatform || selectedPlatform == '') {
                if (props?.is_free && !_global.is_public) {
                    if (auth.platformList && auth.platformList.results && auth.platformList.results.length > 0) {
                        props?.setSelectedPlatform(auth.platformList.results[0].code ? auth.platformList.results[0].code : '')
                        props?.setSelectedPlatformOttName(auth.platformList.results[0]?.ott_name.replace('Amazon ',''));
                        props?.setSelectedPlatformUuid(auth.platformList.results[0]?.uuid);
                    }
                } else {
                    if (platforms && platforms.length > 0) {
                        props?.setSelectedPlatform(platforms[0].code ? platforms[0].code : '')
                        props?.setSelectedPlatformOttName(platforms[0]?.ott_name.replace('Amazon ',''));
                        props?.setSelectedPlatformUuid(platforms[0]?.uuid);
                    }
                }
            } else {
                if (auth.platformList && auth.platformList.results && auth.platformList.results.length > 0) {
                    auth.platformList.results.map((p, k) => {
                        if (selectedPlatformUuid === p.uuid) {
                            props?.setSelectedPlatform(p?.code);
                            props?.setSelectedPlatformOttName(p?.ott_name.replace('Amazon ',''));
                            props?.setSelectedPlatformUuid(p?.uuid);
                            return true;
                        }
                    })
                }
            }
        }
    }, [platforms, auth.platformList])

    const handleAddPlatfrom = () => {
        helpers.trackMoengageEvent('FR3_Frenzi', { add_ott_platform_click: MOENGAGE_ATTRIBUTE.YES });
        if (_global.is_public) {
            dispatch(toggleLoginModal({ isOpen: true }));
        } else {
            setOpenModal(true);
        }
    }

    return (
        <div>
            {props?.is_free && !_global.is_public ?
                <Grid container alignItems={'center'} flexWrap={'nowrap'} className='mb-0 overflow-no-scroll mt-20'>
                    {auth.platformList && auth.platformList.results && selectedPlatform != "" && auth.platformList.results.length > 0 && auth.platformList.results.map((x, i) => {
                        if (_global.is_public && i > 2) {
                            return true;
                        }
                        return <Grid item className={`${(selectedPlatform === x.code || selectedPlatformUuid === x.uuid) ? 'platform-outer-ring' : ''} mr-15 pointer mb-10`} onClick={() => {
                            props?.setSelectedPlatform(x.code);
                            props?.setSelectedPlatformOttName(x.ott_name.replace('Amazon ',''))
                            props?.setSelectedPlatformUuid(x.uuid)
                        }} key={i} >
                            <Box
                                component="img"
                                className='d-block'
                                sx={{
                                    height: { xs: 40, sm: 64 },
                                    width: { xs: 40, sm: 64 },
                                    borderRadius: '50%'
                                }}
                                alt=""
                                src={x.image ? helpers.getFullUrl(x.image) : helpers.getDefaultImage("platform")}
                            />
                        </Grid>
                    })}
                </Grid>
                :
                <Grid container alignItems={'center'} flexWrap={'nowrap'} className='mb-0 overflow-no-scroll mt-20'>
                    {!_global.is_public &&
                        <Grid item onClick={() => handleAddPlatfrom()} sx={{ maxHeight: { xs: 40, sm: 64 }, maxWidth: { xs: 40, sm: 64 }, minWidth: { xs: 40, sm: 64 }, position: 'sticky', left: 0 }} className='mb-10 add-platform-container pointer'>
                            <AddIcon />
                        </Grid>
                    }
                    {platforms && selectedPlatform != "" && platforms.length > 0 && platforms.map((x, i) => {
                        if (_global.is_public && i > 2) {
                            return true;
                        }
                        return <Grid item className={`${(selectedPlatform === x.code || selectedPlatformUuid === x.uuid) ? 'platform-outer-ring' : ''} mr-15 ml-15 pointer mb-10`} onClick={() => {
                            props?.setSelectedPlatform(x.code);
                            props?.setSelectedPlatformOttName(x?.ott_name.replace('Amazon ',''));
                            props?.setSelectedPlatformUuid(x.uuid);
                        }} key={i} >
                            <Box
                                component="img"
                                className='d-block'
                                sx={{
                                    height: { xs: 40, sm: 64 },
                                    width: { xs: 40, sm: 64 },
                                    borderRadius: '50%'
                                }}
                                alt=""
                                src={x.image ? helpers.getFullUrl(x.image) : helpers.getDefaultImage("platform")}
                            />
                        </Grid>
                    })}
                    {_global.is_public &&
                        <Grid item onClick={() => handleAddPlatfrom()} sx={{ maxHeight: { xs: 40, sm: 64 }, maxWidth: { xs: 40, sm: 64 }, minWidth: { xs: 40, sm: 64 }, position: 'sticky', left: 0 }} className='mb-10 add-platform-container pointer mr-20'>
                            <AddIcon />
                        </Grid>
                    }
                </Grid>
            }
            <div>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Platform setActiveSteps={() => setOpenModal(false)} activeSteps={2} />
                    </Box>
                </Modal>
            </div>
        </div>
    )
}
