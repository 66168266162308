import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ClipsDataService from "../services/clips.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    clipsPageData: {},
    clipsData: [],
    commentListPageData: {},
    commentListData: [],
    replyListPageData: {},
    replyListData: [],
    reportReasons: []
};

export const getClipsData = createAsyncThunk(
    "native_main/discoverpostlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.getClipsData(data?.id, data?.section, data?.include_viewed_clips, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getClipsCustomData = createAsyncThunk(
    "native_main/discoverpostlistcustom",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.getClipsData(data?.id, data?.section, data?.include_viewed_clips, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const clipPostActivity = createAsyncThunk(
    "native_main/discoverpostactivity",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.clipPostActivity(data?.type, data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getCommentLists = createAsyncThunk(
    "native_main/discovercomment",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.getCommentLists(data?.id, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getRepliesList = createAsyncThunk(
    "native_main/discoverreply",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.getRepliesList(data?.id, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getReportReasons = createAsyncThunk(
    "native_main/reportreason",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.getReportReasons()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getDiscoverTags = createAsyncThunk(
    "native_main/discoverposttag",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.getDiscoverTags(data?.query)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addClip = createAsyncThunk(
    "native_main/addclips",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.addClip(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const deleteCLipPost = createAsyncThunk(
    "native_main/discoverpostdetaildelete",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ClipsDataService.deleteCLipPost(data?.id)
            return response?.data || '';
        } catch (err) {
            return rejectWithValue(err?.data ? err?.data : {})
        }
    }
);

export const clipsSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        // getClipsData
        [getClipsData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getClipsData.fulfilled]: (state, action) => {
            state.clipsPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.clipsData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.clipsData = helpers.removeDuplicate(state.clipsData.concat(getArrayData(state, action)));
            }
        },
        [getClipsData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getCommentLists
        [getCommentLists.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCommentLists.fulfilled]: (state, action) => {
            state.commentListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.commentListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.commentListData = helpers.removeDuplicate(state.commentListData.concat(getArrayData(state, action)));
            }
        },
        [getCommentLists.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getRepliesList
        [getRepliesList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getRepliesList.fulfilled]: (state, action) => {
            state.replyListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.replyListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.replyListData = helpers.removeDuplicate(state.replyListData.concat(getArrayData(state, action)));
            }
        },
        [getRepliesList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getReportReasons
        [getReportReasons.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getReportReasons.fulfilled]: (state, action) => {
            state.reportReasons = getPayloadData(state, action);
        },
        [getReportReasons.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages } = clipsSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default clipsSlice.reducer;