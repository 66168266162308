import { Grid, Box, FormControl, Select, MenuItem, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import CoinIcon from '../../../assets/icons/coinIcon.svg'
import { getFrenziPointSummary } from '../../../slices/settings';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import helpers from '../../../services/helpers';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useNavigate } from 'react-router-dom';
import MyHelmet from '../../common/MyHelmet';

const CoinIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/coinIcon.svg";

const pageLimit = 20;

export default function PointSummary(props) {
    const { indinvidualPage } = props;
    const [dateRange, setDateRange] = React.useState('last_30_days');
    const [page, setPage] = React.useState(1);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { frenziPointSummaryPageData, userDetails, frenziPointSummaryData, isLoading } = settings;
    const navigate = useNavigate();

    const handleChange = (event) => {
        setDateRange(event.target.value);
    };



    const isInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }


    const handleScroll = () => {
        if (frenziPointSummaryPageData && frenziPointSummaryPageData.pagination && frenziPointSummaryPageData.pagination.is_next_page && !isLoading) {
            let elements = document.getElementsByClassName('title-list-item-summ');
            if (elements && elements.length > 0) {
                let element = elements[elements.length - 1];
                if (isInViewport(element)) {
                    setPage(page + 1);
                }
            }
        }
    }

    useEffect(() => {
        dispatch(getFrenziPointSummary({ date_range: dateRange, page: page, pagelimit: pageLimit }));
    }, [page])

    useEffect(() => {
        setPage(1);
        dispatch(getFrenziPointSummary({ date_range: dateRange, page: 1, pagelimit: pageLimit }));
        document.getElementsByClassName("point-summary-container") && document.getElementsByClassName("point-summary-container")[0].scrollTo(0, 0);
    }, [dateRange])

    return (
        <>
            <MyHelmet
                title={"point summary"}
                description={"Discover the value of your loyalty with points summary. Track and celebrate your progress as you accumulate points through your streaming activities"}
            />
            <Grid item className={`${indinvidualPage ? 'new-container pt-50' : ''} ml-30` }>

                <Grid container alignItems={'center'} className='pb-10'>
                        <KeyboardBackspaceRoundedIcon className='pointer mt-7 mr-20' onClick={() => navigate(-1)} />
                    <p className='fw-700 fs-20 m-0 '>points summary</p>
                </Grid>

                <Grid item className='br-25'>
                    <Grid container justifyContent={'center'} alignItems={'center'} rowSpacing={3}>
                        <Grid item paddingRight={'30px'}>
                            <Box
                                component="img"
                                sx={{
                                    height: 47,
                                    width: 47,
                                    maxHeight: { xs: 47, sm: 47 },
                                    maxWidth: { xs: 47, sm: 47 },
                                }}
                                alt="points"
                                src={CoinIcon}
                            />
                        </Grid>
                        <Grid item className='text-center' paddingLeft={'30px'}>
                            <p className='fw-700 fs-64 m-0 '>{userDetails?.frenzi_points}</p>
                            <p className='fw-400 fs-32 m-0'>points balance</p>
                        </Grid>
                        <Grid item className='text-center' sx={{paddingLeft:{sm:'40px'}}}>
                            <p className='fw-600 fs-24 m-0'>
                                {userDetails?.frenzi_points ? userDetails?.frenzi_points - (frenziPointSummaryPageData?.bought_points ? frenziPointSummaryPageData?.bought_points : 0) : userDetails?.frenzi_points}
                            </p>
                            <p className='fw-400 fs-16 m-0 text-dark-grey'>total points earned</p>
                            <p className='fw-600 fs-24 m-0'>
                                {frenziPointSummaryPageData?.bought_points ? parseInt(frenziPointSummaryPageData?.bought_points) > 0 ? parseInt(frenziPointSummaryPageData?.bought_points) : parseInt(frenziPointSummaryPageData?.bought_points) * -1 : 0}
                            </p>
                            <p className='fw-400 fs-16 m-0 text-dark-grey'>total points used</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{ height: 10, m: 0.5 }} orientation="vertical" style={{ backgroundColor: '#1a1a2e' }} className='br-20 mt-20' />

                <Grid container justifyContent={'space-between'} alignItems={'center'} className='pb-10'>
                    <Grid item>
                        <p className='fw-400 fs-20 m-0 '>transaction history</p>
                    </Grid>
                    <Grid item>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className='text-light demo-simple-select-point'>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-point"
                                    value={dateRange}
                                    onChange={handleChange}
                                    className='text-selected'
                                    size='small'
                                >
                                    <MenuItem value="this_week" className='text-selected'>last week</MenuItem>
                                    <MenuItem value="last_30_days" className='text-selected'>last month</MenuItem>
                                    <MenuItem value="all" className='text-selected'>all</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container onScroll={() => handleScroll()} className='w-100 point-summary-container' sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: indinvidualPage ? '450px' : '350px' }} >
                        {frenziPointSummaryData && frenziPointSummaryData.length > 0 && frenziPointSummaryData.map((x, i) => {
                            return <Grid item xs={12} key={i} className="mt-20 title-list-item-summ">
                                <Grid container spacing={3} className='w-100'>
                                    <Grid item xs={3}>
                                        <span className='text-muted fs-14'>{helpers.pointSummaryDate(x?.created_at)}</span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justifyContent={'flex-end'}>
                                            <p className='p-0 m-0 fs-16'>{parseInt(x?.points) > 0 ? parseInt(x?.points) : parseInt(x?.points) * -1}</p>
                                            <div>
                                                <ArrowDownwardRoundedIcon className={`${x?.points > 0 ? 'positive-point-summary' : 'negative-point-summary'}`} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={7} className="text-right">
                                        <span className='fs-16 text-light-white'>{x?.message}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                <div style={{ height: 350, width: '100%' }} className="data-grid-points" >
                    <DataGrid
                        rows={getTableRows(frenziPointSummaryData)}
                        columns={columns}
                        rowCount={frenziPointSummaryPageData?.pagination && frenziPointSummaryPageData?.pagination?.total_entries}
                        pageSize={pageLimit}
                        showColumnRightBorder={false}
                        showCellRightBorder={false}
                        disableMultipleColumnsFiltering={false}
                        loading={settings.isLoading}
                        onPageChange={(page, details) => {
                            if (frenziPointSummaryPageData?.pagination && frenziPointSummaryPageData?.pagination.is_next_page && frenziPointSummaryPageData?.pagination.page < page + 1) {
                                setPage(page + 1);
                                dispatch(getFrenziPointSummary({ date_range: dateRange, page: page + 1, pagelimit: pageLimit }));
                            }
                        }}
                    />
                </div>
            </Grid> */}

            </Grid>
        </>
    )
}
