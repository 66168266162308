import React from 'react'
// import MediaFlipRed from '../../assets/icons/MediaFlipRed.svg';
// import UserPlus from '../../assets/icons/UserPlus.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { Link } from 'react-router-dom';
import FollowUnfollowPerson from './FollowUnfollowPerson';
import { Grid } from '@mui/material';

const MediaFlipRed = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/MediaFlipRed.svg";

export default function NewUserCard(props) {
    const { data, pageData } = props;

    return (
        <>
            {data && data.length > 0 ?
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    slidesPerView={"auto"}
                    spaceBetween={30}
                    speed={1000}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    id='newUserCard'
                    onSlideChange={(e) => {
                        if (e.isEnd) {
                            if (
                                pageData && pageData?.pagination &&
                                pageData?.pagination?.is_next_page
                            ) {
                                props?.handlePageChange && props?.handlePageChange(pageData.pagination?.next_page);
                            }
                        }
                    }}
                >
                    {data.map((x, i) => {
                        return <SwiperSlide style={{ alignSelf: 'center' }} key={i}>
                            <Grid className='bg-light p-20 text-center' sx={{ borderRadius: '4px', minWidth:{lg:'200px'} }}>
                                <div>
                                    <Link
                                        onClick={() =>
                                            helpers.trackMoengageEvent("FR3_Connect", {
                                                user_pic_click: MOENGAGE_ATTRIBUTE.YES,
                                            })
                                        }
                                        to={`/profile/${x.uuid}`}
                                    >
                                        <img
                                            src={
                                                x.profile_image
                                                    ? helpers.getFullUrl(x?.profile_image)
                                                    : helpers.getDefaultImage("profile")
                                            }
                                            alt={x?.username}
                                            className="w-100 h-100"
                                            style={{
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                                objectPosition: 'top',
                                                aspectRatio: '1/1'
                                            }}
                                        />
                                    </Link>
                                </div>
                                <p title={x?.username} className='p-0 m-0 fs-18 text-dark ellipsis'>{x?.username}</p>
                                <div className='row mb-15 mt-15' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <img alt={''} src={MediaFlipRed} className="mr-10" />
                                    <p className='p-0 m-0 fs-16 text-dark'>{x?.cinescore}</p>
                                </div>
                                <div className='row' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <FollowUnfollowPerson iconBtn={true} data={x} />
                                </div>
                            </Grid>
                        </SwiperSlide>
                    })}
                </Swiper>
                :
                ''
            }
        </>
    )
}
