import { Grid, Button, Box, Modal, AccordionSummary, AccordionDetails, Accordion } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './setting.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SettingAcoountIcon from "../../assets/icons/settingAccount.svg"
// import PointSummaryIcon from "../../assets/icons/PointSummaryIcon.svg"
// import PrivacySettingsIcon from "../../assets/icons/PrivacySettingsIcon.svg"
import PointSummary from './settingComponent/PointSummary';
import PrefrenceSetting from './settingComponent/PrefrenceSetting';
import HelpSupport from './settingComponent/HelpSupprt';
import FAQ from './settingComponent/FAQ';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, getUserDetails } from '../../slices/settings';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { logOut } from '../../slices/auth';
import { Link } from 'react-router-dom';
import PrivacySettings from './settingComponent/PrivacySettings';
import InviteFriends from './settingComponent/InviteFriends';
import AccountsView from './settingComponent/AccountsView';
import DeleteAccount from './settingComponent/DeleteAccount';
import Loader from '../common/Loader';
// import SecurityIcon from '../../assets/icons/SecurityIcon.svg'
// import ChangeDetailsIcon from '../../assets/icons/ChangeDetailsIcon.svg'
import ChangeDetails from './settingComponent/ChangeDetails';
import SecuritySettings from './settingComponent/SecuritySettings';
import MyHelmet from '../common/MyHelmet';
import MyAds from '../adsense/MyAds';

const SettingAcoountIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/settingAccount.svg";
const PointSummaryIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/PointSummaryIcon.svg";
const PrivacySettingsIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/PrivacySettingsIcon.svg";
const SecurityIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/SecurityIcon.svg";
const ChangeDetailsIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ChangeDetailsIcon.svg";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#16213E',
    boxShadow: 24,
    borderRadius: 2,
    width: '100%',
    maxWidth: { xs: '90%', sm: '50%', md: '45%', lg: '35%' },
    p: { xs: 2, sm: 5 }

};

const accordian = [
    {
        id: 1,
        header: 'invite your friends',
        subHeader: 'get 500 points per invite',
        inviteView: true
    },
    {
        id: 2,
        header: 'Account settings',
        subHeader: 'prefrences, privacy, points, change details, security settings',
        isDeleteBtn: true,
        subOption: [
            {
                id: 1,
                img: SettingAcoountIcon,
                subOptionHeader: 'prefrences settings',
                prefrenceOption: <PrefrenceSetting />,
            },
            {
                id: 2,
                subOptionHeader: 'privacy settings',
                img: PrivacySettingsIcon,
                privacySettingOptn: true,
            },
            {
                id: 3,
                subOptionHeader: 'points summary',
                img: PointSummaryIcon,
                subPointSummary: <PointSummary />,
            },
            {
                id: 4,
                subOptionHeader: 'edit details',
                img: ChangeDetailsIcon,
                subOrderHistory: <ChangeDetails />

            },
            {
                id: 5,
                subOptionHeader: 'security settings',
                img: SecurityIcon,
                subOrderHistory: <SecuritySettings />
            },

        ]
    },
    {
        id: 3,
        header: 'help & support',
        subHeader: 'how may I help you?',
        subOption: [
            {
                id: 1,
                subOptionHeader: 'feedback form',
                img: SettingAcoountIcon,
                helpSupportComponent: <HelpSupport />
            },
            {
                id: 2,
                subOptionHeader: 'FAQs',
                img: SettingAcoountIcon,
                faqComponent: <FAQ />
            },

        ]
    }
]
export default function Setting() {
    const [isAccountsScreen, setIsAccountsScreen] = useState(true);
    const [accountsType, setAccountsType] = useState('');
    const [logoutPopup, setLogoutPopup] = useState(false);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { userDetails } = settings;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (settings.errMesg && settings.errMesg != "") {
            helpers.toastError(settings.errMesg);
            dispatch(clearMessages());
        }
        if (settings.successMesg && settings.successMesg != "") {
            helpers.toastSuccess(settings.successMesg);
            dispatch(getUserDetails());
            dispatch(clearMessages());
        }
    }, [settings])

    useEffect(() => {
        dispatch(getUserDetails());
    }, [])

    const getProfileUrl = (uuid) => {
        let url = "/profile";
        let currentUser = localStorage.getItem('frenzi_user_access');
        var currentUserData = JSON.parse(currentUser);
        if (currentUserData.uuid == uuid) {
            return url;
        } else {
            return url + "/" + uuid;
        }
    }

    return (
        <Grid item className=' mt-100 new-container settings-page'>
            <MyHelmet
            />
            {settings.isLoading && <Loader />}
            <p className='fw-600 fs-40 m-0'>settings</p>
            <Grid container justifyContent={'space-between'} >
                <Grid sx={{ order: { xs: 1, sm: 0 } }} item xs={12} sm={8.2}>
                    {isAccountsScreen ?
                        <Grid item className='p-35 br-32 bg-secondary mt-20 mb-20'>
                            {accordian.map((x, i) => {
                                return <Accordion className='accordian-container' disabled={x.disabled ? true : false} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => {
                                            if (x?.subHeader == 'invite your friends') {
                                                helpers.trackMoengageEvent('FR3_Activities', {
                                                    invite_friend_click: MOENGAGE_ATTRIBUTE.YES,
                                                });
                                            }
                                        }}
                                    >
                                        <Grid item className='pl-20'>
                                            <p className="fw-500 fs-20 m-0">{x.header}</p>
                                            <p className="fw-500 fs-16 m-0 text-dark-grey">{x.subHeader}</p>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className='accordian-detail-container'>
                                        {x.inviteView && x.inviteView &&
                                            <InviteFriends data={userDetails} />
                                        }
                                        {x.subOption && x.subOption.length > 0 && x.subOption.map((y, j) => {
                                            return <> <Accordion className='accordian-sub-container'>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    onClick={() => {
                                                        if (y?.subOptionHeader == 'privacy settings') {
                                                            helpers.trackMoengageEvent('FR3_PrivacySettings', {
                                                                privacy_setting_click: MOENGAGE_ATTRIBUTE.YES,
                                                            });
                                                        }
                                                        if (y?.subOptionHeader == 'points summary') {
                                                            helpers.trackMoengageEvent('FR3_Activities', {
                                                                point_summary_click: MOENGAGE_ATTRIBUTE.YES,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <Grid item>
                                                        <Grid container className='pl-20' alignItems={'center'}>
                                                            <img alt='' src={y.img} className='mt-5' />
                                                            <p className="fw-500 fs-20 m-0 pl-13">{y.subOptionHeader}</p>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>

                                                <AccordionDetails className='accordian-detail-container'>
                                                    {y?.prefrenceOption}
                                                    {y?.privacySettingOptn && y?.privacySettingOptn &&
                                                        <PrivacySettings
                                                            data={userDetails}
                                                            setIsAccountsScreen={(val) => setIsAccountsScreen(val)}
                                                            setAccountsType={(val) => setAccountsType(val)}
                                                        />
                                                    }
                                                    {y?.subPointSummary}
                                                    {y?.subOrderHistory}
                                                    {y?.helpSupportComponent}
                                                    {y?.faqComponent}
                                                </AccordionDetails>
                                            </Accordion>
                                            </>

                                        })}
                                        {x.isDeleteBtn ? <DeleteAccount /> : ''}
                                    </AccordionDetails>
                                </Accordion>
                            })}
                            <Accordion expanded={false} className='accordian-container' >
                                <Link to='/aboutus'>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid item className='pl-20'>
                                            <p className="fw-500 fs-20 m-0">about frenzi</p>
                                            <p className="fw-500 fs-16 m-0 text-dark-grey">know more about frenzi</p>
                                        </Grid>
                                    </AccordionSummary>
                                </Link>
                            </Accordion>
                        </Grid>
                        :
                        <Grid item className='p-35 br-32 bg-secondary mt-20 mb-20'>
                            <AccountsView data={userDetails} type={accountsType} setIsAccountsScreen={(val) => setIsAccountsScreen(val)} />
                        </Grid>
                    }
                </Grid>
                <Grid sx={{ order: { xs: 0, sm: 1 } }} item xs={12} sm={3.4}>
                    <Grid item className='bg-secondary text-center mt-20 p-35 br-32 mh-340'>
                        <Link onClick={() => {
                            helpers.trackMoengageEvent('FR3_AccSettings', {
                                user_name_click: MOENGAGE_ATTRIBUTE.YES,
                                username: userDetails?.username
                            });
                        }} to={getProfileUrl(userDetails?.uuid)}>
                            <Box
                                component="img"
                                className='w-100'
                                sx={{
                                    maxHeight: { xs: 140, sm: 170 },
                                    maxWidth: { xs: 140, sm: 170 },
                                    borderRadius: "50%"
                                }}
                                alt="profilepic"
                                src={userDetails.profile_image ? helpers.getFullUrl(userDetails.profile_image) : helpers.getDefaultImage("user")}
                            />
                        </Link>
                        <p className='fw-700 fs-24 m-0'>
                            {userDetails?.username}
                        </p>
                        <p className='fw-400 fs-20 mt-0 mb-10 text-dark-grey'>
                            {userDetails?.unique_name}
                        </p>
                        <Link onClick={() => {
                            helpers.trackMoengageEvent('FR3_AccSettings', {
                                user_name_click: MOENGAGE_ATTRIBUTE.YES,
                                username: userDetails?.username
                            });
                        }} to={getProfileUrl(userDetails?.uuid)}>
                            <Button className='rounded-btn bg-light text-selected mb-20 mw-150'>view profile</Button>
                        </Link>

                    </Grid>
                    <Button onClick={() => {
                        setLogoutPopup(true);
                        helpers.trackMoengageEvent('FR3_Logout', {
                            logout_click: MOENGAGE_ATTRIBUTE.YES
                        });
                    }} className='rounded-btn w-100 bg-selected text-light mt-40 mb-20'>log out</Button>
                </Grid>
            </Grid>
            <div>
                <Modal
                    open={logoutPopup}
                    onClose={() => setLogoutPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <p className='fw-700 fs-26 m-0'>Do you wish to logout?</p>
                        <p className='fw-400 fs-20 m-0 text-dark-grey'>are you sure you want to logout?</p>
                        <Grid item xs={12} style={{ marginTop: '30px', marginBottom: '10px' }} justifyContent={'center'}>
                            <Grid container justifyContent={'center'}>
                                <MyAds type="banner_3" adv_type={'web_logout_popup'} hideInMobile={true} />
                            </Grid>
                        </Grid>
                        <Grid item className='text-center'>
                            <Button onClick={() => dispatch(logOut())} className='rounded-btn w-100 mw-240 bg-selected text-light mt-30 '>continue</Button>
                            <br />
                            <Button onClick={() => {
                                setLogoutPopup(false);
                                helpers.trackMoengageEvent('FR3_Logout', {
                                    no_click: MOENGAGE_ATTRIBUTE.YES
                                });
                            }} className='rounded-btn w-100 mw-240 bg-light text-selected mt-10'>cancel</Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </Grid>
    )
}