import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CommonMediumTileListing from '../common/CommonMediumTileListing';
import parse from 'html-react-parser';


export default function CollectionMedia(props) {
    const { data, withAd , heading } = props;
    const [collectionData, setCollectionData] = useState([]);

    useEffect(() => {
        let sort = '';
        const payload = {};
        let endPoint;
        if (data?.collection_type == 'youtube') {
            endPoint = "youtubelistingdetailsbyuser";
        } else {
            endPoint = "collectionsmedia";
        }
        var path = `native_main/${endPoint}/${data?.collection_uuid}?page=1&pagelimit=${20}&exclude_already_watched=yes&prefered_platforms=yes`
        if (props.is_free) {
            path += `&is_free=${props.is_free}`;
        }
        if (sort && sort != "") {
            path += `&sort=${sort}`;
        }

        const baseUrl = process.env.REACT_APP_BASE_URL;
        var body = JSON.stringify(payload);
        var localData = localStorage.getItem("frenzi_user_access");
        var token = localData && JSON.parse(localData).access;
        if (token && token != '') {
            var config = {
                method: 'post',
                url: baseUrl + path,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                data: body
            };

            axios(config)
                .then(function (response) {
                    if (response.data && response.data.data) {
                        if (response.data.data.results) {
                            setCollectionData([...response.data.data.results]);
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [data])


    return (
        <CommonMediumTileListing
            heading={'best loved documentaries'}
            subheading={data?.section_sub_heading && data?.section_sub_heading !== "" ? parse(data?.section_sub_heading) : ''}
            isShowHeading={true}
            nextLink={data?.section_heading !== 'Youtube Free Movies' ? `/collection-media/${data?.collection_uuid}?name=${encodeURIComponent(data?.section_heading)}&collection_type=${data?.collection_type}` : ''}
            data={collectionData}
            refPage={'Watchlist'}
            tileType={data?.collection_type}
            withAd={withAd}
        />
    )
}
