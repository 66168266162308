import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FreeDataService from "../services/free.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    freePageData : {},
    freeData: [],
};



export const getFreeDetail = createAsyncThunk(
    "native_main/free",
    async (data, { rejectWithValue }) => {
        try {
            const response = await FreeDataService.getFreeDetail(data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const freeSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        // getFreeDetail
        [getFreeDetail.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFreeDetail.fulfilled]: (state, action) => {
            state.freePageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.freeData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.freeData = helpers.removeDuplicate(state.freeData.concat(getArrayData(state, action)));
            }
        },
        [getFreeDetail.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages } = freeSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default freeSlice.reducer;