import {
  Grid,
  Box,
  IconButton,
  Paper,
  Button,
  Typography,
  Modal,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import { Link, matchPath, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./component.scss";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getPlatformList, logOut } from "../../slices/auth";

// import NewNotificationRedIcon from "../../assets/NewNotificationRedIcon.svg";
// import NavLogo from "../../assets/icons/frenziLogo.png";
// import NavLogo from "../../assets/icons/frenziLogoNew.svg";
// import Activity from "../../assets/icons/Activity.svg";
// import FreeIcon from "../../assets/icons/FreeIcon.svg";
// import FAB from "../../assets/icons/FAB.svg";
// import BlogIcon from "../../assets/icons/BlogIcon.svg";
// import BlogRedIcon from "../../assets/icons/BlogRedIcon.svg";
// import ActivityRedIcon from "../../assets/icons/ActivityRedIcon.svg";
// import FreeRedIcon from "../../assets/icons/FreeRedIcon.svg";
// import SpinzIcon from "../../assets/icons/SpinzIcon.svg";
// import SpinzRedIcon from "../../assets/icons/SpinzRedIcon.svg";
// import HomeIcon from "../../assets/icons/HomeIcon.svg";
// import NavLogo from "../../assets/icons/frenziLogo1.svg";
// import ScrollToIcon from "../../assets/icons/ScrollToIcon.svg";
// import PreLoginIcon from '../../assets/icons/LoginMobileViewIocn.svg'
// import SearchhIcon from "../../assets/icons/searchWhite.svg";
// import SearchRedIcon from "../../assets/icons/search.svg";
// import HomeRedIcon from "../../assets/icons/HomeRedIcon.svg";
// import BookmarkNav from "../../assets/icons/BookmarkNav.svg";
// import BookmarkNavRed from "../../assets/icons/BookmarkNavRed.svg";
// import SpinzIcon from "../../assets/icons/Clips.svg";
// import SpinzRedIcon from "../../assets/icons/ClipsRed.svg";
// import ClipsMobile from "../../assets/icons/ClipsMobile.svg";
// import ClipsMobileRed from "../../assets/icons/ClipsMobileRed.svg";
// import NewNotificationIcon from "../../assets/icons/NewNotificationIcon.svg";

import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { getUnreadNotifications, getUserDetails } from "../../slices/settings";
import { toggleLoginModal, toggleSignUpModal } from "../../slices/global";
import { haveYouWatched } from "../../slices/activity";
import { HELPER_TEXT } from "../../lang/HelperText";
import MyAds from "../adsense/MyAds";
import { isMobileDevice } from '../../services/Utils';
// import Frame from "../../assets/img/Frame.png";
import CountrySelection from "./CountrySelection";

const NewNotificationRedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/NewNotificationRedIconNew2.svg";
const Frame = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/Frame.png";

const FAB = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/FAB.svg";
const HomeIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/HomeIcon.svg";
const NavLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/frenziLogoNew.svg"; //frenziLogo1
const ScrollToIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ScrollToIcon.svg";
const PreLoginIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + 'assets/icons/LoginMobileViewIocn.svg'
const SearchhIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/searchWhite.svg";
const SearchRedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/search.svg";
const HomeRedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/HomeRedIcon.svg";
const BookmarkNav = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/BookmarkNav.svg";
const BookmarkNavRed = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/BookmarkNavRed.svg";
const SpinzIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/Clips.svg";
const SpinzRedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ClipsRed.svg";
const ClipsMobile = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ClipsMobile.svg";
const ClipsMobileRed = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/ClipsMobileRed.svg";
const NewNotificationIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewNotificationIcon.svg";


function useRouteMatch(patterns) {
  const { pathname } = useLocation();
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }
  return null;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#16213E",
  boxShadow: 24,
  borderRadius: 2,
  width: "100%",
  maxWidth: { xs: "90%", sm: "50%", md: "45%", lg: "35%" },
  p: { xs: 2, sm: 5 },
};

export default function MainNavBar() {
  const [navOpac, setNavOpac] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isScrollIcon, setIsScrollIcon] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const settings = useSelector((state) => state.settings);
  const { userDetails, unreadNotifications } = settings;
  const global = useSelector((state) => state.global);
  const activity = useSelector((state) => state.activity);
  const [searchParams] = useSearchParams();
  let is_free_check = searchParams.get('is_free');
  let is_free = (is_free_check === true || is_free_check === 'true') ? true : false;

  const { haveYouWatchedData, haveYouWatchedCalled, isLoading } = activity;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!global.is_public && !isLoading && haveYouWatchedData.length === 0 && !haveYouWatchedCalled) {
      dispatch(haveYouWatched({ page: 1, pagelimit: 10 }));
    }

    dispatch(
      getPlatformList({
        is_free: true,
        page: 1,
        pagelimit: 50,
        is_public: global.is_public,
      })
    );
  }, [ global, haveYouWatchedData, isLoading]);

  const routeMatch = useRouteMatch([
    "/",
    "/connect",
    "/deals",
    "/free",
    "/activity",
    "/watchlist",
    "/reviews",
    "/mycinescore",
    "/watchlists",
    "/watchlist-detail",
    "/search",
    "/clips",
    "/blog",
    "/fresh",
    "/fresh/movie",
    "/new-release-on-ott",
    "/new-release-on-ott/movie",
    "/new-release-on-ott/tv",
    "/new-release-on-ott/most-searched",
    "/new-free-release-on-ott",
    "/new-free-release-on-ott/movie",
    "/new-free-release-on-ott/tv",
    "/new-free-release-on-ott/most-searched",
    "/platforms",
    "/trending/details/movie",
    "/trending/details/tv",
    "/trending/details/searched",
    "/fresh/tv",
    "/genres",
    "/fresh/most-searched",
    "/languages",
    "/languages/detail/movie",
    "/languages/detail/tv",
    "/languages/detail/searched",
    "/genres/:genre/movie",
    "/genres/:genre/tv",
    "/genres/:genre/searched",
    "/trending-movies-series-on-ott-this-week",
    "/upcoming-movies-and-series-on-ott",
    "/popular-among-friends",
    "/activity/popular-among-friends",
    "/popular-on-frenzi",
    "/popular-today/detail/movie",
    "/popular-today/detail/tv",
    "/popular-today/detail/search",
    "/collection-media",
    "/friends-sharelists",
    "/connect",
    "/watchlist-list",
    "/notification"
  ]);
  const currentTab = routeMatch?.pattern?.path;

  const trendingPageArr = [
    "/",
    "/platforms",
    "/trending/details/searched",
    "/trending/details/movie",
    "/trending/details/tv",
    "/fresh/tv",
    "/genres",
    "/genres/:genre/movie",
    "/languages/detail/movie",
    "/languages/detail/searched",
    "/languages",
    "/languages/detail/tv",
    "/fresh",
    "/fresh/most-searched",
    "/trending-movies-series-on-ott-this-week",
    "/trending/details/:type",
    "/fresh/movie",
    "/genres/:genre/tv",
    "/genres/:genre/searched",
    "/trending-movies-series-on-ott-this-week",
    "/popular-among-friends",
    "/popular-on-frenzi",
    "/popular-today/detail/movie",
    "/popular-today/detail/tv",
    "/popular-today/detail/search",
    "/collection-media",

    // "/fresh",
  ];

  const activityPageArr = [
    "/activity",
    "/watchlist",
    "/reviews",
    "/mycinescore",
    "/connect",
    "/friends-sharelists",
    "/activity/popular-among-friends"
  ];

  const notificationPageArr = [
    "/notification"
  ];

  const watchlistPageArr = [
    "/watchlists",
    "/watchlist-detail",
    "/watchlist-list"
  ];


  const toggleVisible = () => {
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if (height < 1500) {
      // setIsScrollIcon(false);
      return true;
    } else {
      setIsScrollIcon(true);
    }
    const scrolled = document.documentElement.scrollTop;
    setNavOpac(scrolled / 500);
    if (scrolled > document.body.scrollHeight / 2) {
      setVisible(true);
    } else if (scrolled <= document.body.scrollHeight / 2) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if (height < 1500) {
      // setIsScrollIcon(false);
    }
  }, []);

  useEffect(() => {
    let scrollEle = document.getElementsByClassName("fixed-full-view") && document.getElementsByClassName("fixed-full-view")[0] && document.getElementsByClassName("fixed-full-view")[0];
    if (scrollEle) {
      scrollEle.addEventListener("scroll", setIsScrollIcon(true), { passive: true });
      return () => scrollEle.removeEventListener("scroll", setIsScrollIcon(true));
    }
  }, [isScrollIcon]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    if (!global.is_public) {
      setAnchorElUser(event.currentTarget);
    } else {
      dispatch(toggleLoginModal({ isOpen: true }));
    }
  };

  useEffect(() => {
    if (!global.is_public) {
      dispatch(getUserDetails());
      dispatch(getUnreadNotifications());
    }
  }, [global.is_public]);

  const getProfileUrl = (uuid) => {
    let url = "/profile";
    let currentUser = localStorage.getItem("frenzi_user_access");
    var currentUserData = currentUser && currentUser !== "" && JSON.parse(currentUser);
    if (currentUserData && currentUserData.uuid === uuid) {
      return url;
    } else {
      return url + "/" + uuid;
    }
  };

  const handleOpenLogin = (type) => {
    helpers.trackMoengageEvent('FR3_Land', { login_page: MOENGAGE_ATTRIBUTE.YES });
    if (global.is_public) {
      dispatch(toggleLoginModal({
        isOpen: true,
        content: HELPER_TEXT.NUDGE_TEXT.LOGIN
      }))
    };
  }

  const isWatchlistActive = (tab) => {
    let path = window.location.pathname;
    return path.includes('watchlist-detail') || path.includes('watchlist-list') ? true : false;
  }

  const isFixedNav = currentTab === '/' || currentTab === '/free' || currentTab === '/upcoming-movies-and-series-on-ott';
  const isTransparentNav = isFixedNav && navOpac < 1;

  // const showTopBar = !window.location.href.includes('clips') && !window.location.href.includes('/series/') && !window.location.href.includes('/movie/') && !window.location.href.includes('/activity')

  const showTopBar = window.location.href.includes('/free') || window.location.pathname === "/" || window.location.href.includes('/upcoming-movies-and-series-on-ott');


  return (
    <>
      <ToastContainer className="my-frenzi-toast" />
      {!window.location.href.includes("/clips") &&
        <>
          {isScrollIcon && !visible && (
            <img
              alt=""
              src={ScrollToIcon}
              onClick={() => scrollToBottom()}
              className="scroll-to-icon pointer"
            />
          )}
          {isScrollIcon && visible && (
            <Box
              component="img"
              alt='icon'
              style={{ transform: "rotate(180deg)" }}
              src={ScrollToIcon}
              onClick={() => scrollToTop()}
              className="scroll-to-icon pointer"
              sx={{
                marginLeft: { xs: "-12.5px", sm: "-25px" },
                marginTop: { xs: "-12.5px", sm: "-25px" },
              }}
            />
          )}
        </>
      }
      {/* {!window.location.href.includes("/clips") &&
        <Box
          component="img"
          style={{ transform: "rotate(180deg)" }}
          src={ScrollToIcon}
          onClick={() => scrollToTop()}
          className="scroll-to-icon pointer"
          sx={{
            marginLeft: { xs: "-12.5px", sm: "-25px" },
            marginTop: { xs: "-12.5px", sm: "-25px" },
          }}
        />
      } */}
      {isMobileDevice() ?
        <>
          <Paper
            sx={{
              // display: { xs: "flex", sm: "none" },
              backgroundColor: `rgba(26,26,46,${!isFixedNav ? 1 : navOpac}) !important`,
              position: isFixedNav ? 'fixed' : 'sticky',
              boxShadow: (isTransparentNav && navOpac < 1) ? 'none' : 'auto',
              height: global.is_public ? '70px' : 'auto',
            }}
            elevation={10}
            className="fixed-navbar-top"
          >
            <Grid
              className="pt-30 pl-100 pr-100 pb-30"
              container
              sx={{ minHeight: '46px' }}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  className="w-100 m-0"
                >
                  {global.is_public ?
                    <Grid item>
                      <Link to="/">
                        <Grid container flexWrap={'nowrap'} alignItems={"center"}>
                          <Box
                            component="img"
                            sx={{
                              width: "100%",
                              height: '40px',
                              maxWidth: { xs: 30, sm: 36, md: 40, lg: 40 },
                              paddingRight: "5px",
                              marginRight: '5px'
                            }}
                            src={NavLogo}
                            alt='logo'
                          />
                          <Typography sx={{ marginTop: '-5px !important' }} className={`p-0 m-0 lh-30 fs-30 fw-700 ${isTransparentNav && 'bold-shadow-link'}`}>Frenzi</Typography>
                        </Grid>
                      </Link>
                    </Grid>
                    :
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <Grid item className="text-right" style={{ marginRight: '40px' }}>
                          <Box>
                            <Tooltip title="open menu">
                              <IconButton onClick={handleOpenUserMenu}
                                sx={{
                                  backgroundImage: `url(${Frame})`,
                                  width: '37px',
                                  height: '33px',
                                  paddingTop: '10px',
                                  paddingRight: '15px',
                                  paddingBottom: '15px',
                                  paddingLeft: '12px',
                                  borderRadius: '0px !important',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: '100% 100%',
                                }}
                              >
                                <Avatar
                                  src={
                                    userDetails.profile_image ? (
                                      helpers.getFullUrl(userDetails.profile_image)
                                    ) : (
                                      helpers.getFullUrl('joeschmoe')
                                    )
                                  }
                                  sx={{
                                    width: { xs: 24 },
                                    height: { xs: 24 },
                                    marginLeft: { xs: "3px" },
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Popover
                              sx={{ mt: "45px" }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                              className="profile-nav-menu"
                            >
                              <Link to={getProfileUrl(userDetails?.uuid)} onClick={() => {
                                handleCloseUserMenu();
                                helpers.trackMoengageEvent('FR3_ProSettings', {
                                  profile_setting_click: MOENGAGE_ATTRIBUTE.YES
                                });
                                helpers.trackMoengageEvent('FR3_My_Profile', {
                                  view_profile_btn: MOENGAGE_ATTRIBUTE.YES
                                });
                              }}>
                                <MenuItem className="profile-menu-item">
                                  <ListItemIcon>
                                    <SentimentSatisfiedAltOutlinedIcon fontSize="medium" />
                                  </ListItemIcon>
                                  <p style={{ marginTop: '-7px' }} className="mb-0 ml-0 mr-0 fw-600 list-style">profile</p>
                                </MenuItem>
                              </Link>
                              <Link to="/settings"
                                onClick={() => {
                                  handleCloseUserMenu();
                                  helpers.trackMoengageEvent('FR3_AccSettings', {
                                    account_settings_click: MOENGAGE_ATTRIBUTE.YES
                                  });
                                }}
                              >
                                <MenuItem className="profile-menu-item">
                                  <ListItemIcon>
                                    <SettingsOutlinedIcon fontSize="medium" />
                                  </ListItemIcon>
                                  <p style={{ marginTop: '-7px' }} className="mb-0 ml-0 mr-0 fw-600 list-style">settings</p>
                                </MenuItem>
                              </Link>
                              <MenuItem className="profile-menu-item" onClick={() => {
                                setLogoutPopup(true);
                                helpers.trackMoengageEvent('FR3_Logout', {
                                  logout_click: MOENGAGE_ATTRIBUTE.YES
                                });
                              }}>
                                <ListItemIcon>
                                  <LogoutOutlinedIcon
                                    fontSize="medium"
                                    sx={{ marginLeft: "2px" }}
                                  />
                                </ListItemIcon>
                                <p style={{ marginTop: '-7px' }} className=" mb-0 ml-0 mr-0 fw-600 list-style">logout</p>
                              </MenuItem>
                            </Popover>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Link to={global.is_public ? "#" : "/points"} onClick={() => handleOpenLogin()}>
                            <Grid container>
                              <Box
                                component="img"
                                className="hover-icon"
                                sx={{
                                  width: '100%',
                                  maxWidth: { xs: 20, sm: 24, md: 28, lg: 32 },
                                  display: 'inline-block !important',
                                  marginRight: '7px'
                                }}
                                src={FAB}
                                alt='search'
                              />
                              <span className="fw-600 fs-18"
                                style={{
                                  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.9)'
                                }}
                              >{helpers.getSocialNumbers(userDetails?.frenzi_points)}</span>
                            </Grid>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  }

                  <Grid item>
                    <Grid container alignItems={""}>
                      {global.is_public ?
                        <Grid item onClick={() => handleOpenLogin('login')}>
                          <Box
                            component={'img'}
                            src={PreLoginIcon}
                          />
                        </Grid>
                        :
                        ''
                      }
                      <Grid item className="text-right mt-5">
                        <CountrySelection />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* {!global.is_public ? */}
                <>
                  {showTopBar ?
                    <Grid container alignItems={'center'} justifyContent='center' className="h-100">
                      <Link to="/">
                        <div>
                          <span
                            className={`fw-500 hover-link shadow lh-20 list-style ${(trendingPageArr.includes(currentTab) && !is_free) ? "text-selected fw-800 fs-24" : "fw-600 fs-20"
                              }`}
                          >
                            trending
                          </span>
                        </div>
                      </Link>
                      <Link to="/free">
                        <div style={{ marginLeft: '40px', marginRight: '40px' }}>
                          <span
                            className={`fw-500 hover-link shadow lh-20 list-style ${((currentTab === "/free") || is_free) ? "text-selected fw-800 fs-24" : "fw-600 fs-20"
                              }`}
                          >
                            free
                          </span>
                        </div>
                      </Link>
                      <Link to="/upcoming-movies-and-series-on-ott">
                        <div>
                          <span
                            className={`fw-500 hover-link shadow lh-20 list-style ${(currentTab === "/upcoming-movies-and-series-on-ott") ? "text-selected fw-800 fs-24" : "fw-600 fs-20"
                              }`}
                          >
                            upcoming
                          </span>
                        </div>
                      </Link>
                    </Grid> : ""}
                </>
                {/* :
                   ""
                  }
                     */}
              </Grid>
            </Grid>
          </Paper>

          <Paper
            // sx={{ display: { xs: "flex", sm: "none" } }}
            elevation={10}
            className="fixed-navbar-mobile"
          >
            <Grid
              className="pt-20 pl-100 pr-100 pb-20"
              container
              sx={{ minHeight: '50px' }}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  className="w-100 m-0"
                >
                  <Grid item xs={'auto'} className="text-center">
                    <Link to="/">
                      <div className="text-center w-100">
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 24,
                            maxHeight: { xs: 24, sm: 24 },
                            maxWidth: { xs: 24, sm: 24 },
                          }}
                          src={trendingPageArr.includes(currentTab) ? HomeRedIcon : HomeIcon}
                          alt='trending'
                        />
                      </div>
                    </Link>
                  </Grid>
                  {/* <Grid item xs={'auto'} className="text-center">
                    <Link to="/activity">
                      <div className="text-center w-100">
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 24,
                            maxHeight: { xs: 24, sm: 24 },
                            maxWidth: { xs: 24, sm: 24 },
                          }}
                          src={
                            activityPageArr.includes(currentTab)
                              ? ActivityRedIcon
                              : Activity
                          }
                          alt='activity'
                        />
                      </div>
                    </Link>
                  </Grid> */}
                  <Grid item xs={'auto'} className="text-center">
                    <Link to={global.is_public ? "#" : "/watchlists"} onClick={() => handleOpenLogin()}>
                      <div className="text-center w-100">
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 24,
                            maxHeight: { xs: 24, sm: 24 },
                            maxWidth: { xs: 24, sm: 24 },
                          }}
                          alt=""
                          src={currentTab == "/watchlists" ? BookmarkNavRed : BookmarkNav}
                        />
                      </div>
                    </Link>
                  </Grid>
                  {!global.is_public &&
                    <Grid item xs={'auto'} className="text-center">
                      <Link onClick={() => {
                        helpers.trackMoengageEvent('FR3_Clips', {
                          snipz_click: MOENGAGE_ATTRIBUTE.YES,
                        });
                      }} to="/clips">
                        <div className="text-center w-100">
                          <Box
                            component="img"
                            sx={{
                              height: 24,
                              width: 30,
                              maxHeight: { xs: 24, sm: 24 },
                              maxWidth: { xs: 30, sm: 30 },
                            }}
                            src={
                              window.location.href.includes("/clips")
                                ? ClipsMobileRed
                                : ClipsMobile
                            }
                            alt='clips'
                          />
                        </div>
                      </Link>
                    </Grid>
                  }
                  {!global.is_public &&
                    <Grid item xs={'auto'} className="text-center">
                      <Link to={global.is_public ? "#" : "/notification"} onClick={() => handleOpenLogin()}>
                        <div className="text-center relative w-100">
                          <Box
                            component="img"
                            sx={{
                              height: 30,
                              width: 30,
                              maxHeight: currentTab === "/notification" ? { xs: 30, sm: 23 } : { xs: 28, sm: 30 },
                              maxWidth: currentTab === "/notification" ? { xs: 30, sm: 23 } : { xs: 30, sm: 30 },
                              transform: 'rotate(-3deg)',
                              marginTop: '3px'
                            }}
                            src={currentTab === "/notification" ? NewNotificationRedIcon : NewNotificationIcon}
                            alt='search'
                          />
                          {unreadNotifications > 0 ?
                            <div class="row unread-count">
                              <span className="text-center">{unreadNotifications}</span>
                            </div>
                            : ''
                          }
                        </div>
                      </Link>
                    </Grid>
                  }
                  <Grid item xs={'auto'} className="text-center">
                    <Link to="/search">
                      <div className="text-center w-100">
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 30,
                            maxHeight: { xs: 24, sm: 24 },
                            maxWidth: { xs: 30, sm: 30 },
                          }}
                          src={currentTab === "/search" ? SearchRedIcon : SearchhIcon}
                          alt='search'
                        />
                      </div>
                    </Link>
                  </Grid>
                  {/* <Grid item xs={'auto'} className="text-center">
                    <Link to="/blog">
                      <div className="text-center w-100">
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 24,
                            maxHeight: { xs: 24, sm: 24 },
                            maxWidth: { xs: 24, sm: 24 },
                          }}
                          alt='blogs'
                          src={(currentTab === "/blog" || window.location.href.includes("/blog/")) ? BlogRedIcon : BlogIcon}
                        />
                      </div>
                    </Link>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
        :
        <>
          {showTopBar ?
            <Paper
              sx={{
                boxShadow: 'none'
              }}
              elevation={10}
              className="fixed-navbar-top"
            >
              <Grid container alignItems={'center'} justifyContent='center' className="h-100">
                <Link to="/">
                  <div>
                    <span
                      className={`fw-500 hover-link lh-20 list-style ${(trendingPageArr.includes(currentTab) && !is_free) ? "text-selected fw-800 fs-24" : "fw-600 fs-20"
                        }`}
                    >
                      trending
                    </span>
                  </div>
                </Link>
                <Link to="/free">
                  <div style={{ marginLeft: '35px', marginRight: '35px' }}>
                    <span
                      className={`fw-500 hover-link lh-20 list-style ${((currentTab === "/free") || is_free) ? "text-selected fw-800 fs-24" : "fw-600 fs-20"
                        }`}
                    >
                      free
                    </span>
                  </div>
                </Link>
                <Link to="/upcoming-movies-and-series-on-ott">
                  <div>
                    <span
                      className={`fw-500 hover-link lh-20 list-style ${(currentTab === "/upcoming-movies-and-series-on-ott") ? "text-selected fw-800 fs-24" : "fw-600 fs-20"
                        }`}
                    >
                      upcoming
                    </span>
                  </div>
                </Link>
              </Grid>
            </Paper>
            : ''
          }

          <Paper
            sx={{
              boxShadow: 'none'
            }}
            elevation={10}
            className="fixed-navbar"
          >
            <Grid container className="h-100 menu-container" justifyContent={'space-between'} flexDirection='column' flexWrap={'nowrap'} style={{ overflowY: 'auto' }}>
              <div className="w-100">
                <Link to="/">
                  <Grid container flexWrap={'nowrap'} alignItems={"center"} justifyContent='center'>
                    <Box
                      component="img"
                      alt='logo'
                      sx={{
                        width: "100%",
                        height: 'auto',
                        maxWidth: { sm: 36, md: 50, lg: 44 },
                        marginBottom: '10px'
                      }}
                      src={NavLogo}
                    />
                    {/* <Typography sx={{ marginTop: '-5px !important' }} className="p-0 m-0 lh-32 fs-32 fw-700">Frenzi</Typography> */}
                  </Grid>
                </Link>

                <Link to="/" >
                  <div className="text-center ">
                    <Box
                      component="img"
                      className='mb-10'
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 36, lg: 24 },
                      }}
                      alt='trending'
                      src={(trendingPageArr.includes(currentTab) && !is_free) ? HomeRedIcon : HomeIcon}
                    />
                    <Box
                      component="img"
                      className="hover-icon mb-10"
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 36, lg: 24 },
                      }}
                      src={HomeRedIcon}
                      alt='trending'
                    />
                  </div>
                </Link>

                <Link to={global.is_public ? "#" : "/watchlists"} onClick={() => handleOpenLogin()}>
                  <div className="text-center">
                    <Box
                      component="img"
                      className='mb-10'
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 24, md: 26, lg: 20 },
                      }}
                      src={(watchlistPageArr.includes(currentTab) || isWatchlistActive(currentTab)) ? BookmarkNavRed : BookmarkNav}
                      alt='watchlist'
                    />
                    <Box
                      component="img"
                      className="hover-icon mb-10"
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 24, md: 26, lg: 20 },
                      }}
                      src={BookmarkNavRed}
                      alt='watchlist'
                    />
                  </div>
                </Link>

                {!global.is_public &&
                  <Link onClick={() => {
                    helpers.trackMoengageEvent('FR3_Clips', {
                      snipz_click: MOENGAGE_ATTRIBUTE.YES,
                    });
                  }} to="/clips">
                    <div className="text-center">
                      <Box
                        component="img"
                        className='mb-10'
                        sx={{
                          width: '100%',
                          maxWidth: { sm: 28, md: 36, lg: 29 },
                        }}
                        src={
                          window.location.href.includes("/clips")
                            ? SpinzRedIcon
                            : SpinzIcon
                        }
                        alt='clips'
                      />
                      <Box
                        component="img"
                        className="hover-icon mb-10"
                        sx={{
                          width: '100%',
                          maxWidth: { sm: 28, md: 36, lg: 29 },
                        }}
                        src={SpinzRedIcon}
                        alt='clips'
                      />
                    </div>
                  </Link>
                }

                {/* <Link onClick={() => helpers.trackMoengageEvent('FR3_Free', { free_click: MOENGAGE_ATTRIBUTE.YES })} to="/free">
              <div className="text-center">
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: { sm: 28, md: 36, lg: 41 },
                  }}
                  src={(currentTab === "/free" || is_free) ? FreeRedIcon : FreeIcon}
                  alt='free'
                />
                <Box
                  component="img"
                  className="hover-icon"
                  sx={{
                    width: '100%',
                    maxWidth: { sm: 28, md: 36, lg: 41 },
                  }}
                  src={FreeRedIcon}
                  alt='free'
                />
              </div>
            </Link> */}

                {/* <Link to="/blog">
              <div className="text-center">
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: { sm: 28, md: 36, lg: 41 },
                  }}
                  src={(currentTab === "/blog" || window.location.href.includes("/blog/")) ? BlogRedIcon : BlogIcon}
                  alt='blogs'
                />
                <Box
                  component="img"
                  className="hover-icon"
                  sx={{
                    width: '100%',
                    maxWidth: { sm: 28, md: 36, lg: 41 },
                  }}
                  src={BlogRedIcon}
                  alt='blogs'
                />
              </div>
            </Link> */}

                <Link to="/search" >
                  <div className="text-center">
                    <Box
                      component="img"
                      className='mb-10'
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 30, lg: 24 },
                      }}
                      src={currentTab === "/search" ? SearchRedIcon : SearchhIcon}
                      alt='search'
                    />
                    <Box
                      component="img"
                      className="hover-icon mb-10"
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 30, lg: 24 },
                      }}
                      src={SearchRedIcon}
                      alt='search'
                    />
                  </div>
                </Link>

                <Link to={global.is_public ? "#" : "/notification"} onClick={() => handleOpenLogin()}>
                  <div className="text-center relative">
                    <Box
                      component="img"
                      className='mb-10'
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 36, lg: 32 },
                      }}
                      src={
                        notificationPageArr.includes(currentTab)
                          ? NewNotificationRedIcon
                          : NewNotificationIcon
                      }
                      alt='notification'
                    />
                    <Box
                      component="img"
                      className="hover-icon mb-12"
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 36, lg: 29 },
                      }}
                      src={NewNotificationRedIcon}
                      alt='notification'
                    />
                    {unreadNotifications > 0 ?
                      <div class="row unread-count">
                        <span className="text-center">{unreadNotifications}</span>
                      </div>
                      : ''
                    }
                  </div>
                </Link>


                <Link to={global.is_public ? "#" : "/points"} onClick={() => handleOpenLogin()}>
                  <div className="text-center">
                    <Box
                      component="img"
                      className="hover-icon mb-10"
                      sx={{
                        width: '100%',
                        maxWidth: { sm: 28, md: 32, lg: 27 },
                        display: 'inline-block !important'
                      }}
                      src={FAB}
                      alt='search'
                      title={helpers.getSocialNumbers(userDetails?.frenzi_points)}
                    />
                  </div>
                </Link>
              </div>

              <div className="w-100" style={{ paddingBottom: '40px', paddingTop: '40px' }}>
                <Grid container justifyContent={'center'}>
                  <Grid item className="mb-20">
                    <CountrySelection />
                  </Grid>
                </Grid>
                {global.is_public ?
                  <Grid item className="text-center">
                    <Grid item>
                      <span onClick={() => dispatch(toggleSignUpModal(true))} className="fs-14 lh-18 text-center hover-red pointer">signup</span>
                    </Grid>
                    <div style={{ borderBottom: '1px solid #e94560', marginTop: '10px', marginBottom: '7px' }}></div>
                    <Grid item>
                      <span onClick={() => handleOpenLogin('login')} className="fs-14 lh-18 hover-red pointer">login</span>
                    </Grid>
                  </Grid>
                  :
                  <Grid item className="text-center">
                    <Box>
                      <Tooltip title={userDetails?.username}>
                        <IconButton onClick={handleOpenUserMenu}
                          sx={{
                            backgroundImage: `url(${Frame})`,
                            width: '60px',
                            height: '52px',
                            paddingTop: '7px',
                            paddingRight: '15px',
                            paddingBottom: '15px',
                            paddingLeft: '14px',
                            borderRadius: '0px !important',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%'
                          }}
                        >
                          <Avatar
                            src={
                              userDetails.profile_image ? (
                                helpers.getFullUrl(userDetails.profile_image)
                              ) : (
                                helpers.getFullUrl('joeschmoe')
                              )
                            }

                          />
                        </IconButton>
                      </Tooltip>
                      <Popover
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        className="profile-nav-menu"
                      >
                        <Link to={getProfileUrl(userDetails?.uuid)} onClick={() => {
                          handleCloseUserMenu();
                          helpers.trackMoengageEvent('FR3_ProSettings', {
                            profile_setting_click: MOENGAGE_ATTRIBUTE.YES
                          });
                          helpers.trackMoengageEvent('FR3_My_Profile', {
                            view_profile_btn: MOENGAGE_ATTRIBUTE.YES
                          });
                        }}>
                          <MenuItem className="profile-menu-item">
                            <ListItemIcon>
                              <SentimentSatisfiedAltOutlinedIcon fontSize="medium" />
                            </ListItemIcon>
                            <p style={{ marginTop: '-7px' }} className="mb-0 ml-0 mr-0 fw-600 list-style">profile</p>
                          </MenuItem>
                        </Link>
                        <Link to="/settings"
                          onClick={() => {
                            handleCloseUserMenu();
                            helpers.trackMoengageEvent('FR3_AccSettings', {
                              account_settings_click: MOENGAGE_ATTRIBUTE.YES
                            });
                          }}
                        >
                          <MenuItem className="profile-menu-item">
                            <ListItemIcon>
                              <SettingsOutlinedIcon fontSize="medium" />
                            </ListItemIcon>
                            <p style={{ marginTop: '-7px' }} className="mb-0 ml-0 mr-0 fw-600 fs-16 list-style">settings</p>
                          </MenuItem>
                        </Link>
                        <MenuItem className="profile-menu-item" onClick={() => {
                          setLogoutPopup(true);
                          helpers.trackMoengageEvent('FR3_Logout', {
                            logout_click: MOENGAGE_ATTRIBUTE.YES
                          });
                        }}>
                          <ListItemIcon>
                            <LogoutOutlinedIcon
                              fontSize="medium"
                              sx={{ marginLeft: "2px" }}
                            />
                          </ListItemIcon>
                          <p style={{ marginTop: '-7px' }} className=" mb-0 ml-0 mr-0 fw-600 fs-16 list-style">logout</p>
                        </MenuItem>
                      </Popover>
                    </Box>
                  </Grid>
                }

              </div>

            </Grid>

          </Paper>
        </>
      }

      {logoutPopup &&
        <div>
          <Modal
            open={logoutPopup}
            onClose={() => setLogoutPopup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <p className="fw-700 fs-26 m-0">Do you wish to logout?</p>
              <p className="fw-400 fs-20 m-0 text-dark-grey">
                are you sure you want to logout?
              </p>
              <Grid item xs={12} style={{ marginTop: '30px', marginBottom: '10px' }} justifyContent={'center'}>
                <Grid container justifyContent={'center'}>
                  <MyAds type="banner_3" adv_type={'web_logout_popup'} hideInMobile={true} />
                </Grid>
              </Grid>
              <Grid item className="text-center">
                <Button
                  onClick={() => {
                    dispatch(logOut());
                    helpers.trackMoengageEvent('FR3_Logout', {
                      continue_click: MOENGAGE_ATTRIBUTE.YES
                    });
                  }}
                  className="rounded-btn w-100 mw-240 bg-selected text-light mt-30 "
                >
                  continue
                </Button>
                <br />
                <Button
                  onClick={() => {
                    setLogoutPopup(false);
                    helpers.trackMoengageEvent('FR3_Logout', {
                      no_click: MOENGAGE_ATTRIBUTE.YES
                    });
                  }}
                  className="rounded-btn w-100 mw-240 bg-light text-selected mt-10"
                >
                  cancel
                </Button>
              </Grid>
            </Box>
          </Modal>
        </div>
      }
    </>
  );
}
