import { Box, Button, Grid, Modal } from '@mui/material';
import FollowedDirector from './FollowedDirector';
import ReleaseWeek from './ReleaseWeek';
// import OttNews from './OttNews';
import React, { useEffect, useState } from 'react';
import './activity.scss'
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages as clearMessagesCast } from '../../slices/cast';
import { clearMessages, getActivityHomeData, getActivityNews } from '../../slices/activity';
import helpers from '../../services/helpers';

import CommonMenu from "../common/CommonMenu";
import FanMedia from "./FanMedia";
import AddYourBirthday from "../profile/profileComponent/AddYourBirthday";
import Connect from "../connect/Connect";
import MyAds from "../adsense/MyAds";
import MarkTitleSeen from "../../nudges/MarkTitleSeen";
import { useNavigate } from "react-router-dom";
import { getCinescoreData } from "../../slices/cinescore";
import { HELPER_TEXT } from "../../lang/HelperText";
import { toggleLoginModal } from "../../slices/global";
import InviteUsers from "../common/InviteUsers";
import MyHelmet from "../common/MyHelmet";
import LinearLoader from '../common/LinearLoader';
import { isMobileDevice } from '../../services/Utils';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "90%", sm: "760px" },
    bgcolor: "#16213E",
    boxShadow: 24,
    // p: { xs: 2, sm: 4 },
    borderRadius: "7px",
    maxHeight: "95vh",
    overflowY: "auto",
};

export default function Activity(props) {
    const dispatch = useDispatch();
    const activity = useSelector((state) => state.activity);
    const cast = useSelector((state) => state.cast);
    const navigate = useNavigate();
    const cinescore = useSelector((state) => state.cinescore);
    const global = useSelector((state) => state.global);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (activity.errMesg && activity.errMesg !== "") {
            helpers.toastError(activity.errMesg);
            dispatch(clearMessages());
        }
        if (activity.successMesg && activity.successMesg !== "") {
            helpers.toastSuccess(activity.successMesg);
            dispatch(clearMessages());
        }
    }, [activity, dispatch]);

    useEffect(() => {
        if (cast.errMesg && cast.errMesg !== "") {
            helpers.toastError(cast.errMesg);
            dispatch(clearMessagesCast());
        }
        if (cast.successMesg && cast.successMesg !== "") {
            helpers.toastSuccess(cast.successMesg);
            dispatch(clearMessagesCast());
        }
    }, [cast, dispatch]);

    useEffect(() => {
        dispatch(getCinescoreData());

        if (!props.is_public) {
            dispatch(getActivityHomeData());
        }
        dispatch(
            getActivityNews({ page: 1, pagelimit: 10, is_public: props.is_public, is_blog: false })
        );
    }, []);

    return (
        <>
            <MyHelmet
                title={'Vote, Quiz, Connect!, Get your Cinescore go higher'}
                description={'Play, Earn, and Connect! Unlock OTT Subscriptions, Increase Cinescore, Connect with your Cinematch, Collect Points, Redeem Rewards and much more'}
            />

            <Box className='new-container'>
                {(activity.isLoading || cast.isLoading) && <LinearLoader />}
                <CommonMenu is_public={props.is_public} />
                <Grid item xs={12} className="app-section">
                    <Grid container justifyContent={"center"}>
                        <MyAds type="banner_2" adv_type={"web_activity_page_top_ad"} hideInMobile={true} />
                    </Grid>
                </Grid>
                {/* <Grid container alignItems={'center'} className='app-container mt-0 jc-sb' spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <OttNews data={activity.activityNewsData} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container justifyContent={'center'}>
                            <MyAds type="banner_3" adv_type={'web_activity_page_top_news_ad'} />
                        </Grid>
                    </Grid>
                </Grid> */}

                <MarkTitleSeen
                    titles={
                        (cinescore.cinescoreResults &&
                            cinescore.cinescoreResults.total_watched_tv
                            ? cinescore.cinescoreResults.total_watched_tv
                            : 0) +
                        (cinescore.cinescoreResults &&
                            cinescore.cinescoreResults.total_watched_movie
                            ? cinescore.cinescoreResults.total_watched_movie
                            : 0)
                    }
                    onClick={() => {
                        global.is_public
                            ? dispatch(
                                toggleLoginModal({
                                    isOpen: true,
                                    content: HELPER_TEXT.NUDGE_TEXT.TITLES_WATCHED,
                                })
                            )
                            : navigate("/mycinescore");
                    }}
                />

                {isMobileDevice() ?
                    <Grid item xs={12} className="app-section">
                        <Grid container justifyContent={"center"}>
                            <MyAds mobtype='mobile_1' slotId={'2918011331'} type="banner_2" adv_type={"web_activity_page_top_ad"} />
                        </Grid>
                    </Grid>
                    : ''
                }

                {/* {!global.is_public && <ConnectWithCinematch />} */}

                <Grid container className='ai-center app-container mt-50 mb-50'>
                    <Grid item xs={1} sm={3} className="section-border-bottom"></Grid>
                    <Grid item xs={10} sm={6}>
                        <Button onClick={() => {
                            if (global.is_public) {
                                dispatch(toggleLoginModal({ isOpen: true }));
                            } else {
                                handleOpen();
                            }
                        }} className="rounded-btn text-light bg-selected w-100 lh-20">invite your friends to frenzi and get 100 points </Button>
                    </Grid>
                    <Grid item xs={1} sm={3} className="section-border-bottom"></Grid>
                    <Grid item xs={12} className="text-center text-muted fs-16"><span>win 100 points for every freind that join frenzi</span></Grid>
                </Grid>

                <AddYourBirthday is_public={global.is_public} hideToast={true} />

                <Grid container className="app-section mt-20" justifyContent={"center"}>
                    <MyAds type="banner_2" mobtype='mobile_1' slotId={'7564366180'} adv_type={"web_activity_page_middle_ad"} />
                </Grid>

                {activity.activityHomeData && activity.activityHomeData.actor_id && (
                    <FanMedia
                        refPage="Activity"
                        fanId={activity.activityHomeData.actor_id}
                        name={activity.activityHomeData.actor_name}
                        type={"actor"}
                    />
                )}
                <FollowedDirector hideToast={true} is_public={props.is_public} />
                <Grid
                    container
                    justifyContent={"center"}
                    className="app-section"
                    style={{ marginTop: "20px" }}
                >
                    <MyAds type="banner_2" mobtype='mobile_1' slotId={'5352602980'} adv_type={"web_activity_page_banner_ad"} width={250} height={250} />
                </Grid>
                {activity.activityHomeData &&
                    activity.activityHomeData?.director_id && (
                        <FanMedia
                            refPage="Activity"
                            fanId={activity.activityHomeData?.director_id}
                            name={activity.activityHomeData?.director_name}
                            type={"director"}
                        />
                    )}
                <ReleaseWeek />
                <Grid
                    container
                    justifyContent={"center"}
                    className="app-section"
                    style={{ marginTop: "20px" }}
                >
                    <MyAds type="banner_2" adv_type={"web_activity_page_banner_ad"} />
                </Grid>
                {!props.is_public && <Connect discoveryPage={true} hideToast={true} />}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <InviteUsers onClose={handleClose} />
                    </Box>
                </Modal>
            </Box>
        </>
    );
}
