import React from 'react';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import AWS from 'aws-sdk'
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';

// import defaultCast from '../assets/img/defaultCast.jpg';
// import personPlaceholder from '../assets/img/PersonPlaceholder.png';
// import watchlistImg from '../assets/img/watchlist.jpg';
// import PlaceHolder from '../assets/icons/PlaceHolder.svg';
// import UserIcon from '../assets/icons/UserIcon.svg'

import axios from "axios";
import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
// Create formatter (English).
const timeAgo = new TimeAgo('en-US')

const og_lang = [{ "lang": "English", "icon": "E", "code": "en", "selected": false }, { "lang": "Hindi", "icon": "हि", "code": "hi", "selected": false }, { "lang": "Punjabi", "icon": "ਪੰ", "code": "pa", "selected": false }, { "lang": "Tamil", "icon": "த", "code": "ta", "selected": false }, { "lang": "Telugu", "icon": "తె", "code": "te", "selected": false }, { "lang": "Malyalam", "icon": "മ", "code": "ml", "selected": false }, { "lang": "Kannada", "icon": "ಕ", "code": "kn", "selected": false }, { "lang": "Marathi", "icon": "म", "code": "mr", "selected": false }, { "lang": "Gujrati", "icon": "જી", "code": "gu", "selected": false }, { "lang": "Bengali", "icon": "খ", "code": "bn", "selected": false }, { "lang": "Spanish", "icon": "S", "code": "es", "selected": false }, { "lang": "German", "icon": "G", "code": "de", "selected": false }, { "lang": "Korean", "icon": "ㅞ", "code": "ko", "selected": false }, { "lang": "French", "icon": "F", "code": "fr", "selected": false }, { "lang": "Chinese", "icon": "明", "code": "zh", "selected": false }]

const defaultCast = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/defaultCast.jpg";
const personPlaceholder = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/PersonPlaceholder.png";
const watchlistImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/img/watchlist.jpg";
const PlaceHolder = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/PlaceHolder.svg";
const UserIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/UserIcon.svg";

export const DEFAULT_TITLE = `Frenzi | simplifying "what to watch on OTT...!"`;
export const DEFAULT_DESCRIPTION = `Frenzi simplifies content discovery across 60+ OTT platforms and guides you to platforms where you can legally watch them. Simplifying. Content Discovery.`;

export const MEDIUM_TILE_BREAKPOINTS = {
    280: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    360: {
        slidesPerView: 2.5,
        spaceBetween: 15,
    },

    460: {
        slidesPerView: 3.5,
        spaceBetween: 15,
    },

    690: {
        slidesPerView: 4.1,
        spaceBetween: 20,
        slidesPerGroup: 3
    },

    760: {
        slidesPerView: 4.6,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    860: {
        slidesPerView: 5.1,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    990: {
        slidesPerView: 5.7,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1120: {
        slidesPerView: 6.3,
        spaceBetween: 20,
        slidesPerGroup: 5
    },
    1260: {
        slidesPerView: 6.5,
        spaceBetween: 30,
        slidesPerGroup: 5
    },
    1400: {
        slidesPerView: 7.3,
        spaceBetween: 30,
        slidesPerGroup: 6
    },

    1680: {
        slidesPerView: 8.1,
        spaceBetween: 30,
        slidesPerGroup: 7
    },
    1860: {
        slidesPerView: 8.9,
        spaceBetween: 30,
        slidesPerGroup: 7
    },

    1990: {
        slidesPerView: 10,
        spaceBetween: 30,
        slidesPerGroup: 9
    },
    2240: {
        slidesPerView: 12,
        spaceBetween: 30,
        slidesPerGroup: 11
    },

    2560: {
        slidesPerView: 14,
        spaceBetween: 30,
        slidesPerGroup: 13
    }
}

export const MEDIUM_TILE_BREAKPOINTS2 = {
    280: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    360: {
        slidesPerView: 2.5,
        spaceBetween: 15,
    },

    460: {
        slidesPerView: 3.5,
        spaceBetween: 15,
    },

    690: {
        slidesPerView: 4.1,
        spaceBetween: 20,
        slidesPerGroup: 3
    },

    760: {
        slidesPerView: 4.6,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    860: {
        slidesPerView: 5.1,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    990: {
        slidesPerView: 5.7,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1120: {
        slidesPerView: 6.3,
        spaceBetween: 20,
        slidesPerGroup: 5
    },
    1260: {
        slidesPerView: 6.8,
        spaceBetween: 30,
        slidesPerGroup: 5
    },
    1400: {
        slidesPerView: 6.8,
        spaceBetween: 30,
        slidesPerGroup: 6
    },

    1680: {
        slidesPerView: 8.1,
        spaceBetween: 30,
        slidesPerGroup: 7
    },
    1860: {
        slidesPerView: 8.9,
        spaceBetween: 30,
        slidesPerGroup: 7
    },

    1990: {
        slidesPerView: 10,
        spaceBetween: 30,
        slidesPerGroup: 9
    },
    2240: {
        slidesPerView: 12,
        spaceBetween: 30,
        slidesPerGroup: 11
    },

    2560: {
        slidesPerView: 13,
        spaceBetween: 30,
        slidesPerGroup: 13
    }
}

export const MEDIUM_TILE_BREAKPOINTS_WITH_AD = {
    280: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    360: {
        slidesPerView: 2.5,
        spaceBetween: 15,
    },

    460: {
        slidesPerView: 3.5,
        spaceBetween: 15,
    },

    690: {
        slidesPerView: 4.1,
        spaceBetween: 20,
        slidesPerGroup: 3
    },

    760: {
        slidesPerView: 4.6,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    860: {
        slidesPerView: 5.1,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    990: {
        slidesPerView: 5.7,
        spaceBetween: 20,
        slidesPerGroup: 4
    },
    1120: {
        slidesPerView: 6.3,
        spaceBetween: 20,
        slidesPerGroup: 5
    },
    1201: {
        slidesPerView: 4.7,
        spaceBetween: 30,
        slidesPerGroup: 3
    },
    1360: {
        slidesPerView: 5,
        spaceBetween: 30,
        slidesPerGroup: 3
    },

    1480: {
        slidesPerView: 5.5,
        spaceBetween: 30,
        slidesPerGroup: 6
    },
    1860: {
        slidesPerView: 7.9,
        spaceBetween: 30,
        slidesPerGroup: 6
    },

    1990: {
        slidesPerView: 9,
        spaceBetween: 30,
        slidesPerGroup: 8
    },
    2240: {
        slidesPerView: 11,
        spaceBetween: 30,
        slidesPerGroup: 10
    },

    2560: {
        slidesPerView: 12,
        spaceBetween: 30,
        slidesPerGroup: 11
    }
}


export const MOENGAGE_ATTRIBUTE = {
    YES: 'yes',
    NO: 'no',
    TRUE: true,
    FALSE: false,
}

const frenziStarRating = [
    {
        id: 1,
        minVal: 0,
        maxVal: 1.9,
        // icon: FriedIcon,
        title: 'Fried',
        text: "This title Fried you !",
        color: '#FA262E',
    },
    {
        id: 2,
        minVal: 2,
        maxVal: 2.9,
        // icon: FrownIcon,
        title: 'Frown',
        text: "This title Frown you !",
        color: '#F39200',
    },
    {
        id: 3,
        minVal: 3,
        maxVal: 3.9,
        // icon: FairIcon,
        title: 'Fair',
        text: "This title is Fair !",
        color: '#F39200',
    },
    {
        id: 4,
        minVal: 4,
        maxVal: 4.9,
        // icon: FabIcon,
        title: 'Fab',
        text: "This title is Fab !",
        color: '#F39200',
    },
    {
        id: 5,
        minVal: 5,
        maxVal: 5,
        // icon: FrenziedIcon,
        title: 'Frenzied',
        text: "You Frenzied the Title !",
        color: '#1daa10',
    }
]

const clientParams = {
    endPoint: "",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    signatureVersion: 2,
    region: process.env.REACT_APP_AWS_REGION
}

const region = clientParams.region;
const client = new S3Client({
    region,
    credentials: clientParams
});

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

const eventReporting = (category, action, label, val) => {
    ReactGA.event({
        category: category,
        action: action,
        value: val,
        label: label
    });
}

const eventGa4Reporting = (category, action, label, val) => {
    for (const key in label) {
        let eventData = {
            category: category,
            action: key,
            label: label[key]
        }
        if (val || val == 0) {
            eventData.value = val
        }
        ReactGA.event(eventData);
    }

}

const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}
const toDataURL = (src, callback) => {
    var image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataURL = canvas.toDataURL('image/jpeg');
        callback(dataURL);
    };
    image.src = src;
}

const getKeyFileName = (url) => {
    if (url.includes("?")) {
        if (!url.includes("amazonaws.com/frenzi-native/")) {
            return url.substring(
                url.indexOf("amazonaws.com/") + 14,
                url.lastIndexOf("?")
            );
        } else {
            return url.substring(
                url.indexOf("frenzi-native/") + 14,
                url.lastIndexOf("?")
            );
        }
    } else {
        var splitStr = url.substring(url.indexOf('amazonaws.com/') + 14);
        return splitStr;
    }
}

// const downloadBlob = (blob, name, fileLocation = '') => {
//     const blobUrl = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = blobUrl;
//     link.download = name;
//     document.body.appendChild(link);
//     eventReporting('Common', "Downloaded file from s3 bucket -" + name, fileLocation);
//     link.dispatchEvent(
//         new MouseEvent('click', {
//             bubbles: true,
//             cancelable: true,
//             view: window,
//         })
//     );
//     document.body.removeChild(link);
// }

// const getImage = async (url) => {
//     return await fetch(url)
//         .then(response => response.blob())
//         .then(imageBlob => {
//             return URL.createObjectURL(imageBlob);
//         });
// }

const imageExists = (image_url) => {
    try {
        var http = new XMLHttpRequest();
        http.open('HEAD', image_url, false);
        http.send();
        return http.status != 404;
    } catch (e) {
        return false;
    }
}

const getDatePointSUm = (date) => {
    var first = date.split("-")[0];
    var second = date.split("-")[1];
    var third = date.split("-")[2];
    let res = second + " " + first + " " + third;
    return res;
}

const changeInColour = (obj, o) => {
    if (o && o != "") {
        let opacity = o ? o : 0.5;
        let rgba = `rgba(${obj.r},${obj.g},${obj.b},${opacity})`;
        return rgba;
    } else {
        let rgb = `${obj.r},${obj.g},${obj.b}`;
        // return rgba;
        var txt = `linear-gradient(to right, rgba(${rgb}, 1) calc((50vw - 170px) - 340px), rgba(${rgb}, 0.84) 30%, rgba(${rgb}, 0.84) 100%)`
        return txt;
    }
}

const generateRecaptchaToken = (action) => {
    return new Promise((resolve, reject) => {
        if (window) {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js';
            script.async = true;
            script.defer = true;
            script.onload = () => {
                // reCAPTCHA script has loaded, now you can use window.grecaptcha
                console.log("loadeddd--")
                window.grecaptcha.ready(function () {
                    window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY, { action: action })
                        .then(token => resolve(token))
                        .catch(error => reject(error));
                });
            };
            document.head.appendChild(script);


        }
    });
}

const helpers = {
    getPresignedUrl: async function (filePath) {
        var fileLocation = getKeyFileName(filePath);
        if (fileLocation && fileLocation != "") {
            const getObjectParams = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                Key: fileLocation,
                Expires: 3600
            }
            const command = new GetObjectCommand(getObjectParams);
            const url = await getSignedUrl(client, command);
            return url ? url : '';
        }
    },
    getTempPresignedUrl: async function (filePath) {
        var fileLocation = getKeyFileName(filePath);
        if (fileLocation && fileLocation != "") {
            const getObjectParams = {
                Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME,
                Key: fileLocation,
                Expires: 3600
            }
            const command = new GetObjectCommand(getObjectParams);
            const url = await getSignedUrl(client, command);
            if (url) {
                return url
            }
        }
    },
    getCloudFrontSignedUrl: async function (filePath) {
        let keyPairId = "";
        let privateKey = "";
        const cloudFrontSigner = new AWS.CloudFront.Signer(keyPairId, privateKey);
        const options = {
            Url: filePath,
            Expires: 3600
        }
        cloudFrontSigner.getSignedUrl(options, function (data) {
        });
    },

    // downloadFileFromBucket: async function (filePath, filename) {
    //     var fileLocation = getKeyFileName(filePath);
    //     if (fileLocation && fileLocation != "") {
    //         const getObjectParams = {
    //             Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    //             Key: fileLocation
    //         }

    //         const s3 = new AWS.S3({
    //             params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
    //             region: process.env.REACT_APP_AWS_REGION,
    //         })
    //         s3.getObject(getObjectParams, function (error, data) {
    //             if (error) {
    //                 console.log(error, error.stack);
    //             } else {
    //                 let blobData = new Blob([data.Body], { type: data.ContentType });
    //                 var tempExt = data.ContentType;
    //                 var ext = "";
    //                 if (tempExt.includes("audio")) {
    //                     ext = '.mp3';
    //                 } else if (tempExt.includes("video")) {
    //                     ext = '.mp4';
    //                 }
    //                 if (ext != "") {
    //                     let fileName = filename + ext;
    //                     downloadBlob(blobData, fileName, fileLocation);
    //                 } else {
    //                     alert("File not found !!!")
    //                 }
    //             }
    //         });

    //     }
    // },

    // getUploadedPresignedUrl: async function (filePath) {
    //     if (filePath && filePath != "") {
    //         const getObjectParams = {
    //             Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    //             Key: filePath,
    //         }
    //         const command = new PutObjectCommand(getObjectParams);
    //         const url = await getSignedUrl(client, command);
    //         if (url) {
    //             return url
    //         }
    //     }
    // },

    // getUploadedUrl: async function (e, bucket, key) {
    //     var file = e.target.files[0];
    //     var timeStamp = new Date().getTime();
    //     var randomNo = parseInt(Math.random() * 100000);
    //     var keyName = key + timeStamp + "-" + randomNo + "-" + file.name;
    //     const s3 = new AWS.S3({
    //         params: { Bucket: bucket },
    //         region: process.env.REACT_APP_AWS_REGION,
    //     })
    //     const params = {
    //         Key: keyName,
    //         Body: file,
    //     }
    //     s3.upload(params, async function (err, data) {
    //         if (err) {
    //             throw err;
    //         }
    //         return data;
    //     });
    // },

    isEmptyObject: function (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    },

    getUserImage: function (path) {
        if (path.includes("joeschmoe")) {
            if (imageExists(path)) {
                return path;
            } else {
                return PlaceHolder;
            }
        } else {
            if (path.includes("http")) {
                if (path.includes("googleusercontent")) {
                    return path;
                    // return getImage(path);
                } else {
                    return path;
                }
            } else {
                return process.env.REACT_APP_TMDB_BASE_URL + path;
            }
        }
    },
    getFullUrl: function (path, width) {
        if (path.includes("http")) {
            if (path.includes("googleusercontent")) {
                return path;
                // return getImage(path);
            } else {
                if (path.includes("joeschmoe")) {
                    return personPlaceholder;
                } else {
                    if (path.includes("image.tmdb.org")) {
                        let key = path.split('/');
                        let originalKey = key[key.length - 1];
                        if (width) {
                            let endpoint = `https://image.tmdb.org/t/p/w${width}/`;
                            return endpoint + originalKey;
                        } else {
                            return process.env.REACT_APP_TMDB_BASE_URL + originalKey;
                        }
                    } else {
                        return path;
                    }
                }
            }
        } else {
            if (width) {
                let endpoint = `https://image.tmdb.org/t/p/w${width}/`;
                return endpoint + path;
            } else {
                return process.env.REACT_APP_TMDB_BASE_URL + path;
            }
        }
    },
    getFullUrlOriginal: function (path, width) {
        if (path.includes("http")) {
            if (path.includes("googleusercontent")) {
                return path;
                // return getImage(path);
            } else {
                if (path.includes("image.tmdb.org")) {
                    let key = path.split('/');
                    let originalKey = key[key.length - 1];
                    if (width) {
                        let endpoint = `https://image.tmdb.org/t/p/w${width}/`;
                        return endpoint + originalKey;
                    } else {
                        return process.env.REACT_APP_TMDB_BASE_URL_ORIGINAL + originalKey;
                    }
                } else {
                    return path;
                }
            }
        } else {
            if (width) {
                let endpoint = `https://image.tmdb.org/t/p/w${width}/`;
                return endpoint + path;
            } else {
                return process.env.REACT_APP_TMDB_BASE_URL_ORIGINAL + path;
            }
        }
    },
    getBase64Backdrop: function (path) {
        if (path && path != "") {
            return 'data:image/png;base64,' + path;
        }
    },
    toastSuccess: function (mesg, show = true) {
        if (show && !mesg.includes("Watched") && !mesg.includes("Thank")) {
            toast.success((mesg), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                toastId: makeid(4)
            });
        }
    },
    toastError: function (mesg, show = true) {
        if (show && mesg != "Server error !!!" && mesg != "idpiframe_initdialization_failed" && !mesg.includes("unauthorized") && !mesg.includes("Unauthorized") && !mesg.includes("need to login")) {
            toast.error((mesg), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                toastId: makeid(4)
            });
        }
    },
    toastWarning: function (mesg, show = true) {
        if (show) {
            toast.warning((mesg), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                toastId: makeid(4)
            });
        }
    },
    removeDuplicate: function (array) {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    },
    getLanguage: function (code) {
        return og_lang.filter(x => x.code == code) && og_lang.filter(x => x.code == code)[0] && og_lang.filter(x => x.code == code)[0].lang ? og_lang.filter(x => x.code == code)[0].lang : "";
    },
    getDefaultImage: function (type) {
        if (type == "castcrew") {
            return defaultCast;
        } else if (type == "trendingwatchlist") {
            return watchlistImg;
        } else if (type == "userIcon") {
            return UserIcon;
        } else if (type == "profile") {
            return defaultCast;
        } else if (type == "cinescoregenres") {
            return personPlaceholder;
        } else {
            return PlaceHolder;
        }
    },
    getRatingText: function (rating) {
        if (rating == 0.5) {
            return (<span style={{ color: "#EB0B10" }}>Fried</span>)
        } else if (rating == 1) {
            return (<span style={{ color: "#EB0B10" }}>Fried</span>)
        } else if (rating == 1.5) {
            return (<span style={{ color: "#0218EB" }}>Frown</span>)
        } else if (rating == 2) {
            return (<span style={{ color: "#0218EB" }}>Frown</span>)
        } else if (rating == 2.5) {
            return (<span style={{ color: "#31EB65" }}>Fair</span>)
        } else if (rating == 3) {
            return (<span style={{ color: "#31EB65" }}>Fair</span>)
        } else if (rating == 3.5) {
            return (<span style={{ color: "#EBBD1A" }}>Fab</span>)
        } else if (rating == 4) {
            return (<span style={{ color: "#EBBD1A" }}>Fab</span>)
        } else if (rating == 4.5) {
            return (<span style={{ color: "#E94560" }}>Frenzied</span>)
        } else if (rating == 5) {
            return (<span style={{ color: "#E94560" }}>Frenzied</span>)
        } else {
            return "";
        }
    },
    getMovieRunTime: function (val) {
        const hours = Math.floor(val / 60);
        const minutes = val % 60;
        return hours + "h " + minutes + 'm';
    },
    getDaysLeft: function (val) {
        let today = new Date();
        // var cmas = new Date(today.getFullYear(), 11, 25);
        var cmas = new Date(val);
        if (today.getMonth() == 11 && today.getDate() > 25) {
            cmas.setFullYear(cmas.getFullYear() + 1);
        }

        // const total = Date.parse(endtime) - Date.parse(new Date());
        // const seconds = Math.floor((total / 1000) % 60);
        // const minutes = Math.floor((total / 1000 / 60) % 60);
        // const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        // const days = Math.floor(total / (1000 * 60 * 60 * 24));

        var one_day = 1000 * 60 * 60 * 24;
        return Math.ceil((cmas.getTime() - today.getTime()) / (one_day));
    },
    getOttOrInTheatre: function (x) {
        var txt = "";
        if (x.in_theater) {
            txt = 'In theatre';
        } else {
            txt = x.data && x.data[0] && x.data[0].ott_name ? x.data[0].ott_name.length > 12 ? x.data[0].ott_name.substring(0, 12) + "..." : x.data[0].ott_name : '';
        }
        return txt;
    },
    getWatchlistLabel: function (label) {
        let text = label.split('_').join(" ");
        return text.toUpperCase();
    },
    getMemberNumber: function (number) {
        return number;
    },
    getReviewToken: function (rating) {
        if (!isNaN(rating)) {
            let result = '';
            frenziStarRating.map((y, j) => {
                if (y.minVal <= Number(rating) && y.maxVal >= Number(rating)) {
                    result = <p style={{ color: y.color }} className='m-0 fw-600 fs-20'>{y.title}</p>
                }
            })
            return result;
        } else {
            return <p className='m-0 text-green fw-600 fs-20'>Fried</p>
        }
    },
    getSelectedText: function (word, query) {
        if (word && word != '' && word.length > 0) {
            var index = word.indexOf(query);
            return (<>
                {word.substring(0, index)}<span className='text-selected'>{word.substring(index, index + query.length + 1)}</span>{word.substring(index + query.length + 1)}
            </>)
        } else {
            return word;
        }

    },
    getMediaLink: function (data) {
        return `/${data?.media_type == 'tv' ? 'series' : 'movie'}/${data?.unique_title || data?.title.replace(/ /g, "-")}${data?.unique_title ? '' : data.media ? "/" + data.media : data.uuid ? "/" + data.uuid : ''}`;
    },
    getDynamicLink: function (data) {
        if (data.media_type == 'movie' || data.media_type == 'tv') {
            return `/${data?.media_type == 'tv' ? 'series' : 'movie'}/${data?.unique_title || data?.title.replace(/ /g, "-")}${data?.unique_title ? '' : data.media ? "/" + data.media : data.uuid ? "/" + data.uuid : ''}`;
        } else if (data.watchlist_id && data.watchlist_id != "") {
            return `/watchlist-detail/${data.watchlist_id}`;
        } else if (data.person && data.person != "") {
            return `/person/${data?.unique_title || data?.name.replace(/ /g, "-")}${data?.unique_title ? '' : "/" + data.person}`;
        }
    },
    getSearchDynamicLink: function (data) {
        if (data.type == 'media') {
            return `/${data?.media_type == 'tv' ? 'series' : 'movie'}/${data?.unique_title || data?.title.replace(/[ /]/g, "-")}${data?.unique_title ? '' : data.media ? "/" + data.media : data.uuid ? "/" + data.uuid : ''}`;
        } else if (data.type && data.type == "watchlist") {
            return `/watchlist-detail/${data.uuid}`;
        } else if (data.type && data.type == "person") {
            return `/person/${data?.unique_title || data?.name?.replace(/ /g, "-")}${data?.unique_title ? '' : "/" + data.uuid}`;
        }
    },
    getSocialNumbers: function (num, digits = 2) {
        if (!isNaN(num)) {
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "K" },
                { value: 1e6, symbol: "M" },
                { value: 1e9, symbol: "G" },
                { value: 1e12, symbol: "T" },
                { value: 1e15, symbol: "P" },
                { value: 1e18, symbol: "E" }
            ]

            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
            const item = lookup.slice().reverse().find((item) => {
                return num >= item.value
            })
            return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
        } else {
            return 0;
        }
    },
    getLocalStringDate: function (d, seconds = false) {
        var newD = [d.split("/")[1], d.split("/")[0], d.split("/")[2]].join("/");
        let today = new Date(newD);
        let date = today.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
        if (seconds) {
            let time = today.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            return date + " " + time;
        } else {
            let time = today.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', });
            return date + " " + time;
        }
    },
    getLocalIndianStringDateTime: function (newD, seconds = false) {
        let today = new Date(newD);
        let gg = today.toLocaleDateString('en-IN', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
        return gg;
    },
    isQuizWon: function (data) {
        if (data && data.length > 0) {
            let correctArr = [];
            data.map((ques) => {
                let check = ques.options && ques.options.filter(opt => (opt.selected && opt.is_correct));
                if (check && check[0]) {
                    correctArr.push(check[0]);
                }
            })
            if (correctArr.length > 0) {
                return 'you won';
            } else {
                return 'you loose';
            }
        } else {
            return 'you loose';
        }
    },
    isQuizPlayed: function (data) {
        if (data && data.length > 0) {
            let correctArr = [];
            data.map((ques) => {
                if (ques.already_played) {
                    correctArr.push(ques);
                }
            })
            if (correctArr.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    quizEarnedPints: function (data) {
        if (data && data.length > 0) {
            let totalPoints = 0;
            data.map((ques) => {
                let check = ques.options && ques.options.filter(opt => (opt.selected && opt.is_correct));
                if (check && check[0]) {
                    totalPoints += ques.frenzi_points
                }
            })
            return totalPoints;
        } else {
            return 0;
        }
    },
    getApiResult: function (path, method = 'get', payload = {}) {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        // const baseUrl = process.env.REACT_APP_STAGING_BASE_URL;
        var data = JSON.stringify(payload);
        var localData = localStorage.getItem("frenzi_user_access");
        var token = localData && JSON.parse(localData).access;
        if (token && token != '') {
            if (method == 'get') {
                var config = {
                    method: method,
                    url: baseUrl + path,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                };
            } else {
                var config = {
                    method: method,
                    url: baseUrl + path,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    data: data
                };
            }

            return axios(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    getBase64Img: function (file) {
        toDataURL(file, function (dataURL) {
            console.log(dataURL);
            return dataURL;
        })
    },
    getYtThumbnailUrl: function (key) {
        return `https://img.youtube.com/vi/${key}/0.jpg`;
    },
    getYtVideolUrl: function (key) {
        return `https://www.youtube.com/watch?v=${key}`;
    },
    getAverageRGB: function (src, opac) {
        if (src && src != "") {
            var imgEl = new Image();
            imgEl.onload = function () {
            };
            imgEl.src = src;

            var blockSize = 5, // only visit every 5 pixels
                defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
                canvas = document.createElement('canvas'),
                context = canvas.getContext && canvas.getContext('2d'),
                data, width, height,
                i = -4,
                length,
                rgb = { r: 0, g: 0, b: 0 },
                count = 0;
            if (!context) {
                return changeInColour(defaultRGB, opac);
            }
            height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
            width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
            context.drawImage(imgEl, 0, 0);
            try {
                data = context.getImageData(0, 0, width, height);
            } catch (e) {
                console.log(e);
                return changeInColour(defaultRGB, opac);
            }
            length = data.data.length;
            while ((i += blockSize * 4) < length) {
                ++count;
                rgb.r += data.data[i];
                rgb.g += data.data[i + 1];
                rgb.b += data.data[i + 2];
            }
            rgb.r = ~~(rgb.r / count);
            rgb.g = ~~(rgb.g / count);
            rgb.b = ~~(rgb.b / count);
            return changeInColour(rgb, opac);
        }
    },
    changeInColourGradient: function (obj, o) {
        if (o && o != "") {
            let opacity = o ? o : 0.5;
            let rgba = `rgba(${obj.r},${obj.g},${obj.b},${opacity})`;
            return rgba;
        } else {
            let rgb = `${obj.r},${obj.g},${obj.b}`;
            // return rgba;
            var txt = `linear-gradient(to right, rgba(${rgb}, 1) calc((50vw - 170px) - 340px), rgba(${rgb}, 0.84) 30%, rgba(${rgb}, 0.84) 100%)`
            return txt;
        }
    },
    pointSummaryDate: function (date) {
        if (date) {
            let d = new Date(getDatePointSUm(date));
            return d.getDate() + ", " + d.toLocaleString('en-US', { month: 'short' }) + ", " + d.getFullYear().toString().substr(-2);
        }
    },
    isInViewport: function (el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    },
    checkOttAvailableOn: function (data, type) {
        if (data && data.length > 0 && type && type != "") {
            let flag = false;
            data.map((x, i) => {
                if (x.plan_type == type) {
                    flag = true;
                }
            })
            return flag;
        } else {
            return false
        }
    },
    filterActionName: function (item) {
        if (!item.has_requested && !item.is_followed && !item.is_following && !item.is_requested) {
            return "follow";
        } else {
            if (item.is_followed && item.is_following) {
                return "following";
                // return "chat";
            } else {
                if (!item.is_followed && item.is_following) {
                    return "follow back";
                } else if (item.is_followed && !item.is_following) {
                    return "following";
                } else if (item.is_requested) {
                    return "requested";
                } else {
                    return "follow";
                }
            }
        }
    },
    getCorrectDateTime: function (date) {
        if (date) {
            let dateOnly = date.split(" ")[0];
            let timeOnly = date.split(" ")[1];
            if (dateOnly) {
                let datePart = dateOnly.split("-");
                let newDate = datePart[1] + "/" + datePart[0] + "/" + datePart[2];
                let finaldate = newDate + " " + timeOnly;
                return finaldate;
            } else {
                return '';
            }
        } else {
            return '';
        }
    },
    getIndianTimeAgo: function (date) {
        const timeAgo2 = new TimeAgo('en-IN')
        if (date) {
            let dateOnly = date.split(" ")[0];
            let timeOnly = date.split(" ")[1];
            if (dateOnly) {
                let datePart = dateOnly.split("-");
                let newDate = datePart[1] + "/" + datePart[0] + "/" + datePart[2];
                let finaldate = newDate + " " + timeOnly + " UTC";
                return timeAgo2.format(new Date(finaldate));
            } else {
                return '';
            }
        } else {
            return '';
        }
    },
    getAgo: function (date) {
        if (date) {
            let dateOnly = date.split(" ")[0];
            let timeOnly = date.split(" ")[1];
            if (dateOnly) {
                let datePart = dateOnly.split("-");
                let newDate = datePart[1] + "/" + datePart[0] + "/" + datePart[2];
                let finaldate = newDate + " " + timeOnly;
                // return timeAgo.format(new Date(finaldate));
                const utcDate = new Date(finaldate);
                
                let diff = new Date().getTime() - utcDate.getTime();

                let diffSeconds = Math.floor(diff / 1000);
                let diffMinutes = Math.floor(diff / (60 * 1000) % 60);
                let diffHours = Math.floor(diff / (60 * 60 * 1000) % 24);
                let diffDays = Math.floor(diff / (24 * 60 * 60 * 1000));

                let time = '';
                if (diffDays > 0) {
                    if (diffDays == 1) {
                        time = diffDays + " day ago";
                    } else {
                        time = diffDays + " days ago";
                    }
                } else {
                    if (diffHours > 0) {
                        if (diffHours == 1) {
                            time = diffHours + " hr ago";
                        } else {
                            time = diffHours + " hrs ago";
                        }
                    } else {
                        if (diffMinutes > 0) {
                            if (diffMinutes == 1) {
                                time = diffMinutes + " min ago";
                            } else {
                                time = diffMinutes + " mins ago";
                            }
                        } else {
                            if (diffSeconds > 0) {
                                time = "just now";
                            }
                        }

                    }

                }
                return time;
            } else {
                return '';
            }
        } else {
            return '';
        }
    },
    isTodaysData: function (date) {
        if (date) {
            let dateOnly = date.split(" ")[0];
            let todayDate = new Date();
            let timeOnly = date.split(" ")[1];
            if (dateOnly) {
                let datePart = dateOnly.split("-");
                let newDate = datePart[1] + "-" + datePart[0] + "-" + datePart[2];
                let finaldate = newDate + " " + timeOnly;
                let othersData = new Date(finaldate);
                var diff = othersData.getTime() - todayDate.getTime();
                var hours = Math.floor(diff / 1000 / 60 / 60);
                const diffTime = Math.abs(othersData - todayDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if (hours < 24 && othersData.getMonth() === todayDate.getMonth() && diffDays < 2) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    getNotificationType: function (data) {
        let type = data?.notification_type;
        switch (type) {
            case "watching_now":
                return "is watching " + data?.title;
                break;
            case "like_media":
                return "recommends " + data?.title;
                break;
            case "rate_media":
                return "gave " + data?.ratings + " star rating to " + data?.title
                break;
            case "review":
                return "posted a review on " + data?.title
                break;
            case "av_review":
                if (data?.comment_type.includes("audio")) {
                    return "posted an audio review on " + data?.title
                } else if (data?.comment_type.includes("video")) {
                    return "posted a video review on " + data?.title
                }
                break;
            case "review_request":
                return "has requested a review on " + data?.title
                break;

            case "like_review":
                return "liked your review on " + data?.title
                break;
            case "follow":
                return "started following you"
                break;
            case "follow_request":
                return "has sent a request to follow you"
                break;
            case "follow_request_accept":
                return "accepted your request to follow"
                break;
            case "feed_comment":
                if (data?.title != null && data?.title != '') {
                    return "commented on your post on " + data?.title
                } else {
                    return "commented on your post"
                }
                break;
            case "feed_reply":
                if (data?.title != null && data?.title != '') {
                    return "replied on your comment on " + data?.title
                } else {
                    return "replied on your comment"
                }
                break;
            case "feed_comment_like":
                if (data?.title != null && data?.title != '') {
                    return "liked your comment on " + data?.title
                } else {
                    return "liked your comment"
                }
                break;
            case "feed_reply_like":
                if (data?.title != null && data?.title != '') {
                    return "liked your reply on " + data?.title
                } else {
                    return "liked your reply"
                }
                break;
            case "feed_post_like":
                if (data?.title != null && data?.title != '') {
                    return "liked your post on " + data?.title
                } else {
                    return "liked your post"
                }
                break;
            case "notify_me":
                // holder.txt_notification.setText(model.getTitle());
                return "is now streaming"
                break;
            case "like_recommendation":
                return "liked your recommendation of " + data?.title
                break;
            case "add_recommendation":
                return "has given a recommendation on ", data?.feed_string
                break;
            case "referral_bonus":
                // holder.txt_notification.setText("Congratulations");
                return "you have earned " + data?.points + " points for referring " + data?.username;
                break;
            case "share_trailer":
                return "has posted a Trailer of " + data?.title
                break;
            case "respond_review":
                return "has posted a review for  on your request" + data?.title
                break;

            case "shared_review":
                return "has shared your review on " + data?.title
                break;
            case "bonus":
                // holder.txt_notification.setText("Congratulations");
                return "you have won 100 Frenzi points bonus."
                break;
            case "dislike_review":
                return "has disliked your review on " + data?.title
                break;
            case "promotion":
                // holder.txt_notification.setText("Congratulations");
                switch (data?.more_data) {
                    case "s_frexpert":
                        return "you have become a Frexpert – Silver Member";
                        break;
                    case "p_frexpert":
                        return "you have become a Frexpert – Platinum Member";
                        break;
                    case "g_frexpert":
                        return "you have become a Frexpert – Gold Member";
                        break;
                }
                break;

            case "birthday":
            case "review_reject":
            case "push_post":
            case "flagged_review":
            case "review_deleted":
                // holder.txt_notification.setVisibility(View.GONE);
                return data?.more_data;
                break;
            case "contributor_request":
                return "has sent a request to contribute to your watchlist ", data?.watchlist_name;
                break;
            case "watchlist_media":
                return "added " + data?.title + " to " + data?.watchlist_name;
                break;
            case "suggest_title":
                return "has suggested a title : " + data?.title
                break;
            case "suggest_accept":
                return "has accepted your suggestion";
                break;
            case "watchlist_request":
                return "has requested to join your watchlist " + data?.watchlist_name;
                break;
            case "watchlist_request_accept":
                return "has accepted your request to join the watchlist " + data?.watchlist_name;
                break;
            case "watchlist_invite":
                return "has invited you to join the watchlist " + data?.watchlist_name;
                break;
            case "watchlist_join":
                return "joined your watchlist: " + data?.watchlist_name;
                break;
            case "review_approve":
                // holder.txt_notification.setVisibility(View.GONE);
                return "your review on  has been approved" + data?.title
                break;
            case "report_error":
                return data?.more_data
                break;
            default:
                return data?.notification_type.replace('_', ' ');
        }
    },
    getNotificationTitle: function (data) {
        let type = data?.notification_type;
        switch (type) {
            case "notify_me":
                return data?.title;
                break;
            case "referral_bonus":
                return 'Congratulations';
                break;
            case "bonus":
                return 'Congratulations';
                break;
            case "promotion":
                return 'Congratulations';
                break;
            case "birthday":
            case "review_reject":
            case "push_post":
            case "flagged_review":
            case "review_deleted":
            case "report_error":
                return '';
                break;
            case "review_approve":
                return ''
                break;
            default:
                return data?.username;
        }
    },
    trackMoengageEvent: async function (eventName, data) {
        await window.Moengage.track_event(eventName, { ...data, data_source: 'web' });
        eventGa4Reporting(eventName, eventName, data);
        return true;
    },
    watchlistClick: async function (title, id, refPage) {
        const data = {
            watchlist_name: title,
            watchlist_id: id,
            ref_page: refPage,
            data_source: 'web'
        }
        await window.Moengage.track_event('FR3_Watchlist_Click', data);
        eventGa4Reporting('FR3_Watchlist_Click', 'WatchlistViewed', data);
        eventReporting('FR3_Watchlist_Click', 'WatchlistViewed', id);
    },
    mediaClick: async function (title, id, refPage) {
        const data = {
            title: title,
            media: id,
            ref_page: refPage,
            data_source: 'web'
        }
        await window.Moengage.track_event('FR3_Searches', data);
        eventGa4Reporting('FR3_Searches', 'MediaViewed', data);
        eventReporting('FR3_Searches', 'MediaViewed', id);
    },
    getMeta: function (metaName) {
        const metas = document.getElementsByTagName('meta');
        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === metaName) {
                return metas[i].getAttribute('content');
            }
        }
        return '';
    },
    setMeta: function (metaName, value = '') {
        if (value && value != '') {
            const metas = document.getElementsByTagName('meta');
            for (let i = 0; i < metas.length; i++) {
                if (metas[i].getAttribute('name') === metaName) {
                    metas[i].setAttribute('content', value);
                    return true;
                }
            }
        }
        return true;
    },
    setUpFaceRating: function (rating, isFriendsAvailable, rat_text = 0, media_type = 'movie') {
        let rate_status = '';
        if (isFriendsAvailable) {
            if (rating > 0.0 && rating <= 1) {
                if (rat_text == 0) {
                    rate_status = " were totally fried watching this ";
                } else {
                    rate_status = "+" + rat_text + " others were totally fried watching this ";
                }
            } else if (rating > 1 && rating <= 2) {
                if (rat_text == 0) {
                    rate_status = " did not find this interesting ";
                } else {
                    rate_status = "+" + rat_text + " others did not find this interesting ";
                }
            } else if (rating > 2 && rating <= 2.5) {
                if (rat_text == 0) {
                    rate_status = " found this to below average ";
                } else {
                    rate_status = "+" + rat_text + " others found this to below average ";
                }
            } else if (rating > 2.5 && rating <= 3) {
                if (rat_text == 0) {
                    rate_status = " found this to be an average watch ";
                } else {
                    rate_status = "+" + rat_text + " found this to be an average watch ";
                }
            } else if (rating > 3 && rating <= 3.5) {
                if (rat_text == 0) {
                    rate_status = " found this to be a good watch ";
                } else {
                    rate_status = "+" + rat_text + " found this to be a good watch ";
                }

            } else if (rating > 3.5 && rating <= 4) {
                if (rat_text == 0) {
                    rate_status = " found this to be a great  watch";
                } else {
                    rate_status = "+" + rat_text + " found this to be a great watch";
                }
            } else if (rating > 4 && rating <= 4.5) {
                if (rat_text == 0) {
                    rate_status = " found this to be fabulous ";
                } else {
                    rate_status = "+" + rat_text + " found this to be fabulous ";
                }
            } else if (rating > 4.5 && rating <= 5) {
                if (rat_text == 0) {
                    rate_status = " were left totally frenzied ";
                } else {
                    rate_status = "+" + rat_text + " were left totally frenzied ";
                }
            } else {
                rate_status = `confused if this ${media_type == "movie" ? "movie" : "series"} is worth watching?`;
            }
        } else {
            rate_status = "add your friends to view their ratings.";
        }
        return rate_status;
    },
    convertMinutesToDuration: function (minutes) {
        let hours = Math.floor(minutes / 60);
        let remainingMinutes = minutes % 60;

        return `PT${hours}H${remainingMinutes}M`;
    },
    isValidJSON: function (jsonString) {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    },
    getQueryParams: function (url) {
        if (url) {
            let firstSplit = url.split("&");
            if (firstSplit.length > 1) {
                let obj = {};
                firstSplit.map((x) => {
                    let key = x.split('=')[0];
                    let val = x.split('=')[1];
                    obj = {
                        ...obj,
                        [key]: val
                    }
                })
                return obj;
            } else {
                let key = url.split('=')[0];
                let val = url.split('=')[1];
                return {
                    [key]: val
                }
            }
        } else {
            return null;
        }
    },
    _base64ToArrayBuffer: async function (base64) {
        try {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = await new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            // return bytes.buffer;
            return bytes;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
    getCaptchaToken: async function (id = 'recaptchaToken') {
        let _token = '';
        // let token = await generateRecaptchaToken('submit');
        // console.log("token---", token);
        // window.generateNewToken();
        try {
            _token = document.getElementById(id).value;
        } catch (error) {
            console.error(error);
        }
        // console.log("_token--",_token)
        return _token;
    }

}

export default helpers;