import { configureStore } from '@reduxjs/toolkit';
import cauthReducer from '../slices/auth';
import commonReducer from '../slices/common';
import mediaReducer from '../slices/media';
import castReducer from '../slices/cast';
import trendingReducer from '../slices/trending';
import activityReducer from '../slices/activity';
import cinescoreReducer from '../slices/cinescore';
import watchlistReducer from '../slices/watchlist';
import globalReducer from '../slices/global';
import freeReducer from '../slices/free';
import searchReducer from '../slices/search';
import settingsReducer from '../slices/settings';
import connectReducer from '../slices/connect';
import publicReducer from '../slices/public';
import clipsReducer from '../slices/clips';
import partnerReducer from '../slices/partner';
import blogReducer from '../slices/blog';

const reducer = {
  auth: cauthReducer,
  common: commonReducer,
  media: mediaReducer,
  cast: castReducer,
  trending: trendingReducer,
  activity: activityReducer,
  cinescore: cinescoreReducer,
  watchlist: watchlistReducer,
  global: globalReducer,
  free: freeReducer,
  search: searchReducer,
  settings: settingsReducer,
  connect: connectReducer,
  public: publicReducer,
  clips: clipsReducer,
  partner: partnerReducer,
  blog: blogReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export default store;

