import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import React, { useRef, useEffect, useState } from "react";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../../services/helpers";
// import EditProfileIcon from "../../../assets/icons/EditProfileIcon.svg";
// import ProfileCornerImg from "../../../assets/icons/profileCorner.svg";
import AWS from "aws-sdk";
import { useDispatch } from "react-redux";
import { updateUserDetails, updateUserName } from "../../../slices/settings";
import "../profile.scss";
import { Link } from "react-router-dom";

const EditProfileIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/EditProfileIcon.svg";
const ProfileCornerImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/profileCorner.svg";

export default function MyProfile(props) {
  const { profileUserDetails, cinescoreData } = props;
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [userName, setUserName] = useState("");
  const [uniqueName, setUniqueName] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    if (profileUserDetails?.uuid) {
      setUserName(profileUserDetails?.username);
      setUniqueName(profileUserDetails?.unique_name);
      setBio(profileUserDetails?.bio);
    }
  }, [profileUserDetails]);

  // const handleImageChange_old = (event) => {
  //   let base64String = "";
  //   var file = document.querySelector("input[type=file]")["files"][0];
  //   if (!file) {
  //     helpers.toastError("File not found !!!");
  //     return;
  //   }
  //   var reader = new FileReader();
  //   reader.onload = function () {
  //     base64String = reader.result.replace(
  //       /^data:image\/(png|jpg);base64,/,
  //       ""
  //     );
  //     updateProfileImgData(base64String);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    var timeStamp = new Date().getTime();
    var randomNo = parseInt(Math.random() * 100000);
    var keyName = "users/image/" + timeStamp + "-" + randomNo + "-" + file.name.replace(/-/g, " ");
    handleUpload(file, keyName);
  }

  const handleUpload = async (file, keyName) => {
    var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
    const s3 = new AWS.S3({
      params: { Bucket: bucket },
      region: process.env.REACT_APP_AWS_REGION,
    });
    const params = {
      Key: keyName,
      Body: file,
    };
    s3.upload(params, async function (err, data) {
      if (err) {
        console.log(err);
        helpers.toastError("something went wrong !!!");
        // helpers.toastError(JSON.stringify(err));
        return err;
      }
      if (data && data.Location) {
        updateProfileImgData(data.Location);
      }
    });
  };

  const updateProfileImgData = (imgData) => {
    const body = {
      profile_image: imgData,
    };
    helpers.trackMoengageEvent('FR3_ProSettings', {
      save_click: MOENGAGE_ATTRIBUTE.YES,
      user_upload_photo: imgData
    });
    dispatch(updateUserDetails({ body: body }));
  };

  const saveUserData = () => {
    updateUniqueName();

    const body = {
      username: userName,
      bio: bio,
    };
    helpers.trackMoengageEvent('FR3_AccSettings', {
      change_user_name_submit: MOENGAGE_ATTRIBUTE.YES,
      username: userName
    });
    dispatch(updateUserDetails({ body: body }));
    updateUniqueName();
  };

  const updateUniqueName = () => {
    dispatch(updateUserName({ body: { unique_name: uniqueName } }));
  };

  return (
    <Grid
      xs={12}
      sx={{
        paddingBottom: "20px",
        marginTop: { xs: "60px", sm: "40px", md: "20px" },
      }}
      className="section-border-bottom ml-30"
    >
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={4.5} className="br-8 ">
          <Grid
            container
            className="m-0 h-100 jc-center relative br-8  bg-gunpowder"
          >
            <Box
              className="user-profile-img-container"
              sx={{ top: { xs: "-50px", sm: "-70px", md: "-80px" } }}
            >
              <Box
                className="relative w-100"
                sx={{ maxWidth: { xs: 80, sm: 120, md: 140 } }}
              >
                <Box
                  component="img"
                  className="user-profile-img"
                  sx={{
                    height: "100vh",
                    width: "100vw",
                    maxWidth: { xs: 80, sm: 120, md: 140 },
                    maxHeight: { xs: 80, sm: 120, md: 140 },
                  }}
                  alt="profilepic"
                  src={
                    profileUserDetails.profile_image
                      ? helpers.getFullUrl(profileUserDetails.profile_image)
                      : helpers.getDefaultImage("user")
                  }
                />
                {isEdit ? (
                  <>
                    <Box
                      component="img"
                      className="edit-icon"
                      sx={{
                        width: { xs: 20, sm: 24, md: 32 },
                        height: { xs: 20, sm: 24, md: 32 },
                      }}
                      onClick={() => inputRef.current.click()}
                      alt="edit"
                      src={EditProfileIcon}
                    />
                    <input
                      style={{ display: "none" }}
                      ref={inputRef}
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                      accept="image/*"
                    />
                  </>
                ) : (
                  <Box
                    component="img"
                    className="edit-icon"
                    sx={{
                      width: { xs: 20, sm: 24, md: 32 },
                      height: { xs: 20, sm: 24, md: 32 },
                    }}
                    onClick={() => setIsEdit(true)}
                    alt="edit"
                    src={EditProfileIcon}
                  />
                )}
              </Box>
            </Box>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: { xs: "50px", sm: "60px", md: "72px", lg: "70px" },
              }}
            >
              <Grid container className="jc-center">
                <Grid item>
                  <Typography className="fs-24 fw-700 text-center lh-24">
                    {profileUserDetails?.username}
                  </Typography>
                  <Typography className="fs-16 fw-400 text-center  text-muted pt-5">
                    {profileUserDetails?.unique_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="w-100">
                <Grid item xs={12}>
                  <Typography className="fs-20 fw-400 text-center lh-22 p-25 text-muted">
                    {profileUserDetails?.bio}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isEdit ? (
          <Grid item xs={12} sm={7.5}>
            <Grid
              container
              style={{ paddingBottom: 20 }}
              spacing={2.5}
              className="jc-sb w-100 m-0"
            >
              <Grid item xs={3.75} className="bg-gunpowder hover-link br-8 p-17">
                <Link onClick={() => {
                  helpers.trackMoengageEvent('FR3_My_Profile', {
                    followers_click: MOENGAGE_ATTRIBUTE.YES
                  });
                }} to="/followers">
                  <Typography className="fs-32 fw-700 text-center lh-30 ">
                    {helpers.getSocialNumbers(profileUserDetails?.followers)}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center  text-muted lh-16">
                    Followers
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={3.75} className="bg-gunpowder hover-link br-8 p-17">
                <Link onClick={() => {
                  helpers.trackMoengageEvent('FR3_My_Profile', {
                    following_click: MOENGAGE_ATTRIBUTE.YES
                  });
                }} to="/following">
                  <Typography className="fs-32 fw-700 text-center lh-30">
                    {helpers.getSocialNumbers(profileUserDetails?.following)}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center  text-muted lh-16">
                    Following
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={3.75} className="bg-gunpowder hover-link br-8 p-17">
                <Link to="/points">
                  <Typography className="fs-32 fw-700 text-center lh-30">
                    {helpers.getSocialNumbers(
                      profileUserDetails?.frenzi_points
                    )}
                  </Typography>
                  <Typography className="fs-16 fw-300 text-center  text-muted lh-16">
                    Frenzi Point
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2.5}
              className="m-0 w-100"
              justifyContent={"space-between"}
            >
              <Grid item xs={5.8} className="bg-gunpowder br-8 p-10">
                <Grid container flexWrap={'nowrap'} className="jc-center h-100 ai-center">
                  <Grid item xs={2.9} className='hover-link'>
                    <Link to="/cinescore/movies">
                      <Typography className="fs-32 fw-700 text-center">
                        {helpers.getSocialNumbers(
                          cinescoreData?.total_watched_movie
                        )}
                      </Typography>
                    </Link>
                    <Link to="/cinescore/movies">
                      <Typography className="fs-16 fw-300 text-center text-muted lh-18 ">
                        movies watched
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Grid container justifyContent={"center"}>
                      <Grid
                        item
                        sx={{
                          borderLeft: "1px solid white",
                          maxHeight: { xs: 30, sm: 60 },
                          height: 100,
                        }}
                      ></Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3.6} className='hover-link'>
                    <Link to="/cinescore/webseries">
                      <Typography className="fs-32 fw-700 text-center">
                        {helpers.getSocialNumbers(
                          cinescoreData?.total_watched_tv
                        )}
                      </Typography>
                    </Link>
                    <Link to="/cinescore/webseries">
                      <Typography className="fs-16 fw-300 text-center text-muted lh-18">
                        web series watched
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
                {/* <Grid container justifyContent={"center"} className="h-50">
                  <Grid
                    item
                    xs={11}
                    sm={10}
                    md={11}
                    sx={{ position: "relative" }}
                  >
                    <Link to="/cinescore/movies">
                      <Button
                        className="rounded-btn w-90 bg-light text-selected ml-10 mh-40 h-100"
                        sx={{ position: "absolute", bottom: 0 }}
                      >
                        view more
                      </Button>
                    </Link>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid item xs={5.8} className="bg-secondary br-8">
                <Grid container className="pb-20">
                  <Typography
                    className="fw-700 fs-20 m-0"
                    sx={{
                      maxWidth: { xs: 115, sm: 150, md: 175, lg: 230 },
                    }}
                  >
                    Do you know which type of movie person you are?
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item xs={6.3}>
                    <p className="fw-500 fs-12 m-0  text-muted">
                      Unlock by rating 10 more movies
                    </p>
                  </Grid>

                  <Box
                    component="img"
                    sx={{
                      maxHeight: { xs: 68, sm: 87 },
                      maxWidth: { xs: 45, sm: 80 },
                    }}
                    alt=""
                    src={ProfileCornerImg}
                  ></Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={7.5}>
            <Grid
              container
              spacing={2.5}
              justifyContent={"space-between"}
              className="mt-0"
            >
              <Grid
                item
                xs={12}
                sm={6}
                className="pt-0 pb-0"
                sx={{ marginBottom: { xs: "20px", sm: "0px" } }}
              >
                <TextField
                  value={uniqueName}
                  // onBlur={() => updateUniqueName()}
                  onChange={(e) => setUniqueName(e.target.value)}
                  className="w-100"
                  label="Username"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pt-0">
                <TextField
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="w-100"
                  label="Full name"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "20px" }}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={10}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="write your bio"
                style={{
                  width: "100%",
                  maxWidth: "calc(100%)",
                  maxHeight: "138px",
                  height: "100%",
                  borderRadius: "8px",
                  paddingBottom: "20px",
                  padding: 10,
                  background: "transparent",
                  color: "white",
                }}
              />
            </Grid>
            <Grid container spacing={2.5} justifyContent={"space-between"}>
              <Grid item sm={6}>
                <Button
                  onClick={() => setIsEdit(false)}
                  className="rounded-btn w-100 bg-light text-selected mt-10 "
                >
                  cancel
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  onClick={() => saveUserData()}
                  className="rounded-btn w-100 bg-selected text-light mt-10 "
                >
                  save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
