import { CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useSelector } from 'react-redux';
import MovieTile from '../common/MovieTile';
import SideFilter from './SideFilter';

export default function AdvancedSearch(props) {
    const search = useSelector(state => state.search);

    return (
        <div className=''>
            <div className='search-box-container pt-20 pb-15'>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs={10}>
                        <Grid container alignItems={'center'}>
                            <ArrowBackRoundedIcon onClick={() => props.onClose()} className='pointer mr-10 mt-7' />
                            <Typography className='fw-700 fs-28 lh-28'>advanced search</Typography>
                        </Grid>
                    </Grid>

                    <SideFilter
                        onSearchApply={(e) => props.handleApplyFilter(e)}
                        tempFilter={props?.tempFilter}
                    />
                </Grid>
            </div>
            <Grid container spacing={{ xs: 2, sm: 2, md: 2, lg: 3 }} className="mt-0 pb-50">
                {search.advancedSearchData && search.advancedSearchData.length > 0 && search.advancedSearchData.map((x, i) => {
                    return <Grid className='search-advance-items' item xs={12} sm={6} md={4} key={i}>
                        {/* <MediumTile x={x} /> */}
                        <MovieTile x={x} refPage={'Search'} isBookmark={true} hideAction={true} />
                    </Grid>
                })}
            </Grid>
            {props.isLoading &&
                <Grid container justifyContent={'center'} style={{ paddingBottom: '50px' }}>
                    <CircularProgress />
                </Grid>
            }
        </div>
    )
}
