import React, { useState } from 'react'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { Box, Button, Modal } from "@mui/material";
import FinalReview from '../component/review/FinalReview';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoginModal } from '../../slices/global';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: '100%', sm: '70%', md: '47%', lg: '50%' },
    bgcolor: "#16213E",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    maxHeight: "90vh",
    overflowY: "auto",
    zIndex: 999,
};

export default function ReveiwMovie(props) {
    const { data, showLabel, size, styling, isBtn, btnText } = props;
    const [reviewedPopup, setReviewedPopup] = useState(false);
    const _global = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const handleClose = () => {
        setReviewedPopup(false);
    };

    const handleOpen = () => {
        if (_global.is_public) {
            dispatch(toggleLoginModal({ isOpen: true }));
        } else {
            setReviewedPopup(true);
        }
    };

    return (
        <>
            {isBtn ?
                <Button onClick={() => handleOpen()} className="rounded-btn bg-light fw-500 fs-12 lh-14 text-selected mt-30">{btnText ? btnText : 'create a review'}</Button>
                :
                <>
                    <RateReviewOutlinedIcon
                        onClick={() => handleOpen()} className={`pointer ${styling} fs-${size ? size : 36} ${data?.is_review_added ? 'text-muted' : 'text-light'}`}
                    />
                    {showLabel ?
                        <span onClick={() => handleOpen()} className='d-block text-center pointer fs-14 fw-600'>Review</span>
                        :
                        ''
                    }
                </>
            }
            <Modal
                open={reviewedPopup}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FinalReview handleClose={handleClose} data={data} />
                </Box>
            </Modal>
        </>
    )
}
