import Box from '@mui/system/Box';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import Footer from '../views/common/Footer';
import MainNavBar from '../views/component/MainNavBar';
// import { togglePartnerJourneyModal } from '../slices/global';
import { Outlet } from 'react-router-dom';
// import helpers, { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '../services/helpers';
import { clearMessages } from '../slices/settings';
import { openMobileVerify } from '../slices/global';
import MobileVerify from '../views/common/MobileVerify';
import OldFooter from '../views/common/OldFooter';
import Footer from '../views/common/Footer';


export default function PrivateLayout({ hideFooter }) {
    const auth = useSelector(state => state.auth);
    // const [isRender, setIsRender] = useState(false);
    const dispatch = useDispatch();
    // const params = useParams();
    const settings = useSelector(state => state.settings);
    const _global = useSelector(state => state.global);


    useEffect(() => {
        if (settings.errMesg && settings.errMesg == "mobile not verified") {
            dispatch(openMobileVerify(true));
            dispatch(clearMessages());
        }
    }, [settings])

    // useEffect(() => {
    //     if (params?.redirectlink && params?.redirectlink != '') {
    //         dynamicLinks.getInitialLink().then((link) => {
    //             if (link) {
    //                 // Handle the dynamic link data
    //                 console.log(link.url);
    //                 debugger
    //             }
    //         });
    //     }
    // }, [params?.redirectlink])

    // useEffect(() => {
    //     if (title && title != '') {
    //         document.title = `${title}${params?.name ? ' - ' + params?.name.replace(/-/g, " ") : ''}`;
    //     } else {
    //         document.title = DEFAULT_TITLE;
    //         helpers.setMeta('description', DEFAULT_DESCRIPTION);
    //     }
    // }, [title, params?.name])

    useEffect(() => {
        let data = localStorage.getItem('frenzi_user_access');
        if (data) {
            data = JSON.parse(data);
            if (data && data.access && data.access != "" && auth.token && auth.token != "" && auth.token == data.access) {
                // setIsRender(true);
            } else {
                window.location.href = "/login";
            }
        } else {
            window.location.href = "/login";
        }
    }, []);

    // const handleClose2 = () => {
    //     dispatch(togglePartnerJourneyModal(false));
    //     localStorage.setItem("is_partner_journey_modal_shown", true);
    // }

    // const handleClick = () => {
    //     let element = document.getElementById('others-open');
    //     if (element) {
    //         element.click();
    //     } else {
    //         navigate("/?others=true");
    //     }
    //     dispatch(togglePartnerJourneyModal(false));
    // }

    return (
        <>
            <MainNavBar />
            <main className='fixed-full-view'
            // style={{ backgroundImage: `url(${FullBgImg})` }}
            >
                <Box className='application-container h-100'
                    // sx={{
                    // marginTop: { xs: '47px', sm: '54px', md: '60px', lg: '67px' },
                    // paddingBottom: { xs: '60px', sm: '10px', md: '10px' }, 
                    // minHeight: '100vh'
                    // }}
                    style={{
                        minHeight: '100vh'
                    }}
                >
                    {/* {children} */}
                    <Outlet />
                </Box>
                {!hideFooter &&
                    <Footer />
                }
            </main>
            {_global.mobileVerify ?
                <MobileVerify
                    open={_global.mobileVerify}
                    handleClose={() => dispatch(openMobileVerify(false))}
                />
                : ''
            }
            {/* <PartnerLearningModal handleClick={handleClick} open={_global.isPartnerJourneyModal} isModal={true} handleClose={handleClose2} /> */}
        </>
    )
}
