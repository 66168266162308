import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "./auth.scss";
import MenuItem from "@mui/material/MenuItem";
import { userLogin } from "../../slices/auth";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { countries } from "country-list-json";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { KMSClient, EncryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from "buffer";
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { toggleSignUpModal } from "../../slices/global";

const labelProps = { inputProps: { 'aria-label': 'Color switch demo' } };

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const defaultCountry = {
  name: "India",
  dial_code: "+91",
  code: "IN",
  flag: "🇮🇳",
};

const clientParams = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};

const config = {
  credentials: clientParams,
  endPoint: "",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  signatureVersion: 2,
  region: process.env.REACT_APP_AWS_REGION,
};

const client = new KMSClient(config);

function PhoneLogin(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [currentLoading, setCurrentLoading] = useState(false);
  const [isPhone, setIsPhone] = useState(true);
  const [countryCode, setCountryCode] = useState(defaultCountry);
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);

  const handleLogin = async () => {
    setCurrentLoading(true);
    if (isPhone) {
      if (
        countryCode.dial_code &&
        countryCode.dial_code !== "" &&
        phoneNumber &&
        phoneNumber !== "" &&
        phoneNumber.length === 10
      ) {
        const newNum = countryCode.dial_code + phoneNumber;
        props.setMobile(phoneNumber);
        props.setLoginType('mobile');
        performFormSubmitProcess({ mobile: newNum });
        await helpers.trackMoengageEvent('FR3_Login', { mobile_click: MOENGAGE_ATTRIBUTE.YES });
        // dispatch(userLogin({ mobile: newNum }));
      } else {
        if (phoneNumber.length > 0) {
          helpers.toastError("phone number is not valid");
          setCurrentLoading(false);
        } else {
          helpers.toastError("please enter phone number");
          setCurrentLoading(false);
        }
        await helpers.trackMoengageEvent('FR3_Login', { continue_click: MOENGAGE_ATTRIBUTE.YES });
      }
    } else {
      if (email && email !== "") {
        props.setMobile(email);
        props.setLoginType('email');
        performFormSubmitProcess({ email: email });
        await helpers.trackMoengageEvent('FR3_Login', { mobile_click: MOENGAGE_ATTRIBUTE.NO });
      } else {
        if (email !== "") {
          helpers.toastError("email is not valid");
          setCurrentLoading(false);
        } else {
          helpers.toastError("please enter email address");
          setCurrentLoading(false);
        }
        await helpers.trackMoengageEvent('FR3_Login', { mobile_click: MOENGAGE_ATTRIBUTE.NO });
      }
    }

  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleChange = (value) => {
    setCountryCode(value);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const performFormSubmitProcess = async (tempForm) => {
    const encoder = new TextEncoder();
    var unit8String = encoder.encode(JSON.stringify(tempForm));
    const input = {
      KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
      Plaintext: unit8String,
    };
    try {
      const command = new EncryptCommand(input);
      const response = await client.send(command);
      // process data.
      if (response && response.CiphertextBlob) {
        let buff = new Buffer(response.CiphertextBlob);
        let base64data = buff.toString("base64");
        dispatch(userLogin({ data: base64data })).then(() => {
          setCurrentLoading(false);
        });
      } else {
        helpers.toastError("Data encryptiton failed !!!");
      }
    } catch (error) {
      // error handling.\
      helpers.toastError(error);
    }
  };

  // function _base64ToArrayBuffer(base64) {
  //   var binary_string = window.atob(base64);
  //   var len = binary_string.length;
  //   var bytes = new Uint8Array(len);
  //   for (var i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   // return bytes.buffer;
  //   return bytes;
  // }

  // const convertAgain = async (responseText) => {
  //   var cipherText = _base64ToArrayBuffer(responseText);
  //   const input = {
  //     KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
  //     CiphertextBlob: cipherText,
  //   };
  //   try {
  //     const command = new DecryptCommand(input);
  //     const response = await client.send(command);
  //     // process data.
  //     if (response && response.Plaintext) {
  //       let win1251decoder = new TextDecoder("windows-1251");
  //       let bytes = new Uint8Array(response.Plaintext);
  //       var decryptedText = win1251decoder.decode(bytes);
  //       var jsonData = JSON.parse(decryptedText);
  //       if (jsonData && !helpers.isEmptyObject(jsonData)) {
  //         // setLoader(false);
  //         setTimeout(() => {
  //           localStorage.setItem(
  //             "frenzi_user_access",
  //             JSON.stringify(jsonData)
  //           );
  //           // dispatch(setRegistrationToken(jsonData.access));
  //           let redirectUri = localStorage.getItem("redirect_url");
  //           if (redirectUri && redirectUri !== '') {
  //             window.location.href = redirectUri;
  //           } else {
  //             window.location.href = "/";
  //           }
  //         }, 2000);
  //       }
  //     } else {
  //       helpers.toastError("Data decryption failed !!!");
  //     }
  //   } catch (error) {
  //     // error handling.\
  //     helpers.toastError(error);
  //   }
  // };

  return (
    <Paper elevation={10} className="bg-secondary p-40" sx={{ overflow: 'hidden' }}>
      <Grid align="center">
        {/* <Typography variant="h3" className="fs-28 lh-40 fw-800">
          <b>welcome back to Frenzi your best binge buddy</b>
        </Typography> */}
        <p style={{ maxWidth: '90%' }} className="fs-32 fw-500 m-0">
          <span className="fw-800">welcome back</span> to Frenzi your best binge
          buddy
        </p>
        <Typography
          variant="subtitle1"
          className="mt-8 ls-1 text-muted fs-14 lh-16 fw-500"
        >
          let us help choose what's best for you
        </Typography>
        <Box
          className="mt-40 mw-400"
          // sx={{
          //   "& > :not(style)": { width: "44ch" },
          // }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={1} className="mt-28">
            <Grid item xs={12}>
              <Grid container>
                <FormControlLabel control={<PinkSwitch {...labelProps} checked={isPhone} />} label={isPhone ? 'mobile' : 'email'} onChange={(e) => setIsPhone(e.target.checked)} />
              </Grid>
            </Grid>
            {isPhone ?
              <>
                <Grid item xs={4} sm={5}>
                  <Box >
                    <FormControl fullWidth className="country-code-select">
                      <InputLabel id="demo-simple-select-label">
                        Country Code
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="country-code-select"
                        value={countryCode.dial_code}
                        label="Country Code"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                      >
                        {countries &&
                          countries.length > 0 &&
                          countries.map((x, i) => {
                            return (
                              <MenuItem onClick={() => handleChange(x)} key={i}>
                                <Grid container justifyContent={"space-between"}>
                                  <Grid>{x.name}</Grid>
                                  <Grid>{x.dial_code}</Grid>
                                </Grid>
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <Grid
                        className="country-selected"
                        onClick={() => handleOpen()}
                      >
                        <Grid container>
                          <Grid item className="flag">
                            {countryCode.flag}
                          </Grid>
                          <Grid item className="code fs-16 lh-24">
                            {countryCode.dial_code}
                          </Grid>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={8} sm={7}>
                  <TextField
                    value={phoneNumber}
                    onChange={(e) => {
                      if (e.target.value.length < 11 && !isNaN(e.target.value)) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    onKeyUp={(e) => handleKeyPress(e)}
                    label="Mobile Number"
                    variant="outlined"
                    inputMode={'numeric'}
                  />
                </Grid>
              </>
              :
              <Grid item xs={12}>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={(e) => handleKeyPress(e)}
                  label="email"
                  variant="outlined"
                  sx={{ width: { xs: '100%', sm: '100%' } }}
                />
              </Grid>
            }
          </Grid>
        </Box>
        <Button
          className="rounded-btn bg-selected mw-350 text-light mt-40 fs-14 fw-500"
          onClick={() => handleLogin()}
          disabled={currentLoading}
        >
          {currentLoading ? <CircularProgress style={{ color: '#000' }} /> : 'continue'}
        </Button>
        <Typography variant="subtitle2" className="fs-20 fw-500 mt-40">
          don’t have an account?{" "}
          <span className="pointer" onClick={() => dispatch(toggleSignUpModal(true))}>
            <b>sign up</b>
          </span>
        </Typography>
      </Grid>
    </Paper>
  );
}

export default PhoneLogin;
