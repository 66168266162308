import React, { useEffect, useState } from 'react'
import { Grid, Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { acceptRejectTitleRequest, clearMessages, getAllPendingSuggestions } from '../../slices/watchlist';
import helpers from '../../services/helpers';
import { Link, useNavigate } from 'react-router-dom';
import MyHelmet from '../common/MyHelmet';

const maxLength = 15;

export default function PendingWatchlist(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const watchlist = useSelector(state => state.watchlist);
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(20);

    useEffect(() => {
        if (watchlist.successMesg && watchlist.successMesg != "") {
            dispatch(getAllPendingSuggestions({ page: props.limited ? 1 : page, pagelimit: props.limited ? 3 : pageLimit }));
        }
    }, [watchlist])

    useEffect(() => {
        dispatch(getAllPendingSuggestions({ page: props.limited ? 1 : page, pagelimit: props.limited ? 3 : pageLimit }));
    }, [page])

    const handleAcceptTitle = (data) => {
        const payload = {
            watchlist_id: data.watchlist_id,
            media_id: data.media,
            action: "accept",
            user_id: data.user
        }
        dispatch(acceptRejectTitleRequest({ body: payload }));
    }

    const handleDeclineTitle = (data) => {
        const payload = {
            watchlist_id: data.watchlist_id,
            media_id: data.media,
            action: "reject",
            user_id: data.user
        }
        dispatch(acceptRejectTitleRequest({ body: payload }));
    }

    useEffect(() => {
        const handleScroll = () => {
            if (!props.limited && watchlist.pendingSuggestionsPageData && watchlist.pendingSuggestionsPageData.pagination && watchlist.pendingSuggestionsPageData.pagination.is_next_page && !watchlist.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, watchlist]);


    return (
        <>
            {!props.imported ?
                <MyHelmet
                    title="Pending Wathclist"
                    description={"Your future movie adventures await. Join the watchlist today"}
                />
                :
                ''
            }
            {watchlist.pendingSuggestionsData && watchlist.pendingSuggestionsData.length > 0 &&
                <Grid container sx={{ paddingTop: '20px', paddingBottom: '30px' }} className='app-container section-border-bottom'>
                    <Grid item sm={12}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <p className='fw-700 fs-28 m-0'>your pending to-do list</p>
                            </Grid>
                            <Grid item>
                                {props.limited ?
                                    <Link to="/pending-list">
                                        <ArrowForwardIcon />
                                    </Link>
                                    :
                                    <ArrowForwardIcon className='pointer' onClick={() => navigate(-1)} style={{ transform: 'rotate(180deg)' }} />
                                }
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {watchlist.pendingSuggestionsData.map((x, i) => {
                                if (props.limited && i == 3) {
                                    return true;
                                }
                                return <Grid item key={i} className="pending-watchlist-items" sm={4} sx={{ paddingTop: '20px' }}>
                                    <Grid container className='mt-10'>
                                        <Grid item className='relative text-center'>
                                            <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Watchlist')} to={helpers.getMediaLink(x)}>
                                                <Box
                                                    component="img"
                                                    className='d-block'
                                                    sx={{
                                                        height: 150,
                                                        width: '100%',
                                                        maxHeight: { xs: 150, sm: 150 },
                                                        maxWidth: { xs: 100, sm: 100 },
                                                        borderRadius: '9px 9px 9px 9px',
                                                    }}
                                                    alt="poster"
                                                    src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage("tile")}
                                                />
                                            </Link>
                                        </Grid>
                                        <Grid item className='ml-16 mw-240' >
                                            <Typography title={x.title ? x.title : ''} className='fs-20 fw-700 '>
                                                {x.title ? x.title.length > maxLength ? x.title.substring(0, maxLength) + " ..." : x.title : ''}
                                            </Typography>
                                            <p className='fw-300 fs-12 text-dark-grey'>Recommended by {x.user_name ? x.user_name : x.unique_name} for ”{x.watchlist ? x.watchlist : ''}”</p>
                                            {/* <Stack direction="row" className='ai-center ml-15 mt-10'>
                                                {
                                                    list.map((x, i) => {
                                                        return <Avatar alt="Remy Sharp" src={RoundedProfile} className='release-avatar' />
                                                    })
                                                }
                                                <Typography className='fs-12 fw-500 lh-23 ml-8'> +25 notified it</Typography>
                                            </Stack> */}

                                            <Grid container className='mt-2' justifyContent={'start'} alignItems={'center'}>
                                                {/* <Grid item>
                                                    <Button className='rounded-btn mw-130 bg-secondary text-light fs-12 fw-700 mt-10' endIcon={<PlayArrowIcon />}>
                                                        watch trailer
                                                    </Button>
                                                </Grid> */}
                                                <Grid item onClick={() => handleAcceptTitle(x)} className='rounded-circle mr-10 pointer mt-10'>
                                                    <CheckIcon className='text-selected' />
                                                </Grid>
                                                <Grid item onClick={() => handleDeclineTitle(x)} className='rounded-circle pointer mt-10'>
                                                    <ClearIcon className='text-selected' />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            })}
                        </Box>
                    </Grid >
                </Grid >
            }
        </>
    )
}
