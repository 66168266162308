import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import React, { useEffect, useState } from "react";
import helpers from "../../services/helpers";
import NotifyMe from "../common/NotifyMe";
import CloseIcon from "@mui/icons-material/Close";
import "swiper/css";
import "swiper/css/navigation";
import { getViewingOptions } from "../../slices/media";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Typography } from "@mui/material";
import { toggleLoginModal } from "../../slices/global";
import { getGoogleCastData, isMobileDevice } from "../../services/Utils";
import QrCodeScanner from "./QrCodeScanner";
import CastIcon from '@mui/icons-material/Cast';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
// import ZeroStateWTW from '../../assets/ZeroStateWTW.svg'
// import ZeroStateWTWMob from '../../assets/ZeroStateWTWMob.svg'
// import TVPlay from '../../assets/TVPlay.svg'
// import FrenziLogo from '../../assets/FrenziLogo.svg'

const ZeroStateWTW = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateWTW.svg";
const ZeroStateWTWMob = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateWTWMob.svg";
const TVPlay = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/TVPlay.svg";
const FrenziLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/FrenziLogo.svg";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "95%", sm: "600px" },
    minWidth: { xs: "95%", sm: "auto" },
    bgcolor: "#1a1a2e",
    boxShadow: 24,
    // p: { xs: 2, sm: 4 },
    borderRadius: 5,
    maxHeight: "95vh",
    overflowY: "auto",
};

const playStore = "https://play.google.com/store/apps/details?id=com.frenzee.app";
const AppStore = "https://play.google.com/store/apps/details?id=com.frenzee.app";

export default function WhereToWatch(props) {
    const { mediaData, currentViewingOptions } = props;
    const [checked, setChecked] = useState(true);
    const [open, setOpen] = useState(false);
    const [tvModalOpen, setTvModalOpen] = useState();
    const [currentData, setCurrentData] = useState(null);
    const dispatch = useDispatch();
    const global = useSelector(state => state.global);
    const [myQrReader, setMyQrReader] = useState(null);
    const [isScannerOpen, setIsScannerOpen] = useState(false);

    const handleDownload = () => {
        if (/iPhone|iPad/i.test(navigator.userAgent)) {
            window.open(AppStore, '_blank');
        } else if (/Android/i.test(navigator.userAgent)) {
            window.open(playStore, '_blank');
        } else {
            window.open(playStore, '_blank');
        }
    }

    useEffect(() => {
        getData(mediaData);
    }, [mediaData]);

    useEffect(() => {
        if (!checked && open && currentData) {
            setTimeout(() => {
                const scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                // scriptElement.innerHTML = scriptCode;
                scriptElement.innerHTML = getGoogleCastData(currentData, mediaData);
                document.body.appendChild(scriptElement);
            }, 500)
        }
    }, [checked, open, currentData]);

    const getData = (data, section = 'mobile') => {
        let mid = data?.uuid || data?.media;
        if (mid) {
            dispatch(getViewingOptions({ id: mid, section: section, is_public: global.is_public })).then((data) => {
                if (data.payload && data.payload.data) {
                    props?.setCurrentViewingOptions({ ...data.payload.data });
                }
            })
        }
    }

    const OttView = ({ x }) => {
        return (
            <Grid
                item
                className="w-100 br-8 my-badge"
                style={{
                    padding: "10px",
                }}
                textAlign="center"
            >
                <Grid container flexWrap={'nowrap'} alignItems={'center'}>
                    <Grid item style={{ marginRight: '20px' }}>
                        <Box
                            component="img"
                            className="d-block"
                            sx={{
                                height: '100%',
                                width: '100%',
                                maxHeight: { xs: 25, sm: 50 },
                                maxWidth: { xs: 25, sm: 50 },
                                borderRadius: "4px",
                            }}
                            alt="ott"
                            src={
                                x.platform_logo
                                    ? helpers.getFullUrl(x.platform_logo)
                                    : helpers.getDefaultImage("ott")
                            }
                        />
                    </Grid>
                    <Grid item style={{ marginRight: '20px' }}>
                        <h2 className="m-0 text-left fs-20 fw-700">{x?.platform_name}</h2>
                        {x?.languages && x?.languages.filter(item => item?.language_id).length > 0 ?
                            <h2 className="m-0 text-left fs-16 fw-500 text-muted">
                                {x?.languages.filter(item => item?.language_id).map((x) => { return x?.language }).join(", ")}
                            </h2>
                            :
                            <h2 className="m-0 text-left fs-16 fw-500 text-muted">All available languages</h2>
                        }
                    </Grid>
                    {/* <div style={{ flex: 1, padding: '10px', textAlign:'right' }}>
                            <Button className="rounded-btn bg-light w-90 mw-100 text-selected">buy</Button>
                        </div> */}
                </Grid>
            </Grid>
        )
    }

    const handleScannerOpen = (data) => {
        if (data && data?.deep_link_id !== '' && !global.is_public) {
            // setCurrentData(data);
            // setOpen(true);
            setTvModalOpen(true);
        } else {
            dispatch(toggleLoginModal({ isOpen: true, content: 'login to scan the QR code' || null }));
        }
    }

    const ViewingOptions = ({ x }) => {
        return (
            <>
                {checked ?
                    <>
                        {global.is_public ?
                            <div className="pointer" onClick={() => {
                                dispatch(toggleLoginModal({ isOpen: true, content: 'login to play the Title' || null }))
                            }}>
                                <OttView x={x} />
                            </div>
                            :
                            <a rel="noreferrer" href={x.deep_link.includes('http') ? x?.deep_link : 'https://' + x?.deep_link} target="_blank" className="">
                                <OttView x={x} />
                            </a>
                        }
                    </>
                    :
                    <div className="pointer" onClick={() => handleScannerOpen(x)}>
                        <OttView x={x} />
                    </div>
                }
            </>
        )
    }

    const handleChange = (isTrue) => {
        let section = isTrue ? 'mobile' : 'tv';
        setChecked(isTrue);
        getData(mediaData, section);
    }

    const handleCloseModal = () => {
        if (isScannerOpen && myQrReader) {
            myQrReader.stop();
            setIsScannerOpen(false);
        }
        setOpen(false);
        setCurrentData(null);
    }

    return (
        <>
            <Grid
                container
                className="app-section bg-grey br-7 mt-24 p-20"
                // style={{ paddingTop: "30px", paddingBottom: "20px" }}
                justifyContent={"center"}
            >
                <Grid item xs={12}>
                    {!isMobileDevice() ?
                        <>
                            <Grid container flexWrap={'nowrap'} justifyContent={'space-between'}>
                                <Grid item>
                                    <h2 className="fw-600 mt-20 mb-0">
                                        where to watch <span className="text-selected">{mediaData?.title}</span> {mediaData?.media_type === 'tv' ? 'web series' : 'movie'} ?
                                    </h2>
                                </Grid>
                                <Button onClick={() => { setTvModalOpen(current => !current) }}>
                                    <Grid item className="bg-secondary p-10 br-8" sx={{ textAlign: 'center', display: { xs: 'block', minWidth: '115px' } }}>
                                        <Typography className="fw-700 fs-14 lh-26">play on <span className="text-selected">TV</span></Typography>
                                        <img src={TVPlay} alt="" />
                                    </Grid>
                                </Button>
                            </Grid>
                        </>
                        :
                        <Grid container className="mt-0 mb-50" columnSpacing={1}>
                            <Grid item xs={12}>
                                <h2 className="fs-20 fw-600 mt-0 mb-24 mr-50">
                                    where to watch <span className="text-selected">{mediaData?.title}</span> {mediaData?.media_type === 'tv' ? 'web series' : 'movie'} ?
                                </h2>
                            </Grid>
                            <Grid item xs={6} onClick={() => { handleChange(true) }}>
                                <Button className={`customButton-wtw ${checked ? 'clicked' : ''}`}>Play on mobile</Button>
                            </Grid>
                            <Grid item xs={6} onClick={() => { handleChange(false) }} >
                                <Button className={`customButton-wtw ${!checked ? 'clicked' : ''}`}>Play on tv</Button>
                            </Grid>
                        </Grid>
                    }
                    {(currentViewingOptions?.free && currentViewingOptions?.free.length > 0) || (currentViewingOptions?.rent && currentViewingOptions?.rent.length > 0) || (currentViewingOptions?.subscription && currentViewingOptions?.subscription.length > 0) ? (
                        <>
                            {currentViewingOptions?.free &&
                                currentViewingOptions?.free.length > 0 &&
                                <>
                                    <p className="fs-20 mb-10 fw-500 mt-0">free</p>
                                    <Grid container spacing={3}>
                                        {currentViewingOptions?.free.map((x, i) => {
                                            return (
                                                <Grid key={`free-${i}`} item xs={12} md={6}>
                                                    <ViewingOptions x={x} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </>}


                            {currentViewingOptions?.rent &&
                                currentViewingOptions?.rent.length > 0 &&
                                <>
                                    <p className="fs-20 mb-10 fw-500 mt-20">rent</p>
                                    <Grid container spacing={3}>
                                        {currentViewingOptions?.rent.map((x, i) => {
                                            return (
                                                <Grid key={`rent-${i}`} item xs={12} md={6}>
                                                    <ViewingOptions x={x} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </>}

                            {currentViewingOptions?.subscription &&
                                currentViewingOptions?.subscription.length > 0 &&
                                <>
                                    <p className="fs-20 mb-10 fw-500 mt-20">subscription</p>
                                    <Grid container spacing={3}>
                                        {currentViewingOptions?.subscription.map((x, i) => {
                                            return (
                                                <Grid key={`subscription-${i}`} item xs={12} md={6}>
                                                    <ViewingOptions x={x} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </>}
                        </>
                    ) : (<>
                        <Grid container justifyContent={''}>
                            <Grid item className="relative mt-10">
                                <Grid item>
                                    <img src={isMobileDevice() ? ZeroStateWTWMob : ZeroStateWTW} alt="" />
                                </Grid>
                                <Grid item className="absolute" sx={{ top: "20px", right: '20px' }}>
                                    <Typography className="fw-700 fs-20 lh-20 mb-10">not available on any <span className="text-selected">platform</span></Typography>
                                    <Typography className="fw-500 fs-14 lh-24">get notified when available</Typography>
                                    <NotifyMe
                                        uuid={mediaData.media ? mediaData.media : mediaData?.uuid}
                                        notified={mediaData?.notify_me ? true : false}
                                        label={"notify me"}
                                        isLarge={true}
                                        isWhite={true}
                                        data={mediaData}
                                        className={"mw-170 w-100 mt-30"} />
                                    {/* <img src={NotifiIconZeroState}  */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    )}



                    {/* {isMobileDevice() ?
                        <Grid container className="app-section" style={{ marginTop: '20px' }} justifyContent={"center"}>
                            <MyAds type="banner_2" mobtype={"mobile_1"} slotId={'1179809289'} height={100} adv_type={"web_movie_detail_top_ad"} />
                        </Grid>
                        : ''} */}
                </Grid>


                <Modal
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <button id="scan-close" className="absolute"
                            style={{
                                top: '10px',
                                right: '10px'
                            }}
                            onClick={handleCloseModal}
                        >
                            <CloseIcon
                                className='ml-3 pt-2 pointer animate-close'
                                color="light"
                            />
                        </button>
                        <Grid container className='login-box' justifyContent={'center'}>
                            {isScannerOpen ?
                                <div style={{ maxWidth: '85vw' }}>
                                    <QrCodeScanner
                                        onClose={handleCloseModal}
                                        deeplink_id={currentData?.deep_link_id}
                                        setMyQrReader={setMyQrReader}
                                        myQrReader={myQrReader}
                                        uuid={mediaData.media ? mediaData.media : mediaData?.uuid}
                                    />
                                </div>
                                :
                                <Grid item xs={10} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                    <h2 style={{ paddingRight: '20px' }} className="fw-700 fs-22 m-0">Scan for devices</h2>
                                    <div id="cast-view" style={{ display: 'none' }}>
                                        <button id="cast-button" className="relative scan-btns">
                                            <CastIcon />
                                            <span>Play on TV</span>
                                            <RadioButtonCheckedIcon />
                                        </button>
                                    </div>
                                    <span id="cast-error" className="text-selected fs-20 mt-10" style={{ fontStyle: 'italic', display: 'none' }}>Error:-</span>

                                    <button onClick={() => setIsScannerOpen(true)} className="relative scan-btns">
                                        <QrCodeScannerOutlinedIcon />
                                        <span>Scan QR Code to Play</span>
                                        <RadioButtonCheckedIcon />
                                    </button>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Modal>
                <Modal open={tvModalOpen}
                    onClose={() => { setTvModalOpen(false) }}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    className=""
                >
                    <Box sx={{ maxWidth: { xs: '75%', sm: '350px' } }} className='w-100' >
                        <Grid container className="bg-secondary p-30 br-10" sx={{ width: '100%', maxWidth: '350px' }}>
                            <Grid item xs={3} sx={{ textAlign: 'center' }} className="pt-20">
                                <img src={FrenziLogo} alt="" />
                            </Grid>
                            <Grid item xs={7.5}>
                                <Grid container className="w-100" justifyContent={'center'}>
                                    <Grid item >
                                        <Typography className="fw-400 fs-16 lh-20"> Download the App </Typography>
                                        <Typography className="fw-400 fs-12 lh-20">for a better experience</Typography>
                                        <Button onClick={() => handleDownload()} className="rounded-btn bg-light text-selected w-100 pr-30 pl-30 mt-20 h-10">download</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
        </>
    );
}
