import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import Footer from '../views/common/Footer';
import MainNavBar from '../views/component/MainNavBar';
// import FullBgImg from '../assets/img/full-bg-image.jpg';
// import FrenziLogo from "../assets/icons/FrenziIcon.svg";
import { Grid, Modal } from '@mui/material';
import { toggleLoginModal, toggleOneTimeModal, toggleSignUpModal } from '../slices/global';
import Login from '../views/auth/Login';
import CreateAcoountPopup from '../views/common/CreateAcoountPopup';
// import PartnerLearningModal from '../views/common/PartnerLearningModal';
import helpers, { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '../services/helpers';
import { Outlet, useParams } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import OldFooter from '../views/common/OldFooter';
import Footer from '../views/common/Footer';
import CreateAccount from '../views/auth/CreateAccount';
import Register from '../views/auth/Register';

const FrenziLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/FrenziIcon.svg";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "87%", sm: "600px" },
    bgcolor: "#1a1a2e",
    boxShadow: 24,
    // p: { xs: 2, sm: 4 },
    borderRadius: 5,
    maxHeight: "100vh",
    overflowY: "auto",
};

const styleSignUp = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#1a1a2e",
    // boxShadow: 24,
    borderRadius: 2,
    width: "100%",
    maxWidth: { xs: "90%", sm: "50%", md: "400px", lg: "400px" },
    // p: { xs: 2, sm: 5 },
};

export default function PublicLayout({ title }) {
    // const auth = useSelector(state => state.auth);
    const global = useSelector(state => state.global);
    const { loginLogo, loginContent } = global;
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        if (title && title != '') {
            document.title = `${title}${params?.name ? ' - ' + params?.name.replace(/-/g, " ") : ''}`;
        } else {
            document.title = DEFAULT_TITLE;
            helpers.setMeta('description', DEFAULT_DESCRIPTION);
        }
    }, [title, params?.name])

    const defaultContetnt = <p className={`fs-24 lh-28 mt-10 `}>
        we <b>simplify</b> what to watch on <b>ott</b>
    </p>
    const defaultLogo = <img alt="logo" src={FrenziLogo} />;

    const handleCloseModal = () => {
        dispatch(toggleLoginModal(false));
    }

    const handleClose = () => {
        dispatch(toggleOneTimeModal(false));
        localStorage.setItem("is_auth_modal_shown", true);
    }

    // const handleClose2 = () => {
    //     dispatch(togglePartnerJourneyModal(false));
    //     localStorage.setItem("is_partner_journey_modal_shown", true);
    // }

    // const handleClick = () => {
    //     dispatch(toggleLoginModal({isOpen: true}));
    // }

    return (
        <>
            <main className='fixed-full-view' >
                <MainNavBar />
                <Box className='application-container'
                    style={{
                        minHeight: '100vh'
                    }}
                >
                    <Outlet />
                </Box>
                <Footer />
            </main>
            <Modal
                open={global.isLoginModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseIcon
                        onClick={handleCloseModal} className='ml-3 pt-2 pointer absolute animate-close'
                        color="light"
                        style={{
                            top: '10px',
                            right: '10px'
                        }}
                    />
                    <Grid container className='login-box' justifyContent={'center'}>
                        <div style={{ maxWidth: '80vw' }}>
                            <Login isModal={true} content={loginContent || defaultContetnt} logo={loginLogo || defaultLogo} />
                        </div>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                open={global.isSignUpModalOpen}
                onClose={() => dispatch(toggleSignUpModal(false))}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
                <Box sx={styleSignUp}>
                    {/* <CreateAccount /> */}
                    <Register isFull={true} />
                </Box>
            </Modal>

            <CreateAcoountPopup open={global.isOneTimeModal} isModal={true} handleClose={handleClose} />
            {/* <PartnerLearningModal handleClick={handleClick} open={global.isPartnerJourneyModal} isModal={true} handleClose={handleClose2} /> */}
        </>
    )
}
