import axiosInstance from "../http-common";

const getWatchlistLandingDetail = (section = "frenzi") => {
    return axiosInstance.get(`native_main/watchlistlanding?watchlist_user_type=${section}&medialimit=10`);
};

const getAllPendingSuggestions = (id = "", page = 1, pagelimit = 10) => {
    let url = `native_main/pendingsuggestions?page=${page}&pagelimit=${pagelimit}`;
    if (id && id != "") {
        url += `&watchlist_id=${id}`;
    }
    return axiosInstance.get(url);
};

const getUnwatchedMedias = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/unwatchedmedias?page=${page}&pagelimit=${pagelimit}`);
};

const getWatchlistSection = (section = "frenzi", page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/watchlistsections?section=${section}&page=${page}&pagelimit=${pagelimit}&medialimit=10`);
};

const getFirstWatchlistData = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/firstwatchlist?page=${page}&pagelimit=${pagelimit}`);
};

const addWatchlist = (body) => {
    return axiosInstance.post(`native_main/frenziwatchlist`, body);
};

const updateWatchlist = (id, body) => {
    return axiosInstance.put(`native_main/watchlistdetail/${id}`, body);
};

const getUserWatchlist = (id, page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/userwatchlistdetail?media=${id}&page=${page}&pagelimit=${pagelimit}`);
};

const addRemoveMediaFromWatchlist = (body) => {
    return axiosInstance.post(`native_main/userwatchlist`, body);
};

const joinWatchlist = (id, body) => {
    return axiosInstance.post(`native_main/joinwatchlist/${id}`, body);
};

const acceptRejectTitleRequest = (body) => {
    return axiosInstance.post(`native_main/watchlistmediarequest`, body);
};

const acceptRejectContributorRequest = (id, body) => {
    return axiosInstance.post(`native_main/watchlistrequests?watchlist_id=${id}`, body);
};

const getWatchlistDetail = (id) => {
    return axiosInstance.get(`native_main/watchlistdetail/${id}`);
};

const getWatchlistTitleDetail = (id, page = 1, pagelimit = 10, sort = "", body, is_public = false) => {
    let url = `native_main/${is_public ? 'public_' : ''}watchlisttitle?watchlist_id=${id}&page=${page}&pagelimit=${pagelimit}&sort=${sort}`;
    if (body.exclude_already_watched && body.exclude_already_watched != "") {
        url += '&exclude_already_watched=' + body.exclude_already_watched
    }
    return axiosInstance.post(url, body);
};

const deleteLeaveWatchlist = (id) => {
    return axiosInstance.delete(`native_main/watchlistdetail/${id}`);
};

const getWatchlistMembers = (id, page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/watchlistmember?watchlist_id=${id}&page=${page}&pagelimit=${pagelimit}`);
};

const addMemberToWatchlist = (body) => {
    return axiosInstance.post(`native_main/watchlistmember`, body);
};

const removeMemberToWatchlist = (uid, id) => {
    return axiosInstance.delete(`native_main/watchlistmemberdetail/${uid}?watchlist_id=${id}`);
};

const getSuggestedTitles = (id, page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/watchlistmediarequest?watchlist_id=${id}&page=${page}&pagelimit=${pagelimit}`);
};

const addSuggestTitles = (body) => {
    return axiosInstance.post(`native_main/suggesttitle`, body);
};

const addRemoveWatchlistMedia = (body) => {
    return axiosInstance.post(`native_main/watchlistmedia`, body);
};

const getWatchlistSharelistData = (section = 'private', page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/frenziwatchlist?section=${section}&page=${page}&pagelimit=${pagelimit}&medialimit=10`);
};

const getWatchlistIJoined = (page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/frenziwatchlist?page=${page}&pagelimit=${pagelimit}&medialimit=10`);
};

const getFrenziSharelist = (page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/frenzisharelist?page=${page}&pagelimit=${pagelimit}&medialimit=10`);
};

const getFreindsSharelist = (page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/friendswatchlist?page=${page}&pagelimit=${pagelimit}&medialimit=10`);
};

const getPopularCreatorSharelist = (section = 'popular', page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/friendswatchlist?section=${section}&page=${page}&pagelimit=${pagelimit}&medialimit=10`);
};

const getWatchlistToAdd = (uuid = "", section = 'add', page = 1, pagelimit = 20) => {
    let url = `native_main/addtowatchlist?section=${section}&page=${page}&pagelimit=${pagelimit}&medialimit=10`;
    if (uuid && uuid != '') {
        url += `&media_id=${uuid}`;
    }
    return axiosInstance.get(url);
};

const addToWatchlist = (uuid = "", section = 'add', body) => {
    return axiosInstance.post(`native_main/addtowatchlist?media_id=${uuid}&section=${section}`, body);
};

const getAllPendingWatchlistRequest = (id = "", page = 1, pagelimit = 10) => {
    let url = `native_main/watchlistjoinrequest?page=${page}&pagelimit=${pagelimit}`;
    if (id && id != "") {
        url += `&watchlist_id=${id}`;
    }
    return axiosInstance.get(url);
};

const getAllPendingPrivateWatchlistRequest = (id = "", page = 1, pagelimit = 10) => {
    let url = `native_main/privatewatchlistrequest?page=${page}&pagelimit=${pagelimit}`;
    if (id && id != "") {
        url += `&watchlist_id=${id}`;
    }
    return axiosInstance.get(url);
};

const acceptRejectAllPendingPrivateWatchlistRequest = (id = "", body) => {
    let url = `native_main/privatewatchlistrequest`;
    return axiosInstance.post(url, body);
};

const acceptRejectAllPendingWatchlistRequest = (id = "", body) => {
    let url = `native_main/watchlistjoinrequest`;
    return axiosInstance.post(url, body);
};

const searchWatchlist = (query = "", page = 1, pagelimit = 10) => {
    let url = `native_main/watchlistsearch?page=${page}&pagelimit=${pagelimit}&query=${query}`;
    return axiosInstance.get(url);
};

const acceptRejectJoinWatchlistRequest = (body) => {
    let url = `native_main/watchlistjoinrequest`;
    return axiosInstance.post(url, body);
};

const WatchlistDataService = {
    getWatchlistLandingDetail,
    getAllPendingSuggestions,
    getUnwatchedMedias,
    getWatchlistSection,
    getFirstWatchlistData,
    addWatchlist,
    updateWatchlist,
    getUserWatchlist,
    addRemoveMediaFromWatchlist,
    joinWatchlist,
    acceptRejectTitleRequest,
    acceptRejectContributorRequest,
    getWatchlistDetail,
    getWatchlistTitleDetail,
    deleteLeaveWatchlist,
    getWatchlistMembers,
    addMemberToWatchlist,
    removeMemberToWatchlist,
    getSuggestedTitles,
    addSuggestTitles,
    addRemoveWatchlistMedia,
    getWatchlistSharelistData,
    getWatchlistIJoined,
    getFrenziSharelist,
    getFreindsSharelist,
    getPopularCreatorSharelist,
    getWatchlistToAdd,
    addToWatchlist,
    getAllPendingWatchlistRequest,
    getAllPendingPrivateWatchlistRequest,
    acceptRejectAllPendingPrivateWatchlistRequest,
    acceptRejectAllPendingWatchlistRequest,
    searchWatchlist,
    acceptRejectJoinWatchlistRequest
};

export default WatchlistDataService;