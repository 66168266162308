import React from "react";
import { Grid } from "@mui/material";
// import TxtUnselectedIcon from '../assets/icons/Txt-Rvw-Unselected.svg';
// import TxtSelectedIcon from '../assets/icons/Txt-Rvw-Selected.svg';
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginModal } from "../slices/global";

const TxtSelectedIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/Txt-Rvw-Selected.svg";

export default function CrouselNudge() {
    const global = useSelector(state => state.global);
    const dispatch = useDispatch();

    const handleCLick = () => {
        if (global.is_public) {
            dispatch(toggleLoginModal({ isOpen: 'true' }));
        }
    }


    return (

        <Grid container justifyContent={'space-between'} flexDirection={'column'} className="bg-gradient br-8 p-20 h-100" sx={{ minHeight: '270px' }}>
            <Grid item>
                <h2 className="fw-700 fs-32 m-0 lh-24">Rate & Review</h2>
                <h2 className="fw-500 nudge-subtext fs-22">help others decide if a title is worth watching</h2>
            </Grid>
            <Grid item xs={12} className="text-center">
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <h2 style={{ paddingRight: '10px' }} className="fw-500 fs-20 m-0">earn</h2>
                    <h2 style={{ paddingRight: '10px' }} className="fw-600 fs-54 m-0 lh-64">50</h2>
                    <h2 className="fw-500 fs-20 m-0">points</h2>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <div className='trending-menu' >
                        <div className='my-trending-tabs-button'>
                            <button onClick={() => handleCLick()} className="selected" >
                                <img alt="review" src={TxtSelectedIcon} />
                                Text
                            </button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>

    )
}
