import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobileDevice } from "../../../services/Utils";
import { updateTopTenTabs } from "../../../slices/global";
import { getTopTen } from "../../../slices/trending";
import MyAds from "../../adsense/MyAds";
import CommonMenu from "../../common/CommonMenu";
import ContentAccordion from "../../common/ContentAccordion";
import Loader from "../../common/Loader";
import MyHelmet from "../../common/MyHelmet";
import SingleMediaTile from "../../common/SingleMediaTile";
// import TopTen from "../TopTen";

const menuList = [
  {
    link: "all",
    label: "all",
  },
  {
    link: "movie",
    label: "movies",
  },
  {
    link: "tv",
    label: "web series",
  },
];

export default function TopTenInner() {
  const dispatch = useDispatch();
  // const [page, setPage] = useState(1);
  const trending = useSelector((state) => state.trending);
  const global = useSelector((state) => state.global);
  const { topTenTabValue } = global;

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(1);
  }, [topTenTabValue]);

  const getData = (p) => {
    var body = {
      page: p,
      pagelimit: 10,
      is_free: false,
      is_public: global.is_public,
      media_type: topTenTabValue,
    };
    dispatch(getTopTen(body));
  };

  return (
    <div>
      <MyHelmet
        title={"Trending Movies & Series on OTT This Week"}
        og_title={"Weekly OTT Trending Movies & Series"}
        description={
          "Stay updated with the trending movies & series on OTT this week. Don't miss out on any popular movies and series."
        }
        keywords={"OTT, trending, movies, series, weekly"}
      />
      {trending.isLoading && <Loader />}
      <div className="new-container">
          <ContentAccordion
            title={'TOP_10_H'}
            data={'TOP_10'}
          />
        <CommonMenu
          setTabValeu={(val) => dispatch(updateTopTenTabs(val))}
          menuList={menuList}
          tabValue={topTenTabValue}
          isBack={true}
          heading={
            <><span className="text-selected">top 10</span><span> on ott this week</span></>
          }
        />
      </div>
      <div className="app-container pb-50">
        <Grid container className="new-container mt-30">
          <Grid flex={1} className="left-container">
            <Grid item xs={12} style={{ marginBottom: '10px' }} justifyContent={'center'}>
              <Grid container justifyContent={'center'}>
                <MyAds type="banner_2" adv_type={'web_top_ten_middle_ad'} hideInMobile={true} />
              </Grid>
            </Grid>
            {trending.toptenPageData &&
              trending.toptenPageData.results &&
              trending.toptenPageData.results.length > 0 && (
                <Grid container spacing={2}>
                  {trending.toptenPageData.results.map((x, i) => {
                    return (
                      <>
                        {(i === 4 || i === 8 || (i === 1 && isMobileDevice())) &&
                          <Grid item xs={12} style={{ marginTop: '10px' }} justifyContent={'center'}>
                            <Grid container justifyContent={'center'}>
                              <MyAds type="banner_2" adv_type={'web_top_ten_middle_ad'} />
                            </Grid>
                          </Grid>
                        }
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          key={i}
                          style={{ paddingRight: "10px" }}
                        >
                          <SingleMediaTile
                            isTopTen={true}
                            dataLength={trending.toptenPageData.results.length}
                            index={i}
                            x={x}
                            refPage={'Top Ten'}
                          />
                        </Grid>
                      </>
                    );
                  })}
                  {/* <Grid item xs={12} style={{ marginTop: '10px' }} justifyContent={'center'}>
                    <Grid container justifyContent={'center'}>
                      <MyAds type="banner_2" adv_type={'web_top_ten_bottom_ad'} />
                    </Grid>
                  </Grid> */}
                </Grid>
              )
            }
          </Grid>
          {!isMobileDevice() ?
            <Grid className="right-container">
              <Grid className="right-container-ad">
                <MyAds type="banner_1" adv_type={'web_top_ten_right_ad'} />
              </Grid>
            </Grid>
            : ''}
        </Grid>
      </div>
    </div>
  );
}
