import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme, value, isgreen }) => ({
    height: 14,
    borderRadius: 32,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: value < 40 ? '#FA262E' : (value >= 40 && value < 80) ? '#F39200' : '#1DAA10',
    },
}));

export default function ProgressBar(props) {
    return (
        <>
            {props.value && props.value > 0 ?
                <Box sx={{ flexGrow: 1 }} style={{ maxWidth: props.width ? props.width : 36 }} className="progress-container relative" >
                    <BorderLinearProgress className="bar" isgreen={props.isGreen} variant="determinate" value={props.value ? props.value : 0} />
                    {!props.hideLabel &&
                        <span className='fs-12 fw-600 text-light lh-12 progress-value'>{props.value ? props.value / 10 : 0}</span>
                    }
                </Box>
                :
                null
            }
        </>
    );
}
