import React, { useEffect, useRef, useState } from 'react'
import './watchlist.scss';
import { Grid, Button, Box, Paper, TextField, Switch, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { addWatchlist, getFirstWatchlistData } from '../../slices/watchlist';
import helpers from '../../services/helpers';
import { frenziSearch } from '../../slices/common';
// import rightImg from '../../assets/icons/right.svg';
import { useNavigate } from 'react-router-dom';
import { setCreateWatchlistFormOpen, updateSelectedTitlesForWatchlist } from '../../slices/global';
import CloseIcon from '@mui/icons-material/Close';
import { MEDIUM_TILE_BREAKPOINTS } from '../../services/helpers';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const rightImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/right.svg";

const style = {
    position: 'absolute',
    top: '100px',
    width: '100vw',
    bgcolor: 'transparent',
    boxShadow: 24,
    borderRadius: 4
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
}));

export default function CreateForm(props) {
    const [watchlistName, setWatchlistName] = useState('');
    const [currentSelectedData, setCurrentSelectedData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [page, setPage] = useState(1);
    const [createWatchlistPopup, setCreateWatchlistPopup] = useState(false);
    const inputRefs = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const watchlist = useSelector(state => state.watchlist);
    const common = useSelector(state => state.common);
    const _global = useSelector(state => state.global);
    const { selectedTitlesForWatchlist, watchlistType, isCreateWatchlistFormOpen } = _global;

    useEffect(() => {
        setCurrentSelectedData([...selectedTitlesForWatchlist]);
    }, [selectedTitlesForWatchlist])

    const handleClickOpen = () => {
        setCreateWatchlistPopup(true);
    };
    const handleClose = () => {
        setCreateWatchlistPopup(false);
    };

    useEffect(() => {
        dispatch(getFirstWatchlistData({ page: 1, pagelimit: 10 }));
    }, [])

    useEffect(() => {
        if (searchText && searchText != "") {
            dispatch(frenziSearch({ val: searchText, page: page, pagelimit: 30 }));
        }
    }, [searchText, page])

    useEffect(() => {
        if (createWatchlistPopup) {
            setTimeout(() => {
                if (inputRefs.current) {
                    inputRefs.current.focus();
                }
            }, 100)
        }
    }, [inputRefs, createWatchlistPopup]);

    const handleCreateWatchlist = () => {
        if (watchlistName != "") {
            if (currentSelectedData && currentSelectedData.length > 0) {
                const payload = {
                    is_public: !watchlistType,
                    category_name: watchlistName,
                    medias: currentSelectedData.map(x => x.media)
                }
                helpers.trackMoengageEvent('FR3_Create_Watchlist', {
                    watchlist_name: watchlistName,
                    is_public: !watchlistType,
                    created_by: JSON.parse(localStorage.getItem('frenzi_user_access')).uuid,
                })

                dispatch(addWatchlist({ body: payload })).then((data) => {
                    setWatchlistName('');
                    dispatch(setCreateWatchlistFormOpen({ open: !isCreateWatchlistFormOpen, forceClose: true }));
                    dispatch(updateSelectedTitlesForWatchlist([]));
                    setTimeout(() => {
                        if (data.payload && data.payload.data && data.payload.data?.category_id) {
                            helpers.watchlistClick(watchlistName, data.payload.data?.category_id, 'Create Watchlist');
                            navigate("/watchlist-detail/" + data.payload.data?.category_id);
                        }
                    }, 300)
                });

            }
        } else {
            helpers.toastError("Watchlist name should not be empty.");
            return true;
        }
    }

    const handleOnsearchMediaClick = (data) => {
        var tempArr = currentSelectedData;
        if (tempArr.length > 0) {
            var flag = true;
            var index = -1;
            tempArr.map((x, i) => {
                if (x.media && data.media && x.media == data.media) {
                    flag = false;
                    index = i;
                    return true;
                }
            })
            if (flag) {
                tempArr.push(data);
            } else if (index > -1) {
                tempArr.splice(index, 1);
            }
        } else {
            tempArr.push(data);
        }
        // setCurrentSelectedData([...tempArr]);
        dispatch(updateSelectedTitlesForWatchlist([...tempArr]));
    }

    return (
        <div>
            <Grid item className='bg-secondary pt-20 pb-20 app-container'>
                <Grid item xs={12}>
                    <Grid container alignItems='center' justifyContent={'space-between'} className='mt-20 w-100' >
                        <p className='fs-28 fw-700 lh-28 m-0 '>create your new <span className='text-selected'> {watchlistType ? 'watchlist' : 'sharelist'}</span>  </p>
                        <Grid item>
                            <CloseIcon
                                className="pointer animate-close"
                                onClick={() => dispatch(setCreateWatchlistFormOpen({ open: !isCreateWatchlistFormOpen, forceClose: true }))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems='center' className='mt-20' >
                    <Grid item xs={11} sm={4} className="pr-40">
                        <TextField value={watchlistName} onChange={(e) => setWatchlistName(e.target.value)} id="outlined-basic" label="Watchlist name" variant="outlined" className='w-100' />
                        <Paper
                            component="form"
                            style={{ opacity: !createWatchlistPopup ? 1 : 0 }}
                            className=' w-100 d-flex ai-center br-25 relative mb-5 mt-20 searchbar'
                        >
                            <IconButton className={`p-5 h-100 br-25 ${isInputFocused ? 'left-search-btn-focused' : ''}`} aria-label="menu">
                                <SearchIcon className={`${!isInputFocused ? 'text-light-grey' : ''} ml-5 pt-2`}
                                    color="error"
                                />
                            </IconButton>
                            <InputBase
                                className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500'
                                placeholder="search for your favorite titles"
                                inputProps={{ 'aria-label': 'search for your favorite titles' }}
                                onClick={() => {
                                    handleClickOpen();
                                }}
                            />
                        </Paper>
                    </Grid>
                    {/* <Grid item>
                        <FormGroup>
                            <FormControlLabel label="Public" control={<GreenSwitch checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />} />
                        </FormGroup>
                    </Grid> */}
                </Grid>

                {currentSelectedData && currentSelectedData.length > 0 &&
                    <Grid item className='mt-10'>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={20}
                            // slidesPerGroup={5}
                            freeMode={true}
                            speed={1000}
                            navigation={true}
                            breakpoints={MEDIUM_TILE_BREAKPOINTS}
                            modules={[FreeMode, Navigation]}
                            className="mySwiper"
                        >
                            {currentSelectedData.map((x, i) => {
                                return <SwiperSlide key={i}>
                                    <div style={{ padding: '20px 0px 20px 0px' }} key={i}>
                                        <Grid item className={`pointer ${currentSelectedData.length > 0 && currentSelectedData.filter(item => item.media == x.media).length > 0 ? 'watchlist-title-selected' : ''}`} onClick={() => handleOnsearchMediaClick(x)}>
                                            <Grid className='relative'>
                                                <Box
                                                    component="img"
                                                    className='d-block'
                                                    sx={{
                                                        height: 210,
                                                        width: '100%',
                                                        maxWidth: '147px',
                                                        maxHeight: { xs: 71, sm: 210 },
                                                        maxWidth: { xs: '100%', sm: '100%' },
                                                        borderRadius: "8px",
                                                    }}
                                                    alt="poster"
                                                    src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('title')}
                                                />
                                                {(currentSelectedData.length > 0 && currentSelectedData.filter(item => item.media == x.media).length > 0) &&
                                                    <div className='selected-watchlist-icon'>
                                                        <img alt="" src={rightImg} />
                                                    </div>
                                                }
                                            </Grid>
                                            <Grid>
                                                <p title={x.title ? x.title : 'N/A'} className='text-light fs-14 lh-12 fw-700'>{x.title ? x.title.length > 12 ? x.title.substring(0, 12) + "..." : x.title : 'N/A'}</p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </Grid>
                }
                {currentSelectedData && currentSelectedData.length > 0 &&
                    <Grid container justifyContent={'flex-end'} className="pb-10">
                        <Button
                            onClick={() => handleCreateWatchlist()}
                            disabled={!(currentSelectedData.length > 0)}
                            className={`rounded-btn ${(watchlistName != "" && currentSelectedData.length > 0) ? 'bg-selected text-light' : 'bg-pink text-dark'} fs-14 mw-320 fw-500`}>create watchlist</Button>
                    </Grid>
                }
            </Grid>

            {/* SEARCH WATCHLIST POPUP */}
            <div>
                <Modal
                    open={createWatchlistPopup}
                    onClose={() => {
                        handleClose();
                        setSearchText("");
                    }}
                    className="wathlist-title-search"
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid item className='pt-10 pb-10 app-container'>
                            <Paper
                                component="form"
                                style={{ height: 40 }}
                                className=' w-100 d-flex ai-center br-25 relative mb-5 mt-20 searchbar'
                            >
                                <IconButton className={`p-5 h-100 br-25 ${isInputFocused ? 'left-search-btn-focused' : ''}`} aria-label="menu">
                                    <SearchIcon className={`${!isInputFocused ? 'text-light-grey' : ''} ml-5 pt-2`}
                                        color="error"
                                    />
                                </IconButton>
                                <InputBase
                                    autoFocus={true}
                                    className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500'
                                    placeholder="search for your favorite titles"
                                    inputProps={{ 'aria-label': 'search for your favorite titles' }}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onFocus={() => setIsInputFocused(true)}
                                    onBlur={() => setIsInputFocused(false)}
                                    value={searchText}
                                    onClick={handleClickOpen}
                                    inputRef={(element) => {
                                        inputRefs.current = element;
                                    }}
                                />
                                {(common.mediaSearchResults && common.mediaSearchResults.results && common.mediaSearchResults.results.length > 0 || currentSelectedData.length > 0) &&
                                    <Box className='watchlist-title-search-panel'>
                                        <Grid container spacing={2}>
                                            {helpers.removeDuplicate([...currentSelectedData, ...common.mediaSearchResults.results]).map((x, i) => {
                                                return <Grid item key={i} className={`pointer ${currentSelectedData.length > 0 && currentSelectedData.filter(item => item.media == x.media).length > 0 ? 'watchlist-title-selected' : ''}`} onClick={() => handleOnsearchMediaClick(x)}>
                                                    <Grid className='relative'>
                                                        <Box
                                                            component="img"
                                                            className='d-block'
                                                            sx={{
                                                                height: 210,
                                                                width: 147,
                                                                maxHeight: { xs: 71, sm: 210 },
                                                                maxWidth: { xs: '100%', sm: 147 },
                                                                borderRadius: "8px",
                                                            }}
                                                            alt="poster"
                                                            src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('title')}
                                                        />
                                                        {(currentSelectedData.length > 0 && currentSelectedData.filter(item => item.media == x.media).length > 0) &&
                                                            <div className='selected-watchlist-icon'>
                                                                <img alt="" src={rightImg} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid>
                                                        <p title={x.title ? x.title : 'N/A'} className='text-light fs-14 lh-12 fw-700'>{x.title ? x.title.length > 12 ? x.title.substring(0, 12) + "..." : x.title : 'N/A'}</p>
                                                    </Grid>
                                                </Grid>
                                            })}
                                        </Grid>
                                        {currentSelectedData.length > 0 &&
                                            <Grid item xs={12} sm={'auto'} className="floating-btn-box">
                                                <Grid container justifyContent={'flex-end'} className="w-100">
                                                    <Button
                                                        onClick={() => {
                                                            handleClose();
                                                        }} className='pl-30 pr-30 rounded-btn bg-selected text-light fs-14 mw-320 fw-500'>continue</Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Box>
                                }
                            </Paper>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}
