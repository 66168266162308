import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useSearchParams } from 'react-router-dom';
import { DEFAULT_TITLE } from '../services/helpers';
import MyHelmet from '../views/common/MyHelmet';

export default function AuthLayout({ children, title }) {
    const auth = useSelector(state => state.auth);
    const [isRender, setIsRender] = useState(false);
    const [searchParams] = useSearchParams();


    useEffect(() => {
        if (title && title != '') {
            document.title = `${title} | ${DEFAULT_TITLE}`;
        } else {
            document.title = DEFAULT_TITLE;
        }
    }, [title])

    useEffect(() => {
        let data = localStorage.getItem('frenzi_user_access');
        if (data) {
            data = JSON.parse(data);
            if (data && data.access && data.access != "" && auth.token && auth.token != "" && auth.token == data.access) {
                let referer = searchParams.get('referer');
                if (referer == 'interactions') {
                    window.location.href = "/rrvcfam";
                } else {
                    if (referer && referer != '') {
                        window.location.href = referer;
                    } else {
                        window.location.href = "/";
                    }
                }
            } else {
                setIsRender(true);
            }
        } else {
            setIsRender(true);
        }
    }, []);

    return (
        <>
    
            {isRender &&
                <main><Outlet /></main>
            }
        </>
    )
}

