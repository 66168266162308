import axios from "axios";
import store from "./app/store";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access ? JSON.parse(data).access : null;
    return token;
};


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // baseURL: process.env.REACT_APP_STAGING_BASE_URL,
    // baseURL: 'https://vkaqft43bk.execute-api.ap-south-1.amazonaws.com/stage_server/frenzee/',
    // baseURL: 'https://a452-2405-201-3008-4187-7772-567-445a-a8ae.ngrok-free.app/frenzee/',
    headers: {
        Authorization: getToken() ? `Bearer ${getToken()}` : "",
    },
});

axiosInstance.interceptors.request.use((req) => {
    const { auth } = store.getState();
    if (auth.token) {
        req.headers.Authorization = `Bearer ${auth.token}`;
    }
    return req;
});

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        // console.log(err.response);
        const { status } = err.response;
        if (status === 401 || status === 403) {
            if (getToken() && getToken() != null) {
                window.location.href = "/";
                localStorage.clear();
            } else {
                if (err.config.method != 'get' || err.config.method != 'GET') {
                }
            }
            //     store.dispatch({ type: USER.LOGOUT_SUCCESS });
        }
        return Promise.reject(err.response);
    }
);

export default axiosInstance;

// export default axios.create({
// //   baseURL: process.env.REACT_APP_BASE_URL,
//   baseURL: process.env.REACT_APP_STAGING_BASE_URL,
//   headers: {
//     "Content-type": "application/json"
//   }
// });