import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import { useActionData } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// const Accordion = styled((props) => (
//     <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//     border: `1px solid ${theme.palette.divider}`,
//     '&:not(:last-child)': {
//         borderBottom: 0,
//     },
//     '&:before': {
//         display: 'none',
//     },
// }));

// const AccordionSummary = styled((props) => (
//     <MuiAccordionSummary
//         expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//         {...props}
//     />
// ))(({ theme }) => ({
//     backgroundColor:
//         theme.palette.mode === 'dark'
//             ? 'rgba(255, 255, 255, .05)'
//             : 'rgba(233, 69, 96, 1)',
//     flexDirection: 'row-reverse',
//     '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//         transform: 'rotate(90deg)',
//     },
//     '& .MuiAccordionSummary-content': {
//         marginLeft: theme.spacing(1),
//     },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//     padding: theme.spacing(2),
//     borderTop: '1px solid rgba(233, 69, 96, 1)',
// }));

export default function FAQ(props) {
    const { data } = props;
    const [expanded, setExpanded] = React.useState(0);
    const [isShowFaq, setIsShowFaq] = React.useState(false)

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleViewFaqs = () => {
        setIsShowFaq(current => !current)
    }

    return (
        <div className='faq-accordian'>
            {data.length > 0 ?
                <>
                    <Grid container justifyContent={'space-between'} className='faq-container-blog'>
                        <h2 className='m-0 fs-24 fw-700 p-10 text-dark'>FAQs</h2>
                        {/* <Button onClick={handleViewFaqs} className='text-selected rounded-btn mw-100 m-10' sx={{ bgcolor: '#F3F1FF' }}>view FAQs</Button> */}
                    </Grid>
                    {isShowFaq &&
                        <Grid container className='p-30 faq-container-blog'>
                            {data.map((x, i) => {
                                return <Grid item xs={12} className='pb-15'>
                                    <Accordion sx={{ overflow: 'hidden', boxShadow: ' 1px 1px 2px grey' }} expanded={expanded === i} onChange={handleChange(i)}>

                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className='br-40 h-25 w-25' sx={{ bgcolor: '#E94560' }} />}
                                            className='bg-light'
                                            aria-controls="panel1d-content"
                                            id="panel1d-header">
                                            <Typography className='text-dark' >{i + 1}. {x?.question || ''}</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails className='bg-light'>
                                            <Typography className='text-dark' >
                                                {x?.answer || ''}
                                            </Typography>
                                        </AccordionDetails>
                                        
                                    </Accordion>
                                </Grid>
                            })}
                        </Grid>
                    }
                </>
                :
                ''
            }
        </div>
    );
}