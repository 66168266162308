import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CastDataService from "../services/cast.services";
import helpers from "../services/helpers";
import { decryptData } from "../services/Utils";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    castData: {},
    personMediaPageData: {},
    personMediaData: [],
    mostFavPersonPageData: {},
    mostFavPersonData: [],
    topFansPageData: {},
    topFansData: []
};



export const getCastDetail = createAsyncThunk(
    "native_main/cinescorepersondetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.getCastDetail(data.id, data?.unique_title, data?.is_public)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPersonMedia = createAsyncThunk(
    "native_main/personmedia",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.getPersonMedia(data.id, data.page, data.pagelimit, data?.is_public)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getFavPerson = createAsyncThunk(
    "native_main/mostfavouriteperson",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.getFavPerson(data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const addRemovePersonFan = createAsyncThunk(
    "native_main/personfan",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.addRemovePersonFan(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getTopFans = createAsyncThunk(
    "native_main/cinescoretopfans",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.getTopFans(data.id, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const followUser = createAsyncThunk(
    "native_main/followuser",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.followUser(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const unFollowUser = createAsyncThunk(
    "native_main/unfollowuser",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CastDataService.unFollowUser(data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const castSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        clearCastData: (state) => {
            state.castData = {};
            state.topFansData = [];
            state.personMediaData = [];
            state.personMediaPageData = {};
            state.topFansPageData = {};
        }
    },
    extraReducers: {
        [getCastDetail.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getCastDetail.fulfilled]: (state, action) => {
            state.castData = getPayloadData(state, action);
        },
        [getCastDetail.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getPersonMedia
        [getPersonMedia.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getPersonMedia.fulfilled]: (state, action) => {
            state.personMediaPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.personMediaData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.personMediaData = helpers.removeDuplicate(state.personMediaData.concat(getArrayData(state, action)));
            }
        },
        [getPersonMedia.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFavPerson
        [getFavPerson.pending]: (state, action) => {
            // handlePendingRequest(state, action);
        },
        [getFavPerson.fulfilled]: (state, action) => {
            state.mostFavPersonPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.mostFavPersonData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.mostFavPersonData = helpers.removeDuplicate(state.mostFavPersonData.concat(getArrayData(state, action)));
            }
        },
        [getFavPerson.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getTopFans
        [getTopFans.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getTopFans.fulfilled]: (state, action) => {
            state.topFansPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.topFansData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.topFansData = helpers.removeDuplicate(state.topFansData.concat(getArrayData(state, action)));
            }
        },
        [getTopFans.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // addRemovePersonFan
        [addRemovePersonFan.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [addRemovePersonFan.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [addRemovePersonFan.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // followUser
        [followUser.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [followUser.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [followUser.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // unFollowUser
        [unFollowUser.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [unFollowUser.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [unFollowUser.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    }
})

export const { clearMessages, clearCastData } = castSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}

export default castSlice.reducer;