import React from 'react'
import { Grid, Box } from '@mui/material';
// import TopPoster from "../../../assets/User.png"
// import Seen from '../../../assets/icons/Seen.svg';
import helpers, { MEDIUM_TILE_BREAKPOINTS2 } from '../../../services/helpers';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import { Link } from 'react-router-dom';

const TopPoster = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/User.svg";
const Seen = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/Seen.svg";

const topList = [
    {
        id: 1,
        icon: TopPoster,
        title: "Sports",
        logo: Seen,
        count: '120',
    },
]

export default function TopCastList(props) {
    const { data } = props;

    return (
        <Grid item className='w-100 mt-20 pb-50'>
            <Swiper
                slidesPerView={2}
                spaceBetween={20}
                // slidesPerGroup={5}
                freeMode={true}
                navigation={true}
                speed={1000}
                breakpoints={MEDIUM_TILE_BREAKPOINTS2}
                modules={[FreeMode, Navigation]}
                className="mySwiper"
                autoHeight={true}
            >
                {data.map((x, i) => {
                    return <SwiperSlide key={i}>
                        <div style={{ padding: '20px 0px 20px 0px' }} key={i}>
                            <Grid item className='toplist-container h-100' alignItems={'center'} key={topList.id}>
                                <Grid container flexDirection={'column'} justifyContent="space-between" className='h-100' >
                                    <Grid xs={12} item>
                                        <Link to={`/person/${x?.unique_title || x?.name.replace(/ /g, "-")}${x?.unique_title ? '' : '/' + x?.person}`}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    aspectRatio: '3/4',
                                                    maxWidth: '100%',
                                                    borderTopLeftRadius: '8px',
                                                    borderTopRightRadius: '8px',
                                                    objectFit: 'cover',
                                                    objectPosition: 'top'
                                                }}
                                                src={x.genre_logo ? helpers.getFullUrl(x.genre_logo) : x.image ? helpers.getFullUrl(x.image) : x.icon ? helpers.getFullUrl(x.icon) : x.logo ? helpers.getFullUrl(x.logo) : x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('cinescoregenres')}
                                                alt='logo'
                                            />
                                        </Link>
                                        <Grid container justifyContent={'center'}>
                                            <div style={{ maxWidth: '80%' }}>
                                                <p title={x.genre ? x.genre : x.language ? x.language : x.decade ? x.decade + "0s" : x.ott_name ? x.ott_name : x?.name ? x.name : ''} className='fw-600 fs-16 m-0 text-center pb-12 ellipsis'>
                                                    {/* Sports */}
                                                    {x.genre ? x.genre : x.language ? x.language : x.decade ? x.decade + "0s" : x.ott_name ? x.ott_name : x?.name ? x.name : ''}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item className='toplist-bottom-container w-100'>
                                        <Grid container alignItems={'center'} justifyContent={'center'} className='w-100'>
                                            <Box
                                                className='pointer'
                                                component="img"
                                                sx={{
                                                    height: 24,
                                                    width: 24,
                                                    maxHeight: { xs: 24, sm: 24 },
                                                    maxWidth: { xs: 24, sm: 24 },
                                                }}
                                                src={Seen}
                                                alt='seen'
                                            />
                                            <span className='d-block ml-20 text-center fs-16 fw-500'>
                                                {x.views ? x.views : 0}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </SwiperSlide>
                })}
            </Swiper>
        </Grid >
    )
}
