import { Grid, IconButton, InputBase, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFollowers } from '../../slices/connect';

import UserData from './UserData';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import Loader from '../common/Loader';
import settings from '../../slices/settings';

export default function Followers(props) {
    const { heading, otheruser, userId, name } = props;
    const [page, setPage] = useState(1);
    const [searchVal, setSearchVal] = useState('');
    const dispatch = useDispatch();

    const connect = useSelector(state => state.connect);
    const { followersData, isLoading, followersPageData } = connect;
    const settings = useSelector((state) => state.settings);
    const { userDetails } = settings;


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleSearchTextChange = (e) => {
        setSearchVal(e.target.value);
    }

    useEffect(() => {
        if (otheruser && userId !== "") {
            dispatch(getFollowers({ search: searchVal, userId: userId, page: 1, pagelimit: 20 }));
        } else {
            dispatch(getFollowers({ search: searchVal, page: 1, pagelimit: 20 }));
        }
    }, [searchVal])

    useEffect(() => {
        if (otheruser && userId !== "") {
            dispatch(getFollowers({ search: searchVal, userId: userId, page: page, pagelimit: 20 }));
        } else {
            dispatch(getFollowers({ search: searchVal, page: page, pagelimit: 20 }));
        }
    }, [page])

    useEffect(() => {
        const handleScroll = () => {
            if (followersPageData && followersPageData && followersPageData.pagination && followersPageData.pagination.is_next_page && !isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, connect]);

    // console.log(followersData[1].uuid , "amanaman")
    return (
        <>
            {isLoading && <Loader />}
            <Grid item className="new-container pb-20">
                <Grid container alignItems={'center'} className='app-section' justifyContent="space-between">
                    <Grid item xs={12} sm={6}>
                        <h2 className="fw-700 fs-28 ellipsis">{heading && heading !== "" ? heading : <><span className='text-selected'>people</span> who follow {otheruser ? name || 'you' : 'you'}</>}</h2>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper
                            component="form"
                            style={{ opacity: 1 }}
                            className='w-100 d-flex ai-center br-25 relative searchbar'
                        >
                            <IconButton aria-label="search" className='bg-selected' sx={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}>
                                <SearchIcon className='ml-3 pt-2' color="light" />
                            </IconButton>
                            <InputBase
                                className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500'
                                value={searchVal}
                                type='text'
                                onChange={handleSearchTextChange}
                                placeholder="Search"
                            />
                            <IconButton aria-label="search" onClick={() => setSearchVal('')} className='h-100 bg-muted' sx={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                                <CloseIcon className='ml-3 pt-2' color="light" />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>
                {followersData && followersData.length > 0 &&
                    <Grid container className='app-section' spacing={2}>
                        {followersData.map((x, i) => {
                            {/* if (x.uuid !== userDetails.uuid) { */}
                                return <Grid className='followers-items' item xs={12} sm={6} md={3} key={i}>
                                    <UserData x={x} />
                                </Grid>
                            {/* } */}
                        })}
                    </Grid>
                }
            </Grid>
        </>
    )
}
