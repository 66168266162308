import { Box, Grid, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { clearMessages, getFeaturedReview, getFrenziAllReviews } from '../../slices/media';
import Loader from '../common/Loader';
import FeaturedReview from './FeaturedReview';
import TextReviews from './TextReviews';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import helpers from '../../services/helpers';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MyHelmet from '../common/MyHelmet';

const type = "text";

export default function ReviewDetail() {
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('newest');
    const params = useParams();
    const dispatch = useDispatch();
    const media = useSelector(state => state.media);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    var r_id = searchParams.get('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentLoading, setCurrentLoading] = useState(false);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentLoading(true);
    }, [])

    const handleSortChange = (type) => {
        setPage(1);
        setSort(type);
        handleClose();
    }

    useEffect(() => {
        if (media.errMesg && media.errMesg !== "") {
            helpers.toastError(media.errMesg);
            dispatch(clearMessages());
        }
        if (media.successMesg && media.successMesg !== "") {
            helpers.toastSuccess(media.successMesg);
            dispatch(clearMessages());
        }
    }, [media])

    useEffect(() => {
        if (type && type != "" && params.category && params.category !== "") {
            dispatch(getFeaturedReview({ media: r_id && r_id !== "" ? r_id : '', comment_type: type }));
            dispatch(getFrenziAllReviews({ media: r_id && r_id !== "" ? r_id : '', category: params.category, comment_type: type, page: page, pagelimit: 12, sort: sort })).then(() => {
                setTimeout(() => {
                    setCurrentLoading(false);
                }, 500)
            });
        }
    }, [page, params, sort])

    useEffect(() => {
        const handleScroll = () => {
            if (media.frenziAllReviewPageData && media.frenziAllReviewPageData[type] && media.frenziAllReviewPageData[type].pagination && media.frenziAllReviewPageData[type].pagination.is_next_page && !media.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                    console.log('loading....');
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, media]);

    return (
        <div className='new-container'>
            <MyHelmet
                title={`${type} reviews`}
                description={`Discover ${type} reviews on films and web series that provide valuable review for your next title, whether to watch the title or not.`}
            />
            {currentLoading && <Loader />}
            <Grid container alignItems={'center'} className="new-container pt-20 pb-20" justifyContent={'flex-start'}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item className='pointer' onClick={handleClickListItem}>
                        <Grid container alignItems={'center'} flexWrap={'nowrap'}>
                            <p className='fw-700 fs-15 m-0 mr-6 '>
                                {/* sort */}
                            </p>
                            <SwapVertIcon />
                        </Grid>
                    </Grid>
                    <ArrowBackRoundedIcon className='pointer' onClick={() => navigate(-1)} />
                </Grid>
                <Popover
                    id={'menbu'}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box className='pl-20 pr-20'>
                        <Grid onClick={() => handleSortChange('newest')} container className='pt-10 pb-0 pointer' sm={12} justifyContent={'start'}>
                            <p className={`fw-500 fs-16 m-0 ${sort == 'newest' && 'text-selected'}`} >Newest</p>
                        </Grid>
                        <Grid onClick={() => handleSortChange('popular')} container className='pt-10 pb-10 pointer' sm={12} justifyContent={'start'}>
                            <p className={`fw-500 fs-16 m-0 ${sort == 'popular' && 'text-selected'}`} >Popular</p>
                        </Grid>
                    </Box>
                </Popover>
            </Grid>
            {media.featuredReviewData && media.featuredReviewData.length > 0 &&
                <FeaturedReview data={media.featuredReviewData} />
            }
            {type == "text" && media.frenziAllReviewData && media.frenziAllReviewData['text'] && media.frenziAllReviewData['text'].length > 0 &&
                <TextReviews isLoading={media.isLoading} category={params.category} isDetailPage={true} data={media.frenziAllReviewData['text']} />
            }
        </div>
    )
}
