import React, { useState } from 'react'
import { Box } from '@mui/system';
import helpers from '../../services/helpers';
import { Grid } from '@mui/material';
import LikeAction from './LikeAction';

export default function Replies(props) {
    const { data } = props;


    return (
        <div>
            {data.replies && data.replies.length > 0 && data.replies.map((x, i) => {
                return <Grid container key={i} justifyContent='space-between' sx={{ marginTop: '20px' }}>
                    <Grid item>
                        <Grid container >
                            <Grid item>
                                <Box
                                    component={'img'}
                                    className='d-block'
                                    sx={{
                                        clipPath: 'circle()',
                                        width: { xs: '28px', sm: '36px', md: '40px' },
                                        height: { xs: '28px', sm: '36px', md: '40px' },
                                        backgroundColor: '#000'
                                    }}
                                    alt="profilepic"
                                    src={x?.profile_image ? helpers.getFullUrl(x?.profile_image) : helpers.getDefaultImage('profile')}
                                />
                            </Grid>
                            <Grid item sx={{ paddingLeft: '7px' }}>
                                <p className='m-0 fs-16 fw-500'>{x?.user_name}</p>
                                <span className='m-0 fs-12 text-muted'>{x?.reply}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box
                            className='text-center pointer'
                            sx={{
                                maxHeight: 48,
                                height: "100%",
                                borderRadius: '50%',
                            }}>
                            <LikeAction is_liked={x?.is_liked} data={x} type="reply_like" />
                        </Box>
                    </Grid>
                </Grid>
            })}
        </div>
    )
}
