import { Box, Button, FormControl, Grid, Select, InputLabel, Modal, TextField, MenuItem, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
// import OttBucket from '../../assets/img/OttBucket.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import OTPInput from 'otp-input-react';
import { countries } from "country-list-json";
import { KMSClient, EncryptCommand, DecryptCommand } from "@aws-sdk/client-kms";
import { Buffer } from 'buffer'

import helpers from '../../services/helpers';
import { updatePhoneEmail, verifyOtp } from '../../slices/settings';
import { useDispatch } from 'react-redux';
import './common.scss';
import { resendOtp } from '../../slices/auth';

const OttBucket = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/img/OttBucket.svg";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "90vw", sm: "70vw", md: "550px" },
    maxHeight: "97vh",
    overflowY: "auto",
    bgcolor: "#1A1A2E",
    boxShadow: 24,
    p: { xs: 2, sm: 3, md: 5 },
    borderRadius: 5,
    zIndex: 999,
};
const otpLength = 6;

const defaultCountry = {
    name: "India",
    dial_code: "+91",
    code: "IN",
    flag: "🇮🇳",
};

const clientParams = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const config = {
    credentials: clientParams,
    endPoint: "",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    signatureVersion: 2,
    region: process.env.REACT_APP_AWS_REGION
}

export default function MobileVerify(props) {
    const [isVerify, setIsVerify] = useState(false);
    const [otpData, setOtpData] = useState(null);
    const [OTP, setOTP] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState(defaultCountry);
    const [open, setOpen] = useState(false);
    const [currentLoading, setCurrentLoading] = useState(false);
    const [timer, setTimer] = useState(30);

    const client = new KMSClient(config);
    const dispatch = useDispatch();


    useEffect(() => {
        if (timer > 0) {
            var tempInterval = setInterval(() => {
                setTimer((prevState) => prevState - 1);
            }, 1000)
        } else {
            if (tempInterval) {
                setTimer(0);
                clearInterval(tempInterval);
            }
        }
        return () => clearInterval(tempInterval);
    }, [timer])

    const handleResendOtp = () => {
        const payload = {
            otp_id: otpData?.otp_id
        }
        setOTP('');
        setTimer(30);
        performFormSubmitProcess(payload, true);
    }

    const handleChange = (value) => {
        setCountryCode(value);
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        // return bytes.buffer;
        return bytes;
    }

    const handleOtpResponse = async (responseText) => {
        var cipherText = _base64ToArrayBuffer(responseText);

        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            CiphertextBlob: cipherText
        }
        try {
            const command = new DecryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.Plaintext) {
                let win1251decoder = new TextDecoder('windows-1251');
                let bytes = new Uint8Array(response.Plaintext);
                var decryptedText = win1251decoder.decode(bytes);
                var jsonData = JSON.parse(decryptedText);
                if (jsonData && !helpers.isEmptyObject(jsonData)) {
                    setOtpData(jsonData);
                    setTimer(30);
                }
                else {
                    setOtpData(null);
                }
            } else {
                helpers.toastError('Data decryption failed !!!');
            }
        } catch (error) {
            // error handling.\
            helpers.toastError(error);
        }
    }

    const handleVerifyPhone = () => {
        performFormSubmitProcess({ mobile: phoneNumber });
    }

    const performFormSubmitProcess = async (tempForm, resend = false) => {
        const encoder = new TextEncoder();
        setCurrentLoading(true);
        var unit8String = encoder.encode(JSON.stringify(tempForm));
        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            Plaintext: unit8String
        }
        try {
            const command = new EncryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.CiphertextBlob) {
                let buff = new Buffer(response.CiphertextBlob);
                let base64data = buff.toString('base64');
                if (resend) {
                    dispatch(resendOtp({ data: base64data })).then(() => {
                        setCurrentLoading(false);
                    });
                } else {
                    dispatch(updatePhoneEmail({ body: { data: base64data } })).then((resp) => {
                        if (resp.payload?.message && resp.payload.message === 'otp sent') {
                            helpers.toastSuccess("Otp Sent");
                            handleOtpResponse(resp.payload?.data?.data);
                        }
                        if (!resp.payload?.status) {
                            helpers.toastError(resp.payload?.message);
                        }
                        setCurrentLoading(false);
                    })
                }
            } else {
                helpers.toastError('Data encryptiton failed !!!');
                setCurrentLoading(false);
            }
        } catch (error) {
            // error handling.\userLogin
            console.log(error)
            helpers.toastError(JSON.stringify(error));
            setCurrentLoading(false);
        }
    }

    const handleVerifyOtp = async () => {
        const tempForm = {
            mobile: phoneNumber,
            otp: OTP,
            otp_id: otpData?.otp_id
        }
        const encoder = new TextEncoder();
        setCurrentLoading(true);
        var unit8String = encoder.encode(JSON.stringify(tempForm));
        const input = {
            KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
            Plaintext: unit8String
        }
        try {
            const command = new EncryptCommand(input);
            const response = await client.send(command);
            // process data.
            if (response && response.CiphertextBlob) {
                let buff = new Buffer(response.CiphertextBlob);
                let base64data = buff.toString('base64');

                dispatch(verifyOtp({ body: { data: base64data } })).then((resp) => {
                    helpers.toastSuccess(resp?.payload?.message);
                    props?.handleClose();
                    setCurrentLoading(false);
                });
            } else {
                helpers.toastError('Data encryptiton failed !!!');
                setCurrentLoading(false);
            }
        } catch (error) {
            // error handling.\userLogin
            console.log(error)
            helpers.toastError(JSON.stringify(error));
            setCurrentLoading(false);
        }
    }

    return (
        <div>
            <Modal
                open={props?.open}
                onClose={props?.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseRoundedIcon sx={{ float: 'right' }} onClick={props?.handleClose} className='animate-close pointer close-icon' />
                    {isVerify ?
                        <>
                            <h2 className='fs-28 fw-700 lh-28 m-0 text-center'>verify mobile no</h2>
                            {otpData?.otp_id ?
                                <>
                                    <h2 className='text-muted fs-18 mb-0'>we'll text you with an OTP to confirm your number.</h2>
                                    <h2 className='text-muted fs-18 mt-0'>standard message and data rates apply.</h2>
                                    <OTPInput className="otp-container" inputmode={'numeric'} value={OTP} onChange={setOTP} autoFocus OTPLength={otpLength} otpType="number" disabled={false} secure />
                                    <Grid container justifyContent={'flex-end'} alignItems='center' style={{ marginTop: '10px' }}>
                                        {timer && timer > 0 ?
                                            <Grid className='pl-20 mt-12' item>
                                                <h2 className='fw-500 text-muted fs-14 m-0'>Resend OTP in <span className='text-selected'>{timer}s</span></h2>
                                            </Grid>
                                            :
                                            <Grid onClick={() => handleResendOtp()} className='pl-20 mt-12' item>
                                                <h2 className='fw-500 pointer fs-14 m-0'>Resend OTP</h2>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid container justifyContent={'space-between'} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                                        <Button disabled={currentLoading} onClick={() => handleVerifyOtp()} sx={{ maxWidth: { xs: '100%', sm: '200px' } }} className='w-100 rounded-btn bg-selected text-light'>{currentLoading ? <CircularProgress color='success' /> : 'verify mobile number'}</Button>
                                        <Button onClick={() => setOtpData(null)} sx={{ maxWidth: { xs: '100%', sm: '120px' }, marginTop: { xs: '20px', sm: 0 } }} className='w-100 rounded-btn bg-light text-selected'>back</Button>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid container justifyContent={'center'} spacing={1} className="mt-30">
                                        <Grid item>
                                            <Box sx={{ minWidth: { xs: 100, sm: 120 } }}>
                                                <FormControl fullWidth className="country-code-select">
                                                    <InputLabel id="demo-simple-select-label2">
                                                        Country Code
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label2"
                                                        id="country-code-select"
                                                        value={countryCode.dial_code}
                                                        label="country code"
                                                        open={open}
                                                        onClose={handleClose}
                                                        onOpen={handleOpen}
                                                    >
                                                        {countries &&
                                                            countries.length > 0 &&
                                                            countries.map((x, i) => {
                                                                return (
                                                                    <MenuItem onClick={() => handleChange(x)}>
                                                                        <Grid container justifyContent={"space-between"}>
                                                                            <Grid>{x.name}</Grid>
                                                                            <Grid>{x.dial_code}</Grid>
                                                                        </Grid>
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                    <Grid
                                                        className="country-selected"
                                                        onClick={() => handleOpen()}
                                                    >
                                                        <Grid container>
                                                            <Grid item className="flag">
                                                                {countryCode.flag}
                                                            </Grid>
                                                            <Grid item className="code fs-16 lh-24">
                                                                {countryCode.dial_code}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item flex={1}>
                                            <TextField
                                                error
                                                id="outlined-errors-helper-text"
                                                label="Phone number"
                                                className='w-100'
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent={'space-between'} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                                        <Button disabled={currentLoading} onClick={() => handleVerifyPhone()} sx={{ maxWidth: { xs: '100px', sm: '120px' } }} className='w-100 rounded-btn bg-selected text-light'>{currentLoading ? <CircularProgress color='success' /> : 'continue'}</Button>
                                        <Button onClick={props?.handleClose} sx={{ maxWidth: { xs: '100px', sm: '120px' } }} className='w-100 rounded-btn bg-light text-selected'>skip</Button>
                                    </Grid>
                                </>
                            }

                        </>
                        :
                        <>
                            <h2 className='fs-28 fw-700 lh-28 m-0 text-center'>verify your mobile number</h2>
                            <Grid container justifyContent={'center'} alignItems='center'>
                                <h2 className='fw-500 fs-24 mt-0 mb-0 text-center' style={{ marginRight: '10px' }}>earn </h2>
                                <span style={{ marginRight: '10px' }} className='text-selected fs-32 fw-700'>100</span>
                                <h2 className='fw-500 fs-24 m-0 fw-500 text-center'>points</h2>
                            </Grid>
                            <Grid container justifyContent={'center'} alignItems='center'>
                                <Box
                                    component={'img'}
                                    src={OttBucket}
                                    style={{
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}
                                />
                            </Grid>
                            <h2 className='fw-400 fs-22 mt-0 mb-0 text-center'>and redeem for deals across</h2>
                            <Grid container justifyContent={'space-between'} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Button onClick={() => setIsVerify(true)} sx={{ maxWidth: { xs: '100px', sm: '120px' } }} className='w-100 rounded-btn bg-selected text-light'>verify now</Button>
                                <Button onClick={props?.handleClose} sx={{ maxWidth: { xs: '100px', sm: '120px' } }} className='w-100 rounded-btn bg-light text-selected'>skip</Button>
                            </Grid>
                        </>
                    }
                </Box>
            </Modal>
        </div >
    )
}
