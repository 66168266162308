import Grid from '@mui/material/Grid'
import React, { useEffect, useRef, useState } from 'react'
import { Paper, IconButton, InputBase, Typography, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import helpers from '../../../services/helpers';
import { Box } from '@mui/system';
import { resetSearchData, searchWatchlist } from '../../../slices/watchlist';


export default function SearchWatchlist(props) {
    const { isFullWidth } = props;
    const [value, setValue] = useState('');
    const watchlist = useSelector(state => state.watchlist);
    const inputRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (value && value != "") {
            dispatch(searchWatchlist({ query: value, page: 1, pagelimit: 20 }))
        } else {
            dispatch(resetSearchData());
        }
    }, [value])


    return (
        <Grid container justifyContent={'center'}>
            <Grid sx={{ paddingTop: { xs: '20px', sm: '30px' }, paddingBottom: { xs: '20px', sm: '30px' } }} className={`${isFullWidth ? '' : 'app-container'}`}>
                <div className='relative pb-10'>
                    <Paper
                        component="form"
                        style={{ opacity: 1, border: '1px solid grey' }}
                        className='w-100 d-flex ai-center br-25 relative mb-5 mt-20'
                        sx={{ maxHeight: { xs: '32px', sm: '40px' } , minWidth:{sm:'700px',xs:'300px'} }}
                    >
                        <IconButton aria-label="search" className='bg-primary'
                            sx={{
                                borderBottomRightRadius: '0px',
                                borderTopRightRadius: '0px',
                                maxHeight: { xs: '22px', sm: '30px' },
                                maxWidth: { xs: '32px', sm: '40px' }
                            }}
                        >
                            <SearchIcon className='ml-3 pt-2' color="light" />
                        </IconButton>
                        <InputBase
                            className='f-1 fs-14 pl-10 lh-18 ls-1 fw-500 text-muted'
                            value={value}
                            type='text'
                            inputRef={inputRef}
                            placeholder={'search sharelist/watchlist'}
                            onChange={(e) => setValue(e.target.value)}
                        />

                        {/* <IconButton aria-label="search" onClick={() => setValue('')} className='h-100 bg-muted' sx={{
                        borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px',
                        maxHeight: { xs: '32px', sm: '40px' },
                        maxWidth: { xs: '32px', sm: '40px' }
                    }}>
                        <CloseIcon className='ml-3 pt-2' color="light" />
                    </IconButton> */}
                    </Paper>
                    {watchlist.searchWatchlistPageData && watchlist.searchWatchlistPageData.results && watchlist.searchWatchlistPageData.results.length > 0 &&
                        <Grid container className='search-result-show'>
                            <Grid item className='mh-300 bg-light w-100 br-10'
                                sx={{ overflowY: 'auto' }} lg={12}>
                                {watchlist.searchWatchlistPageData.results.map((item, i) => {
                                    return <>
                                        <Link onClick={() => helpers.watchlistClick(item?.category_name, item?.uuid, 'Watchlist')} to={`/watchlist-detail/${item?.uuid}`}>
                                            <Grid container key={i} className='pl-20 pt-10 pointer ai-center' alignItems={'center'}>
                                                <Grid item className='h-50px mw-65 w-100 pr-20'>
                                                    <Box
                                                        component="img"
                                                        alt="poster"
                                                        src={
                                                            item.posters && item.posters && item.posters[0] ? helpers.getFullUrl(item.posters[0]?.watchlist__media__poster_path) : helpers.getDefaultImage('search')
                                                        }
                                                        sx={{
                                                            height: '65px',
                                                            width: '50px',
                                                            borderRadius: '7px'
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography className='text-dark fw-500'>{helpers.getSelectedText(item?.category_name ? item?.category_name : '', value)}</Typography>
                                                    <p className='fw-300 fs-14 m-0 p-0 text-muted'>created by : {item?.owner_username}</p>
                                                </Grid>
                                            </Grid>
                                            <Grid container className='pl-20 pr-20'>
                                                <Box className='w-100'>
                                                    <Divider />
                                                </Box>
                                            </Grid>
                                        </Link>
                                    </>
                                })}
                            </Grid>
                        </Grid>
                    }
                </div>
            </Grid>
        </Grid>
    )
}
