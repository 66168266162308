import axiosInstance from "../http-common";

const getCinescoreData = () => {
    return axiosInstance.get(`native_main/cinescore`);
};

const getCinescoreDetails = (body, page = 1, pagelimit = 10, media_type = "", sort = "", only_poster = 'no') => {
    return axiosInstance.post(`native_main/cinescoredetail?media_type=${media_type}&sort=${sort}&only_poster=${only_poster}&page=${page}&pagelimit=${pagelimit}`, body);
};

const getCinescoreGenre = () => {
    return axiosInstance.get(`native_main/cinescoregenre`);
};

const getCinescorePlatform = () => {
    return axiosInstance.get(`native_main/cinescoreplatform`);
};

const getCinescoreDecade = () => {
    return axiosInstance.get(`native_main/cinescoredecade`);
};

const getCinescoreLang = () => {
    return axiosInstance.get(`native_main/cinescorelanguage`);
};

const getCinescorePersonData = (section, page = 1, pagelimit = 20) => {
    return axiosInstance.get(`native_main/cinescoreperson?section=${section}&page=${page}&pagelimit=${pagelimit}`);
};

const getCinescoreDetailsByMediaType = ({page=1, pagelimit=1, exclude_already_watched = "yes", media_type = "", prefered_platforms="yes"}) => {
    return axiosInstance.post(`native_main/alreadywatched?page=${page}&pagelimit=${pagelimit}&exclude_already_watched=${exclude_already_watched}&media_type=${media_type}&prefered_platforms=${prefered_platforms}`);
};



const CinescoreService = {
    getCinescoreData,
    getCinescoreDetails,
    getCinescoreGenre,
    getCinescorePlatform,
    getCinescoreDecade,
    getCinescoreLang,
    getCinescorePersonData,
    getCinescoreDetailsByMediaType
};

export default CinescoreService;