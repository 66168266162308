import { Grid } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFreshArrivalMovieData, getFreshArrivalTvData, getFreshTrailers, getMostSearchedTitles } from '../../../slices/trending';
import CarouselTile from '../../common/CarouselTile';
import CommonDetailTileListing from '../../common/CommonDetailTileListing';
import CommonMediumTileListing from '../../common/CommonMediumTileListing';
import Loader from '../../common/Loader';
// import { CustomCarousel } from '../../component/custom3DCarousel/CustomCarousel';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import MyHelmet from '../../common/MyHelmet';
import MyAds from '../../adsense/MyAds';
import SectionHeading from '../../component/headings/SectionHeading';
import ContentAccordion from '../../common/ContentAccordion';

const tabValue = 'all';

export default function Fresh(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const trending = useSelector(state => state.trending);
    const global = useSelector(state => state.global);
    const { freshMovieData, freshTvData, freshSearchedData, freshTrailerData, isLoading } = trending;
    const [searchParams] = useSearchParams();
    const is_free = searchParams.get('is_free') && searchParams.get('is_free') == 'true' ? true : false;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        dispatch(getFreshArrivalMovieData({ is_public: global.is_public, is_free: is_free, body: {}, media_type: 'movie', sort: '', date_range: tabValue, page: 1 }));
        dispatch(getFreshArrivalTvData({ is_public: global.is_public, is_free: is_free, body: {}, media_type: 'tv', sort: '', date_range: tabValue, page: 1 }));
        dispatch(getMostSearchedTitles({ is_public: global.is_public, is_free: is_free, body: {}, media_type: 'all', sort: 's', date_range: tabValue, page: 1 }));
        dispatch(getFreshTrailers({ is_public: global.is_public, is_free: is_free, date_range: tabValue, page: 1 }));
    }, [tabValue])


    return (
        <>
            <MyHelmet
                title={`Fresh ${global.is_public ? '& Free' : ''} on OTT`}
                og_title={`Watch New Release Movies & Series ${global.is_public ? 'for Free' : ''} on OTT`}
                description={`Find the latest free movies and series on OTT platforms. Stay updated with fresh ${global.is_public ? 'and free' : ''} content on MyFrenzi.`}
                keywords={`fresh content, ${global.is_public ? 'free content' : ''}, OTT platforms, MyFrenzi`}
            />
            {isLoading && <Loader />}
            {/* <CommonMenu
                setTabValeu={(val) => setTabValue(val)}
                menuList={menuList}
                tabValue={tabValue}
                isBack={true}
            /> */}
            <div className="new-container">
                <ContentAccordion
                    isFull={true}
                    title={'FRESH_H'}
                    data={'FRESH'}
                />
            </div>
            <Grid className='new-container pb-20'>
                <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_fresh_fold_ad'} hideInMobile={true} />
                    </Grid>
                </Grid>
                <Grid container alignItems={'center'} className="pt-20" justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                            </Grid>
                            <Grid item>
                                <SectionHeading title={<><span className='text-selected'>fresh </span> <span>arrivals</span></>} />
                            </Grid>
                            {/* <h2 className='fs-28 fw-700 lh-28 m-0'><span className='text-selected'>fresh </span> arrivals</h2> */}
                        </Grid>
                    </Grid>
                </Grid>
                <CommonMediumTileListing
                    heading='movie'
                    subheading=''
                    isShowHeading={true}
                    nextLink={`/new-${is_free ? 'free-' : ''}release-on-ott/movie?is_free=${is_free ? true : false}`}
                    data={freshMovieData}
                    refPage={'Fresh Arrivals'}
                />
                <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_fresh_fold_ad'} />
                    </Grid>
                </Grid>
                {freshTrailerData && freshTrailerData.length > 0 &&
                    <div className='mt-30 mb-10'>
                        {/* <CustomCarousel
                            autoplay={true}
                            interval={3000}
                            slides={getSlides(freshTrailerData)}
                            isShowDots={true}
                        /> */}
                        <Grid xs={12}>
                            <Swiper
                                // spaceBetween={30}
                                centeredSlides={true}
                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                //     pauseOnMouseEnter: true
                                // }}
                                loop={true}
                                // pagination={{
                                //   clickable: true,
                                // }}
                                speed={1000}
                                navigation={true}
                                modules={[Autoplay, Navigation]}
                                className="mySwiper"
                            >
                                {freshTrailerData.map((x, i) => {
                                    return <SwiperSlide>
                                        <CarouselTile ref_page={'Fresh Arrivals'} x={x} isMediaSubDetails={true} />
                                    </SwiperSlide>
                                })}
                            </Swiper>
                        </Grid>
                    </div>
                }
                <CommonMediumTileListing
                    heading='web series'
                    subheading=''
                    isShowHeading={true}
                    nextLink={`/new-${searchParams.get('is_free') ? 'free-' : ''}release-on-ott/tv${searchParams.get('is_free') ? '?is_free=true' : ''}`}
                    data={freshTvData}
                    refPage={'Fresh Arrivals'}
                />
                <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_fresh_fold_ad'} />
                    </Grid>
                </Grid>
                <CommonDetailTileListing
                    heading='most searched titles'
                    subheading='new titles that are gaining attention across platforms'
                    isShowHeading={true}
                    nextLink={`/new-${searchParams.get('is_free') ? 'free-' : ''}release-on-ott/most-searched${searchParams.get('is_free') ? '?is_free=true' : ''}`}
                    data={freshSearchedData}
                    refPage="Fresh Arrivals"
                />
                <Grid item key={`ads-last`} xs={12} style={{ marginTop: '10px' }} >
                    <Grid container justifyContent={'center'}>
                        <MyAds type="banner_2" adv_type={'web_fresh_fold_ad'} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
