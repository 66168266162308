import React, { useState } from "react";
import { Box, Grid, Typography, Avatar, Stack } from "@mui/material";
import helpers from "../../services/helpers";
import { Link } from "react-router-dom";
import BookmarkAction from "../watchlist/BookmarkAction";
import NotifyMe from "./NotifyMe";
import MediaSubdetails from "./MediaSubdetails";
import OttShow from "./OttShow";
import SeenMovie from "./SeenMovie";
import RateMovie from "./RateMovie";
import MediaTrailerBtn from "./MediaTrailerBtn";

export default function SingleMediaTile(props) {
  const { x, showNotifyButton, comingSoon, size } = props;
  const [addedId, setAddeddId] = useState("");

  return (
    <>
      {size === "sm" ? (
        <Grid container flexWrap={"nowrap"} className="mb-20">
          <Grid
            item
            className="scale-1"
            xs={6}
            sx={{ maxWidth: { xs: 80, sm: 90 }, marginRight: "20px" }}
          >
            <div className="relative">
              <Link
                onClick={() =>
                  helpers.mediaClick(
                    x?.title,
                    x.media ? x.media : x.uuid ? x.uuid : "",
                    props?.refPage
                  )
                }
                to={helpers.getMediaLink(x)}
              >
                <Box
                  component="img"
                  className="d-block"
                  sx={{
                    height: 215,
                    width: "100%",
                    maxHeight: { xs: 180, sm: 125 },
                    maxWidth: { xs: 120, sm: 90 },
                    minWidth: { xs: 120, sm: 70 },
                    borderRadius: "4px",
                  }}
                  alt={x?.title || "poster"}
                  src={
                    x.poster
                      ? helpers.getFullUrl(x.poster, 185)
                      : helpers.getDefaultImage("trendingmedia")
                  }
                />
              </Link>
              {x.media_type &&
                (x.media_type === "movie" || x.media_type === "tv") && (
                  <BookmarkAction
                    data={x}
                    addedId={addedId}
                    setAddedId={setAddeddId}
                  />
                )}
              {
                <Grid item xs={12} textAlign={"center"}>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ minWidth: { xs: "30px", sm: "80px", xl: "80px" } }}
                    flexWrap={"nowrap"}
                    className="bottom-right"
                  >
                    <p
                      style={{ textTransform: "uppercase" }}
                      className="m-0 fs-12 pl-10"
                    >
                      {" "}
                      {x.media_type ? x.media_type : ""}{" "}
                    </p>
                    <p className="m-0 fs-12 pl-5 pr-5"> | </p>{" "}
                    <p
                      className="m-0 fs-12 pr-10"
                      style={{ textTransform: "uppercase" }}
                    >
                      {" "}
                      {x.language ? x.language : ""}
                    </p>
                  </Grid>
                </Grid>
              }
              {props.isTopTen &&
                props.dataLength &&
                (props.index === 0 || props.index > 0) && (
                  <p className="top-ten-txt m-0">
                    {props.dataLength - props.index}
                  </p>
                )}
              {/* <Typography title={props.platform && props.platform != "" ? props.platform + (x.data && x.data.length > 1 ? " +" + (x.data.length - 1) : '') : x.in_theater ? 'in cinemas' : x.data && x.data[0] ? x.data[0]?.ott_name : ''} className='in-cinema-containetr text-center fs-18 pb-5  fw-500 ellipsis pl-5 pr-5'>
                    {props.platform && props.platform != "" ? props.platform + (x.data && x.data.length > 1 ? " +" + (x.data.length - 1) : '') : x.in_theater ? 'in cinemas' : x.data && x.data[0] ? x.data[0]?.ott_name : 'coming soon'}
                </Typography> */}
              {x.in_theater ? (
                <Typography className="in-cinema-containetr text-center fs-18 pb-5  fw-500 ellipsis pl-5 pr-5">
                  in cinemas
                </Typography>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              justifyContent={"space-between"}
              className={"h-100"}
              flexDirection={"column"}
            >
              <Grid item sx={{ width: "100%" }}>
                {showNotifyButton && (
                  <>
                    {x.release_date && x.release_date !== "" ? (
                      <p className="p-0 fs-14 text-muted m-0">
                        releasing on {x.release_date.toLowerCase()}
                      </p>
                    ) : (
                      <p className="p-0 fs-14 text-muted m-0">
                        coming soon on ott
                      </p>
                    )}
                  </>
                )}
                <Link
                  onClick={() =>
                    helpers.mediaClick(
                      x?.title,
                      x.media ? x.media : x.uuid ? x.uuid : "",
                      props?.refPage
                    )
                  }
                  to={helpers.getMediaLink(x)}
                >
                  <Typography
                    title={x.title}
                    className="fs-18 fw-600 lh-22 ellipsis"
                  >
                    {x.title}
                  </Typography>
                </Link>
                <MediaSubdetails data={x} size={size} />
              </Grid>
              <Grid
                item
                sx={{ width: "100%", paddingTop: "10px", paddingBottom: "2px" }}
              >
                {x.notify_me_data && x.notify_me_data.length > 0 && (
                  <Stack
                    direction="row"
                    sx={{ marginBottom: "20px" }}
                    className="ai-center mt-10 mb-10"
                  >
                    {x.notify_me_data.map((item, j) => {
                      if (j < 3) {
                        return (
                          <Avatar
                            key={j}
                            sx={{
                              width: 36,
                              height: 36,
                              maxWidth: { xs: 24, sm: 36 },
                              maxHeight: { xs: 24, sm: 36 },
                            }}
                            alt="Remy Sharp"
                            src={
                              item.user__profile_image
                                ? helpers.getFullUrl(item.user__profile_image)
                                : helpers.getDefaultImage("halfsmallprofile")
                            }
                            className="ott-avatar"
                          />
                        );
                      }
                    })}
                    <Typography className="fs-16 fw-500 lh-23 ml-8">
                      {" "}
                      {x.total_notify_me > 3
                        ? `+${x.total_notify_me - 3}`
                        : ""}{" "}
                      notified it
                    </Typography>
                  </Stack>
                )}
                <Grid>
                  <Grid
                    container
                    spacing={2}
                    flexWrap="nowrap"
                    alignItems={"center"}
                    className="mw-500"
                  >
                    {comingSoon ? (
                      <Grid item>
                        <MediaTrailerBtn
                          mediaData={x}
                          ref_page={props?.refPage}
                          controls={true}
                          isPlay={true}
                          data={x.trailer}
                          sx={{
                            backgroundColor: "#3f3d56",
                            borderRadius: "20px",
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <RateMovie
                            size={22}
                            height={32}
                            width={32}
                            hideLabel={true}
                            data={x}
                          />
                        </Grid>
                        <Grid item>
                          <SeenMovie
                            size={22}
                            height={32}
                            width={32}
                            hideLabel={true}
                            data={x}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={7}>
                      {!showNotifyButton && x.data && x.data.length > 0 ? (
                        <OttShow data={x.data} />
                      ) : showNotifyButton ? (
                        <NotifyMe
                          uuid={x.media ? x.media : x?.uuid}
                          notified={x?.notify_me ? true : false}
                          label={"notify me"}
                          data={x}
                          isWhite={true}
                          className={"mw-110 fs-10 lh-15"}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container flexWrap={"nowrap"} sx={{ borderRadius: '15px' }} className="bg-secondary p-20">
          <Grid
            item
            className="scale-1 "
            xs={6}
            sx={{ maxWidth: { xs: 120, sm: 149 }, marginRight: "20px", }}
          >
            <div className="relative ">
              <Link
                onClick={() =>
                  helpers.mediaClick(
                    x?.title,
                    x.media ? x.media : x.uuid ? x.uuid : "",
                    props?.refPage
                  )
                }
                to={helpers.getMediaLink(x)}
              >
                <Box
                  component="img"
                  className="d-block"
                  sx={{
                    height: 215,
                    width: "100%",
                    maxHeight: { xs: 180, sm: 215 },
                    maxWidth: { xs: 120, sm: 149 },
                    minWidth: { xs: 120, sm: 149 },
                    borderRadius: "4px",
                  }}
                  alt={x?.title || "poster"}
                  src={
                    x.poster
                      ? helpers.getFullUrl(x.poster, 185)
                      : helpers.getDefaultImage("trendingmedia")
                  }
                />
              </Link>
              {/* {x.media_type &&
                (x.media_type === "movie" || x.media_type === "tv") && (
                  <BookmarkAction
                    data={x}
                    addedId={addedId}
                    setAddedId={setAddeddId}
                  />
                )} */}
              {
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems={"center"}
                    sx={{ maxWidth: { xs: "40px", sm: "30px", xl: "30px" } }}
                    flexWrap={"nowrap"}
                    className="bottom-right w-100"
                  >
                    <p
                      style={{ textTransform: "uppercase" }}
                      className="m-0 pl-10 fs-12"
                    >
                      {" "}
                      {x.media_type ? x.media_type : ""}{" "}
                    </p>
                    <p className="m-0 pl-5 pr-5 fs-12"> | </p>{" "}
                    <p
                      className="m-0 pr-10 fs-12"
                      style={{ textTransform: "uppercase" }}
                    >
                      {" "}
                      {x.language ? x.language : ""}
                    </p>
                  </Grid>
                </Grid>
              }
              {props.isTopTen &&
                props.dataLength &&
                (props.index === 0 || props.index > 0) && (
                  <p className="top-ten-txt fs-64 m-0">
                    {props.dataLength - props.index}
                  </p>
                )}
              {/* <Typography title={props.platform && props.platform != "" ? props.platform + (x.data && x.data.length > 1 ? " +" + (x.data.length - 1) : '') : x.in_theater ? 'in cinemas' : x.data && x.data[0] ? x.data[0]?.ott_name : ''} className='in-cinema-containetr text-center fs-18 pb-5  fw-500 ellipsis pl-5 pr-5'>
                        {props.platform && props.platform != "" ? props.platform + (x.data && x.data.length > 1 ? " +" + (x.data.length - 1) : '') : x.in_theater ? 'in cinemas' : x.data && x.data[0] ? x.data[0]?.ott_name : 'coming soon'}
                    </Typography> */}
              {x.in_theater ? (
                <Typography className="in-cinema-containetr text-center fs-18 pb-5  fw-500 ellipsis pl-5 pr-5">
                  in cinemas
                </Typography>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              justifyContent={"space-between"}
              className={"h-100"}
              flexDirection={"column"}
            >
              <Grid item sx={{ width: "100%" }}>
                {showNotifyButton && (
                  <>
                    {x.release_date && x.release_date !== "" ? (
                      <p className="p-0 fs-14 text-muted m-0">
                        releasing on {x.release_date.toLowerCase()}
                      </p>
                    ) : (
                      <p className="p-0 fs-14 text-muted m-0">
                        coming soon on ott
                      </p>
                    )}
                  </>
                )}
                <Link
                  onClick={() =>
                    helpers.mediaClick(
                      x?.title,
                      x.media ? x.media : x.uuid ? x.uuid : "",
                      props?.refPage
                    )
                  }
                  to={helpers.getMediaLink(x)}
                >
                  <Typography
                    title={x.title}
                    className="fs-18 fw-600 lh-32 ellipsis"
                  >
                    {x.title}
                  </Typography>
                </Link>
                <MediaSubdetails className="fs-10" data={x} />
              </Grid>
              <Grid
                item
                sx={{  paddingTop: "10px", paddingBottom: "2px" }}
              >
                <Grid>
                  <Grid
                    container
                    // spacing={2}
                    flexWrap="wrap"
                    alignItems={"center"}
                    className="mw-500"
                  >
                    {/* {comingSoon ? (
                      <Grid item xs={12} lg={6}>
                        <MediaTrailerBtn
                          mediaData={x}
                          ref_page={props?.refPage}
                          controls={true}
                          isPlay={true}
                          data={x.trailer}
                          sx={{
                            backgroundColor: "#3f3d56",
                            borderRadius: "20px",
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <RateMovie
                            size={22}
                            height={32}
                            width={32}
                            hideLabel={true}
                            data={x}
                          />
                        </Grid>
                        <Grid item>
                          <SeenMovie
                            size={22}
                            height={32}
                            width={32}
                            hideLabel={true}
                            data={x}
                          />
                        </Grid>
                      </>
                    )} */}
                    <Grid item xs={'auto'} sm={'auto'} lg={7.1} className="mt-20 mb-10">
                      {!showNotifyButton && x.data && x.data.length > 0 ? (
                        <OttShow data={x.data} />
                      ) : showNotifyButton ? (
                        <NotifyMe
                          uuid={x.media ? x.media : x?.uuid}
                          notified={x?.notify_me ? true : false}
                          label={"notify me"}
                          data={x}
                          isWhite={true}
                        // className={"w-100 fs-6 lh-15"}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={'auto'} sm={3} lg={4.5} className="mt-20 mb-10">
                      <RateMovie
                        size={22}
                        height={32}
                        width={32}
                        hideLabel={true}
                        data={x}
                        type={'emoji'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* {x.notify_me_data && x.notify_me_data.length > 0 && (
                  <Stack
                    direction="row"
                    sx={{ marginBottom: "20px" }}
                    className="ai-center mt-10 mb-10"
                  >
                    {x.notify_me_data.map((item, j) => {
                      if (j < 3) {
                        return (
                          <Avatar
                            key={j}
                            sx={{
                              width: 30,
                              height: 30,
                              maxWidth: { xs: 24, sm: 36 },
                              maxHeight: { xs: 24, sm: 36 },
                            }}
                            alt="Remy Sharp"
                            src={
                              item.user__profile_image
                                ? helpers.getFullUrl(item.user__profile_image)
                                : helpers.getDefaultImage("halfsmallprofile")
                            }
                            className="ott-avatar"
                          />
                        );
                      }
                    })}
                    <Typography className="fs-14 fw-500 lh-23 ml-8">
                      {" "}
                      {x.total_notify_me > 3
                        ? `+${x.total_notify_me - 3}`
                        : ""}{" "}
                      notified it
                    </Typography>
                  </Stack>
                )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
