import React, { useState } from 'react'
import { Grid, Box, CircularProgress } from '@mui/material'

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonSearch } from '../../slices/search';
import { clearMessages as clearMessagesMedia } from "../../slices/media";
import helpers from "../../services/helpers";
import MyHelmet from '../common/MyHelmet';

export default function SearchInnercast() {
    const [page, setPage] = useState(1);
    const _search = useSelector((state) => state.search);
    const _global = useSelector((state) => state.global);
    const media = useSelector((state) => state.media);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { personSearchPageData } = _search;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (media.successMesg && media.successMesg != "") {
            helpers.toastSuccess(media.successMesg);
            dispatch(getPersonSearch({ query: params?.query, page: 1, pagelimit: 20, is_public: _global.is_public }));
            dispatch(clearMessagesMedia());
        }
    }, [media]);


    useEffect(() => {
        dispatch(getPersonSearch({ query: params?.query, page: page, pagelimit: 20, is_public: _global.is_public }));
    }, [page])


    useEffect(() => {
        const handleScroll = () => {
            if (personSearchPageData && personSearchPageData.is_next_page && !_search.isLoading) {
                if ((window.innerHeight + window.scrollY + 500) >= document.body.offsetHeight) {
                    setPage(page + 1);
                }
            }
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, dispatch]);

    return (
        <div className='new-container mb-40'>
        <MyHelmet
            title = {"search results for cast"}
            description = {"Unlock a treasure trove of entertainment with our comprehensive search results. Our powerful search engine scours through our vast content library, bringing you a curated selection of results tailored to your query."}
        />
            <Grid container alignItems={'center'} className="pt-20 mb-30" justifyContent='space-between'>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <ArrowBackRoundedIcon onClick={() => navigate(-1)} className='pointer mr-10 mt-7' />
                        <p className='fs-28 fw-700 lh-28 m-0'>cast based on <span className='text-selected'>{params?.query}</span></p>
                    </Grid>
                </Grid>
            </Grid>
            {_search.personSearchData.length && _search.personSearchData.length > 0 ?
                <Grid container spacing={3} className='w-100'>
                    {_search.personSearchData.map((item, i) => {
                        return <Grid item key={i} xs={4} className="scale-1 pointer" sm={3} md={2} lg={1.5}>
                            <Link className='d-block w-100' to={`/person/${item?.unique_title || item?.name.replace(/ /g, "-")}${item?.unique_title ? '' : '/' + (item?.person || item?.uuid)}`}>
                                <Box
                                    alt='cast'
                                    className='d-block'
                                    component="img"
                                    sx={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        objectFit: 'cover',
                                        objectPosition: 'top'
                                    }}
                                    src={
                                        item.profile_path
                                            ? helpers.getFullUrl(item.profile_path, 185)
                                            : item.poster
                                                ? helpers.getFullUrl(item.poster, 185)
                                                : helpers.getDefaultImage("cinescoregenres")
                                    }
                                />
                                <p className="m-0 fs-14 fw-500">{item.name}</p>
                                <p
                                    style={{ color: "#979797", width: "100%" }}
                                    className="m-0 fs-14 text-center fw-500 ellipsis "
                                >
                                    {item.role
                                        ? item.role
                                        : item.character
                                            ? item.character
                                            : ""}
                                </p>
                            </Link>
                        </Grid>
                    })

                    }
                </Grid>
                :
                ''
            }
            {_search.isLoading ?
                <Grid container justifyContent={'center'}>
                    <CircularProgress />
                </Grid>
                :
                ''
            }
        </div>
    )
}
