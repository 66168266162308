import Grid from '@mui/material/Grid'
import React from 'react'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UserData from './UserData';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

const breakpoints = {
    319: {
        slidesPerView: 1,
        spaceBetween: 15,
    },
    425: {
        slidesPerView: 1.8,
        spaceBetween: 15,
    },
    510: {
        slidesPerView: 2.2,
        spaceBetween: 15,
    },
 
    768: {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerGroup: 1
    },
    1024: {
        slidesPerView: 3.5,
        spaceBetween: 20,
        slidesPerGroup: 2
    },
    1240: {
        slidesPerView: 3.5,
        spaceBetween: 20,
        slidesPerGroup: 2
    },
    1440: {
        slidesPerView:4.5 ,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    1680: {
        slidesPerView: 5,
        spaceBetween: 20,
        slidesPerGroup: 3
    },
    2280: {
        slidesPerView: 6,
        spaceBetween: 30,
        slidesPerGroup: 4
    },
    2560: {
        slidesPerView: 7,
        spaceBetween: 30,
        slidesPerGroup: 5
    },
}

export default function FilmyHeads(props) {
    const { data, heading } = props;


    return (
        <div>
            <Grid container className='new-container' alignItems={'center'} justifyContent={"space-between"}>
                <Grid item>
                    <h2 className="fw-700 fs-28 m-0">{heading && heading !== "" ? heading :<> frenzi <span className='text-selected'>filmyheads</span> </>}</h2>
                    {/* <p className='fw-500 fs-24 m-0 text-dark-grey'>{heading && heading != "" ? heading : 'friends list to the world'}</p> */}
                </Grid>
                {props.isHorizontal &&
                    <Link to="/connect">
                        <ArrowForwardIcon />
                    </Link>
                }
            </Grid>
            {props.isHorizontal ?
                <>
                    {data && data.length > 0 &&
                        <Swiper
                            // slidesPerView={1}
                            // spaceBetween={20}
                            // slidesPerGroup={5}
                            freeMode={true}
                            speed={1000}
                            navigation={true}
                            breakpoints={breakpoints}
                            modules={[FreeMode, Navigation]}
                            className="mySwiper"
                        >
                            {
                                data.map((x, i) => {
                                    return <SwiperSlide key={i}>
                                        <Grid key={i} sx={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                            <UserData x={x} />
                                        </Grid>
                                    </SwiperSlide>
                                })
                            }
                        </Swiper>
                    }
                </>
                :
                <>
                    {data && data.length > 0 &&
                        <Grid container>
                            {
                                data.map((x, i) => {
                                    return <Grid className='filmyheads-items new-container' item xs={12} sm={6} md={4} key={i} style={{paddingRight:'20px'}}>
                                        <UserData x={x} />
                                    </Grid>
                                })
                            }
                        </Grid>
                    }
                </>
            }
        </div>

    )
}
