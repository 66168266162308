import { Button, Grid, Modal, TextareaAutosize } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { toggleLoginModal } from '../../slices/global';
import { getreportError, reportError, postreportError } from '../../slices/media';
import { HELPER_TEXT } from '../../lang/HelperText'
import { SwiperSlide } from 'swiper/react';
import CloseIcon from "@mui/icons-material/CloseRounded";
import '../../views/onboarding/onboarding.scss'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100%', sm: '515px' },
    bgcolor: '#16213E',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
    zIndex: 999,
    height: '70vh',
    overflowY: 'auto'
};

const form = {
    media: "",
    platform: null,
    report_type: null,
    description: null,
    device_type: 'web'
}

export default function ReportError(props) {
    const { media, reported, type, subtype, data } = props;
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [reportErrorPopup, setReportErrorPopup] = useState(false);
    const [reportErrors, setReportErrors] = useState({});
    const [formData, setFormData] = useState(form);
    const _global = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const { report_type, description, device_type } = formData;

    const handleFormData = (val, name) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: val
        }));
    }

    const getData = (uuid) => {
        if (uuid) {
            dispatch(getreportError({ id: uuid })).then((resp) => {
                if (resp.payload && resp.payload.data) {
                    setReportErrors({ ...resp.payload.data });
                    const { platforms, report_types } = resp.payload.data;
                    if (platforms && platforms[0]) {
                        handleFormData(platforms[0]?.uuid, "platform");
                        setSelectedPlatform(platforms[0]?.uuid);
                    }
                    if (report_types && report_types[0]) {
                        handleFormData(report_types[0], "report_type");
                    }
                }
            })
        }
    }

    const handleClose = () => {
        setReportErrorPopup(false);
    };

    const handleSubmit = () => {
        handleReportError();
    }

    const handleReportError = () => {
        if (media && media !== "") {
            helpers.trackMoengageEvent('FR3_Searches', {
                title: data.title,
                report: MOENGAGE_ATTRIBUTE.YES,
            });

            dispatch(postreportError({ body: formData })).then(() => {
                setFormData({ ...form });
                handleClose();
            })
        }
    }

    const handleCancelReportError = () => {
        if (media && media !== "") {
            const payload = {
                media: media,
                description: null
            }
            dispatch(reportError({ body: payload }));
        }
    }

    // const handleCheckboxChange = (e, val) => {
    //     if (e.target.checked) {
    //         setCheckboxValue(val);
    //     }
    //     // else {
    //     //     setCheckboxValue('');
    //     // }
    // }

    const isiOS = () => {
        return /iPhone|iPad|iPod/.test(navigator.userAgent);
    }

    const handleChange = (e) => {
        let section = e.target.checked ? 'web' : 'tv';
        handleFormData(section, "device_type")
    }

    const handleOpenPopUp = () => {
        setReportErrorPopup(true);
        handleFormData(media, "media");
        getData(media);
    }

    const handlePlatformSelection = (x) => {
        setSelectedPlatform(x.uuid);
    }


    return (
        <>
            {reported ?
                <>
                    {type === 'text' ?
                        <p className={`fs-18 fw-300 m-0 ${props.className}`}>accidentally reported an issue ? <b onClick={() => handleCancelReportError()} className={`pointer fw-300 ${subtype === 'red' ? 'text-selected' : ''}`}>cancel it</b></p>
                        :
                        <Button onClick={() => handleCancelReportError()} className={`w-100 rounded-btn bg-selected text-light pl-20 pr-20 ${props.className}`}>cancel report</Button>
                    }
                </>
                :
                <>
                    {type === 'text' ?
                        <p className={`fs-12 fw-500 lh-12 m-0 ${props.className}`}>{subtype === 'red' ? '' : 'facing any issues ?'}<b onClick={() => {
                            if (!_global.is_public) {
                                handleOpenPopUp();
                            } else {
                                dispatch(toggleLoginModal({
                                    isOpen: true,
                                    content: HELPER_TEXT.NUDGE_TEXT.REPORT_ERROR
                                }));
                            }
                        }} className={`pointer fs-12 fw-500 lh-12 pt-10 ${subtype === 'red' ? 'text-selected' : ''} ${props.className}`}>{props.title}</b></p>
                        :
                        <Button onClick={() => {
                            if (!_global.is_public) {
                                handleOpenPopUp();
                            } else {
                                dispatch(toggleLoginModal({
                                    isOpen: true,
                                    content: HELPER_TEXT.NUDGE_TEXT.REPORT_ERROR
                                }));
                            }
                        }} className={`rounded-btn bg-light text-selected pl-20 pr-20 lh-22 w-100  ${props.className}`}>report error</Button>
                    }
                </>
            }
            {/* REVIEWED POPUP */}
            <div>
                <Modal
                    open={reportErrorPopup}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className='relative'>
                        <CloseIcon
                            onClick={handleClose} className='ml-3 pt-2 pointer absolute animate-close'
                            color="light"
                            style={{
                                top: '10px',
                                right: '10px',
                                fontSize: "32px"
                            }}
                        />

                        <Grid item xs={12}>
                            <p className='fw-400 fs-28 m-0'>report an error</p>
                            <p className='fw-400 fs-20 m-0'>We are sorry for any inconvenience caused to you</p>
                            <p className='fw-400 fs-13 m-0 text-muted'>our team is working constantly to provide you better service</p>
                        </Grid>
                        <Grid item>
                            <p className='fw-400 fs-16px'>Select platform</p>
                            {reportErrors?.platforms && reportErrors?.platforms.length > 0 ?
                                <SwiperSlide>
                                    <Grid container spacing={3}>
                                        {reportErrors?.platforms.map((x, i) => {
                                            return (
                                                <Grid key={i} item onClick={() => handleFormData(x?.uuid, "platform")} className={`${x.uuid === selectedPlatform ? 'platform-outer-ring' : ""}`}>
                                                    <Box
                                                        component="img"

                                                        sx={{
                                                            height: { xs: 40, sm: 64 },
                                                            width: { xs: 40, sm: 64 },
                                                            borderRadius: '50%'
                                                        }}
                                                        alt=""
                                                        onClick={() => { handlePlatformSelection(x) }}
                                                        src={x.platform_logo
                                                            ? helpers.getFullUrl(x.platform_logo)
                                                            : helpers.getDefaultImage("ott")}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </SwiperSlide>
                                :
                                ""
                            }
                        </Grid>
                        <Grid item>
                            <Grid container alignItems={'center'} justifyContent={'space-between'} flexWrap='nowrap'>
                                <p>what was wrong </p>
                                <Box className='custom-switch' sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <div className="switch-button-2">
                                        <input checked={device_type !== 'tv' ? true : false} onChange={(e) => handleChange(e)} className="switch-button-checkbox" type="checkbox"></input>
                                        <label className="switch-button-label" for=""><span className="switch-button-label-span">tv</span></label>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid container className='mt-10'>
                                {reportErrors?.report_types && reportErrors?.report_types.map((x, i) => {
                                    return <Button key={i} sx={{ width: 'auto' }}
                                        onClick={() => handleFormData(x, "report_type")} className={`rounded-btn border-muted mr-20 mb-20 fs-14 ${report_type === x ? 'bg-selected text-light' : 'text-light'}`}>{x}</Button>
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="mt-20">
                            <p className='fs-16 fw-400'>would you like to tell us more in detail ?</p>
                            <TextareaAutosize className='w-100 fs-18 h-100'
                                onChange={(e) => handleFormData(e.target.value, 'description')}
                                value={description}
                                placeholder={'//incorrect information was there//'}
                                style={{ minHeight: 100, maxWidth: '100%', backgroundColor: '#16213E', paddingLeft: '20px' }}
                            />
                            <Grid container justifyContent={'center'} className='mt-20'>
                                <Button onClick={() => handleSubmit()} className='rounded-btn bg-selected text-light'>submit</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>
    )
}
