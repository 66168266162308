import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CinescoreService from "../services/cinescore.services";
import helpers from "../services/helpers";

const getToken = () => {
  var data = localStorage.getItem("frenzi_user_access");
  var token = data && JSON.parse(data).access;
  return token;
};

const initialState = {
  isLoading: false,
  isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
  token: getToken(),
  user: {},
  errMesg: "",
  status: "",
  successMesg: "",
  cinescoreResults: {},
  cinescoreDetailPageData: {},
  cinescoreDetailData: [],
  cinescoreGenre: {},
  cinescorePlatform: {},
  cinescoreDecade: {},
  cinescoreLang: {},
  cinescoreMalePageData: {},
  cinescoreMaleData: [],
  cinescoreFeMalePageData: {},
  cinescoreFeMaleData: [],
  cinescoreDirectorPageData: {},
  cinescoreDirectorData: [],
  getCinescoreDetailsByMediaTypeData: {},
};

export const getCinescoreData = createAsyncThunk(
  "native_main/cinescore",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescoreData();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescoreDetails = createAsyncThunk(
  "native_main/cinescoredetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescoreDetails(
        data.body,
        data.page,
        data.pagelimit,
        data.media_type,
        data.sort,
        data.only_poster
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescoreGenre = createAsyncThunk(
  "native_main/cinescoregenre",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescoreGenre();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescorePlatform = createAsyncThunk(
  "native_main/cinescoreplatform",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescorePlatform();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescoreDecade = createAsyncThunk(
  "native_main/cinescoredecade",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescoreDecade();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescoreLang = createAsyncThunk(
  "native_main/cinescorelanguage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescoreLang();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescorePersonDataMale = createAsyncThunk(
  "native_main/cinescorepersonmale",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescorePersonData(
        "actor",
        data?.page,
        data?.pagelimit
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescorePersonDataFeMale = createAsyncThunk(
  "native_main/cinescorepersonfemale",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescorePersonData(
        "actress",
        data?.page,
        data?.pagelimit
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescorePersonDataDirector = createAsyncThunk(
  "native_main/cinescorepersondir",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescorePersonData(
        "director",
        data?.page,
        data?.pagelimit
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCinescoreDetailsByMediaType = createAsyncThunk(
  "native_main/alreadywatched",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CinescoreService.getCinescoreDetailsByMediaType(
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const cinescoreSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.errMesg = "";
      state.successMesg = "";
      state.isLoading = false;
    },
  },
  extraReducers: {
    [getCinescoreData.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescoreData.fulfilled]: (state, action) => {
      state.cinescoreResults = getPayloadData(state, action);
    },
    [getCinescoreData.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescoreDetails
    [getCinescoreDetails.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescoreDetails.fulfilled]: (state, action) => {
      state.cinescoreDetailPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.cinescoreDetailData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.cinescoreDetailData = helpers.removeDuplicate(
          state.cinescoreDetailData.concat(getArrayData(state, action))
        );
      }
    },
    [getCinescoreDetails.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescoreGenre
    [getCinescoreGenre.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescoreGenre.fulfilled]: (state, action) => {
      state.cinescoreGenre = getPayloadData(state, action);
    },
    [getCinescoreGenre.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescorePlatform
    [getCinescorePlatform.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescorePlatform.fulfilled]: (state, action) => {
      state.cinescorePlatform = getPayloadData(state, action);
    },
    [getCinescorePlatform.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescoreDecade
    [getCinescoreDecade.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescoreDecade.fulfilled]: (state, action) => {
      state.cinescoreDecade = getPayloadData(state, action);
    },
    [getCinescoreDecade.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescoreLang
    [getCinescoreLang.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescoreLang.fulfilled]: (state, action) => {
      state.cinescoreLang = getPayloadData(state, action);
    },
    [getCinescoreLang.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescorePersonDataMale
    [getCinescorePersonDataMale.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescorePersonDataMale.fulfilled]: (state, action) => {
      state.cinescoreMalePageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.cinescoreMaleData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.cinescoreMaleData = helpers.removeDuplicate(
          state.cinescoreMaleData.concat(getArrayData(state, action))
        );
      }
    },
    [getCinescorePersonDataMale.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescorePersonDataFeMale
    [getCinescorePersonDataFeMale.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescorePersonDataFeMale.fulfilled]: (state, action) => {
      state.cinescoreFeMalePageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.cinescoreFeMaleData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.cinescoreFeMaleData = helpers.removeDuplicate(
          state.cinescoreFeMaleData.concat(getArrayData(state, action))
        );
      }
    },
    [getCinescorePersonDataFeMale.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCinescorePersonDataDirector
    [getCinescorePersonDataDirector.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescorePersonDataDirector.fulfilled]: (state, action) => {
      state.cinescoreDirectorPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.cinescoreDirectorData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.cinescoreDirectorData = helpers.removeDuplicate(
          state.cinescoreDirectorData.concat(getArrayData(state, action))
        );
      }
    },
    [getCinescorePersonDataDirector.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },

    [getCinescoreDetailsByMediaType.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCinescoreDetailsByMediaType.fulfilled]: (state, action) => {
      state.cinescoreDetailsByMediaTypePageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.cinescoreDetailsByMediaTypeData = getPayloadData(state, action)
          .results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.cinescoreDetailsByMediaTypeData = helpers.removeDuplicate(
          state.cinescoreDetailsByMediaTypeData.concat(
            getArrayData(state, action)
          )
        );
      }
    },
    [getCinescoreDetailsByMediaType.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
  },
});
export const { clearMessages } = cinescoreSlice.actions;

const handlePendingRequest = (state, action) => {
  state.isLoading = true;
  state.successMesg = "";
  state.errMesg = "";
  state.status = "";
};

const getPayloadData = (state, action) => {
  state.isLoading = false;
  return action.payload && action.payload.data ? action.payload.data : {};
};

const getArrayData = (state, action) => {
  state.isLoading = false;
  return action.payload &&
    action.payload.data &&
    action.payload.data &&
    action.payload.data.results &&
    action.payload.data.results.length > 0
    ? action.payload.data.results
    : [];
};

const handleRejectedAction = (state, action) => {
  state.isLoading = false;
  state.errMesg =
    action.payload && action.payload.message
      ? action.payload.message
      : "Server error !!!";
};

export default cinescoreSlice.reducer;
