import { Box, Divider, Grid, IconButton, InputBase, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from 'react-redux';
import helpers from '../../services/helpers';
import { getSearchAutoComplete, resetSearchAutoComplete } from '../../slices/search';

export default function SearchTitleCast(props) {
    const [showList, setShowList] = useState(false);
    const [value, setValue] = useState("");
    const inputRef = useRef();
    const dispatch = useDispatch();

    const { selectedData, placeholder, subheading } = props;

    const _search = useSelector((state) => state.search);

    const handleSelect = (item) => {
        if (!selectedData.includes(item)) {
            props.onSelect([...selectedData, item]);
            setShowList(false);
        }
    }

    useEffect(() => {
        if (value && value != "") {
            setShowList(true);
            dispatch(getSearchAutoComplete({ query: value, page: 1, pagelimit: 20 }));
        } else {
            setShowList(false);
            dispatch(resetSearchAutoComplete());
        }
    }, [value]);

    const handleSearchTextChange = (e) => {
        setValue(e.target.value);
    };


    return (
        <div>
            {props.hideSearch ? ''
                :
                <div style={{ backgroundColor: 'transparent', ...props.style }} className="search-box-container pl-0 pr-0 pt-10">
                    <div className="relative">
                        <Paper
                            component="form"
                            style={{ opacity: 1 }}
                            className="w-100 searchbar d-flex ai-center br-25 relative mb-5 mt-0"
                        >
                            <IconButton
                                aria-label="search"
                                className="bg-selected"
                                sx={{
                                    borderBottomRightRadius: "0px",
                                    borderTopRightRadius: "0px",
                                    // maxHeight: { xs: '32px', sm: '40px' },
                                    // maxWidth: { xs: '32px', sm: '40px' }
                                }}
                            >
                                <SearchIcon className="ml-3 pt-2" color="light" />
                            </IconButton>
                            <InputBase
                                className="f-1 fs-14 pl-10 lh-18 ls-1 fw-500"
                                value={value}
                                type="text"
                                inputRef={inputRef}
                                subheading={props.subheading ? subheading : " "}
                                placeholder={props.placeholder ? placeholder : 'search for your favourite titles'}
                                onChange={handleSearchTextChange}
                            />

                            <IconButton
                                aria-label="search"
                                onClick={() => setValue("")}
                                className="h-100 bg-muted"
                                sx={{
                                    borderBottomLeftRadius: "0px",
                                    borderTopLeftRadius: "0px",
                                    // maxHeight: { xs: '32px', sm: '40px' },
                                    // maxWidth: { xs: '32px', sm: '40px' }
                                }}
                            >
                                <CloseIcon className="ml-3 animate-close pointer pt-2" color="light" />
                            </IconButton>
                        </Paper>
                        {showList && _search.searchAutoCompleteResult &&
                            _search.searchAutoCompleteResult.results &&
                            _search.searchAutoCompleteResult.results.length > 0 && (
                                <Grid container className="search-result-show">
                                    <Grid
                                        item
                                        className="mh-300 bg-light w-100 br-10"
                                        sx={{ overflowY: "auto" }}
                                        lg={12}
                                    >
                                        {_search.searchAutoCompleteResult.results.map(
                                            (item, i) => {
                                                if (props.mediaOnly && item.type != 'media') {
                                                    return true;
                                                }
                                                return (
                                                    <>
                                                        <Grid
                                                            container
                                                            key={i}
                                                            className="pl-20 pt-10 pointer ai-center"
                                                            alignItems={"center"}
                                                            onClick={() => handleSelect(item)}
                                                        >
                                                            <Grid
                                                                item
                                                                className="h-50px mw-65 w-100 pr-20"
                                                            >
                                                                <Box
                                                                    component="img"
                                                                    alt="poster"
                                                                    src={
                                                                        item.poster && item.poster != ""
                                                                            ? helpers.getFullUrl(item.poster)
                                                                            : helpers.getDefaultImage(
                                                                                item.type ? item.type : "search"
                                                                            )
                                                                    }
                                                                    sx={{
                                                                        height: "65px",
                                                                        width: "50px",
                                                                        borderRadius: "7px",
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className="text-dark fw-500">
                                                                    {helpers.getSelectedText(
                                                                        item?.title ? item?.title : "",
                                                                        value
                                                                    )}
                                                                </Typography>
                                                                <Grid container>
                                                                    <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                                                        {item.media_type ? item.media_type : item.department ? item.department : ''}
                                                                    </p>
                                                                    {item?.release_date && <>
                                                                        <span className="w-300 fs-14 m-0 p-0 text-muted">
                                                                            {" "}|{" "}
                                                                        </span>
                                                                        <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                                                            {new Date(item?.release_date).getFullYear()}
                                                                        </p>
                                                                    </>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className="pl-20 pr-20">
                                                            <Box className="w-100">
                                                                <Divider />
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                );
                                            }
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                    </div>
                </div>

            }
            {selectedData && selectedData.length > 0 && selectedData.map((item, i) => {
                return <div className='bg-primary' style={{ marginTop: '20px' }} key={i}>
                    <Grid
                        container
                        className="br-7 pointer ai-center relative"
                        alignItems={"center"}
                        sx={{ padding: { xs: '10px', sm: '15px' } }}
                    >
                        {props.hideSearch ? '' :
                            <CloseIcon
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    zIndex: 9,
                                    margin: '10px'
                                }}
                                onClick={() => {
                                    if (selectedData.length == 1) {
                                        props.onSelect('');
                                    } else {
                                        props.onSelect(selectedData.splice(i, 1));
                                    }
                                }}
                                className="ml-3 animate-close pointer pt-2" color="light"
                            />
                        }

                        <Grid
                            item
                            className="h-50px mw-65 w-100 pr-20"
                        >
                            <Box
                                component="img"
                                className='d-block'
                                alt="poster"
                                src={
                                    item.poster && item.poster != ""
                                        ? helpers.getFullUrl(item.poster)
                                        : helpers.getDefaultImage(
                                            item.type ? item.type : "search"
                                        )
                                }
                                sx={{
                                    height: "65px",
                                    width: "50px",
                                    borderRadius: "7px",
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className="fw-500">
                                {item?.title}
                            </Typography>
                            <Grid container>
                                <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                    {item.media_type ? item.media_type : item.department ? item.department : ''}
                                </p>
                                {item?.release_date && <>
                                    <span className="w-300 fs-14 m-0 p-0 text-muted">
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                    </span>
                                    <p className="fw-300 fs-14 m-0 p-0 text-muted">
                                        {new Date(item?.release_date).getFullYear()}
                                    </p>
                                </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="pl-20 pr-20">
                        <Box className="w-100">
                            <Divider />
                        </Box>
                    </Grid>
                </div>
            })}
        </div>
    )
}
