import axiosInstance from "../http-common";
import helpers from "./helpers";
import { encryptData } from "./Utils";

const getCastDetail = async (id, unique_title, is_public = false) => {

    let url = `native_main/cinescorepersondetail?is_web=true`;
    if (id) {
        url += `&person=${id}`;
    } else {
        url += `&unique_title=${unique_title}`;
    }
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();

    if (is_public) {
        let newBody = helpers.getQueryParams(url.split('?')[1]);
        newBody = { ...newBody, captcha_token: captcha_token }
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_cinescorepersondetail`, { data: encryptedData });
    } else {
        return axiosInstance.get(url);
    }
};

const getPersonMedia = async (id, page = 1, pagelimit = 10, is_public = false) => {
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();
    if (is_public) {
        const newBody = {
            person: id,
            page: page,
            pagelimit: pagelimit,
            captcha_token: captcha_token
        }
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(`native_main/public_personmedia`, { data: encryptedData });
    } else {
        return axiosInstance.post(`native_main/personmedia?person=${id}&page=${page}&pagelimit=${pagelimit}`);

    }
};

const getFavPerson = (page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/mostfavouriteperson?page=${page}&pagelimit=${pagelimit}`);
};

const getTopFans = (id, page = 1, pagelimit = 10) => {
    return axiosInstance.get(`native_main/cinescoretopfans?person=${id}&page=${page}&pagelimit=${pagelimit}`);
};

const addRemovePersonFan = (body) => {
    return axiosInstance.post(`native_main/personfan`, body);
};

const followUser = (body) => {
    return axiosInstance.post(`native_main/followuser`, body);
};

const unFollowUser = (body) => {
    return axiosInstance.post(`native_main/unfollowuser`, body);
};

const CastDataService = {
    getCastDetail,
    getPersonMedia,
    getFavPerson,
    getTopFans,
    addRemovePersonFan,
    followUser,
    unFollowUser
};

export default CastDataService;