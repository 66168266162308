import React from 'react'
import { Grid, Box, Button, Stack, Avatar, Typography } from '@mui/material';
// import HandpicIcon from "../../assets/icons/HandpicIcon.svg"
// import RoundedProfile from "../../assets/profile.svg"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CommonMenu from '../common/CommonMenu';
import MyHelmet from '../common/MyHelmet';

const RoundedProfile = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/profile.svg";
const HandpicIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/HandpicIcon.svg";

const releaselist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const list = [1, 2, 3]

export default function UnWatchedWatchlist() {
    return (
        <>
        <MyHelmet
            title = {"Unwatched watchlist"}
            description = {"Your Unexplored Watchlist: With every unwatched title, there's a chance to encounter a hidden gem that will leave a lasting impression."}
        />
            <CommonMenu />
            <Grid container className='pl-100 pr-100 pt-20 pb-20 section-border-bottom'>
                <Grid item sm={12}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-10'>
                        <Grid item>
                            <p className='fw-600 fs-40 m-0'>unwatched titles in your watchlist </p>
                        </Grid>
                        {/* <Grid item>
                        <ArrowForwardIcon />
                    </Grid> */}
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {releaselist.map((x, i) => {
                            return <Grid item sm={4}>
                                <Grid container className='mt-15'>
                                    <Grid item className='relative text-center'>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 150,
                                                width: '100%',
                                                maxHeight: { xs: 150, sm: 150 },
                                                maxWidth: { xs: 100, sm: 100 },
                                                borderRadius: '9px 9px 9px 9px',
                                            }}
                                            alt="handpic"
                                            src={HandpicIcon}
                                        />
                                    </Grid>
                                    <Grid item className='ml-16 mw-240' >
                                        <Typography className='fs-20 fw-700 '>
                                            The Women IN ...
                                        </Typography>
                                        <p className='fw-300 fs-12 text-dark-grey'>Recommended by vivek for ”loremmipsum”</p>
                                        <Stack direction="row" className='ai-center ml-15 mt-10'>
                                            {
                                                list.map((x, i) => {
                                                    return <Avatar alt="Remy Sharp" src={RoundedProfile} className='release-avatar' />
                                                })
                                            }
                                            <Typography className='fs-12 fw-500 lh-23 ml-8'> +25 notified it</Typography>
                                        </Stack>

                                        <Grid container className='mt-2' justifyContent={'space-between'} alignItems={'center'}>
                                            <Grid item>
                                                <Button className='rounded-btn mw-130 bg-secondary text-light fs-12 fw-700 mt-10' endIcon={<PlayArrowIcon />}>
                                                    watch trailer
                                                </Button>
                                            </Grid>
                                            <Grid item className='rounded-circle mt-10'>
                                                <CheckIcon />
                                            </Grid>
                                            <Grid item className='rounded-circle mt-10'>
                                                <ClearIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Box>
                </Grid >
            </Grid >
        </>


    )
}
