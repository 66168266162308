import React, { useEffect, useState } from 'react'
import { Grid, Box, TextareaAutosize, Button, Modal } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { addReview, reviewCheck } from '../../../slices/media';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import ProgressBar from '../ProgressBar';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 515,
    maxWidth: { xs: '90vh', sm: '515px' },
    bgcolor: '#16213E',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
};

const tabValue = 'text';

export default function FinalReview(props) {
    const { data } = props;
    const [pointsPopUp, setPointsPopUp] = useState(false);
    const [wordCheck, setWordCheck] = useState(1);
    const [tdayPostedReview, setTodayPostedReview] = useState(0);
    const [congratsPopUp, setCongratsPopUp] = useState(false);
    const [comment, setComment] = useState('');
    const [commentUrl, setCommentUrl] = useState('');
    const [commentVideoUrl, setVideoCommentUrl] = useState('');
    const media = useSelector(state => state.media);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reviewCheck());
    }, [])

    const handleAddReview = (type, uid) => {
        helpers.trackMoengageEvent('FR3_Post_Review', {
            review_type: type,
            comment: type === "text" ? comment : type === 'audio' ? commentUrl : commentVideoUrl,
            continue_click: MOENGAGE_ATTRIBUTE.YES,
            media_type: data?.media_type,
            post_by: JSON.parse(localStorage.getItem('frenzi_user_access'))?.uuid
        });
        if (uid && uid !== "") {
            if (type === "text" && comment !== "") {
                const payload = {
                    media: uid,
                    comment: comment,
                    allow_adult_post: null
                }
                dispatch(addReview({ body: payload }));
                setPointsPopUp(false);
                props.handleClose();
                setTimeout(() => {
                    if (media.reviewCheckData && media.reviewCheckData.reviews_posted_today) {
                        setTodayPostedReview(media.reviewCheckData.reviews_posted_today);
                        setCongratsPopUp(true);
                    }
                }, 1000)

            } else {
                var mediaElement = type === 'audio' ? document.getElementById("upload-audio-recording") : document.getElementById("upload-video-recording");
                if (mediaElement && mediaElement.duration === Infinity) {
                    mediaElement.currentTime = 1e101;
                }
                props.handleClose();
                setTimeout(() => {
                    if (commentUrl !== "" || commentVideoUrl !== "") {
                        const payload = {
                            media: uid,
                            comment: '',
                            comment_url: type === 'audio' ? commentUrl : commentVideoUrl,
                            comment_type: type,
                            duration: mediaElement.duration,
                            allow_adult_post: null
                        }
                        dispatch(addReview({ body: payload }));
                        setCommentUrl('');
                        setVideoCommentUrl('');
                        setTimeout(() => {
                            if (media.reviewCheckData && media.reviewCheckData.av_reviews_posted_today) {
                                setTodayPostedReview(media.reviewCheckData.av_reviews_posted_today);
                                setCongratsPopUp(true);
                            }
                        }, 1000)
                    }
                }, 700)
            }
        }
    }

    return (
        <div>
            {!congratsPopUp ?
                <>
                    <Grid item>
                        <Grid item>
                            <p className='fw-700 fs-28 m-0'>title review</p>
                            <p className='fw-400 fs-20 m-0 mt-16'>{data.title ? data.title : ''} ({data.media_type ? data.media_type : 'N/A'})</p>
                        </Grid>
                    </Grid>
                    <Grid item className='mt-10'>
                        <p className='fw-500 fs-16 m-0'>{tabValue === "text" ? "add your review and earn 40 Frenzi points" : "add your review and earn 90 Frenzi points"}</p>
                        {tabValue === "text" &&
                            <>
                                <Grid container justifyContent={'space-between'} className='mt-20'>
                                    <p className='p-l-5 m-0'>{comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length > 0 ? comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length - wordCheck : 0}/150</p>
                                    {(50 + wordCheck) - comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length > 0 ?
                                        <>
                                            {comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length - wordCheck > 0 ?
                                                <p className='text-selected m-0 fw-700 fs-12 m-0 text-right'>{(50 + wordCheck) - comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length} words more to bag frenzi points</p>
                                                :
                                                <p className='fw-700 fs-12 m-0'>50 words to bag frenzi points</p>
                                            }
                                        </>
                                        :
                                        <p className='fw-700 text-green fs-12 m-0'>{comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length} words</p>
                                    }
                                </Grid>
                                <Grid item className='mt-6'>
                                    <Box
                                        sx={{
                                            height: '120px',
                                            maxWidth: '100%',
                                        }}
                                        className='paragraph-container'
                                    >
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={3}
                                            maxRows={10}
                                            placeholder={'write a review (minimum 30 words and maximum 150 words)'}
                                            style={{ width: '100%', maxHeight: '100%', height: '100%', borderRadius: '8px', paddingBottom: '20px', padding: 10, color: '#000' }}
                                            value={comment}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                if (val.replace(/^[ ]+|[ ]+$/g, '').split(' ').length <= 150) {
                                                    setComment(val)
                                                    if (val.length > 0) {
                                                        setWordCheck(0);
                                                    } else {
                                                        setWordCheck(1);
                                                    }
                                                } else {
                                                    helpers.toastWarning("Word limit reached");
                                                }
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        }
                        <div className='d-flex jc-sb mt-20 pb-10 mt-10' >
                            <Button onClick={() => props.handleClose()} className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 lh-16 '>cancel</Button>
                            <Button onClick={() => {
                                if (tabValue === 'text' && comment.split(" ").length < 50) {
                                    setPointsPopUp(true);
                                } else {
                                    handleAddReview(tabValue, data.media ? data.media : data.uuid);
                                }
                            }} disabled={
                                ((comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length < 30 && tabValue === 'text') || (commentUrl === "" && tabValue === 'audio') || (commentVideoUrl === "" && tabValue === 'video')) ? true : false} className={`rounded-btn mw-240 ${((comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length > 30 && tabValue === 'text') || (commentUrl !== "" && tabValue === 'audio') || (commentVideoUrl !== "" && tabValue === 'video')) ? 'bg-selected text-light' : 'bg-pink text-dark'} fs-14 fw-500 lh-16`}>submit</Button>
                        </div>
                    </Grid>
                    {/* POINTS POPUP */}
                    <div>
                        <Modal
                            open={pointsPopUp}
                            onClose={() => setPointsPopUp(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Grid item>
                                    <Grid item>
                                        <p className='fw-700 fs-28 m-0'>earn points</p>
                                        <p className='fw-400 fs-20 m-0 mt-16'>unlock and earn frenzi points with {50 - comment.split(" ").length} more words</p>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ flexWrap: { xs: 'wrap !important', sm: 'nowrap !important' } }} className='d-flex jc-sb mt-20 pb-10 mt-10 w-100' >
                                    <Button sx={{ marginRight: { xs: '0px', sm: '10px', md: '20px' } }} onClick={() => setPointsPopUp(false)} className={`rounded-btn w-100 bg-selected text-light fs-14 mt-30 fw-500 lh-16`}>continue</Button>
                                    <Button onClick={() => handleAddReview(tabValue, data.uuid)} className='rounded-btn bg-light text-selected w-100 mt-30 fs-14 fw-500 lh-16 '>no thanks, submit review</Button>
                                </Grid>
                            </Box>
                        </Modal>
                    </div>
                </>
                :
                <div>
                    <Grid item>
                        <Grid item>
                            <p className='fw-700 fs-28 mt-0 mb-15'>congratulations</p>
                            <ProgressBar hideLabel={true} isGreen={true} width={'100%'} value={tdayPostedReview * 10} />
                            <p className='fw-400 fs-20 mt-15'>{tdayPostedReview} out of 10 for today. Your review is recieved and sent for consideration on the Frenzi.</p>
                        </Grid>
                    </Grid>
                    <div className='d-flex jc-center mt-20 pb-10 mt-10' >
                        <Button onClick={() => {
                            setCongratsPopUp(false);
                            props.handleClose();
                        }} className={`rounded-btn mw-240 bg-selected text-light fs-14 fw-500 lh-16`}>done</Button>
                    </div>
                </div>
            }
        </div>
    )
}
