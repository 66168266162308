import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import helpers from "../services/helpers";
import MediaDataService from "../services/media.services";
import { decryptData } from "../services/Utils";

const getToken = () => {
  var data = localStorage.getItem("frenzi_user_access");
  var token = data && JSON.parse(data).access;
  return token;
};

const initialState = {
  isLoading: false,
  isMediaLoading: false,
  isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
  token: getToken(),
  user: {},
  errMesg: "",
  status: "",
  successMesg: "",
  mediaData: {},
  mediaCastData: {},
  mediaCrewData: {},
  mediaReviewPageData: {},
  mediaReviewData: [],
  featuredReviewData: [],
  castMediaData: {},
  crewMediaData: {},
  similarMediaData: {},
  reviewCheckData: {},
  frenziAllReviewPageData: {},
  frenziAllReviewData: [],
  frenziTopReviewersPageData: {},
  frenziTopReviewersData: [],
  notifyMeData: {},
  viewingOptionsPageData: {},
  viewingOptionsData: [],
  reportReasonData: [],
};

export const getMediaDetail = createAsyncThunk(
  "native_main/mediapage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getMediaDetail(
        data?.id,
        data?.unique_title,
        data?.type,
        data?.is_public
      );
      
      if (data?.is_public) {
        let decryptedData = await decryptData(response?.data?.data);
        return decryptedData ? decryptedData : { data: null }
      } else {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getMediaCastDetail = createAsyncThunk(
  "native_main/mediacastcrew?section=cast",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getMediaCastDetail(
        data.media,
        data.page,
        data.pagelimit,
        data?.is_public
      );
      if (data?.is_public) {
        let decryptedData = await decryptData(response?.data?.data);
        return decryptedData ? decryptedData : { data: null }
      } else {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getMediaCrewDetail = createAsyncThunk(
  "native_main/mediacastcrew?section=crew",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getMediaCrewDetail(
        data.media,
        data.page,
        data.pagelimit,
        data?.is_public
      );
      if (data?.is_public) {
        let decryptedData = await decryptData(response?.data?.data);
        return decryptedData ? decryptedData : { data: null }
      } else {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getFeaturedReview = createAsyncThunk(
  "native_main/featuredreviews",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getFeaturedReview(
        data.media,
        data.comment_type,
        data?.page,
        data?.pagelimit,
        data?.unique_title
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getMediaReviewsList = createAsyncThunk(
  "native_main/mediafrenziallreviews",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getMediaReviewsList(
        data?.media,
        data?.page,
        data?.pagelimit,
        data?.category,
        data?.comment_type,
        data?.sort_order,
        data?.user
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCastMedia = createAsyncThunk(
  "native_main/castmedia",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getCastMedia(data.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getCrewMedia = createAsyncThunk(
  "native_main/castmediacrew",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getCastMedia(data.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getSimilarMediaData = createAsyncThunk(
  "native_main/similardata",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getSimilarMediaData(data.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const rateAndLikeMedia = createAsyncThunk(
  "native_main/userlikerate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.rateAndLikeMedia(data.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const addRemoveAlreadyWatched = createAsyncThunk(
  "native_main/mediaalreadywatched",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.addRemoveAlreadyWatched(
        data.body
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const removeAlreadyWatched = createAsyncThunk(
  "native_main/removeuseralreadywatched",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.removeAlreadyWatched(
        data.uuid
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const notifyMe = createAsyncThunk(
  "native_main/notifyme",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.notifyMe(data.body, data?.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const removeNotifyMe = createAsyncThunk(
  "native_main/removenotifyme",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.removeNotifyMe(data.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const addReview = createAsyncThunk(
  "native_main/postreview",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.addReview(data.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const reviewCheck = createAsyncThunk(
  "native_main/reviewcheck",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.reviewCheck();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const reportError = createAsyncThunk(
  "native_main/reporterror",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.reportError(data.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getreportError = createAsyncThunk(
  "native_main/reporterrorv3",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getreportError(data?.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const postreportError = createAsyncThunk(
  "native_main/postreporterrorv3",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.postreportError(data?.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getFrenziAllReviews = createAsyncThunk(
  "native_main/frenziallreviews",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getFrenziAllReviews(
        data?.media,
        data.category,
        data.comment_type,
        data.page,
        data.pagelimit,
        data?.sort,
        data?.unique_title
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getFrenziTopReviewers = createAsyncThunk(
  "native_main/frenzitopreviewers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getFrenziTopReviewers(
        data.page,
        data.pagelimit
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const likeDislikeReview = createAsyncThunk(
  "native_main/likereview",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.likeDislikeReview(data.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const reportReview = createAsyncThunk(
  "native_main/reportreview",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.reportReview(data.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const reportReason = createAsyncThunk(
  "native_main/reportreason",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.reportReason(data?.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getNotifyMeData = createAsyncThunk(
  "native_main/notifymev3",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getNotifyMeData(data?.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const getViewingOptions = createAsyncThunk(
  "native_main/viewingoptions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.getViewingOptions(
        data?.id,
        data?.section,
        data?.is_public
      );
      if (data?.is_public) {
        let decryptedData = await decryptData(response?.data?.data);
        return decryptedData ? decryptedData : { data: null }
      } else {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const mediaIntermediateData = createAsyncThunk(
  "native_main/mediaintermediatepage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.mediaIntermediateData(
        data?.tmdb_id,
        data?.media_type
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const addHistory = createAsyncThunk(
  "native_main/addhistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.addHistory(data?.body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const watchOnTv = createAsyncThunk(
  "native_main/watchontv",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MediaDataService.watchOnTv(data?.body, data?.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data ? err.data : {});
    }
  }
);

export const mediaSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.errMesg = "";
      state.successMesg = "";
      state.isLoading = false;
    },
    clearMediaData: (state) => {
      state.mediaData = {};
      state.mediaCastData = {};
      state.mediaCrewData = {};
      state.crewMediaData = {};
      state.castMediaData = {};
      state.similarMediaData = {};
    },
  },
  extraReducers: {
    [getMediaDetail.pending]: (state, action) => {
      handlePendingRequest(state, action);
      state.isMediaLoading = true;
    },
    [getMediaDetail.fulfilled]: (state, action) => {
      state.mediaData = getPayloadData(state, action);
      state.isMediaLoading = false;
    },
    [getMediaDetail.rejected]: (state, action) => {
      handleRejectedAction(state, action);
      state.isMediaLoading = false;
    },
    // getMediaCastDetail
    [getMediaCastDetail.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getMediaCastDetail.fulfilled]: (state, action) => {
      state.mediaCastPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.mediaCastData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.mediaCastData = helpers.removeDuplicate(
          state.mediaCastData.concat(getArrayData(state, action))
        );
      }
    },
    [getMediaCastDetail.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getMediaCrewDetail
    [getMediaCrewDetail.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getMediaCrewDetail.fulfilled]: (state, action) => {
      //state.mediaCrewData = getPayloadData(state, action);

      state.mediaCrewPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.mediaCrewData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.mediaCrewData = helpers.removeDuplicate(
          state.mediaCrewData.concat(getArrayData(state, action))
        );
      }
    },
    [getMediaCrewDetail.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getFeaturedReview
    [getFeaturedReview.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getFeaturedReview.fulfilled]: (state, action) => {
      state.featuredReviewData = getPayloadData(state, action);
    },
    [getFeaturedReview.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getMediaReviewsList
    [getMediaReviewsList.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getMediaReviewsList.fulfilled]: (state, action) => {
      state.mediaReviewPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.mediaReviewData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.mediaReviewData = helpers.removeDuplicate(
          state.mediaReviewData.concat(getArrayData(state, action))
        );
      }
    },
    [getMediaReviewsList.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCastMedia
    [getCastMedia.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCastMedia.fulfilled]: (state, action) => {
      state.castMediaData = getPayloadData(state, action);
    },
    [getCastMedia.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getCrewMedia
    [getCrewMedia.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getCrewMedia.fulfilled]: (state, action) => {
      state.crewMediaData = getPayloadData(state, action);
    },
    [getCrewMedia.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getSimilarMediaData
    [getSimilarMediaData.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getSimilarMediaData.fulfilled]: (state, action) => {
      state.similarMediaData = getPayloadData(state, action);
    },
    [getSimilarMediaData.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // rateAndLikeMedia
    [rateAndLikeMedia.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [rateAndLikeMedia.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
        // if (state.mediaData && state.mediaData && action.payload.data && action.payload.data.media && action.payload.data.media == state.mediaData.uuid) {
        //     state.is_liked = action.payload.data.like_status == 1 ? true : false;
        // }
      }
    },
    [rateAndLikeMedia.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // addRemoveAlreadyWatched
    [addRemoveAlreadyWatched.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [addRemoveAlreadyWatched.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [addRemoveAlreadyWatched.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // removeAlreadyWatched
    [removeAlreadyWatched.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [removeAlreadyWatched.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [removeAlreadyWatched.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // notifyMe
    [notifyMe.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [notifyMe.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [notifyMe.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // removeNotifyMe
    [removeNotifyMe.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [removeNotifyMe.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [removeNotifyMe.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // addReview
    [addReview.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [addReview.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [addReview.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // reviewCheck
    [reviewCheck.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [reviewCheck.fulfilled]: (state, action) => {
      state.reviewCheckData = getPayloadData(state, action);
    },
    [reviewCheck.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // reportError
    [reportError.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [reportError.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [reportError.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // postreportError
    [postreportError.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [postreportError.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [postreportError.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getFrenziAllReviews
    [getFrenziAllReviews.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getFrenziAllReviews.fulfilled]: (state, action) => {
      state.frenziAllReviewPageData = getPayloadDataSection(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        if (
          action.payload &&
          action.payload.data &&
          action.payload.data.status_flag
        ) {
          var tempData = {
            ...state.frenziAllReviewData,
            [action.payload.data.status_flag]: getPayloadData(state, action)
              .results
              ? getPayloadData(state, action).results
              : [],
          };
          state.frenziAllReviewData = tempData;
        } else {
          state.frenziAllReviewData = getPayloadData(state, action).results
            ? getPayloadData(state, action).results
            : [];
        }
      } else {
        state.frenziAllReviewData = getArrayDataSection(state, action);
      }
    },
    [getFrenziAllReviews.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getFrenziTopReviewers
    [getFrenziTopReviewers.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getFrenziTopReviewers.fulfilled]: (state, action) => {
      state.frenziTopReviewersPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.frenziTopReviewersData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.frenziTopReviewersData = helpers.removeDuplicate(
          state.frenziTopReviewersData.concat(getArrayData(state, action))
        );
      }
    },
    [getFrenziTopReviewers.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // likeDislikeReview
    [likeDislikeReview.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [likeDislikeReview.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [likeDislikeReview.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // reportReview
    [reportReview.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [reportReview.fulfilled]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.successMesg = action.payload.message;
        state.isLoading = false;
      }
    },
    [reportReview.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getNotifyMeData
    [getNotifyMeData.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getNotifyMeData.fulfilled]: (state, action) => {
      state.notifyMeData = getPayloadData(state, action);
    },
    [getNotifyMeData.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    //reportReason
    [reportReason.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [reportReason.fulfilled]: (state, action) => {
      state.reportReasonData = getPayloadData(state, action);
    },
    [reportReason.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
    // getViewingOptions
    [getViewingOptions.pending]: (state, action) => {
      handlePendingRequest(state, action);
    },
    [getViewingOptions.fulfilled]: (state, action) => {
      state.viewingOptionsPageData = getPayloadData(state, action);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.pagination.page == 1
      ) {
        state.viewingOptionsData = getPayloadData(state, action).results
          ? getPayloadData(state, action).results
          : [];
      } else {
        state.viewingOptionsData = helpers.removeDuplicate(
          state.viewingOptionsData.concat(getArrayData(state, action))
        );
      }
    },
    [getViewingOptions.rejected]: (state, action) => {
      handleRejectedAction(state, action);
    },
  },
});
export const { clearMessages, clearMediaData } = mediaSlice.actions;

const handlePendingRequest = (state, action) => {
  state.isLoading = true;
  state.successMesg = "";
  state.errMesg = "";
  state.status = "";
};

const getPayloadData = (state, action) => {
  state.isLoading = false;
  return action.payload && action.payload.data ? action.payload.data : {};
};

const getArrayData = (state, action) => {
  state.isLoading = false;
  return action.payload &&
    action.payload.data &&
    action.payload.data &&
    action.payload.data.results &&
    action.payload.data.results.length > 0
    ? action.payload.data.results
    : [];
};

const handleRejectedAction = (state, action) => {
  state.isLoading = false;
  state.errMesg =
    action.payload && action.payload.message
      ? action.payload.message
      : "Server error !!!";
};

const getArrayDataSection = (state, action) => {
  var res =
    action.payload &&
      action.payload.data &&
      action.payload.data &&
      action.payload.data.results &&
      action.payload.data.results.length > 0
      ? action.payload.data.status_flag
        ? {
          ...state.frenziAllReviewData,
          [action.payload.data.status_flag]: state.frenziAllReviewData[
            action.payload.data.status_flag
          ]
            ? helpers.removeDuplicate(
              state.frenziAllReviewData[
                action.payload.data.status_flag
              ].concat(action.payload.data.results)
            )
            : action.payload.data.results,
        }
        : action.payload.data.results
      : [];
  state.isLoading = false;
  return res;
};

const getPayloadDataSection = (state, action) => {
  state.isLoading = false;
  return action.payload && action.payload.data
    ? action.payload.data.status_flag
      ? {
        ...state.frenziAllReviewPageData,
        [action.payload.data.status_flag]: action.payload.data,
      }
      : action.payload.data
    : {};
};

export default mediaSlice.reducer;
