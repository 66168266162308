// import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
// import React, { useState } from 'react'
import React from 'react'
// import { countries } from "country-list-json";
// import { Box, FormControl, Grid, MenuItem, Select } from '@mui/material';
// import React, { useState } from 'react'
// import IND from "../../assets/icons/IND.svg";
import Box from '@mui/material/Box';

const IND = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/IND.svg";

const defaultCountry = {
    name: "India",
    dial_code: "+91",
    code: "IN",
    flag: "🇮🇳",
};

export default function CountrySelection(props) {
    // const [open, setOpen] = useState(false);
    // const [countryCode, setCountryCode] = useState(defaultCountry);


    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleChange = (value) => {
    //     setCountryCode(value);
    //     props.onSelect && props?.onSelect(value);
    //     handleClose();
    // }

    return (
        <div>
            <Box sx={{ minWidth: '50px' }} className="text-center">
                {/* <p className='fs-40 p-0 m-0 text-center'>{defaultCountry?.flag}</p> */}
                <img
                    alt={defaultCountry?.name}
                    src={IND}
                />

                {/* <FormControl fullWidth className="country-selection">
                    <Select
                        labelId="demo-simple-select-label"
                        id="country-selection"
                        value={countryCode.dial_code}
                        label="Country Code"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        size="small"
                    >
                        {countries &&
                            countries.length > 0 &&
                            countries.map((x, i) => {
                                return (
                                    <MenuItem onClick={() => handleChange(x)} key={i}>
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid>{x.name}</Grid>
                                            <Grid>{x.flag}</Grid>
                                        </Grid>
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Grid
                        className="country-selected"
                        onClick={() => handleOpen()}
                    >
                        <Grid container>
                            <Grid item className="flag">
                                {countryCode.flag}
                            </Grid>
                        </Grid>
                    </Grid>
                </FormControl> */}
            </Box>
        </div>
    )
}
