import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import helpers from "../../services/helpers";
import {
  addHistory,
  clearMediaData,
  clearMessages,
  getCastMedia,
  getCrewMedia,
  getFeaturedReview,
  getMediaCastDetail,
  getMediaCrewDetail,
  getMediaDetail,
  getMediaReviewsList,
} from "../../slices/media";
// import Loader from "../common/Loader";
import CastCrew from "./CastCrew";
import MovieBanner from "./MovieBanner";
import Trailers from "./Trailers";
import { clearMessages as clearMessagesWatchlist } from "../../slices/watchlist";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ZeroStateimg from '../../assets/ZeroState.svg'
// import ZeroStateMob from '../../assets/ZeroStateMob.svg'
// import ZeroStateClips from '../../assets/ZeroStateClips.svg'
// import ZeroStateClipsMob from '../../assets/ZeroStateClipsMob.svg'
import { getClipsCustomData } from "../../slices/clips";
import ClipsSlider from "../common/ClipsSlider";
import MyHelmet from "../common/MyHelmet";
import { getMediaSchemaMarkup, isMobileDevice } from "../../services/Utils";
import MyAds from "../adsense/MyAds";
import SimilarMovies from "./SimilarMovies";
import WhereToWatch from "./WhereToWatch";
import Box from "@mui/material/Box";

import Reviews from "./Reviews";
import LinearLoader from "../common/LinearLoader";

import MediaOverView from "../common/MediaOverView";
import { Button, Typography } from "@mui/material";

import ReveiwMovie from "../common/ReveiwMovie";
import UploadClip from "../snipz/UploadClip";

let mediaId = "";

const sections = {
  WHERE_TO_WATCH: "WHERE_TO_WATCH",
  CAST_AND_CREWS: "CAST_AND_CREWS",
  REVIEWS: "REVIEWS",
  CLIPS: "CLIPS",
  SIMILAR: "SIMILAR",
}

const ZeroStateimg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroState.svg";
const ZeroStateMob = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateMob.svg";
const ZeroStateClips = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateClips.svg";
const ZeroStateClipsMob = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/ZeroStateClipsMob.svg";


export default function MovieDetail(props) {
  const [castName, setCastName] = useState("");
  const [crewName, setCrewName] = useState("");
  const [activeSection, setActiveSection] = useState("WHERE_TO_WATCH");
  const [currentLoading, setCurrentLoading] = useState(true);
  const [clipsData, setClipsData] = useState([]);
  const [clipsPagination, setClipsPagination] = useState({});
  const [isClipLoading, setIsClipLoading] = useState(false);
  const [currentViewingOptions, setCurrentViewingOptions] = useState({});
  const dispatch = useDispatch();
  const media = useSelector((state) => state.media);
  const watchlist = useSelector((state) => state.watchlist);
  const _global = useSelector((state) => state.global);
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearMediaData());
    setCastName("");
    setCrewName("");
  }, [params]);

  useEffect(() => {
    if (media.mediaData && media.mediaData.title) {
      const {
        title,
        media_overview,
        release_date,
        media_type,
        rating,
        total_rating,
        season,
      } = media.mediaData;
      helpers.trackMoengageEvent("FR3_Searches", {
        title,
        synopsis: media_overview,
        release_date: release_date,
        // ott_view
        type: media_type,
        frenzi_rating: rating,
        user_rating: total_rating,
        season_count: season.length || 0,
        ref_page: props?.refPage || "",
        ref_url: document.referrer,
        tag_name: "mediapage",
      });
    }
  }, [media.mediaData]);

  useEffect(() => {
    if (media.errMesg && media.errMesg !== "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessages());
    }
    if (media.successMesg && media.successMesg !== "") {
      helpers.toastSuccess(media.successMesg);
      var mid = params?.uuid || null;
      var body = {
        id: mid || null,
        unique_title: !mid ? params?.name : null,
        type: "",
        is_public: _global.is_public
      };
      dispatch(getMediaDetail(body));
      dispatch(clearMessages());
    }
  }, [media]);

  useEffect(() => {
    var mid = params?.uuid || null;
    var body = {
      id: mid,
      type: "",
      unique_title: !mid ? params?.name : null,
      is_public: _global.is_public
    };
    setCurrentLoading(true);

    dispatch(getMediaDetail(body)).then((resp) => {
      setCurrentLoading(false);
      if (resp.payload?.data && resp.payload?.data?.uuid) {
        mediaId = resp.payload?.data?.uuid;
        getOtherData(resp.payload?.data?.uuid);
        getClipData(1, resp.payload?.data?.uuid);
        if (!_global.is_public) {
          dispatch(
            addHistory({ body: { media_id: resp.payload?.data?.uuid } })
          );
        }
      }
    });
  }, [params]);

  const handleNextClipLoad = (data) => {
    var mid = data.uuid || "";
    if (clipsPagination.is_next_page && clipsPagination.next_page) {
      getClipData(clipsPagination.next_page, mid);
    }
  };

  const getClipData = (page, id) => {
    setIsClipLoading(true);
    if (!isClipLoading && !_global.is_public) {
      const body = {
        id: id,
        page: page,
        section: "media",
        include_viewed_clips: 1,
        pagelimit: 20,
      };
      dispatch(getClipsCustomData(body)).then((resp) => {
        if (resp.payload && resp.payload.data) {
          const { pagination, results } = resp.payload.data;
          setClipsPagination(pagination);
          // setClipsData(helpers.removeDuplicate([...clipsData, ...results]));
          setClipsData(helpers.removeDuplicate(results));
          setIsClipLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (watchlist.errMesg && watchlist.errMesg !== "") {
      helpers.toastError(watchlist.errMesg);
      dispatch(clearMessagesWatchlist());
    }
    if (watchlist.successMesg && watchlist.successMesg !== "") {
      helpers.toastSuccess(watchlist.successMesg);
      dispatch(clearMessagesWatchlist());
    }
  }, [watchlist]);

  const getOtherData = (mid) => {
    if (mid && mid !== "") {
      var body = {
        media: mid,
        page: 1,
        pagelimit: 10,
        category: "all",
        comment_type: "all",
        sort_order: "newest",
        is_public: _global.is_public
      };
      dispatch(getMediaCastDetail(body));
      dispatch(getMediaCrewDetail(body));
      if (!_global.is_public) {
        dispatch(getFeaturedReview(body));
        dispatch(getMediaReviewsList(body));
      }
    }
  };

  const handleOnCastSlideChange = (e) => {
    if (
      media?.mediaCastPageData?.pagination &&
      media?.mediaCastPageData?.pagination.is_next_page &&
      !media.isLoading
    ) {
      if (e.isEnd) {
        var body = {
          media: mediaId,
          page: media?.mediaCastPageData?.pagination.page + 1,
          pagelimit: 10,
          category: "all",
          comment_type: "all",
          sort_order: "newest",
          is_public: _global.is_public
        };
        dispatch(getMediaCastDetail(body));
      }
    }
  };

  const handleOnCrewSlideChange = (e) => {
    if (
      media?.mediaCrewPageData?.pagination &&
      media?.mediaCrewPageData?.pagination.is_next_page &&
      !media.isLoading
    ) {
      if (e.isEnd) {
        var body = {
          media: mediaId,
          page: media?.mediaCrewPageData?.pagination.page + 1,
          pagelimit: 10,
          category: "all",
          comment_type: "all",
          sort_order: "newest",
          is_public: _global.is_public
        };
        dispatch(getMediaCrewDetail(body));
      }
    }
  };

  useEffect(() => {
    if (
      !currentLoading &&
      !_global.is_public &&
      media.mediaCastData?.results &&
      media.mediaCastData.results.length > 0
    ) {
      dispatch(getCastMedia({ id: media.mediaCastData.results[0].uuid }));
      setCastName(media.mediaCastData.results[0].name);
    }
  }, [media.mediaCastData, currentLoading]);

  useEffect(() => {
    if (
      !currentLoading &&
      !_global.is_public &&
      media.mediaCrewData?.results &&
      media.mediaCrewData.results.length > 0
    ) {
      dispatch(getCrewMedia({ id: media.mediaCrewData.results[0].uuid }));
      setCrewName(media.mediaCrewData.results[0].name);
    }
  }, [media.mediaCrewData, currentLoading]);

  // userReviewData

  const myReviewsData = [...media?.mediaReviewData, ...media?.featuredReviewData];

  return (
    <div>
      <MyHelmet
        // title={`${media?.mediaData?.title} ${media?.mediaData?.release_date
        //   ? new Date(media?.mediaData?.release_date).getFullYear()
        //   : ""
        //   } ${media?.mediaData?.media_type === "tv" ? "Series" : "Movie"
        //   } | Reviews | Cast & Release Date - Frenzi`}
        title={`Where to Watch ${media?.mediaData?.title} on OTT?`}
        og_image={
          media?.mediaData?.poster_path && media?.mediaData?.poster_path !== ""
            ? helpers.getFullUrl(media?.mediaData?.poster_path)
            : helpers.getDefaultImage("poster")
        }
        og_url={window.location.href}
        keywords={`${media?.mediaData?.title}, ${media?.mediaData?.title} ${media?.mediaData?.release_date
          ? new Date(media?.mediaData?.release_date).getFullYear() : ""}, ${media?.mediaData?.title} streaming, ${media?.mediaData?.title} online, watch ${media?.mediaData?.title}, stream ${media?.mediaData?.title}, where to watch ${media?.mediaData?.title}, ${media?.mediaData?.title} on ott, ${media?.mediaData?.title} trailer, ${media?.mediaData?.title} starcast, ${media?.mediaData?.title} review`}
        // keywords={`${media?.mediaData?.title} watch online, ${media?.mediaData?.title} trailer, ${media?.mediaData?.title} starcast, ${media?.mediaData?.title} reviews`}
        // description={`where to watch ${media?.mediaData?.title} online?, ${media?.mediaData?.title
        //   } streaming now on ${getOttName(currentViewingOptions) !== ""
        //     ? getOttName(currentViewingOptions)
        //     : "?"
        //   }. Know about ${media?.mediaData?.title
        //   } reviews, cast and movie plot on Frenzi`}
        description={`Find out where to watch ${media?.mediaData?.title} ${media?.mediaData?.media_type === "tv" ? "tv series" : "movie"} online. ${media?.mediaData?.title} ${media?.mediaData?.media_type === "tv" ? "tv series" : "movie"} OTT streaming, trailer, review, cast and much more.
          `}
        schemaMarkup={getMediaSchemaMarkup(
          media?.mediaData,
          media?.mediaCastData,
          media?.mediaCrewData
        )}
      />
      {currentLoading && <LinearLoader background={true} />}
      <>
        <Grid item >
          <MovieBanner
            isLoading={currentLoading}
            data={media.mediaData ? media.mediaData : {}}
            mediaData={media.mediaData}
            media_type={media.mediaData && media.mediaData?.media_type}
            reported={media.mediaData.report_error}
            currentViewingOptions={currentViewingOptions}
            setCurrentViewingOptions={setCurrentViewingOptions}
            uuid={
              media.mediaData && media.mediaData.uuid ? media.mediaData.uuid : ""
            }
          />
        </Grid>
        {isMobileDevice() ?
          <Grid container justifyContent={'center'}>
            <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"3422829246"} adv_type={"web_movie_detail_top_ad"} />
          </Grid>
          : ''
        }

        {/* {isMobileDevice() ?
          <div>
            <SeenMovie
              data={data}
              width={35}
              size={25}
              height={35} />
          </div>
          : ''} */}

        {isMobileDevice() ?
          <Grid container sx={{ position: 'sticky', top: '0px', zIndex: '99', minWidth: '320px', overflowX: 'auto' }} className="bg-grey br-30 mb-50 hide-scrollbar" wrap='no-wrap'>
            <Grid item onClick={() => setActiveSection(sections.WHERE_TO_WATCH)}>
              <Button className={`minw fs-14 fw-500 lh-14 rounded-btn ${activeSection === sections.WHERE_TO_WATCH ? 'text-selected bg-light' : 'text-selected'}`}>where to watch</Button>
            </Grid>
            <Grid item onClick={() => setActiveSection(sections.CAST_AND_CREWS)}>
              <Button className={`minw fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.CAST_AND_CREWS ? 'text-selected bg-light' : 'text-selected'}`}>cast and crew</Button>
            </Grid>
            <Grid item onClick={() => setActiveSection(sections.REVIEWS)}>
              <Button className={`minw fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.REVIEWS ? 'text-selected bg-light' : 'text-selected'}`}>reviews</Button>
            </Grid>
            <Grid item onClick={() => setActiveSection(sections.CLIPS)}>
              <Button className={`minw fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.CLIPS ? 'text-selected bg-light' : 'text-selected'}`}>clips</Button>
            </Grid>
            <Grid item onClick={() => setActiveSection(sections.SIMILAR)}>
              <Button className={`minw fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.SIMILAR
                ? 'text-selected bg-light' : 'text-selected'}`}>similar titles</Button>
            </Grid>
          </Grid>
          :
          <Grid container style={{ position: 'sticky', top: '0px', zIndex: '99' }} className="new-container" display={'block'} >
            <Grid md={12} lg={12} style={{ paddingLeft: '25px' }}>
              <Grid container justifyContent={'space-evenly'} className="bg-grey br-30 mt-20 mb-20">
                <Grid item flex={1} onClick={() => setActiveSection(sections.WHERE_TO_WATCH)}>
                  <Button className={`fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.WHERE_TO_WATCH ? 'text-selected bg-light' : 'text-selected'}`}>where to watch</Button>
                </Grid>
                <Grid item flex={1} onClick={() => setActiveSection(sections.CAST_AND_CREWS)}>
                  <Button className={`fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.CAST_AND_CREWS ? 'text-selected bg-light' : 'text-selected'}`}>cast and crew</Button>
                </Grid>
                <Grid item flex={1} onClick={() => setActiveSection(sections.REVIEWS)}>
                  <Button className={`fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.REVIEWS ? 'text-selected bg-light' : 'text-selected'}`}>reviews</Button>
                </Grid>
                <Grid item flex={1} onClick={() => setActiveSection(sections.CLIPS)}>
                  <Button className={`fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.CLIPS ? 'text-selected bg-light' : 'text-selected'}`}>clips</Button>
                </Grid>
                <Grid item flex={1} onClick={() => setActiveSection(sections.SIMILAR)}>
                  <Button className={`fs-14 fw-500 lh-14 rounded-btn w-100 ${activeSection === sections.SIMILAR
                    ? 'text-selected bg-light' : 'text-selected'}`}>similar titles</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid container className="new-container mb-50" >
          <Grid flex={1} className="left-container">
            {activeSection === sections.WHERE_TO_WATCH ?
              <Grid container rowSpacing={2} className="" justifyContent={'space-between'} >
                <WhereToWatch
                  notified={media.mediaData.notify_me}
                  currentViewingOptions={currentViewingOptions}
                  setCurrentViewingOptions={setCurrentViewingOptions}
                  uuid={
                    media.mediaData && media.mediaData.uuid
                      ? media.mediaData.uuid
                      : ""
                  }
                  media_type={media.mediaData && media.mediaData?.media_type}
                  mediaData={media.mediaData}
                  reported={media.mediaData.report_error}
                />

                <Grid item xs={12} className=" app-section">
                  <p className="fw-600 fs-20 mb-20 ls-10 lh-20">synopsis</p>
                  <MediaOverView
                    isLess={true}
                    className=""
                    text={media?.mediaData && media?.mediaData?.media_overview ? media.mediaData.media_overview : ""}
                    fullHeight={true}
                    max={500} />
                </Grid>

                <Grid item xs={12} >
                  {media.mediaData?.media_videos &&
                    media.mediaData.media_videos[0] &&
                    media.mediaData.media_videos[0].results &&
                    media.mediaData.media_videos[0].results.length > 0 && (
                      <Trailers
                        mediaData={media.mediaData}
                        withAd={true}
                        data={media.mediaData.media_videos[0].results}
                      />
                    )}
                </Grid>
              </Grid>
              : ''
            }

            {activeSection === sections.CAST_AND_CREWS ?
              <>
                <Grid item>
                  {media.mediaCastData && media.mediaCastData.length > 0 && (
                    <CastCrew
                      heading={
                        <>
                          cast of
                          <span className="text-selected">
                            {" "}
                            {`${media.mediaData.title}`}
                          </span>
                        </>
                      }
                      withAd={true}
                      data={media.mediaCastData}
                      onSlideChange={handleOnCastSlideChange}
                    />
                  )}
                </Grid>
                <Grid item>
                  {media.mediaCrewData && media.mediaCrewData.length > 0 && (
                    <CastCrew
                      heading={
                        <>
                          crew of
                          <span className="text-selected">
                            {" "}
                            {`${media.mediaData.title}`}
                          </span>
                        </>
                      }
                      withAd={true}
                      data={media.mediaCrewData}
                      onSlideChange={handleOnCrewSlideChange}
                    />
                  )}
                </Grid>
              </>
              : ''
            }

            {activeSection === sections.REVIEWS ?
              <>
                <Grid item>
                  {myReviewsData &&
                    myReviewsData.length > 0 ? (
                    <Box>
                      <Reviews
                        mediaData={media.mediaData}
                        data={myReviewsData
                          ? myReviewsData.map((x) => {
                            return {
                              ...x,
                              unique_title: media.mediaData?.unique_title || "",
                              title: media.mediaData?.title || "",
                              media: media.mediaData?.uuid || "",
                            };
                          })
                          : []
                        }
                        unique_title={params?.name || null}
                        type={"grid"}
                        size={"sm"}
                      />
                    </Box>
                  ) : (
                    <Grid className="mt-50">
                      <Grid container justifyContent={'center'} >
                        <Grid item className="relative">
                          <Grid item>
                            <img src={isMobileDevice() ? ZeroStateMob : ZeroStateimg} alt={''} />
                          </Grid>
                          <Grid item className="absolute" sx={{ top: "20px", right: '20px' }}>
                            <Typography className="fw-700 fs-20 lh-20 mb-10">be the first to <span className="text-selected">review</span></Typography>
                            <Typography className="fw-500 fs-14 lh-24">Create your own review and get points</Typography>
                            <ReveiwMovie data={media.mediaData} isBtn={true} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
              : ''
            }

            {activeSection === sections.CLIPS ?
              <>
                <Grid item>
                  {clipsData.length > 0 ? (
                    <Grid xs={12} className="app-section list-clip-player">
                      <ClipsSlider
                        withAd={true}
                        isMovie={true}
                        getClipData={() => handleNextClipLoad(media?.mediaData)}
                        clipsData={clipsData}
                      />
                    </Grid>
                  ) :
                    <Grid className="mt-50">
                      <Grid container justifyContent={'center'}>
                        <Grid item className="relative">
                          <Grid item>
                            <img src={isMobileDevice() ? ZeroStateClipsMob : ZeroStateClips} alt="" />
                          </Grid>
                          <Grid item className="absolute" sx={{ top: "20px", right: '20px' }}>
                            <Typography className="fw-700 fs-20 lh-20 mb-10">be the first one to <span className="text-selected">create a clip</span></Typography>
                            <Typography className="fw-500 fs-14 lh-24">create your own clip and earn points</Typography>
                            <UploadClip isBtn={true} hideSearch={true} mediaData={media.mediaData} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </>
              : ''

            }

            {activeSection === sections.SIMILAR ?
              <>
                <Grid item>
                  {!_global.is_public ? (
                    <Box>
                      <SimilarMovies
                        uuid={
                          media.mediaData && media.mediaData.uuid
                            ? media.mediaData.uuid
                            : ""
                        }
                        type={"grid"}
                        title={media?.mediaData?.title || ""}
                      />
                    </Box>
                  ) : (
                    ' '
                  )}
                </Grid>
              </>
              : ''
            }

          </Grid>

          {!isMobileDevice() ?
            <Grid className="right-container">
              <Grid className="right-container-ad">
                <MyAds type="banner_1" adv_type={'web_movie_detail_right_ad'} />
              </Grid>
            </Grid>
            : ''}
        </Grid>
        {/* <Grid container className="new-container mt-50 mb-50" justifyContent={'center'}>
          <Grid item className="bg-grey br-7 p-20" sx={{ width: { xs: '500px' }, display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <Typography className="fw-700 fs-16 lh-20 mb-50">report an <span className="text-selected">error</span> </Typography>
              <ReportError
                data={media?.mediaData}
                media={
                  media.mediaData && media.mediaData.uuid ? media.mediaData.uuid : ""
                }
                reported={media?.mediaData && media?.mediaData?.report_error}
              />
            </Grid>
            <Grid item xs={5} textAlign={"Right"} className="mt-80">
              <img src={ReportErrIcon} alt="" />
            </Grid>
          </Grid>
        </Grid>  */}
      </>
    </div>
  );
}
