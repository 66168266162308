import { Box} from "@mui/material"
// import ShareListIcon from '../assets/icons/ShareListIcon.svg'
// import HandpicTitle from '../assets/icons/HandpicTitle.svg'
// import WatchlistIcon from '../assets/icons/WatchlistIcon.svg'
import './nudges.scss'
import { HELPER_TEXT } from "../lang/HelperText"

const ShareListIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/ShareListIcon.svg";
const HandpicTitle = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/HandpicTitle.svg";
const WatchlistIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/WatchlistIcon.svg";


export const NUDGE_DATA = [
   
    {
        heading: <h2 className="nudge-heading fw-600 fs-32 lh-24">Create Watchlist</h2>,
        sub_heading: <h2 className="nudge-subtext fs-22">create your first watchlist</h2>,
        image: <Box component={'img'} sx={{ maxWidth: '185px', height: '126px' }} src={WatchlistIcon} />,
        btn: 'sign up',
        contentText: HELPER_TEXT.NUDGE_TEXT.WATCHLIST
        
    },

    {
        heading: <h2 className="nudge-heading fw-600 fs-32 lh-24">Personal Recommendation</h2>,
        sub_heading: <h2 className="nudge-subtext fs-22">tailored content of your choice</h2>,
        image: <Box component={'img'} sx={{ maxWidth: '185px', height: '126px' }} src={HandpicTitle} />,
        btn: 'sign up',
        contentText: HELPER_TEXT.NUDGE_TEXT.HANDPICK
    },

    {
        heading: <h2 className="nudge-heading fw-600 fs-32 lh-24">Create Sharelist</h2>,
        sub_heading: <h2 className="nudge-subtext fs-22">create interesting sharelist with friends</h2>,
        image: <Box component={'img'} sx={{ maxWidth: '185px', height: '126px' }} src={ShareListIcon} />,
        btn: 'sign up',
        contentText: HELPER_TEXT.NUDGE_TEXT.SHARELIST
    },

]