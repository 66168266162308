import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import {
  addRemoveMediaFromWatchlist,
  addToWatchlist,
  addWatchlist,
  getSharelistToAdd,
  getWatchlistToAdd,
} from "../../slices/watchlist";
import { useDispatch, useSelector } from "react-redux";
// import rightImg from "../../assets/icons/right.svg";
// import BookmarkPinkIcon from "../../assets/icons/NewWatchlistWhite.svg";
// import BookmarkWhiteIcon from "../../assets/icons/NewBookmark.svg";
import { toggleLoginModal } from "../../slices/global";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import { HELPER_TEXT } from "../../lang/HelperText";
import CloseIcon from "@mui/icons-material/Close";

const rightImg = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/right.svg";
const BookmarkPinkIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewWatchlistWhite.svg";
const BookmarkWhiteIcon = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewBookmark.svg";

const breakpoints = {
  319: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 2.2,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 3.7,
    spaceBetween: 20,
    slidesPerGroup: 3
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 20,
    slidesPerGroup: 4
  },
  1440: {
    slidesPerView: 4.7,
    spaceBetween: 20,
    slidesPerGroup: 3.7
  },
  1880: {
    slidesPerView: 6,
    spaceBetween: 30,
    slidesPerGroup: 5
  },
  2560: {
    slidesPerView: 9,
    spaceBetween: 30,
    slidesPerGroup: 8
  },
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: "90%", sm: "750px" },
  bgcolor: "#16213E",
  boxShadow: 24,
  // p: { xs: 2, sm: 4 },
  borderRadius: "7px",
  maxHeight: "85vh",
  overflowY: "auto",
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

export default function BookmarkAction(props) {
  const { data, btnWithLabel, customIcon, customSelecetdIcon, forceLink } = props;
  const [currentAddedIds, setCurrentAddedIds] = useState([]);
  const [createWatchlistPopup, setCreateWatchlistPopup] = useState(false);
  const [currentLoading, setCurrentLoading] = useState(false);
  const [currentSelectedData, setCurrentSelectedData] = useState([]);
  const [currentUnSelectedData, setCurrentUnSelectedData] = useState([]);
  const [currentSelectedSuggestData, setCurrentSelectedSuggestData] = useState(
    []
  );
  const [currentUnSelectedSuggestData, setCurrentUnSelectedSuggestData] =
    useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [watchlistName, setWatchlistName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const watchlist = useSelector((state) => state.watchlist);
  const global = useSelector((state) => state.global);
  // const { watchlistToAddPageData, sharelistToAddPageData, isLoading } = watchlist;

  const resetState = () => {
    setCurrentSelectedData([]);
    setCurrentUnSelectedData([]);
    setCurrentSelectedSuggestData([]);
    setCurrentUnSelectedSuggestData([]);
  };

  const handleClickOpen = (id) => {
    props?.handleStop && props?.handleStop();
    props.onClick && props.onClick();
    if (forceLink && forceLink != '') {
      navigate(forceLink);
    } else {
      if (global.is_public) {
        dispatch(toggleLoginModal({ isOpen: true, content: HELPER_TEXT.NUDGE_TEXT.CREATE_WATCHLIST }));
      } else {
        helpers.trackMoengageEvent('FR3_Searches', {
          title: data.title,
          add_to_watchlist: MOENGAGE_ATTRIBUTE.YES,
        });
        setCurrentLoading(true);
        if (id && id != "" && id == data.media) {
          getData(id);
        }
        setCreateWatchlistPopup(true);
      }
    }
  };

  useEffect(() => {
    if (data.media && data.media != "" && page > 1) {
      dispatch(
        getWatchlistToAdd({ uuid: data.media, page: page, pagelimit: 10 })
      );
    }
  }, [page]);

  useEffect(() => {
    if (data.media && data.media != "" && page2 > 1) {
      dispatch(
        getSharelistToAdd({ uuid: data.media, page: page2, pagelimit: 10 })
      );
    }
  }, [page2]);

  const getData = (id) => {
    dispatch(getWatchlistToAdd({ uuid: id, type: 'add', page: page, pagelimit: 10 }));
    dispatch(getSharelistToAdd({ uuid: id, type: 'suggest', page: page2, pagelimit: 10 })).then(() => {
      setTimeout(() => {
        setCurrentLoading(false);
      }, 500)
    });
  };

  // const handleScroll = (nextSlide) => {
  //   if (
  //     watchlistToAddPageData &&
  //     watchlistToAddPageData.pagination &&
  //     watchlistToAddPageData.pagination.is_next_page &&
  //     !isLoading
  //   ) {
  //     let elements = document.getElementsByClassName("watchlist-item");
  //     if (elements && elements.length > 0) {
  //       if (nextSlide > (elements.length - 5)) {
  //         setPage(page + 1);
  //       }
  //     }
  //   }
  // };

  // const handleScroll2 = (nextSlide) => {
  //   if (
  //     sharelistToAddPageData &&
  //     sharelistToAddPageData.pagination &&
  //     sharelistToAddPageData.pagination.is_next_page &&
  //     !isLoading
  //   ) {
  //     let elements = document.getElementsByClassName("sharelist-item");
  //     if (elements && elements.length > 0) {
  //       if (nextSlide > (elements.length - 5)) {
  //         setPage2(page2 + 1);
  //       }
  //     }
  //   }
  // };

  const handleClose = () => {
    props?.handlePlay && props?.handlePlay();
    setCreateWatchlistPopup(false);
  };

  const handleCreateWatchlist = async () => {
    if (watchlistName != "") {
      if (data.media && watchlistName != "") {
        helpers.trackMoengageEvent('FR3_Searches', {
          title: data.title,
          create_new_watchlist: MOENGAGE_ATTRIBUTE.YES,
        });
        const payload = {
          is_public: isPublic,
          category_name: watchlistName,
          medias: [data.media],
        };
        props?.setAddedId(data.media);
        dispatch(addWatchlist({ body: payload }));
        setPage(1);
        setPage2(1);
        handleClose();
      }
    } else {
      helpers.toastError("Watchlist name should not be empty.");
      return true;
    }
  };

  const handleOnsearchMediaClick = (wdata) => {
    let tempArr = currentSelectedData;
    let tempArr2 = currentUnSelectedData;
    if (tempArr.length > 0) {
      if (tempArr.includes(wdata.uuid)) {
        let index = tempArr.indexOf(wdata.uuid);
        tempArr.splice(index, 1);
        if (wdata.already_added) {
          tempArr2.push(wdata.uuid);
        }
      } else {
        tempArr.push(wdata.uuid);
        if (wdata.already_added) {
          let index2 = tempArr2.indexOf(wdata.uuid);
          tempArr2.splice(index2, 1);
        }
      }
    } else {
      tempArr.push(wdata.uuid);
    }
    setCurrentSelectedData([...tempArr]);
    setCurrentUnSelectedData([...tempArr2]);
  };

  const handleOnsearchMediaSuggestClick = (wdata) => {
    let tempArr = currentSelectedSuggestData;
    let tempArr2 = currentUnSelectedSuggestData;
    if (tempArr.length > 0) {
      if (tempArr.includes(wdata.uuid)) {
        let index = tempArr.indexOf(wdata.uuid);
        tempArr.splice(index, 1);
        if (wdata.already_added) {
          tempArr2.push(wdata.uuid);
        }
      } else {
        tempArr.push(wdata.uuid);
        if (wdata.already_added) {
          let index2 = tempArr2.indexOf(wdata.uuid);
          tempArr2.splice(index2, 1);
        }
      }
    } else {
      tempArr.push(wdata.uuid);
    }
    setCurrentSelectedSuggestData([...tempArr]);
    setCurrentUnSelectedSuggestData([...tempArr2]);
  };

  useEffect(() => {
    var tempArr = [];
    if (
      watchlist.watchlistToAddData &&
      watchlist.watchlistToAddData.length > 0
    ) {
      watchlist.watchlistToAddData.map((x, i) => {
        if (x.already_added) {
          tempArr.push(x.uuid);
        }
      });
      setCurrentSelectedData([...tempArr]);
    }
  }, [watchlist.watchlistToAddData]);

  useEffect(() => {
    var tempArr = [];
    if (
      watchlist.sharelistToAddData &&
      watchlist.sharelistToAddData.length > 0
    ) {
      watchlist.sharelistToAddData.map((x, i) => {
        if (x.already_added) {
          tempArr.push(x.uuid);
        }
      });
      setCurrentSelectedSuggestData([...tempArr]);
    }
  }, [watchlist.sharelistToAddData]);

  const handleUpdateWatchlist = (id) => {
    if (
      (currentSelectedData.length > 0 || currentUnSelectedData.length > 0) &&
      id &&
      id == data.media
    ) {
      const payload = {
        categories: currentSelectedData,
        removed_categories: currentUnSelectedData,
        media: id,
      };
      if (props?.setAddedId && currentSelectedData.length > 0) {
        props?.setAddedId(id);
        let tempArr = currentAddedIds;
        tempArr.push(id);
        setCurrentAddedIds([...tempArr]);
      } else if (props?.setAddedId && currentSelectedData.length == 0) {
        props?.setAddedId(id + '-removed');
        let tempArr = currentAddedIds;
        let indexOfId = tempArr.indexOf(id);
        if (indexOfId > -1) {
          tempArr.splice(indexOfId, 1);
          setCurrentAddedIds([...tempArr]);
        }
      }
      helpers.trackMoengageEvent('FR3_Searches', {
        title: data.title,
        add_to_existing_watchlist: MOENGAGE_ATTRIBUTE.YES,
      });
      dispatch(addRemoveMediaFromWatchlist({ body: payload }));
      setPage(1);
      setPage2(1);
    }
    if (
      (currentSelectedSuggestData.length > 0 ||
        currentUnSelectedSuggestData.length > 0) &&
      id &&
      id == data.media
    ) {
      const payload = {
        categories: currentSelectedSuggestData,
        removed_categories: currentUnSelectedSuggestData,
        media: id,
      };
      dispatch(addToWatchlist({ uuid: id, section: "suggest", body: payload }));
    }
    resetState();
    handleClose();
  };

  return (
    <>
      {(currentAddedIds.includes(data.media)) || (currentAddedIds.length == 0 && data.in_watchlist && data.in_watchlist) ? (
        <>
          {btnWithLabel && btnWithLabel != ""
            ?
            <Button
              title="add to wathclist/sharelist"
              sx={{ alignItems: "center" }}
              className="bg-gunpowder rounded-btn mr-20 pr-30 d-flex w-100"
              onClick={() => handleClickOpen(data.media)}
            >
              <Box
                component="img"
                src={BookmarkWhiteIcon}
                alt="bookmark"
                sx={props?.sx}
                style={{ width: props.size ? props.size : "36px", height: props.size ? props.size : "36px" }}
                className="create-wt-bookmark pointertext-selected pointer"
              />
              <Typography
                sx={{ marginRight: { xs: "20px", lg: "0px", md: "0px" }, marginTop: '-3px' }}
                className="text-selected fw-600 fs-16"
              >
                watchlist
              </Typography>
            </Button>
            :
            <Box
              component="img"
              alt=""
              src={customSelecetdIcon ? customSelecetdIcon : BookmarkWhiteIcon}
              sx={props?.sx}
              style={{ width: props.size ? props.size : "36px", height: props.size ? props.size : "36px" }}
              onClick={() => handleClickOpen(data.media)}
              className={`${!customSelecetdIcon && 'create-wt-bookmark'} pointer text-selected pointer`}
            />
          }
        </>
      ) : (
        <>
          {btnWithLabel && btnWithLabel != ""
            ?
            <Button
              sx={{ alignItems: "center" }}
              className="bg-gunpowder rounded-btn mr-20 pr-30 d-flex w-100"
              onClick={() => handleClickOpen(data.media)}
            >
              <Box
                component="img"
                alt="bookmark"
                src={BookmarkPinkIcon}
                sx={{ ...props?.sx, width: { xs: 32, sm: 38 }, height: { xs: 32, sm: 38 }, borderRadius: '100%', }}
                style={{ width: props.size ? props.size : "36px", height: props.size ? props.size : "36px" }}
                className="create-wt-bookmark text-selected pointer"
              />
              <Typography
                sx={{ marginRight: { xs: "20px", lg: "0px", md: "0px" }, marginTop: '-3px' }}
                className="text-selected fw-600 fs-16"
              >
                watchlist
              </Typography>
            </Button>
            :
            <Box
              component="img"
              alt=""
              src={customIcon ? customIcon : BookmarkPinkIcon}
              sx={{ ...props?.sx, width: { xs: 32, sm: 38 }, height: { xs: 32, sm: 38 }, borderRadius: !customIcon ? '100%' : 0 }}
              style={{ width: props.size ? props.size : "36px", height: props.size ? props.size : "36px" }}
              onClick={() => handleClickOpen(data.media)}
              className={`${!customIcon && 'create-wt-bookmark'} pointer text-selected pointer`}
            />
          }
        </>
      )}
      {/* CREATE WATCHLIST POPUP */}
      <div>
        <Modal
          open={createWatchlistPopup}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid
              sx={{
                p: { xs: 2, sm: 4 },
                paddingBottom: "10px !important",
                position: "sticky",
                top: 0,
                zIndex: 1009,
              }}
              container
              flexWrap={"nowrap"}
              className="bg-gunpowder"
            >
              <Grid
                item
                sx={{ width: "100%", maxWidth: { xs: 100, sm: 155 } }}
                className="relative"
              >
                <Box
                  component="img"
                  sx={{
                    height: 210,
                    width: "100%",
                    maxHeight: { xs: 150, sm: 210 },
                    maxWidth: { xs: 100, sm: 155 },
                    borderRadius: "9px",
                  }}
                  alt="poster"
                  src={
                    data.poster
                      ? helpers.getFullUrl(data.poster)
                      : data.poster_path
                        ? helpers.getFullUrl(data.poster_path)
                        : helpers.getDefaultImage("title")
                  }
                />
              </Grid>
              <div className="ml-20 w-100 relative">
                <CloseIcon
                  onClick={handleClose}
                  className='ml-3 pt-2 pointer absolute animate-close'
                  color="light"
                  sx={{
                    top: { xs: '-10px', sm: '-12px', md: '-22px' },
                    right: { xs: '-10px', sm: '-12px', md: '-22px' },
                    zIndex: 9,
                    fontSize: { xs: '24px', sm: '36px', md: '40px' }
                  }}
                />
                <p className="fs-26 fw-700 lh-28 m-0 ">
                  add a name for your new
                  <span className="text-selected">
                    {" "}
                    {isPublic ? "sharelist" : "watchlist"}
                  </span>{" "}  
                </p>
                <Grid container alignItems="center" className="mt-20">
                  <Grid item sm={8}>
                    <input
                      onChange={(e) => setWatchlistName(e.target.value)}
                      id="outlined-basic"
                      placeholder={`${isPublic ? "sharelist" : "watchlist"} name`}
                      variant="outlined"
                      className="w-100 fs-16 watchlist-input"
                    />
                  </Grid>
                  <Grid item className="ml-20">
                    <FormGroup>
                      <FormControlLabel
                        label={isPublic ? "sharelist" : "watchlist"}
                        // sx={{padding:'5px'}}
                        control={
                          <GreenSwitch
                            checked={isPublic}
                            onChange={(e) => setIsPublic(e.target.checked)}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"} className="mt-30">
                  <Button
                    onClick={() => handleCreateWatchlist()}
                    className={`rounded-btn ${watchlistName != ""
                      ? "bg-selected text-light"
                      : "bg-pink text-dark"
                      } w-100 fs-14 fw-500`}
                  >
                    create
                  </Button>
                </Grid>
              </div>
            </Grid>
            <div className="relative">
              {currentLoading && (
                <div className="relative-loader">
                  <CircularProgress color="success" />
                </div>
              )}
              <Grid
                item
                xs={12}
                sx={{ p: { xs: 2, sm: 4 }, paddingTop: "10px !important" }}
              >
                {watchlist.watchlistToAddData &&
                  watchlist.watchlistToAddData.length > 0 && (
                    <>
                      {/* <p className='fw-500 fs-32 text-selected m-0 text-center'>or</p> */}
                      <p className="fs-28 fw-700 lh-28 m-0 ">
                        add to
                        <span className="text-selected">
                          {" "}
                          existing watchlist
                        </span>{" "}
                      </p>
                      <Grid
                        xs={12}
                        sx={{ marginTop: { xs: "10px", sm: "20px" } }}
                      >
                        <Swiper
                          slidesPerView={2}
                          spaceBetween={20}
                          // slidesPerGroup={5}
                          freeMode={true}
                          speed={1000}
                          navigation={true}
                          breakpoints={breakpoints}
                          modules={[FreeMode, Navigation]}
                          className="mySwiper"
                        >
                          {watchlist.watchlistToAddData.map((x, i) => {
                            return (
                              <SwiperSlide key={i}>
                                <div style={{ padding: '20px 0px 20px 0px' }}
                                  className="watchlist-item pointer"
                                  key={i}
                                  onClick={() => handleOnsearchMediaClick(x)}
                                >
                                  <div
                                    className={`text-center ${currentSelectedData.length > 0 &&
                                      currentSelectedData.includes(x.uuid)
                                      ? "watchlist-title-selected"
                                      : ""
                                      }`}
                                  >
                                    <div className="relative">
                                      <Box
                                        component="img"
                                        className="d-block"
                                        sx={{
                                          height: 180,
                                          width: "100%",
                                          maxHeight: { xs: 150, sm: 180 },
                                          maxWidth: { xs: 120, sm: 155 },
                                          borderRadius: "9px",
                                        }}
                                        alt="poster"
                                        src={
                                          x.poster
                                            ? helpers.getFullUrl(x.poster)
                                            : helpers.getDefaultImage(
                                              "watchlistsection"
                                            )
                                        }
                                      />
                                      {currentSelectedData.length > 0 &&
                                        currentSelectedData.includes(x.uuid) && (
                                          <div className="selected-watchlist-icon">
                                            <img alt="" src={rightImg} />
                                          </div>
                                        )}
                                    </div>
                                    <div>
                                      <p
                                        title={
                                          x.category_name
                                            ? x.category_name
                                            : "N/A"
                                        }
                                        className="text-light fs-14 lh-12 fw-700"
                                      >
                                        {x.category_name
                                          ? x.category_name.length > 12
                                            ? x.category_name.substring(0, 12) +
                                            "..."
                                            : x.category_name
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </Grid>
                    </>
                  )}
                {watchlist.sharelistToAddData &&
                  watchlist.sharelistToAddData.length > 0 && (
                    <>
                      <p className="fs-28 fw-700 lh-28 m-0 ">
                        suggest to
                        <span className="text-selected"> sharelist</span>{" "}
                      </p>
                      <Grid
                        xs={12}
                        sx={{ marginTop: { xs: "10px", sm: "20px" } }}
                      >
                        <Swiper
                          slidesPerView={2}
                          spaceBetween={20}
                          // slidesPerGroup={5}
                          freeMode={true}
                          speed={1000}
                          navigation={true}
                          breakpoints={breakpoints}
                          modules={[FreeMode, Navigation]}
                          className="mySwiper"
                        >
                          {watchlist.sharelistToAddData.map((x, i) => {
                            return (
                              <SwiperSlide key={i}>
                                <div style={{ padding: '20px 0px 20px 0px' }}
                                  className="pointer sharelist-item"
                                  key={i}
                                  onClick={() =>
                                    handleOnsearchMediaSuggestClick(x)
                                  }
                                >
                                  <div
                                    className={`text-center ${currentSelectedSuggestData.length > 0 &&
                                      currentSelectedSuggestData.includes(x.uuid)
                                      ? "watchlist-title-selected"
                                      : ""
                                      }`}
                                  >
                                    <div className="relative">
                                      <Box
                                        component="img"
                                        className="d-block"
                                        sx={{
                                          height: 180,
                                          width: "100%",
                                          maxHeight: { xs: 150, sm: 180 },
                                          maxWidth: { xs: 120, sm: 155 },
                                          borderRadius: "9px",
                                        }}
                                        alt="poster"
                                        src={
                                          x.poster
                                            ? helpers.getFullUrl(x.poster)
                                            : helpers.getDefaultImage(
                                              "watchlistsection"
                                            )
                                        }
                                      />
                                      {currentSelectedSuggestData.length > 0 &&
                                        currentSelectedSuggestData.includes(
                                          x.uuid
                                        ) && (
                                          <div className="selected-watchlist-icon">
                                            <img alt="" src={rightImg} />
                                          </div>
                                        )}
                                    </div>
                                    <div>
                                      <p
                                        title={
                                          x.category_name
                                            ? x.category_name
                                            : "N/A"
                                        }
                                        className="text-light fs-14 lh-12 fw-700"
                                      >
                                        {x.category_name
                                          ? x.category_name.length > 12
                                            ? x.category_name.substring(0, 12) +
                                            "..."
                                            : x.category_name
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </Grid>
                    </>
                  )}
                {(watchlist.sharelistToAddData.length > 0 ||
                  watchlist.watchlistToAddData.length > 0) && (
                    <div className="mt-10 text-center">
                      <Button
                        onClick={() => handleUpdateWatchlist(data.media)}
                        className={`rounded-btn bg-light text-selected w-100 fs-14 fw-500`}
                      >
                        update
                      </Button>
                    </div>
                  )}
              </Grid>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
