import Grid from '@mui/material/Grid'
import React, { useEffect, useRef, useState } from 'react'
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Keyboard, Mousewheel, Pagination, Parallax } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import './snipz.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clipPostActivity, getClipsData, getCommentLists } from '../../slices/clips';
import ShareIcon from "@mui/icons-material/Share";
import { Box, Modal } from '@mui/material';
import ClipsPlayer from './ClipsPlayer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import BookmarkAction from '../watchlist/BookmarkAction';
import ShareMovie from '../common/ShareMovie';
import Loader from '../common/Loader';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Replies from './Replies';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LikeAction from './LikeAction';
import ReportClip from './ReportClip';
import { updateClipActiveIndex } from '../../slices/global';
import UploadClip from './UploadClip';
import MyHelmet from '../common/MyHelmet';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { isMobileDevice } from "../../services/Utils";
// import NewWatchlistIconPink from '../../assets/icons/NewWatchlistIconPink.svg'
// import NewWatchlistIconWhite from '../../assets/icons/NewWatchlistIconWhite.svg'

const NewWatchlistIconPink = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewWatchlistIconPink.svg";
const NewWatchlistIconWhite = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/NewWatchlistIconWhite.svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "90vw", sm: "75vw", md: "750px" },
    maxHeight: "97vh",
    overflowY: "auto",
    bgcolor: "#1A1A2E",
    boxShadow: 24,
    paddingBottom: '0px !important',
    p: { xs: 2, sm: 3, md: 5 },
    borderRadius: 5,
    zIndex: 999,
};

const pageLimit = 10;

export default function Snipz() {
    const [commentText, setCommentText] = useState('');
    const [commentId, setCommentId] = useState('');
    const [isCommented, setIsCommented] = useState([]);
    const [isClipReady, setIsClipReady] = useState(false);
    const [myComments, setMyComments] = useState(0);
    const [replyToUser, setReplyToUser] = useState('');
    const [commentPostId, setCommentPostId] = useState('');
    const [openComments, setOpenComments] = useState(false);
    const [isClipLoading, setIsClipLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [addedId, setAddeddId] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const clips = useSelector(state => state.clips);
    const _global = useSelector(state => state.global);
    const { activeClipIndex } = _global;
    const { clipsData, clipsPageData, commentListData } = clips
    const inputRef = useRef();
    const [controlledSwiper, setControlledSwiper] = useState(null);

    const getUserId = () => {
        let udata = localStorage.getItem('frenzi_user_access');
        udata = JSON.parse(udata);
        return udata.uuid || '';
    }

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if (clipsData.length == 0) {
            getData(1);
        } else {
            if (activeClipIndex > 0) {
                if (controlledSwiper && !controlledSwiper.destroyed) {
                    setIsClipReady(true);
                    controlledSwiper.slideTo(activeClipIndex);
                }
            } else {
                setIsClipReady(true);
            }
        }
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let nvh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${nvh}px`);
        });
    }, [controlledSwiper])


    const handleArrowCLick = () => {
        if (controlledSwiper && !controlledSwiper.destroyed) {
            controlledSwiper.slideTo(controlledSwiper.activeIndex + 1);
        }
    }

    useEffect(() => {
        if (page > 1) {
            getPageData(page);
        }
    }, [page])

    const getPageData = (page) => {
        const body = {
            page: page,
            section: 'post',
            include_viewed_clips: 0,
            pagelimit: pageLimit
        }
        dispatch(getClipsData(body));
    }

    const getData = (page) => {
        setIsClipLoading(true);
        const body = {
            id: params.id ? params.id : '',
            page: page,
            section: 'post',
            include_viewed_clips: 0,
            pagelimit: pageLimit
        }
        dispatch(getClipsData(body)).then((resp) => {
            if (resp.payload && resp.payload.data && resp.payload.data.results && resp.payload.data.results[0]) {
                let id = resp.payload.data.results[0].post_id;
                navigate('/clips/' + id);
            }
            dispatch(updateClipActiveIndex(0));
            setIsClipLoading(false);
            setIsClipReady(true);
        });
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleGetComments = (data, total) => {
        setCommentPostId(data?.post_id);
        helpers.trackMoengageEvent('FR3_Clips', {
            comment_click: MOENGAGE_ATTRIBUTE.YES,
        });
        dispatch(getCommentLists({ id: data?.post_id, page: 1, pagelimit: 10 })).then(() => {
            setOpenComments(true);
        });
    }

    const handleOpenReplyInput = (data) => {
        if (data.comment_id) {
            setCommentId(data.comment_id);
            setReplyToUser(data.user_name);
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
            }
        }
    }

    const clearReplyData = () => {
        setCommentId('');
        setReplyToUser('');
    }

    const handleAddComment = () => {
        if (commentId != '' && replyToUser != '' && commentText != '') {
            let body = {
                "post_id": commentPostId,
                "comment_id": commentId,
                "reply": commentText
            }
            dispatch(clipPostActivity({ type: 'reply', body })).then(() => {
                setCommentText('');
                setCommentId('');
                setReplyToUser('');
                dispatch(getCommentLists({ id: commentPostId, page: 1, pagelimit: 10 })).then(() => {
                });
            })
        } else {
            if (commentText && commentPostId) {
                let body = {
                    "post_id": commentPostId,
                    "comment": commentText
                }
                dispatch(clipPostActivity({ type: 'comment', body })).then(() => {
                    setCommentText('');
                    dispatch(getCommentLists({ id: commentPostId, page: 1, pagelimit: 10 })).then(() => {
                        setIsCommented([...isCommented, commentPostId]);
                        setMyComments(myComments + 1);
                    });
                })
            }
        }
    }

    const handleOnSlideChange = (e) => {
        helpers.trackMoengageEvent('FR3_Clips', {
            move_to_next: MOENGAGE_ATTRIBUTE.YES,
        });
        dispatch(updateClipActiveIndex(e.activeIndex));
        let id = clipsData[e.activeIndex]?.post_id;
        navigate('/clips/' + id);
        if (e.activeIndex > clipsData.length - 3 && clipsPageData.pagination && clipsPageData.pagination.is_next_page) {
            setPage(page + 1);
        }
    }

    return (
        <>
            <MyHelmet
                title={'Explore clips & know about a title you want to watch'}
                description={'Get a Glimpse of Titles, Explore Clips for a Sneak Peek into Movies and Shows Before Diving In!'}
            />
            {(isClipLoading || !isClipReady) && <Loader />}
            <Grid className='h-100' id="frenzi-clips-page">
                {clipsData.length > 0 &&
                    <Grid className="h-100 w-100">
                        <Swiper
                            slidesPerView={1.05}
                            slidesPerGroup={1}
                            direction={"vertical"}
                            grabCursor={false}
                            breakpoints={{
                                160: {
                                    slidesPerView: 1,
                                },
                                319: {
                                    slidesPerView: 1,
                                },
                                426: {
                                    slidesPerView: 1.05,
                                }
                            }}
                            keyboard={{
                                enabled: true,
                            }}
                            color='red'
                            speed={500}
                            parallax={true}
                            // spaceBetween={30}
                            onSwiper={setControlledSwiper}
                            autoHeight={true}
                            mousewheel={true}
                            freeMode={{
                                enabled: false,
                                sticky: true,
                                minimumVelocity: 1,
                            }}
                            modules={[Mousewheel, Keyboard, Parallax, FreeMode, Pagination]}
                            className="mySwiper"
                            onSlideChange={(e) => handleOnSlideChange(e)}
                        >
                            {isClipReady && clipsData && clipsData.length > 0 && clipsData.map((x, i) => {
                                return <SwiperSlide key={i}>
                                    <Grid container className='clips-item-container' justifyContent={'center'} >
                                        <Grid item className="h-100 media-container w-100 pointer relative"
                                            sx={{
                                                marginRight: { sm: '20px' },
                                                maxWidth: { xs: '100%', sm: '500px', md: '400px', lg: '400px' }
                                            }}
                                        >
                                            <div className='owner-details' style={{ width: 'calc(100% - 75px)' }}>
                                                <Grid container flexWrap={'nowrap'} alignItems={'center'}>
                                                    <Link to={`/profile/${x?.user}`}>
                                                        <Grid item>
                                                            <Box
                                                                component={'img'}
                                                                className='d-block'
                                                                src={x?.profile_image ? helpers.getFullUrl(x?.profile_image) : helpers.getDefaultImage('profile')}
                                                                alt="profilepic"
                                                                sx={{
                                                                    clipPath: 'circle()',
                                                                    width: { xs: '24px', sm: '32px', md: '32px' },
                                                                    height: { xs: '24px', sm: '32px', md: '32px' },
                                                                    backgroundColor: '#000'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Link>
                                                    <Link to={`/profile/${x?.user}`}>
                                                        <Grid item sx={{ paddingLeft: '5px' }} xs='auto'>
                                                            <span className='ellipsis shadow fs-16 fw-600 w-100'>{x?.username}</span>
                                                        </Grid>
                                                    </Link>
                                                </Grid>
                                                {x?.total_views > 0 &&
                                                    <p className='fs-12 pl-35 m-0 shadow ellipsis'>{x?.total_views} have watched this</p>
                                                }
                                            </div>
                                            <UploadClip />
                                            <ClipsPlayer
                                                id={x?.post_id}
                                                isPlay={activeClipIndex === i}
                                                src={x?.post_url}
                                                onScroll={true}
                                                thumbnail_url={x?.thumbnail_url}
                                            />
                                            <div className='media-details'>
                                                <Grid container flexWrap={'nowrap'} alignItems={'center'} >
                                                    <Link
                                                        onClick={() => { x.post_type == 'collection' ? helpers.watchlistClick(x?.watchlist, x?.watchlist_id, 'Clips') : helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Clips') }}

                                                        to={x.media ? helpers.getMediaLink(x) : `/watchlist-detail/${x?.watchlist_id}`}
                                                    >
                                                        <Grid item>
                                                            {x.provider && x.provider.length > 0 ?
                                                                <Box
                                                                    component={'img'}
                                                                    className='d-block'
                                                                    src={x?.provider[0] ? helpers.getFullUrl(x?.provider[0]?.ott_platform__platform_logo) : helpers.getDefaultImage('poster')}
                                                                    alt=""
                                                                    sx={{
                                                                        clipPath: 'circle()',
                                                                        width: { xs: '24px', sm: '32px', md: '32px' },
                                                                        height: { xs: '24px', sm: '32px', md: '32px' },
                                                                        backgroundColor: '#000'
                                                                    }}
                                                                />
                                                                :
                                                                <Box
                                                                    component={'img'}
                                                                    className='d-block'
                                                                    src={x?.poster ? helpers.getFullUrl(x?.poster) : helpers.getDefaultImage('poster')}
                                                                    alt="poster"
                                                                    sx={{
                                                                        clipPath: 'circle()',
                                                                        width: { xs: '24px', sm: '32px', md: '32px' },
                                                                        height: { xs: '24px', sm: '32px', md: '32px' },
                                                                        backgroundColor: '#000'
                                                                    }}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Link>
                                                    <Link
                                                        onClick={() => { x.post_type == 'collection' ? helpers.watchlistClick(x?.watchlist, x?.watchlist_id, 'Clips') : helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Clips') }}
                                                        to={x.media ? helpers.getMediaLink(x) : `/watchlist-detail/${x?.watchlist_id}`}>
                                                        <Grid item sx={{ paddingLeft: '5px' }} xs='auto'>
                                                            <span className='ellipsis shadow fs-16 fw-600 w-100'>{x?.title || x?.watchlist}</span>
                                                        </Grid>
                                                    </Link>
                                                </Grid>

                                                {x?.hashtags && x?.hashtags.length > 0 &&
                                                    <p className='fs-14 pl-35 m-0 shadow ellipsis'>#{x?.hashtags.join(' #')}</p>
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item display={'flex'} flexDirection='column' justifyContent={'flex-end'}
                                            sx={{
                                                position: { xs: 'absolute', sm: 'relative' },
                                                bottom: { xs: '10px', sm: '0px' },
                                                zIndex: 9,
                                                right: { xs: '10px', sm: '0px' }
                                            }}
                                        >
                                            <Box
                                                className='text-center pointer'
                                                sx={{
                                                    maxHeight: 48,
                                                    height: "100%",
                                                    borderRadius: '50%',
                                                }}>
                                                <LikeAction is_liked={x?.is_liked} data={x} like_count={x?.total_likes} showCount={true} type="clip_like" />
                                            </Box>

                                            <Box
                                                className='text-center pointer'
                                                onClick={() => handleGetComments(x, x?.total_comments)}
                                                sx={{
                                                    maxHeight: 48,
                                                    height: "100%",
                                                    borderRadius: '50%',
                                                    marginTop: { xs: '15px', sm: '20px', md: '30px' }
                                                }}>
                                                <ChatBubbleOutlineRoundedIcon
                                                    className={`${(isCommented.includes(x?.post_id) || x?.is_commented) && 'text-selected'}`}
                                                />
                                                <p className='text-center m-0 fs-14 fw-600'>{x?.total_comments + myComments}</p>
                                            </Box>
                                            <Box
                                                className='text-center relative'
                                                sx={{
                                                    maxHeight: 48,
                                                    height: "100%",
                                                    borderRadius: '50%',
                                                    marginTop: { xs: '15px', sm: '20px', md: '30px' }
                                                }}>
                                                <BookmarkAction
                                                    data={{ ...x, ['in_watchlist']: x?.is_watchlist }}
                                                    customIcon={NewWatchlistIconWhite}
                                                    forceLink={x.watchlist_id && x.post_type == 'collection' ? `/watchlist-detail/${x?.watchlist_id}` : ''}
                                                    customSelecetdIcon={NewWatchlistIconPink}
                                                    size={22}
                                                    onClick={() => {
                                                        helpers.trackMoengageEvent('FR3_Clips', {
                                                            watchlist_click: MOENGAGE_ATTRIBUTE.YES,
                                                        });
                                                    }}
                                                    addedId={addedId}
                                                    setAddedId={setAddeddId}
                                                />
                                                <p className='text-center m-0 fs-14 fw-600'>{x?.watchlist_count}</p>
                                            </Box>

                                            <Box
                                                className='text-center'
                                                sx={{
                                                    maxHeight: 48,
                                                    maxWidth: 48,
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: '50%',
                                                    marginTop: { xs: '15px', sm: '20px', md: '30px' }
                                                }}>
                                                <ShareMovie
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    hideShare={true}
                                                    myComponent={true}
                                                    customComponent={<ShareIcon />}
                                                    size={48}
                                                    shareUrl={window.location.href}
                                                />
                                            </Box>

                                            <Box
                                                className='text-center pointer'
                                                sx={{
                                                    maxHeight: 48,
                                                    height: "100%",
                                                    borderRadius: '50%',
                                                    marginTop: { xs: '1px', sm: '15px', md: '20px' }
                                                }}>
                                                {/* {x.user !== getUserId()} */}
                                                <ReportClip data={x} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </Grid>
                }
                {!isMobileDevice() &&
                    <div onClick={() => handleArrowCLick()} className='pointer clips-arow'>
                        <ArrowDownwardRoundedIcon color='primary' />
                    </div>
                }
                <div>
                    <Modal
                        open={openComments}
                        onClose={() => setOpenComments(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <CloseRoundedIcon style={{ float: 'right' }} onClick={() => setOpenComments(false)} className='animate-close pointer' />
                            <p className='fs-28 fw-700 lh-28 m-0 '>comments</p>
                            <div>
                                {commentListData && commentListData.length > 0 ? commentListData.map((x, i) => {
                                    return <Grid container key={i} justifyContent='space-between' sx={{ marginTop: '20px' }}>
                                        <Grid item>
                                            <Grid container >
                                                <Grid item>
                                                    <Box
                                                        component={'img'}
                                                        className='d-block'
                                                        sx={{
                                                            clipPath: 'circle()',
                                                            width: { xs: '28px', sm: '36px', md: '40px' },
                                                            height: { xs: '28px', sm: '36px', md: '40px' },
                                                            backgroundColor: '#000'
                                                        }}
                                                        alt="profilepic"
                                                        src={x?.profile_image ? helpers.getFullUrl(x?.profile_image) : helpers.getDefaultImage('profile')}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ paddingLeft: '7px' }}>
                                                    <p className='m-0 fs-16 fw-500'>{x?.user_name || 'Unknown'}</p>
                                                    <span className='m-0 fs-14 text-muted'>{x?.comment}</span>
                                                    <br />
                                                    <span onClick={() => handleOpenReplyInput(x)} className='fs-12 pointer'>reply</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                className='text-center pointer'
                                                sx={{
                                                    maxHeight: 48,
                                                    height: "100%",
                                                    borderRadius: '50%',
                                                }}>
                                                <LikeAction is_liked={x?.is_liked} data={x} type="comment_like" />
                                            </Box>
                                        </Grid>
                                        <Grid sx={{ paddingLeft: { xs: '28px', sm: '36px', md: '40px' } }} item xs={12}>
                                            <Replies data={x} />
                                        </Grid>
                                    </Grid>
                                })
                                    :
                                    <p>no comments yet</p>
                                }
                            </div>
                            <div className='clip-comment-input-box'>
                                <input ref={inputRef} className='clip-comment-input' onChange={(e) => setCommentText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') { handleAddComment() } }} value={commentText} placeholder={`${replyToUser != '' && commentId != '' ? 'you are replying to ' + (replyToUser ? replyToUser : 'Unknown') : 'add your comment'}`} />
                                {replyToUser != '' && commentId != '' &&
                                    <span className='cross-icon'>
                                        <CloseRoundedIcon onClick={() => clearReplyData()} className='animate-close pointer' />
                                    </span>
                                }
                                <ChevronRightRoundedIcon onClick={() => handleAddComment()} className='text-selected send-icon pointer' />
                            </div>
                        </Box>
                    </Modal>
                </div>
            </Grid >
        </>
    )
}
