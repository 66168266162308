import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import helpers from '../../services/helpers';
import BookmarkAction from '../watchlist/BookmarkAction';
import MediaSubdetails from './MediaSubdetails'
import RateMovie from './RateMovie';
import SeenMovie from './SeenMovie';

export default function MovieTile(props) {
    const { x, isBookmark } = props;
    const [addedId, setAddeddId] = useState('');

    return (
        <Grid item xs={12}>
            <Grid container flexWrap={'nowrap'}>
                <Grid item className='relative text-center' >
                    <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', props?.refPage)} to={helpers.getMediaLink(x)}>
                        <Box
                            component="img"
                            className='d-block'
                            sx={{
                                height: 148,
                                width: 102,
                                maxHeight: { xs: 148, sm: 148 },
                                maxWidth: { xs: 100, sm: 102 },
                                borderRadius: '9px 9px 9px 9px',
                            }}
                            alt={x?.title || 'poster'}
                            src={x.poster ? helpers.getFullUrl(x.poster) : helpers.getDefaultImage('trendingmedia')}
                        />
                    </Link>
                    {isBookmark &&
                        <BookmarkAction data={x} addedId={addedId} setAddedId={setAddeddId} />
                    }
                </Grid>
                <Grid item style={{ marginLeft: 16 }} xs={6}  >
                    <Grid container justifyContent={'space-between'} flexDirection={'column'} className="h-100" >
                        <Grid item className='w-100'>
                            <h2 className='fw-500 fs-12 text-dark-grey m-0 ellipsis'>
                                {x.data && x.data[0] && x.data[0].ott_name &&
                                    x.data[0]?.ott_name
                                }
                                {x.data && x.data.length > 1 ? ` +${x.data.length - 1}` : ''}
                            </h2>
                            <Typography title={x.title} className='fs-20 ellipsis fw-700'>
                                {x.title ? x.title : ''}
                            </Typography>
                            <MediaSubdetails data={x} />
                            {/* <Stack direction="row" className='ai-center ml-15 mt-10'>
                                {
                                    list.map((x, i) => {
                                        return <Avatar alt="Remy Sharp" src={RoundedProfile} className='release-avatar' />
                                    })
                                }
                                <Typography className='fs-12 fw-500 lh-23 ml-8'> +25 notified it</Typography>
                            </Stack> */}
                        </Grid>
                        {!props.hideAction &&
                            <Grid item className='w-100'>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <SeenMovie data={x} width={32} size={24} height={32} hideLabel={true} />
                                    </Grid>
                                    <Grid item>
                                        <RateMovie refPage={props?.refPage} data={x} width={32} size={24} height={32} hideLabel={true} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
