import { Grid, Skeleton } from '@mui/material'
import React from 'react'


export default function MediaDetailLoader() {

    return (
        <Grid container flexWrap={"nowrap"} className="mb-20 mt-20">
            <Grid
                item
                className="w-100 h-100"
                xs={12}
                sm={6}
                sx={{ marginRight: "20px" }}
            >
                <Grid container >
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            height: 215,
                            width: "100%",
                            maxHeight: { xs: 180, sm: 125, md: 215 },
                            maxWidth: { xs: 120, sm: 90, md: 149 },
                            minWidth: { xs: 120, sm: 70 },
                            borderRadius: "4px",
                            marginRight: "20px"
                        }}
                    />
                    <Grid item flex={1} >
                        <Grid container flexDirection={'column'} justifyContent='space-between' className='h-100'>
                            <Grid item>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    className="mt-10 fs-12 pl-10"
                                />
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    className="mt-10 fs-32 pl-10 w-50"
                                />
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    className="mt-10 fs-26 pl-10 w-75"
                                />
                            </Grid>
                            <Grid item>
                                <div className='row'>
                                    <Skeleton
                                        animation="wave"
                                        variant="circular"
                                        className="mt-10 pl-10"
                                        sx={{
                                            width: '48px',
                                            height: '48px'
                                        }}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        variant="circular"
                                        className="mt-10 pl-10"
                                        sx={{
                                            width: '48px',
                                            height: '48px',
                                            marginLeft: '16px'
                                        }}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        variant="circular"
                                        className="mt-10 pl-10"
                                        sx={{
                                            width: '48px',
                                            height: '48px',
                                            marginLeft: '16px'
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
