import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "./activity.scss";
import { useNavigate } from "react-router-dom";
// import cinescore_image from '../../assets/icons/Vector.svg'
// import leaf_Left from '../../assets/icons/Left alg.svg'
// import leaf_Right from '../../assets/icons/Right alg.svg'
import { useDispatch, useSelector } from "react-redux";
import { getCinescoreData, getCinescoreDetails } from "../../slices/cinescore";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import WatchedSlides from "./WatchedSlides";
import { isMobileDevice } from '../../services/Utils';
// import CinescoreBG from '../../assets/img/CinescoreBG.png';
// import Button_watchlist_added from '../../assets/Button_watchlist_added.svg'
// import CinescoreButtonLogo from '../../assets/CinescoreButtonLogo.svg'
import helpers from "../../services/helpers";

const cinescore_image = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/Vector.svg";
const leaf_Left = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/Left alg.svg";
const leaf_Right = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/icons/Right alg.svg";

const CinescoreBG = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/img/CinescoreBG.png";
const Button_watchlist_added = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/Button_watchlist_added.svg";
const CinescoreButtonLogo = process.env.REACT_APP_CDN_ASSETS_ENDPOINT+"assets/CinescoreButtonLogo.svg";

const mediaType = "movie";
export default function CinescoreLook(props) {
  const activity = useSelector(state => state.activity);
  const dispatch = useDispatch();
  const cinescore = useSelector((state) => state.cinescore);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCinescoreData());
    const body = {
      service: [],
      genre: [],
      year: [],
      language: [],
    };
    const payload = {
      body: body,
      page: 1,
      pagelimit: 10,
      media_type: mediaType,
      sort: "",
      only_poster: "no",
    };
    dispatch(getCinescoreDetails(payload));
  }, [mediaType, dispatch]);

  return (
    <>
      {isMobileDevice() ?
        <Grid container className="mt-40 mb-30">
          <Grid item xs={12}>
            <Grid container className="w-100" justifyContent={'space-between'}>
              <Grid xs={3}
                item
                className=" w-100  pt-12 pb-8 br-8 pointer"
                sx={{
                  maxWidth: { md: "150px", sm: "150px" },
                  marginTop: { xs: "30px", md: "30px" },

                }}
                onClick={() => navigate("/cinescore/movies")}
              >
                <Grid container justifyContent='center'>
                  <Grid item sx={{ marginTop: { xs: "20px", sm: '10px' }, textAlign: 'center' }}>
                    <img src={cinescore_image} alt='' />
                    <Typography mt={-1} className=" fw-600 fs-24 text-selected">
                      {" "}
                      {cinescore.cinescoreResults &&
                        cinescore.cinescoreResults.total_watched_movie
                        ? cinescore.cinescoreResults.total_watched_movie
                        : 0}
                    </Typography>
                    <p className="fw-300 fs-10 m-0">Movies</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid

                item xs={6}
                // style={{ border: "2px solid #D9D9D9" }}
                sx={{
                  maxWidth: { md: "170px", sm: "112px" },
                  marginTop: { xs: "10px" },

                }}
                className="w-100 pt-10 pb-10 "
              >
                <Grid container justifyContent='center'>
                  <img src={leaf_Left} alt='' style={{ height: 90 }} />
                  <Grid item sx={{ marginTop: { xs: "20px", sm: '10px' }, textAlign: 'center' }}>
                    <img src={cinescore_image} alt='' style={{ height: 21, width: 22 }} />
                    <Typography mt={-1.5} className="fw-600 fs-36 text-selected">
                      {" "}
                      {(cinescore.cinescoreResults &&
                        cinescore.cinescoreResults.total_watched_tv
                        ? cinescore.cinescoreResults.total_watched_tv
                        : 0) +
                        (cinescore.cinescoreResults &&
                          cinescore.cinescoreResults.total_watched_movie
                          ? cinescore.cinescoreResults.total_watched_movie
                          : 0)}
                    </Typography>
                    <p className="fw-400 fs-10 m-0">cinescore</p>
                  </Grid>
                  <img src={leaf_Right} alt='' style={{ height: 90 }} />
                </Grid>
              </Grid>
              <Grid
                item xs={3}
                className=" w-100  pt-12 pb-8 br-8 pointer"
                sx={{
                  maxWidth: { md: "150px", sm: "150px" },
                  marginTop: { xs: "30px", md: "30px" },
                }}
                onClick={() => navigate("/cinescore/webseries")}>
                <Grid container justifyContent='center'>
                  <Grid item sx={{ marginTop: { xs: "20px", sm: '10px' }, textAlign: 'center' }}>
                    <img src={cinescore_image} alt='' />
                    <Typography mt={-1} className="fw-600 fs-24 text-selected">
                      {" "}
                      {cinescore.cinescoreResults &&
                        cinescore.cinescoreResults.total_watched_tv
                        ? cinescore.cinescoreResults.total_watched_tv
                        : 0}
                    </Typography>
                    <p className="fw-300 fs-10 m-0">web series</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ bgcolor: '#3F3D5680' }} className="p-10 mt-30 text-center br-8">
            <p className="fw-600 fs-16 m-0">CINESCORE</p>
            <p className="fw-500 fs-14 m-0">
              count of titles you've watched so far
            </p>
          </Grid>
          <Grid item className="mt-50">
            <Typography className="fw-500 fs-20 lh-24">Have You Seen This ?</Typography>
          </Grid>
          <Grid item xs={12} className="pt-30">
            <Swiper
              centeredSlides={true}
              fadeEffect={{ crossFade: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
              }}
              object='cover'
              navigation={true}
              effect={"fade"}
              modules={[Autoplay, Navigation, EffectFade, Pagination, A11y]}
              className="new-swiper"

            >
              {activity?.haveYouWatchedData && activity.haveYouWatchedData.map((x, i) => {
                return <SwiperSlide key={i}>
                  <WatchedSlides data={x} />
                </SwiperSlide>
              })}
            </Swiper>
          </Grid>
          <Grid container className="pt-60" justifyContent={'space-around'} columnSpacing={2}>
            <Grid item textAlign={'center'}>
              <img src={Button_watchlist_added} alt="" />
              <Typography>watchlist</Typography>
            </Grid>
            <Grid item textAlign={'center'}>
              <img src={CinescoreButtonLogo} />
              <Typography>sharelist</Typography>
            </Grid>
          </Grid>
        </Grid>
        :
        <Grid className="" >
          {cinescore.cinescoreResults &&
            cinescore.cinescoreResults.uuid &&
            (cinescore.cinescoreResults.total_watched_movie > 0 ||
              cinescore.cinescoreResults.total_watched_tv > 0) && (
              <>
                <Grid container
                  className="overflow-hidden"
                  sx={{ bgcolor: 'black' }}>
                  <Grid item
                    sx={{
                      backgroundImage: `url(${CinescoreBG})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      minHeight: '600px'
                    }}
                    xs={12} md={12} lg={12}>
                    <Grid container className="" justifyContent={"center"} >
                      <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                          <Grid style={{ maxWidth: 'max-content' }} item className="text-center mt-70 w-100">
                            <Grid
                              container
                              justifyContent={"space-between"}
                              flexWrap='nowrap'
                              spacing={2}
                              className="mb-20"
                            >
                              <Grid xs={3}
                                item
                                className=" w-100  pt-12 pb-8 br-8 pointer"
                                sx={{
                                  maxWidth: { md: "150px", sm: "150px" },
                                  marginTop: { xs: "30px", md: "30px" },

                                }}
                                onClick={() => navigate("/cinescore/movies")}
                              >
                                <Grid container flexWrap={'nowrap'} justifyContent='center'>
                                  <img src={leaf_Left} alt='' />
                                  <Grid item sx={{ marginTop: { xs: "20px", sm: '10px' }, minWidth: '55px' }}>
                                    <img src={cinescore_image} alt='' />
                                    <Typography mt={-1} className=" fw-600 fs-24 text-selected">
                                      {" "}
                                      {helpers.getSocialNumbers(cinescore.cinescoreResults &&
                                        cinescore.cinescoreResults.total_watched_movie
                                        ? cinescore.cinescoreResults.total_watched_movie
                                        : 0)}
                                    </Typography>
                                    <p className="fw-300 fs-10 m-0">Movies</p>
                                  </Grid>
                                  <img src={leaf_Right} alt='' />
                                </Grid>
                              </Grid>
                              <Grid
                                item xs={6}
                                // style={{ border: "2px solid #D9D9D9" }}
                                sx={{
                                  maxWidth: { md: "170px", sm: "112px" },
                                  marginTop: { xs: "10px" },
                                }}
                                className="w-100 pt-10 pb-10 "
                              >
                                <Grid container flexWrap={'nowrap'} justifyContent='center'>
                                  <img src={leaf_Left} alt='' style={{ height: 100 }} />
                                  <Grid item sx={{ marginTop: { xs: "20px", sm: '10px' } }}>
                                    <img src={cinescore_image} alt='' style={{ height: 21, width: 22 }} />
                                    <Typography mt={-1.5} className="fw-600 fs-36 text-selected">
                                      {" "}
                                      {helpers.getSocialNumbers((cinescore.cinescoreResults &&
                                        cinescore.cinescoreResults.total_watched_tv
                                        ? cinescore.cinescoreResults.total_watched_tv
                                        : 0) +
                                        (cinescore.cinescoreResults &&
                                          cinescore.cinescoreResults.total_watched_movie
                                          ? cinescore.cinescoreResults.total_watched_movie
                                          : 0))}
                                    </Typography>
                                    <p className="fw-400 fs-10 m-0">cinescore</p>
                                  </Grid>
                                  <img src={leaf_Right} alt='' style={{ height: 100 }} />
                                </Grid>
                              </Grid>
                              <Grid
                                item xs={3}
                                className=" w-100  pt-12 pb-8 br-8 pointer"
                                sx={{
                                  maxWidth: { md: "150px", sm: "150px" },
                                  marginTop: { xs: "30px", md: "30px" },
                                }}
                                onClick={() => navigate("/cinescore/webseries")}>
                                <Grid container flexWrap={'nowrap'} justifyContent='center'>
                                  <img src={leaf_Left} alt='' />
                                  <Grid item sx={{ marginTop: { xs: "20px", sm: '10px' }, minWidth: '55px' }}>
                                    <img src={cinescore_image} alt='' />
                                    <Typography mt={-1} className="fw-600 fs-24 text-selected">
                                      {" "}
                                      {helpers.getSocialNumbers(cinescore.cinescoreResults &&
                                        cinescore.cinescoreResults.total_watched_tv
                                        ? cinescore.cinescoreResults.total_watched_tv
                                        : 0)}
                                    </Typography>
                                    <p className="fw-300 fs-10 m-0">web series</p>
                                  </Grid>
                                  <img src={leaf_Right} alt='' />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item sx={{ bgcolor: '#3F3D5680' }} className="p-10 text-center br-8">
                              <p className="fw-600 fs-16 m-0">CINESCORE</p>
                              <p className="fw-500 fs-14 m-0">
                                count of titles you've watched so far
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: '150px', paddingRight: '150px' }} className="pt-30">
                        <Swiper
                          centeredSlides={true}
                          fadeEffect={{ crossFade: true }}
                          autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                          }}
                          // loop={true}
                          // speed={1500}
                          // navigation={true}
                          effect={"fade"}
                          modules={[Autoplay, Navigation, EffectFade, Pagination]}
                          className="new-swiper"
                          pagination={{
                            clickable: true,
                          }}
                        >
                          {activity?.haveYouWatchedData && activity.haveYouWatchedData.map((x, i) => {
                            return <SwiperSlide key={i}>
                              <WatchedSlides data={x} />
                            </SwiperSlide>
                          })}
                        </Swiper>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </>
            )
          }
        </Grid>}
    </>
  );
}
