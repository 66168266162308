import Grid from '@mui/material/Grid'
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom'
import ShareListData from '../connect/ShareListData'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import MyAds from '../adsense/MyAds';

export default function BulkWatchlist(props) {
    const { data, heading, showAds, showName, pageData, nextLink } = props;

    return (
        <div className='section-border-bottom'>
            <Grid container className=''>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'} className="mt-10" justifyContent='space-between'>
                        <Grid item>
                            <h2 className="fw-700 fs-24 m-0">{heading && heading !== "" ? heading : ''}{pageData.pagination && pageData.pagination?.total_entries ? ` (${pageData.pagination?.total_entries})` : ''}</h2>
                        </Grid>
                        {nextLink && nextLink !== "" &&
                            <Link to={nextLink}>
                                <ArrowForwardIcon className='pointer' />
                            </Link>
                        }
                    </Grid>
                    <Grid item xs={12} className={'pb-20'}>
                        {data && data.length > 0 &&
                            <Swiper
                                speed={1000}
                                freeMode={true}
                                navigation={true}
                                modules={[FreeMode, Navigation]}
                                className="mySwiper"
                            >
                                {data.map((x, i) => {
                                    return <>
                                        <SwiperSlide key={i} className='watchlist-outline'>
                                            <Grid key={i} className='mt-20' sx={{ paddingTop: '15px' ,paddingLeft:'8px', border:'1px solid white',borderRadius:'30px',marginRight:'20px' }}>
                                                <ShareListData x={x} showName={showName} />
                                            </Grid>
                                        </SwiperSlide>

                                        {showAds && (data.length > 2 ? i === 1 : data.length - 1 === i) &&
                                            <SwiperSlide style={{ maxWidth: '400px', alignSelf: 'center' }} key={`ads=${i}`}>
                                                <MyAds type="banner_3" adv_type={'web_watchlist_bulk_list_ad'} />
                                            </SwiperSlide>
                                        }
                                    </>
                                })}
                            </Swiper>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
