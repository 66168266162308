import axiosInstance from "../http-common";

const getBlogCategoryList = () => {
    return axiosInstance.get(`blog/blogcategorylist`);
};

const getBlogs = (section = '', category = '', page = 1, pagelimit = 20) => {
    return axiosInstance.get(`blog/blogs?section=${section}&category=${category}&page=${page}&pagelimit=${pagelimit}`);
};

const getBlogDetail = (unique_title, id, section = '', category = '', index = '') => {
    let url = `blog/retrieveblog/`
    if (unique_title && unique_title != '') {
        url += `${unique_title}`;
    } else {
        url += `${id}?section=${section}&category=${category}&index=${index}`;
    }
    return axiosInstance.get(url);
};

const getCommentLists = (id, sort = 'n', page = 1, pagelimit = 10) => {
    let url = `blog/blogcomment?blog=${id}&page=${page}&pagelimit=${pagelimit}&sort=${sort}`;
    return axiosInstance.get(url);
};

const getRepliesList = (id, page = 1, pagelimit = 10) => {
    let url = `blog/blogreply?comment=${id}&page=${page}&pagelimit=${pagelimit}`;
    return axiosInstance.get(url);
};

const addBlogComment = (body) => {
    return axiosInstance.post(`blog/blogcomment`, body);
};

const addBlogReply = (body) => {
    return axiosInstance.post(`blog/blogreply`, body);
};

const deleteCommentReply = (url) => {
    return axiosInstance.delete(`blog/blogcommentreplydelete?${url}`);
};

const likeCommentReply = (url) => {
    return axiosInstance.get(`blog/bloglikecommentreply?${url}`);
};

const webStoriesList = (url, pagelimit = 20) => {
    return axiosInstance.get(`blog/webstorylist?pagelimit=${pagelimit}`)
}

const webStoriesReelList = (unique_title) => {
    return axiosInstance.get(`blog/webstoryreellist?web_story=${unique_title}`)
}

const BlogsDataService = {
    getBlogCategoryList,
    getBlogs,
    getBlogDetail,
    getCommentLists,
    getRepliesList,
    addBlogComment,
    addBlogReply,
    deleteCommentReply,
    likeCommentReply,
    webStoriesList,
    webStoriesReelList

};

export default BlogsDataService;