import React, { useState } from 'react'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Button, Grid, Modal, Popover, TextareaAutosize, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { clipPostActivity, getReportReasons } from '../../slices/clips';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../services/helpers';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: { xs: "90vw", sm: "75vw", md: "600px" },
    maxHeight: "97vh",
    overflowY: "auto",
    bgcolor: "#1A1A2E",
    boxShadow: 24,
    p: { xs: 2, sm: 3, md: 5 },
    borderRadius: 5,
    zIndex: 999,
};

export default function ReportClip(props) {
    const [openReport, setOpenReport] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const clips = useSelector(state => state.clips);
    const { reportReasons } = clips

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReportOpen = () => {
        helpers.trackMoengageEvent('FR3_Clips', {
            report_click_popup: MOENGAGE_ATTRIBUTE.YES,
        });
        handleClose();
        if (reportReasons && reportReasons.length > 0) {
            setOpenReport(true);
            setReason(reportReasons[0]);
        } else {
            dispatch(getReportReasons()).then((resp) => {
                if (resp.payload && resp.payload.data && resp.payload.data[0]) {
                    setOpenReport(true);
                    setReason(resp.payload.data[0]);
                }
            })
        }
    }

    const handleSubmit = () => {
        helpers.trackMoengageEvent('FR3_Clips', {
            report_submit: MOENGAGE_ATTRIBUTE.YES,
        });
        if (reason && reason != '' && props.data && props.data.post_id) {
            let myReason = reason;
            if (reason == 'others') {
                myReason = 'others - ' + otherReason;
            }
            const body = {
                "post_id": props.data.post_id,
                "reason": myReason
            }
            dispatch(clipPostActivity({ type: 'report', body })).then(() => {
                setOpenReport(false);
                helpers.toastSuccess('Clip is reported.')
            })
        }
    }

    return (
        <>
            <MoreVertRoundedIcon
                className='pointer'
                onClick={handleClickListItem}
            />
            <Popover
                id={"moremenu"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Grid container style={{ padding: '14px' }} >
                    <Grid item xs={12}>
                        <p className='fs-24 fw-700 m-0 '>report this <span className='text-selected'>clip</span></p>
                        <p className='fs-16 text-muted m-0 '>found something objectionable?</p>
                        <Grid container justifyContent={'center'} sx={{ marginTop: '20px' }}>
                            <Button
                                sx={{
                                    maxHeight: { xs: '24px', sm: '32px' },
                                }}
                                onClick={() => handleReportOpen()}
                                className='rounded-btn bg-selected fs-14 text-light'>continue</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>

            <Modal
                open={openReport}
                onClose={() => setOpenReport(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpenReport(false)} className='pointer animate-close close-icon' />
                    <p className='fs-28 fw-700 m-0 '>why are you reporting this clip?</p>
                    <p className='fs-16 text-muted m-0 '>your report is anonymous except for IPR violation</p>
                    <Grid container sx={{ marginTop: '30px', marginBottom: '10px' }}>
                        {reportReasons && reportReasons.length > 0 && reportReasons.map((x, i) => {
                            return <Button
                                key={i}
                                sx={{
                                    maxHeight: { xs: '24px', sm: '32px' },
                                    width: 'auto',
                                    marginRight: '10px',
                                    marginBottom: '10px'
                                }}
                                onClick={() => setReason(x)}
                                className={`rounded-border-btn ${reason == x ? 'active' : ''} fs-14 text-light`}>{x}</Button>
                        })}
                    </Grid>
                    {reason == 'others' &&
                        <>
                            <p className='fs-24 fw-700 m-0 '>would you like to tell us more in detail?</p>
                            <TextField multiline label="additional comments" className='text-light' rows={6} style={{ background: 'transparent', width: '100%', marginTop: '10px', color: '#fff' }} />
                        </>
                    }
                    <Grid sx={{ marginTop: '40px' }} container justifyContent={'center'}>
                        <Button sx={{ width: 'auto' }} onClick={() => handleSubmit()} className='rounded-btn bg-selected fs-14 text-light pr-30 pl-30'>submit</Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}
