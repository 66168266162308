import { Avatar, Button, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import { Link } from "react-router-dom";
import AddRemoveTitles from "../watchlist/includes/AddRemoveTitles";
import SuggestTitles from "../watchlist/includes/SuggestTitles";
import { joinWatchlist } from "../../slices/watchlist";
import { useDispatch } from "react-redux";

export default function ShareListData(props) {
  const { x, showName } = props;
  const dispatch = useDispatch();

  const handleJoinWatchlist = (item) => {
    helpers.trackMoengageEvent('FR3_Join_Watchlist', { join_btn_click: MOENGAGE_ATTRIBUTE.YES, watchlist_name: item?.category_name });

    let id = item.watchlist_id ? item.watchlist_id : item.uuid;
    if (id && id !== "") {
      dispatch(joinWatchlist({ id: id, body: {} }));
    }
  };

  return (
    <Grid item display={'flex'} flexDirection={'column'} className='h-100' sx={{minHeight:{xs:270 , sm:300}}} justifyContent={'space-between'}>

      {/* <Grid item>
                    <Avatar alt="Remy Sharp" src={RoundedProfile} className='' />
                </Grid> */}
      {/* <Grid item className="pt-15 h-100">
        <p title={x?.category_name} className="fw-600 fs-20 m-0 mw-280 ellipsis">{x?.category_name}</p>
        {showName && x?.owner_username &&
          <p title={x?.owner_username} className="fw-400 text-muted fs-16 m-0 ellipsis mw-280">created by {x?.owner_username}</p>
        }
      </Grid> */}
      <Grid item className="h-100 mb-50">
        <Grid item className="pt-10  ml-15" xs={5} >
          {x.posters && x.posters.length > 0 ? (
            <Link onClick={() => helpers.watchlistClick(x?.category_name, x?.uuid, 'Sharelist')} to={`/watchlist-detail/${x?.uuid}`}>
              <Stack direction="row" className="ai-center">
                {x.posters.map((y, j) => {
                  if (j < 3) {
                    return (
                      <Box
                        key={j}
                        component="img"
                        alt="sharelist"
                        src={
                          y.watchlist__media__poster_path &&
                            y.watchlist__media__poster_path !== ""
                            ? helpers.getFullUrl(y.watchlist__media__poster_path)
                            : helpers.getDefaultImage("title")
                        }
                        sx={{
                          height: { xs: 150, sm: 180 },
                          width: "100%",
                          maxHeight: { xs: 145, sm: 180 },
                          minWidth: { xs: 80, sm: 105 },
                          maxWidth: { xs: 88, sm: 115 },
                          borderRadius: "8px",
                        }}
                        className="shareListPoster"
                      />
                    );
                  }
                })}
              </Stack>
            </Link>
          ) : (
            <Stack direction="row" className="ai-center">
              <Box
                key={"default-img"}
                component="img"
                alt="sharelist"
                src={helpers.getDefaultImage("poster")}
                sx={{
                  height: { xs: 150, sm: 180 },
                  width: '100%',
                  maxHeight: { xs: 150, sm: 180 },
                  maxWidth: { xs: 85, sm: 100 },
                  borderRadius: "8px",
                }}
                className="shareListPoster"
              />
            </Stack>
          )}
        </Grid>
        <Grid item className="pt-15 h-100  ml-15">
          <p title={x?.category_name} className="fw-600 fs-20 m-0 mw-280 ellipsis">{x?.category_name}</p>
          {showName && x?.owner_username &&
            <p title={x?.owner_username} className="fw-400 text-muted fs-16 m-0 ellipsis mw-280">created by {x?.owner_username}</p>
          }
        </Grid>
        {x.contributors_data && x.contributors_data.length > 0 && (
          <Grid item className="pt-10 h-100  ml-15">
            <Stack direction="row" className="ai-center">
              {x.contributors_data.map((x, i) => {
                return (
                  <Avatar
                    alt="Remy Sharp"
                    src={x?.user__profile_image}
                    className="ott-avatar"
                    sx={{height:24,width:24}}
                  />
                );
              })}
              {x.total_members && x.total_members > 0 && (
                <Typography className="fs-12 fw-500 lh-23 ml-8">
                  {" "}
                  {x.total_members > 3 ? "+" + (x.total_members - 3) : ''} people joined this{" "}
                  {x.is_public ? "sharelist" : "watchlist"}{" "}
                </Typography>
              )}
            </Stack>
          </Grid>
        )}
        <Grid item>
          <Grid container sx={{ justifyContent: { xs: 'center', sm: 'center' } }}>
            <Grid item xs={9} className="text-left mt-20">
              {x.role === "owner" ? (
                <AddRemoveTitles data={x} label={"add titles"} className={'mw-320'} isWhite={true} />
              ) : x.is_member ? (
                <SuggestTitles data={x} />
              ) : (
                <Button
                  onClick={() => handleJoinWatchlist(x)}
                  className="rounded-btn bg-light text-selected fs-14 mw-320 fw-500 w-100"
                >
                  join watchlists
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
