import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, IconButton, InputBase, Modal, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CustomSearch from '../../search/CustomSearch';
import { useDispatch, useSelector } from 'react-redux';
import { addMemberToWatchlist, getWatchlistMembers, removeMemberToWatchlist } from '../../../slices/watchlist';
import { Link } from 'react-router-dom';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 360, sm: 550, md: 650 },
    bgcolor: '#16213E',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
    minHeight: '470px'
};


export default function AddRemoveFriends(props) {
    const { data, isWhite } = props;
    const [page, setPage] = useState(1);
    const [isModal, setIsModal] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const dispatch = useDispatch();
    const watchlist = useSelector(state => state.watchlist);
    const { watchlistMemberData, watchlistMemberPageData, isLoading } = watchlist;

    useEffect(() => {
        if (watchlist.successMesg && watchlist.successMesg != "") {
            if (currentId && props?.data.watchlist_id == currentId) {
                setPage(1);
                dispatch(getWatchlistMembers({ id: currentId, page: 1, pagelimit: 20 }));
                setTimeout(() => {
                    setIsModal(true);
                }, 200)
            }
        }
    }, [watchlist])

    const handleGetFriends = (data, open = false) => {
        helpers.trackMoengageEvent('FR3_Create_Watchlist', { add_friend_click: MOENGAGE_ATTRIBUTE.YES });
        if (data && data.watchlist_id && props?.data.watchlist_id == data?.watchlist_id) {
            dispatch(getWatchlistMembers({ id: data?.watchlist_id, page: page, pagelimit: 20 }));
            if (open) {
                setTimeout(() => {
                    setIsModal(true);
                }, 200)
            }
        }
    }

    const handleAddFriends = (e, item, data) => {
        if (item?.uuid && data && data.watchlist_id && props?.data.watchlist_id == data?.watchlist_id) {
            setCurrentId(data.watchlist_id);
            const payload = {
                "watchlist_id": data.watchlist_id,
                "user": item?.uuid
            }
            dispatch(addMemberToWatchlist({ body: payload }));
        }
    }

    const handleDeleteFriends = (item, data) => {
        if (item?.uuid && data && data.watchlist_id && props?.data.watchlist_id == data?.watchlist_id) {
            setCurrentId(data.watchlist_id);
            dispatch(removeMemberToWatchlist({ uuid: item?.uuid, id: data.watchlist_id }));
        }
    }

    useEffect(() => {
        handleGetFriends(props?.data);
    }, [page])

    const isInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleScroll = () => {
        if (watchlistMemberPageData && watchlistMemberPageData.pagination && watchlistMemberPageData.pagination.is_next_page && !isLoading) {
            let elements = document.getElementsByClassName('title-list-item');
            if (elements && elements.length > 0) {
                let element = elements[elements.length - 1];
                if (isInViewport(element)) {
                    setPage(page + 1);
                }
            }
        }
    }

    return (
        <>
            {isWhite ?
                <Button onClick={() => handleGetFriends(props?.data, true)} className='pl-30 pr-30 rounded-btn bg-light text-selected fs-14 mt-10 mb-10 mw-320 w-100 fw-500'>add friends</Button>
                :
                <Grid container onClick={() => handleGetFriends(props?.data, true)} className='pointer' justifyContent={'flex-end'} alignItems={'center'} >
                    <AddIcon className='text-selected mr-10' />
                    <p className='fw-500 fs-20 lh-20 m-0'>add Friends</p>
                </Grid>
            }
            <Modal
                open={isModal}
                onClose={() => setIsModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item xs={12}>
                        <p className='fw-700 fs-28 m-0'>Add friends</p>
                    </Grid>
                    <Grid item xs={12} className="mt-20">
                        <CustomSearch
                            placeholder="Search to add friends"
                            clickedItem={(e, item) => handleAddFriends(e, item, props?.data)}
                            searchType={'media'}
                            isClearOnClick={true}
                            type="friends"
                        />
                    </Grid>
                    {watchlistMemberData && watchlistMemberData.length > 0 &&
                        <Grid item xs={12} className="mt-0">
                            {/* <p className='p-0 m-0 fw-600 fs-14'>Members ({watchlistMemberPageData?.pagination?.total_entries})</p> */}
                            {/* <p className='p-0 m-0 fw-600 fs-14'>all members</p> */}
                            <Grid item xs={12} className="mt-10" onScroll={() => handleScroll()} style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                {watchlistMemberData.map((x, i) => {
                                    return <div key={i} className="mt-20 title-list-item">
                                        <Grid container alignItems={'center'} justifyContent='space-between'>
                                            <Grid item>
                                                <Grid container alignItems={'center'}>
                                                    <Link onClick={() => helpers.mediaClick(x?.title, x.media ? x.media : x.uuid ? x.uuid : '', 'Watchlist')} to={`/profile/${x?.uuid}`}>
                                                        <div style={{ paddingRight: '10px' }}>
                                                            <Box
                                                                component="img"
                                                                className='d-block'
                                                                sx={{
                                                                    height: '65px',
                                                                    width: '50px',
                                                                    borderRadius: '7px'
                                                                }}
                                                                alt="profilepic"
                                                                src={x.profile_image ? helpers.getFullUrl(x.profile_image) : helpers.getDefaultImage('personmedia')}
                                                            />
                                                        </div>
                                                    </Link>
                                                    <p className='fw-700 fs-16 p-0 m-0'>{x?.username}</p>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={() => handleDeleteFriends(x, props?.data)}>
                                                    <DeleteForeverRoundedIcon className='text-selected' />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                })}
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Modal>
        </>
    )
}
