import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import helpers from '../../services/helpers';

export default function OttShow(props) {

    const { data } = props;

    return (
        <Stack direction="row" className='ai-center'>
            {
                data.map((x, i) => {
                    if (i < 3) {
                        return <Avatar key={i} alt="ott" src={x.image ? helpers.getFullUrl(x.image) : helpers.getDefaultImage('ott')} className='ott-avatar' />
                    }
                })
            }
            {data.length > 3 &&
                <Typography className='fs-16 fw-500 lh-23 ml-8'> +{data.length - 3}</Typography>
            }
        </Stack>
    )
}
