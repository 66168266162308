import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBlogCategoryList, getBlogs } from '../../slices/blog';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import helpers from '../../services/helpers';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import MyAds from '../adsense/MyAds';
import { isMobileDevice } from '../../services/Utils'

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

export default function WPblogs(props) {
    // const [isLoading, setIsLoading] = useState(false);

    const { max } = props;
    const isLess = true;
    const fullHeight = true;

    const currentPage = {
        FEATURED_BLOG: 1,
        LATEST_BLOG: 1,
        POPULAR_BLOG: 1,
    };

    const blog = useSelector(state => state.blog);
    const { blogsCategoryList } = blog;
    const [category, setCategory] = useState('');
    const dispatch = useDispatch();
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [popularBlogs, setPopularBlogs] = useState([]);
    const [isShow, setIsShow] = useState('');
    const maxLength = max && max !== "" ? max : 100;



    const handleChangeCategory = (id, name) => {
        setCategory(id);
        // setActiveCategory(name);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        // setIsLoading(true);
        dispatch(getBlogCategoryList()).then((resp) => {
            if (resp.payload && resp.payload.data && resp.payload.data[0]) {
                setCategory(resp.payload.data[0].uuid);
                // setActiveCategory(resp.payload.data[0].category);
            }
        });
    }, [])

    useEffect(() => {
        if (category && category !== '') {
            dispatch(getBlogs({ section: 'featured', category: category, page: currentPage.FEATURED_BLOG })).then((resp) => {
                // setIsLoading(false);
                if (resp.payload && resp.payload.data && resp.payload.data.results) {
                    setFeaturedBlogs(resp.payload.data.results);
                }
            });
            dispatch(getBlogs({ section: 'latest', category: category, page: currentPage.LATEST_BLOG })).then((resp) => {
                // setIsLoading(false);
                if (resp.payload && resp.payload.data && resp.payload.data.results) {
                    setLatestBlogs(resp.payload.data.results);
                }
            });
            dispatch(getBlogs({ section: 'popular', category: category, page: currentPage.POPULAR_BLOG })).then((resp) => {
                // setIsLoading(false);
                if (resp.payload && resp.payload.data && resp.payload.data.results) {
                    setPopularBlogs(resp.payload.data.results);
                }
            });
        }
    }, [category, dispatch])

    const getLink = (data, section, currentPage, currentIndex) => {
        if (data?.unique_title && data?.unique_title != "") {
            let link1 = `/blog/${data?.unique_title}`;
            return link1;
        } else {
            let index = currentPage + currentIndex;
            let titleUrl = data?.url || data?.title;
            let link = `/blog/${titleUrl.replace(/ /g, '-')}/${data?.uuid}?section=${section}&category=${category}&i=${index}`;
            return link;
        }
    }
    const descriptionn = popularBlogs[0]?.description;
    const cleanedHtml = descriptionn?.replace(/<\/?(p|i)>/g, ' ');
    console.log("amanaman", cleanedHtml)

    return (
        <>
            <Grid container className='new-container  new-blog-layout pt-20'>
                <Grid flex={1} className="left-container">

                    {featuredBlogs.length > 0 &&
                        <Grid container className='app-section'>
                            {/* <Grid item>
                            <p className='fs-20 fw-800 lh-29 text-selected'>featured</p>
                        </Grid> */}

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Swiper
                                    slidesPerView={1}
                                    slidesPerGroup={1}
                                    navigation={true}
                                    modules={[Pagination, Navigation, Autoplay, A11y]}
                                    autoplay={{ delay: 2500 }}
                                    centeredSlides={true}
                                    className='blogs-swiper'
                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                    }}

                                >
                                    {featuredBlogs.map((x, i) => {
                                        return <SwiperSlide key={i}>
                                            <Link to={getLink(x, 'featured', currentPage.FEATURED_BLOG, i)}>
                                                <Grid container key={i}>
                                                    <Grid item xs={12} md={12}>
                                                        <Box component={'img'}
                                                            className='d-block'
                                                            sx={{
                                                                width: '100%',
                                                                height: '100%',
                                                                maxWidth: { xs: '100%' },
                                                                maxHeight: '500px',
                                                                objectFit: 'cover'
                                                            }}
                                                            alt='banner'
                                                            src={x?.banner ? helpers.getFullUrl(x?.banner) : helpers.getDefaultImage('banner')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </SwiperSlide>
                                    })}
                                </Swiper>
                            </Grid>

                            {/* <Grid item lg={3} className='pt-100'   >
                            {!isMobileDevice() ?
                                <MyAds type="banner_3" adv_type={"web_blogs_page_ad"} />
                                : ''
                            }
                        </Grid> */}

                            <Grid item lg={12} className='ml-100' >
                                {!isMobileDevice() ?
                                    <MyAds type="banner_2" adv_type={"web_blogs_page_ad"} />
                                    : ' '
                                }
                            </Grid>
                        </Grid>
                    }

                    {popularBlogs.length > 0 &&
                        <Grid conatiner className='app-section pb-20 pt-100'>
                            <Grid item xs={12} >
                                <h2 style={{ fontWeight: 600, fontSize: '36px' }}>Popular topics</h2>
                            </Grid>

                            <Grid container className='hide-scrollbar' wrap='no-wrap' sx={{ mb: '30px', overflowX: 'auto' }}>
                                {blogsCategoryList.length > 0 ? blogsCategoryList.map((x, i) => {
                                    return <Typography key={i} onClick={() => handleChangeCategory(x?.uuid, x?.category)} sx={{ whiteSpace: 'nowrap' }} className={`fs-16 fw-600 mr-60 pointer ${category === x?.uuid ? 'text-selected' : ''} `}>{x?.category}</Typography>
                                })
                                    :
                                    ''
                                }
                            </Grid>

                            <Grid container columnSpacing={5}>
                                <Grid item lg={12}>
                                    <Grid container spacing={3}>
                                        {popularBlogs.map((x, i) => {
                                            return <Grid key={i} item xs={12} sm={12} md={12} lg={4}>
                                                <Grid container flexDirection={'column'} justifyContent={'space-between'} sx={{ height: '100%', overflow: "clip" }} >
                                                    <Grid item className='w-100'>
                                                        <Link to={getLink(x, 'popular', currentPage.POPULAR_BLOG, i)}>
                                                            <Box component={'img'}
                                                                className='d-block pointer scale-1'
                                                                sx={{
                                                                    width: '100%',
                                                                    // height: '150px',
                                                                    ":hover": { opacity: 0.3 },
                                                                    // objectFit: 'cover'
                                                                }}
                                                                src={x?.banner ? helpers.getFullUrl(x?.banner) : helpers.getDefaultImage('banner')}
                                                                alt='banner'
                                                            />
                                                        </Link>
                                                        <h2 className='fs-18 fw-700  mb-0'>{x?.title}</h2>
                                                    </Grid>

                                                    <Grid item className='w-100 mt-15'>
                                                        <Typography style={{ height: '80px', overflowY: 'auto' }} className={`fs-12 m-0 hide-scrollbar`}>
                                                            {/* {x?.description ? parse(x?.description) : ''} */}
                                                            {x?.description && x?.description.length > 0 && isShow != x?.uuid ? (
                                                                <>
                                                                    {x?.description && x?.description.length && maxLength ? (
                                                                        <>
                                                                            {parse(x?.description.substring(0, maxLength))}
                                                                            <span
                                                                                onClick={() => setIsShow(x?.uuid)}
                                                                                className="pointer text-selected h-100"
                                                                            >
                                                                                {" "}
                                                                                ...more
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <> {x.description} </>
                                                                    )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {parse(x.description || '')}
                                                                    {isLess && (
                                                                        <span
                                                                            onClick={() => setIsShow('')}
                                                                            className="pointer text-selected"
                                                                        >
                                                                            {" "}
                                                                            less
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )
                                                            }

                                                            {/* {x?.description.replace(/<[^>]+>/g,'')} */}
                                                        </Typography>

                                                        {/* <Button onClick={() => navigate(getLink(x, 'popular', currentPage.POPULAR_BLOG, i))} className='rounded-btn mw-77 mh-22 bg-light text-selected fs-10 fw-500 lh-10 mb-20'>read more </Button> */}
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {latestBlogs.length > 0 &&
                        <Grid conatiner className='app-section blog-latest pb-20'>
                            <Grid item >
                                <Swiper
                                    slidesPerView={1}
                                    slidesPerGroup={1}
                                    navigation={true}
                                    modules={[Pagination, Navigation, Autoplay, A11y]}
                                    autoplay={{ delay: 2500 }}
                                    centeredSlides={true}
                                    className='blogs-swiper'
                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                    }}
                                >
                                    {latestBlogs.map((x, i) => {
                                        return <SwiperSlide key={i}>
                                            <Link to={getLink(x, 'latest', currentPage.LATEST_BLOG, i)}>
                                                <Grid sx={{ mb: '20px' }}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} className='latest-blog-box relative' >
                                                        <Box component={'img'}
                                                            className='pointer d-block'
                                                            sx={{
                                                                width: '100%',
                                                                height: '100%',
                                                                maxWidth: { xs: '100%' },
                                                                maxHeight: '500px',
                                                                objectFit: 'cover'
                                                            }}
                                                            src={x?.banner ? helpers.getFullUrl(x?.banner) : helpers.getDefaultImage('banner')}
                                                            alt='banner'
                                                        />
                                                        {/* <div className='overlay-div'>
                                                        <h2 className='fs-18 fw-600 lh-20 m-0 text-light text-center'>{x?.title}</h2>
                                                    </div> */}
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </SwiperSlide>
                                    })}
                                </Swiper>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {!isMobileDevice() ?
                    <Grid className="right-container">
                        <Grid className="right-container-ad">
                            <MyAds type="banner_1" adv_type={'web_trending_top_ad'} />
                        </Grid>
                    </Grid>
                    : ''}
            </Grid>

        </>
    )
}