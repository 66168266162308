import { Grid } from '@mui/material'
import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import PrivateLock from '../../assets/icons/PrivateLock.svg'
import { followUser, unFollowUser } from '../../slices/cast';
import { useDispatch } from 'react-redux';
import helpers from '../../services/helpers';

const PrivateLock = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/PrivateLock.svg";

const style = {
  width: '100%',
  marginTop: '20px',
  p: 4,
  height: '100%',
};


export default function PrivateAccount(props) {
  const dispatch = useDispatch();

  // const handleFollow = (uuid) => {
  //   if (uuid && uuid != '') {
  //     dispatch(followUser({ body: { target_user: uuid } }));
  //   }
  // }

  const handleFollowUnfollowUser = (uuid, type) => {
    if (type === "follow" || type === "follow back") {
      dispatch(followUser({ body: { target_user: uuid } }))
    } else if (type === "unfollow" || type === "following" || type === "requested") {
      dispatch(unFollowUser({ body: { target_user: uuid } }))
    } else if (type === "chat") {

    }
  }

  return (
    <div>
      <Grid container className='new-container'>
        <Grid item sx={style} className=''>
          <Grid container className='jc-center'>
            <Grid item className='bg-light'
              sx={{
                width: { xs: '75px', sm: '80px' },
                height: { xs: '75px', sm: '80px' },
                borderRadius: '50%'
              }}>
              <Grid container className='jc-center ai-center h-100 p-12'>
                <Box component="img"
                  alt=""
                  src={PrivateLock} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='jc-center pt-20 pb-12'>
            <Typography className='fs-28 fw-700 lh-32'>
              private account
            </Typography>
          </Grid>
          <Grid container className='jc-center'>
            <Typography className='fs-20 fw-400 text-muted lh-24 pb-40 text-center'>
              Follow this account to get user views and activiites
            </Typography>
          </Grid>
          <Grid container className='jc-center'>
            <Grid item xs={6} sm={4} className='text-center'>
              <Button onClick={() => handleFollowUnfollowUser(props?.userId, helpers.filterActionName(props?.item))} className='rounded-btn fs-14 bg-selected text-light'>
                {helpers.filterActionName(props?.item)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}



