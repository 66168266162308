import { Avatar, Button, Grid, Modal, TextareaAutosize, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import ShareMovie from "../../common/ShareMovie";
import FollowUnfollowPerson from "../../common/FollowUnfollowPerson";
import { useDispatch, useSelector } from 'react-redux';
import { likeDislikeReview, reportReview, reportReason } from '../../../slices/media';
import helpers, { MOENGAGE_ATTRIBUTE } from '../../../services/helpers';
import { deleteReveiw, removeDeletedReview, updateReview } from '../../../slices/settings';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: { xs: '80vh', sm: '540px' },
    bgcolor: '#16213E',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    zIndex: 999,
};

export default function ReviewActions(props) {
    const { x, isOwner } = props;
    const [currentData, setCurrentData] = useState(x);
    const [editReviewPopUp, setEditReviewPopUp] = useState(false);
    const [comment, setComment] = useState('');
    const [pointsPopUp, setPointsPopUp] = useState(false);
    const [wordCheck, setWordCheck] = useState(1);
    const dispatch = useDispatch();

    const reportReasonData = useSelector(state => state.media.reportReasonData);


    const localData = localStorage.getItem("frenzi_user_access");
    var currUserId = localData && JSON.parse(localData)?.uuid;

    useEffect(() => {
        dispatch(reportReason())
    }, [])

    const handleLikeDIslike = (data) => {
        if (data && data.comment_id) {
            const paylaod = {
                like_status: !data.is_liked,
                comment: data.comment_id
            }
            helpers.trackMoengageEvent('FR3_View_Review', { like: !data.is_liked });
            dispatch(likeDislikeReview({ body: paylaod }));
            setCurrentData({ ...currentData, is_liked: !data.is_liked });
            if (data.is_liked) {
                setLikes(likes + 1)
            } else {
                setLikes(likes - 1)
            }
        }
    }

    const handleDelete = (data) => {
        if (data && data.comment_id) {
            dispatch(deleteReveiw({ id: data.comment_id })).then(() => {
                dispatch(removeDeletedReview(data.comment_id));
            });
        }
    }

    const handleFlag = (data) => {
        if (data && data.comment_id) {
            const paylaod = {
                comment: data.comment_id,
                reason: selectbtn === 'others' ? textAreaValue : selectbtn
            }
            helpers.trackMoengageEvent('FR3_View_Review', { report: MOENGAGE_ATTRIBUTE.YES });
            dispatch(reportReview({ body: paylaod }));
            setCurrentData({ ...currentData, is_reported: !data.is_reported });
        }
    }


    const handleUpdateReview = (data) => {
        if (data && data.comment_id && comment !== '') {
            const body = {
                comment,
            }
            dispatch(updateReview({ id: data.comment_id, body })).then((resp) => {
                setEditReviewPopUp(false);
                if (resp.payload && resp.payload.status) {
                    helpers.toastSuccess(resp.payload?.message);
                    dispatch(removeDeletedReview(data.comment_id));
                } else {
                    helpers.toastError(resp.payload?.message || 'someting went wrong!!!');
                }
            });
        }
    }
    const [likes, setLikes] = useState(null)
    const [is_like, setIs_like] = useState(true)
    const [colorChange, setColorChange] = useState(false)
    // const withoutothers = reportReasonData.slice(0, 7)

    const likesDislikes = () => {
        if (is_like) {
            setLikes(likes + 1)
        } else {
            setLikes(likes - 1)
        }
        setIs_like(!is_like)
        setColorChange(!colorChange)

    }

    const handleOpen = () => {
        if (currentData.is_reported) {
            setUnReport(true)
        } else {
            setOpen(true)
        }
    }

    const handleClose = () => setOpen(false);
    const [open, setOpen] = useState(false);
    const [unReport, setUnReport] = useState(false);
    const [selectbtn, setSelectbtn] = useState("");
    const [showText, setShowText] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState("")


    const handleButtonClick = (x) => {
        if (x === "others") {
            setShowText(true)
        } else {
            setShowText(false)
        }
        setSelectbtn(x)
    };


    console.log('aman aman', currentData)
    return (
        <Grid
            container
            justifyContent={"space-between"}
            className="pt-0 pb-20"
            alignItems={'flex-end'}>

            {currUserId === x.user_id ?
                <Grid item xs={6}>
                    <Grid container justifyContent={"space-between"} alignItems='center'>
                        <Grid item className="rounded-circle pointer" onClick={() => handleLikeDIslike(currentData)}>
                            <ThumbUpAltOutlinedIcon className={`${(currentData.is_liked) ? 'text-selected' : ''}`} />
                        </Grid>
                        <Grid item className="rounded-circle pointer">
                            <ShareMovie hideShare={true} size={24} width={40} height={40} />
                        </Grid>
                    </Grid>
                </Grid>
                :
                <>
                    <Grid item className="rounded-circle pointer" onClick={likesDislikes}>
                        <ThumbUpAltOutlinedIcon className={`${(colorChange) ? 'text-selected' : 'text-light'}`} />
                    </Grid>
                    <Grid item className="rounded-circle pointer" >
                        <OutlinedFlagIcon onClick={handleOpen} className={`${(currentData.is_reported) ? 'text-selected' : 'text-light'}`} />
                    </Grid>
                    <Grid item className="rounded-circle">
                        <ShareMovie hideShare={true} size={24} width={40} height={40} />
                    </Grid>
                    <Grid item className="pb-10">
                        <Typography>{likes} likes</Typography>
                    </Grid>
                    <Grid item className='text-center' >
                        <Link to={`/profile/${x?.user_id}`}>
                            <Grid container justifyContent={'center'} className="text-center">
                                <Avatar sx={{ width: 32, height: 32 }}>
                                    <img style={{ width: 32, height: 32 }} src={x.profile_image ? x.profile_image : helpers.getDefaultImage("profile")} /></Avatar>
                            </Grid>
                            <p className="fs-10 fw-500 text-center" sx={{ color: '#FFFFFF' }}>
                                {x.user_name ? x.user_name : ""}
                            </p>
                        </Link>
                        <Grid item className="text-center">
                            <FollowUnfollowPerson data={{ ...x, uuid: x.user_id, is_followed: x.is_followed_by_me }} />
                        </Grid>
                    </Grid>
                </>
            }
            {isOwner && currUserId === x.user_id &&
                <Grid item xs={12} sx={{ marginBottom: '10px', marginTop: '10px' }}>
                    <Grid container justifyContent={"space-between"} alignItems='center'>
                        <Grid item className="" >
                            <Button onClick={() => handleDelete(x)} className="rounded-btn bg-light text-selected pl-30 pr-30">delete</Button>
                        </Grid>
                        <Grid item className="">
                            <Button onClick={() => {
                                setComment(x.comment);
                                setEditReviewPopUp(!editReviewPopUp);
                            }} className="rounded-btn bg-selected text-light lh-18 pl-30 pr-30">edit review</Button>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Modal
                open={editReviewPopUp}
                onClose={() => setEditReviewPopUp(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item className='mt-10'>
                        <p className='fw-500 fs-24 fw-600 m-0'>update your review</p>
                        <>
                            <Grid container justifyContent={'space-between'} className='mt-20'>
                                <p className='p-l-5 m-0'>{comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length > 0 ? comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length - wordCheck : 0}/150</p>
                                {(50 + wordCheck) - comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length > 0 ?
                                    <>
                                        {comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length - wordCheck > 0 ?
                                            <p className='text-selected m-0 fw-700 fs-12 m-0 text-right'>{(50 + wordCheck) - comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length} words more to bag frenzi points</p>
                                            :
                                            <p className='fw-700 fs-12 m-0'>50 words to bag frenzi points</p>
                                        }
                                    </>
                                    :
                                    <p className='fw-700 text-green fs-12 m-0'>{comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length} words</p>
                                }
                            </Grid>
                            <Grid item className='mt-6'>
                                <Box
                                    sx={{
                                        height: '120px',
                                        maxWidth: '100%',
                                    }}
                                    className='paragraph-container'
                                >
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={3}
                                        maxRows={10}
                                        placeholder={'write a review (minimum 30 words and maximum 150 words)'}
                                        style={{ width: '100%', maxHeight: '100%', height: '100%', borderRadius: '8px', paddingBottom: '20px', padding: 10, color: '#000' }}
                                        value={comment}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            if (val.replace(/^[ ]+|[ ]+$/g, '').split(' ').length <= 150) {
                                                setComment(val)
                                                if (val.length > 0) {
                                                    setWordCheck(0);
                                                } else {
                                                    setWordCheck(1);
                                                }
                                            } else {
                                                helpers.toastWarning("Word limit reached");
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </>

                        <div className='d-flex jc-sb mt-20 pb-10 mt-10' >
                            <Button onClick={() => setEditReviewPopUp(false)} className='rounded-btn mw-240 bg-light text-selected fs-14 fw-500 lh-16 w-40'>cancel</Button>
                            <Button onClick={() => {
                                if (comment.split(" ").length < 50) {
                                    setPointsPopUp(true);
                                } else {
                                    handleUpdateReview(x);
                                }
                            }}
                                disabled={
                                    (comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length < 30) ? true : false
                                }

                                className={`rounded-btn w-40 mw-240 ${comment.replace(/^[ ]+|[ ]+$/g, '').split(" ").length > 30 ? 'bg-selected text-light' : 'bg-pink text-dark'} fs-14 fw-500 lh-16`}
                            >submit</Button>
                        </div>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={pointsPopUp}
                onClose={() => setPointsPopUp(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item>
                        <Grid item>
                            <p className='fw-700 fs-28 m-0'>earn points</p>
                            <p className='fw-400 fs-20 m-0 mt-16'>unlock and earn frenzi points with {50 - comment.split(" ").length} more words</p>
                        </Grid>
                    </Grid>
                    <Grid sx={{ flexWrap: { xs: 'wrap !important', sm: 'nowrap !important' } }} className='d-flex jc-sb mt-20 pb-10 mt-10 w-100' >
                        <Button sx={{ marginRight: { xs: '0px', sm: '10px', md: '20px' } }} onClick={() => setPointsPopUp(false)} className={`rounded-btn w-100 bg-selected text-light fs-14 mt-30 fw-500 lh-16`}>continue</Button>
                        <Button onClick={() => handleUpdateReview(x)} className='rounded-btn bg-light text-selected w-100 mt-30 fs-14 fw-500 lh-16 '>no thanks, submit review</Button>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                sx={{ m: '0px 10px' }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container className='mb-40' rowSpacing={2}>
                        <Grid item xs={12}>
                            <Typography className='fs-24 fw-700 lh-24'>Report an Error</Typography>
                        </Grid>
                        <Grid item >
                            <Typography className='fs-18 fw-700 lh-24'>We are sorry for any inconvenience caused to you</Typography>
                            <Typography className='text-muted fs-13 fw-400 lh-24'>our team is working constantly to Typographyrovide you better service</Typography>
                        </Grid>
                        <Grid item >
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='fs-16 fw-700 lh-24'>report review</Typography>
                            <Typography className='text-muted fs-12 fw-400 lh-24'>let us know why do you want to report this page</Typography>
                        </Grid>
                        {reportReasonData.map((x, i) => {
                            return <> <Grid item className='tab-menu-review' >
                                <Grid container className='newly-tabs-button-review'>
                                    <Button key={i} onClick={() => handleButtonClick(x, i)} className={`${selectbtn === x ? 'selected-button' : ''} fs-14 fw-500 lh-18`}>
                                        {x}
                                    </Button>
                                </Grid>
                            </Grid>
                            </>
                        })}
                        {showText && (
                            <><Grid item xs={12}>
                                <Typography className='fw-700 fs-16 lh-16'>would you like to tell us more in detail?</Typography>
                            </Grid><Grid item xs={12}>
                                    <TextareaAutosize
                                        className='bg-secondary'
                                        aria-label="minimum height"
                                        minRows={5}
                                        style={{ width: '100%', maxHeight: '100%', height: '100%', borderRadius: '8px', paddingBottom: '20px', padding: 10, color: '#FFFFFF' }}
                                        value={textAreaValue}
                                        onChange={(e) => setTextAreaValue(e.target.value)}
                                        placeholder={'//incorrect information was there//'} /></Grid>
                            </>)}

                    </Grid>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button onClick={() => { handleClose(); handleFlag(currentData); }} className='w-70 rounded-btn bg-selected text-light text-center lh-16 fw-500 fs-14'>
                                submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            {/* <Modal
                sx={{ m: '0px 10px' }}
                open={unReport}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
            
            </Modal> */}
        </Grid>
    )
}
