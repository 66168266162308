import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CommonDataService from "../services/common.services";
import helpers from "../services/helpers";

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    mediaSearchResults: {},
    userWatchlistDetail: {},
    friendsListPageData: {},
    friendsListData: [],
};


export const frenziSearch = createAsyncThunk(
    "native_main/frenzisearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.frenziSearch(data.val, data.page, data.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getUserWatchListDetail = createAsyncThunk(
    "native_main/userwatchlistdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.userWatchListDetail(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const createWatchList = createAsyncThunk(
    "native_main/frenziwatchlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.createWatchList(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateWatchList = createAsyncThunk(
    "native_main/watchlistdetail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.updateWatchList(data.id, data.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);


export const getFriendsList = createAsyncThunk(
    "native_main/friendslist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.getFriendsList(data.query, data?.page, data?.pagelimit)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAdData = createAsyncThunk(
    "native_main/advstate",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.getAdData(data?.name)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getReferalLink = createAsyncThunk(
    "native_main/firebaseshortlink",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CommonDataService.getReferalLink(data?.name)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const commonSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        }
    },
    extraReducers: {
        [frenziSearch.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [frenziSearch.fulfilled]: (state, action) => {
            state.mediaSearchResults = getPayloadData(state, action);
        },
        [frenziSearch.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getUserWatchListDetail.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getUserWatchListDetail.fulfilled]: (state, action) => {
            state.userWatchlistDetail = getPayloadData(state, action);
        },
        [getUserWatchListDetail.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // createWatchList
        [createWatchList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [createWatchList.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [createWatchList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // updateWatchList
        [updateWatchList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateWatchList.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateWatchList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getFriendsList
        [getFriendsList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getFriendsList.fulfilled]: (state, action) => {
            state.friendsListPageData = getPayloadData(state, action);
            if (action.payload && action.payload.data && action.payload.data.pagination && action.payload.data.pagination.page == 1) {
                state.friendsListData = getPayloadData(state, action).results ? getPayloadData(state, action).results : [];
            } else {
                state.friendsListData = helpers.removeDuplicate(state.friendsListData.concat(getArrayData(state, action)));
            }
        },
        [getFriendsList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    },
});
export const { clearMessages } = commonSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}


const getArrayData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data && action.payload.data && action.payload.data.results && action.payload.data.results.length > 0 ? action.payload.data.results : [];
}

export default commonSlice.reducer;