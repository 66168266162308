import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MediumTile from "./MediumTile";
import YoutubeModalPlayer from "./YoutubeModalPlayer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation, Pagination } from "swiper";
import NextButtonTile from "./NextButtonTile";
import SectionHeading from "../component/headings/SectionHeading";
import { isMobileDevice } from '../../services/Utils';
import { Box } from "@mui/material";
import SeeAll from "./SeeAll";

const LargeSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/LargeSeeAll.svg";
const SmallSeeAll = process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/SmallSeeAll.svg";


const breakpoints2 = {
  425: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 1.5,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2,
  },
  1440: {
    slidesPerView: 3,
    spaceBetween: 20,
    slidesPerGroup: 2,
  },
  1880: {
    slidesPerView: 4,
    spaceBetween: 30,
    slidesPerGroup: 3,
  },
  2560: {
    slidesPerView: 6,
    spaceBetween: 30,
    slidesPerGroup: 5,
  },
};

const breakpoints3 = {
  425: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 1.25,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 1.50,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  1024: {
    slidesPerView: 1.75,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  1440: {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  1880: {
    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 2,
  },
  2560: {
    slidesPerView: 5,
    spaceBetween: 30,
    slidesPerGroup: 4,
  },
};

export default function CommonMediumTileListing(props) {
  const { heading, withAd, selectedPlatformOttName, isShowHeading, nextLink, data, tileType, isLoading } = props;
  const [controlledSwiper, setControlledSwiper] = useState(null);

  useEffect(() => {
    if (props?.currentSlide === 0 && controlledSwiper) {
      if (
        !controlledSwiper.destroyed &&
        controlledSwiper.slides &&
        controlledSwiper.slides.length
      ) {
        controlledSwiper.slideTo(0);
      }
    }
  }, [props?.currentSlide, controlledSwiper]);

  return (
    <>
      {isShowHeading && (
        <Grid
          container
          alignItems={"center"}
          sx={{ marginTop: { xs: "20px", sm: "30px" } }}
          justifyContent="space-between"
        >
          <Grid item>
            {/* <h2 className="fs-28 fw-700 lh-28 m-0 pb-10 lowercase">
              {heading}
            </h2> */}
            <SectionHeading title={heading} />
          </Grid>
          {nextLink && nextLink !== "" && (
            <Link to={nextLink}>
              {/* <Box component={'img'} src={isMobileDevice() ? SmallSeeAll : LargeSeeAll} sx={{ paddingTop: { xs: '5px', sm: '10px' } }} /> */}
              <SeeAll />
            </Link>
          )}
        </Grid>
      )}
      {tileType === "youtube" ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          speed={1000}
          freeMode={true}
          navigation={true}
          breakpoints={withAd ? breakpoints3 : breakpoints2}
          modules={[FreeMode, Navigation]}
          className="mySwiper"
        >
          {!isLoading && data &&
            data.length > 0 &&
            data.map((x, i) => {
              return (
                <SwiperSlide key={i}>
                  <div
                    style={{ padding: "20px 0px 20px 4px" }}
                    className="scale-1"
                    key={i}
                  >
                    <YoutubeModalPlayer ytkey={x?.youtube_link} />
                  </div>
                </SwiperSlide>
              );
            })}
          {(nextLink && nextLink !== "") ? (
            <SwiperSlide style={{ height: "100%" }} key={"next-btn"} >
              <NextButtonTile data={data} isLoading={isLoading} yt={true} link={nextLink} />
            </SwiperSlide>
          ) : (
            ""
          )}
        </Swiper>
      ) : (
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          slidesPerView={"auto"}
          spaceBetween={10}
          speed={1000}
          navigation={true}
          modules={[Pagination, FreeMode, Navigation]}
          className="mySwiper"
          freeMode={true}
          // onSwiper={setControlledSwiper}
          // onSlideChange={(e) =>
          //   props.setCurrentSlide && props?.setCurrentSlide(e.activeIndex)
          // }
        >

          {!isLoading && data &&
            data.length > 0 &&
            data.map((x, i) => {
              return (
                <SwiperSlide key={i} className="pr-10" style={{ alignSelf: 'center', width: 'auto' }}>
                  <Grid sx={{
                    maxWidth: { xs: 100, sm: 140 },
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: 'inline-block'
                  }}
                  >
                    <MediumTile selectedPlatformOttName={selectedPlatformOttName} x={x} refPage={props?.refPage} />
                  </Grid>
                </SwiperSlide>
              );
            })}


          {(nextLink && nextLink !== "") ? (
            <SwiperSlide className="pr-10" style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
              <Grid className="h-100" sx={{
                maxWidth: { xs: 100, sm: 140 },
                display: 'inline-block'
              }}
              >
                <NextButtonTile data={isLoading ? [] : data} link={nextLink} />
              </Grid>
            </SwiperSlide>
          ) : (
            ""
          )}
        </Swiper>
      )}
    </>
  );
}
